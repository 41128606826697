import { ConfigProject } from "../../../Helper/ConfigProject";
import { LoadingIcon } from "../../../assets/icons/LoadingIcon";
import { Text } from "../Text/Text";
import { TouchableHighlight } from "../Touch/TouchableHighlight";
import { View } from "../View/View"

export const ButtonUI = ({
    style = {},
    text = "",
    onHandle = false,
    btn = true,
    btnLoad = false,
    boxShadow = false
}) => {

    let btnStyle = "componentBtn_Btn"

    if (btnLoad || !btn) {
        btnStyle += " componentBtn_BtnDisable"
    }

    if (boxShadow) {
        btnStyle += " box-shadow-default"
    }
    
    return (
        <TouchableHighlight
            style={style}
            // className={btn && !btnLoad ? "componentBtn_Btn" : "componentBtn_Btn componentBtn_BtnDisable"}
            className={btnStyle}
            onPress={() => {
                if (onHandle && (btn && !btnLoad)) {
                    onHandle()
                }
            }}
        >
            {
                btnLoad ?
                    <View className="componentBtn_ContainerLoader">
                        <LoadingIcon color={"#fff"} width={"20px"} height={"20px"} />
                    </View>
                : null
            }
            <Text>{text}</Text>
        </TouchableHighlight>
    )
}