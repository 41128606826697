import { useEffect, useState } from "react"
import { AppLayoutIndex } from "../Components/Header/AppLayoutIndex"
import { View } from "../Components/ui/View/View"
import DatePicker, { registerLocale } from "react-datepicker"
import { Text } from "../Components/ui/Text/Text"
import "react-datepicker/dist/react-datepicker.css";
import { ButtonUI } from "../Components/ui/Buttons/ButtonUI"
import { Map } from "../Components/Map/Map"
import { useNavigate } from "react-router-dom"
import { TouchableHighlight } from "../Components/ui/Touch/TouchableHighlight"
import { useRootStore } from "../core/hooks/useRootStore"
import { observer } from "mobx-react-lite"
import {DotLoader} from "react-spinners";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

const colors = ["#B2ADAD", "#00BE08", "#FEB7B7", "#E41414"]

export const MapRoute = observer(() => {
    const navigate = useNavigate();
    const {
        statisticStore
    } = useRootStore();

    const [activeTab, setActiveTab] = useState(-1)
    const [activeTitle, setActiveTitle] = useState(null)
    const listTabs = statisticStore.category

    useEffect(() => {
        handleTabFromList(1)
    }, [])

    const getFormattName = (text) => {
        let eSubName = text.split('.')
        delete eSubName[0]
        eSubName = eSubName.join('')

        return eSubName
    }

    const handleProfile = () => {
        navigate("/profile");
    }

    const handleTab = (index) => {
        setActiveTab(index === activeTab ? -1 : index)
    }

    const handleTabFromList = async (index) => {
        setActiveTab(-1)
        setActiveTitle(null)
        let activeTitle = false

        listTabs.forEach((el, key) => {
            el?.vars?.forEach((eSub, kSub) => {
                if (eSub?.id === index) {
                    activeTitle = eSub
                }
            })
        })

        await statisticStore.getStatisticMap({
            tab: activeTitle?.id
        })

        setActiveTitle(activeTitle)
    }

    const handleDatePicker = (date, type) => {
        switch (type) {
            case 'start':
                statisticStore.setMapPeriodStart(date.getTime())
                break;
        
            default:
                statisticStore.setMapPeriodEnd(date.getTime())
                break;
        }

        statisticStore.getStatisticMap()
    }

    return (
        <AppLayoutIndex>
            <View style={{padding: '25px', paddingBottom: '9px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <View style={{display: 'flex', flex: 1, width: '100%'}}>
                    <View style={{flex: 1, alignContent: 'center'}}>
                        <View style={{display: 'flex', alignItems: 'center'}}>
                            <Text style={{marginRight: 8}}>Период:</Text>
                            <View style={{display: 'flex'}}>
                                <View style={{marginRight: 8}}>
                                    <DatePicker
                                        showIcon
                                        selected={statisticStore.mapPeriodStart}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={async (date) => handleDatePicker(date, 'start')}
                                        style={{marginRight: 8}}
                                        locale="ru"
                                    />
                                </View>
                                <DatePicker
                                    showIcon
                                    selected={statisticStore.mapPeriodEnd}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={async (date) => handleDatePicker(date, 'end')}
                                    locale="ru"
                                />
                            </View>
                        </View>
                    </View>
                    <View style={{flex: 1, margin: '0px 16px', alignContent: 'center', textAlign: 'center'}}>
                        <Text>Система мониторинга за инфекционными стационарами (отделениями) в субъектах Российской Федерации</Text>
                    </View>
                    <View style={{flex: 1, display: 'flex', justifyContent: 'end', alignContent: 'center'}}>
                        <ButtonUI
                            text={"Профиль"}
                            onHandle={handleProfile}
                        />
                    </View>
                </View>
                <View style={{display: 'flex', flex: 1, width: '100%', marginTop: 16}}>
                    {
                        listTabs?.map((el, key) => {
                            return (
                                <View
                                key={`${key}-tab_header`}
                                style={{
                                    margin: '0 5px',
                                    display: 'flex',
                                    flex: 1,
                                    position: 'relative'
                                }}>
                                    <ButtonUI
                                        text={el?.name}
                                        onHandle={() => handleTab(key)}
                                        style={{
                                            backgroundColor: "#D9D9D9",
                                            borderRadius: 17,
                                            borderWidth: 0,
                                            color: "#000",
                                            fontSize: 10,
                                            flex: 1,
                                            height: 59,
                                            lineHeight: 1
                                        }}
                                    />
                                    <View className="subListTabs" style={{display: activeTab === key ? "block" : "none"}}>
                                        {
                                            el?.vars.map((eSub, kSub) => {
                                                return (
                                                    <TouchableHighlight
                                                        key={kSub}
                                                        className="subListTabsEl"
                                                        style={{borderBottomWidth: kSub === el?.vars?.length-1 ? 0 : 1}}
                                                        onPress={() => handleTabFromList(eSub?.id)}
                                                    >
                                                        <Text style={{fontSize: 13}}>{eSub?.id}. {getFormattName(eSub?.name||"")}</Text>
                                                    </TouchableHighlight>
                                                )
                                            })
                                        }
                                    </View>
                                </View>
                            )
                        })
                    }
                </View>
                <View style={{textAlign: 'center', backgroundColor: "#FFA37B", borderRadius: 17, padding: '9px 16px', display: 'inline-block', marginTop: 16}}>
                    <Text style={{fontWeight: '400',fontSize: '13px'}}>
                        {`${activeTitle?.id||"0"}. ${getFormattName(activeTitle?.name||"")}`}
                    </Text>
                </View>
            </View>
            <View style={{width: '100vw',height: '80vh'}}>
                {
                    !statisticStore.statisticMapLoader ?
                        <Map data={statisticStore.statisticMap} />
                    :
                    <View style={{width: '100%', height: '100%', background: 'rgba(0,0,0,0.1)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <DotLoader
                            color={"rgb(200, 155, 155)"}
                            loading={true}
                            cssOverride={override}
                            size={100}
                            aria-label="Loading..."
                            data-testid="loader"
                            speedMultiplier={1}
                        />
                    </View>
                }
            </View>
        </AppLayoutIndex>
    )
})

const override = {
    display: "block",
    margin: "0 auto",
};