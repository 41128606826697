import React from 'react';
import { AuthStore } from './modules/auth/AuthStore';
import { UserStore } from './modules/user/UserStore';
import { RegionStore } from './modules/region/RegionStore';
import { StatisticStore } from './modules/statistic/StatisticStore';

class RootStore {
  authStore: AuthStore;
  userStore: UserStore;
  regionStore: RegionStore;
  statisticStore: StatisticStore;

  constructor() {
    this.authStore = new AuthStore();
    this.userStore = new UserStore();
    this.regionStore = new RegionStore();
    this.statisticStore = new StatisticStore();
  }

  sync = async () => {
    await Promise.all(
      Object.values(this).map(store => {
        return store?.sync ? store?.sync() : Promise.resolve();
      }),
    );
  };
}

export let rootStore = new RootStore();

export const storesContext = React.createContext(rootStore);

export const resetStores = () => {
  rootStore = new RootStore();
};
