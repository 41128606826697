import FormValidationHelper from '../../../helpers/FormValidationHelper';

export const RegistrationForm: IRegistrationForm = {
  email: '',
  password: '',
  type_account: 1,
  region: 1,

  isFormValid: (form: IRegistrationForm) => {
    return FormValidationHelper.isRequired(form.email) && FormValidationHelper.isRequired(form.password);
  },
};

export interface IRegistrationForm {
  email: string;
  password: string;
  type_account: number;
  region: number,

  isFormValid: (form: IRegistrationForm) => boolean;
}

export enum RegistrationFormFormFields {
  email = 'email',
  password = 'password',
  type_account = 'type_account',
  region = 'region'
}
