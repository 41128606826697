import { View } from "../ui/View/View"
import { Footer } from "./Footer"
import { MenuHeader } from "./MenuHeader"



export const AppLayoutIndex = (props) => {

    return (
        <View>
            <View>
                <View className="containerContentBody">{props.children}</View>
            </View>
            <View>
                <Footer />
            </View>
        </View>
    )
}