import React, { useEffect, useState } from "react"
import { View } from "../Components/ui/View/View"
import { AppLayout } from "../Components/Header/AppLayout"
import { useRootStore } from "../core/hooks/useRootStore";
import { TableUI } from "../Components/ui/Table/TableUI";
import { observer } from "mobx-react-lite";
import { ButtonUI } from "../Components/ui/Buttons/ButtonUI";
import { Text } from "../Components/ui/Text/Text";
import { InputUI } from "../Components/ui/Input/InputUI";
import { SelectInputUI } from "../Components/ui/Input/SelectInputUI";

export const UsersRoute = observer(() => {
    const { userStore, authStore, regionStore } = useRootStore();
    const [createUserModal, setCreateUserModal] = useState(false)

    useEffect(() => {
        userStore.getUserAll()
        regionStore.getRegionAll()
    }, [])
    
    
    const getRegionsFormatt = () => {
        let res = []

        regionStore.regionAll.forEach((el, key) => {
            res[key] = {
                id: el?.id,
                value: el?.name
            }
        })

        return res
    }

    const handleNewUser = () => {
        setCreateUserModal(true)
    }

    const onCloseModalNewUser = () => {
        setCreateUserModal(false)
    }

    const onChangeInputNewUser = (text, name) => {
        console.log("text", text)
        console.log("name", name)
        authStore.changeRegistrationForm(name, text)
        console.log("registrationForm", authStore.registrationForm)
    }

    const onSaveNewUser = async () => {
        const res = await authStore.registration()

        if (res) {
            onCloseModalNewUser()
            userStore.getUserAll()
            alert("Пользователь создан")
        }
    }

    return (
        <AppLayout
            title={"Пользователи"}
            tabMenuUsers={false}
            tabMenuProfile={true}
        >
            <View className="container" style={{padding: '16px 16px'}}>
                <View style={{width: '40%', margin: '0 auto', marginBottom: '30px'}}>
                    <ButtonUI
                        onHandle={handleNewUser}
                        text="Добавить нового пользователя"
                    />
                </View>
                <View className='modal' style={{display: createUserModal ? 'block' : 'none'}}>
                    <View className="modal_container">
                        <View className='modal_header'>
                            <Text className='modal_header_text'>Создать пользователя</Text>
                        </View>
                        <View className='modal_content'>
                            <InputUI
                                label="E-mail"
                                value={authStore.registrationForm.email}
                                name={"email"}
                                onChange={onChangeInputNewUser}
                                style={{marginBottom: 8}}
                            />
                            <InputUI
                                label="Пароль"
                                value={authStore.registrationForm.password}
                                name={"password"}
                                onChange={onChangeInputNewUser}
                                type={'password'}
                                style={{marginBottom: 8}}
                            />
                            <SelectInputUI
                                label="Тип пользователя"
                                value={authStore.registrationForm.type_account}
                                name={"type_account"}
                                onChange={onChangeInputNewUser}
                                style={{marginBottom: 8}}
                                list={[
                                    {
                                        id: 1,
                                        value: "Администратор"
                                    },
                                    {
                                        id: 2,
                                        value: "Наблюдатель (Министр)"
                                    },
                                    {
                                        id: 3,
                                        value: "Регион"
                                    },
                                ]}
                            />
                            <SelectInputUI
                                label="Доступ к региону"
                                value={authStore.registrationForm.region}
                                name={"region"}
                                onChange={onChangeInputNewUser}
                                list={getRegionsFormatt()}
                            />
                        </View>
                        <View className='modal_footer'>
                            <ButtonUI
                                onHandle={onSaveNewUser}
                                text="Создать"
                                style={{backgroundColor: '#198754', borderColor: "#198754"}}
                            />
                            <ButtonUI
                                onHandle={onCloseModalNewUser}
                                text="Закрыть"
                            />
                        </View>
                    </View>
                </View>
                <TableUI data={userStore.userAll} />
            </View>
        </AppLayout>
    )
})