import React, { useState } from "react"
import { View } from "../Components/ui/View/View"
import { InputUI } from "../Components/ui/Input/InputUI"
import { Text } from "../Components/ui/Text/Text"
import { ButtonUI } from "../Components/ui/Buttons/ButtonUI"
import { useRootStore } from "../core/hooks/useRootStore"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

export const LoginRoute = observer(() => {
    const navigate = useNavigate();
    
    const {
        authStore
    } = useRootStore();

    const onChangeInput = (text, name) => {
        authStore.changeLoginForm(name, text)
    }

    const handleLogin = async () => {
        const login = await authStore.login()

        if (login === true) {
            navigate("/");
        }
    }

    return (
        <View className="container containerCenter">
            <View style={{
                backgroundColor: 'rgb(200, 155, 155)',
                borderRadius: 16,
                padding: '8px 16px',
                alignItems: 'center'
            }}>
                <View style={{textAlign: 'center', marginBottom: 16}}>
                    <Text>Авторизация</Text>
                </View>
                <View style={{marginBottom: 16}}>
                    <InputUI
                        label="E-mail"
                        value={authStore.loginForm.email}
                        name={"email"}
                        onChange={onChangeInput}
                        style={{marginBottom: 8}}
                    />
                    <InputUI
                        label="Пароль"
                        value={authStore.loginForm.password}
                        name={"password"}
                        onChange={onChangeInput}
                        type={'password'}
                    />
                </View>
                <View>
                    <ButtonUI
                        text={"Войти"}
                        onHandle={handleLogin}
                    />
                </View>
            </View>
        </View>
    )
})