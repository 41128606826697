import { MaskListener } from "../../../Helper/MaskHelper"
import { View } from "../View/View"

export const SelectInputUI = ({
    styleInput = {},
    style = {},
    value = "",
    label = "",
    name = "",
    onChange = false,
    Error = "",
    list = [],
    multi = false
}) => {

    return (
        <>
        <View style={{...style}}>
            {
                label ?
                    <label style={{color: "#000"}}>{label}</label>
                : null
            }
            <select
                className="component_InputUI_input"
                style={styleInput}
                value={value}
                onChange={e => onChange(parseInt(e.target.value), name)}
                multiple={multi}
            >
                {
                    list.map((el, key) => {
                        return (
                            <option value={el?.id}>{el?.value}</option>
                        )
                    })
                }
            </select>
        </View>
        {
            Error ?
            <View className="component_InputUI_ErrorBlock">
                {Error}
            </View> : null
        }
        </>
    )
}