import { makeAutoObservable } from 'mobx';

import RegionService from './RegionService';
import { Region } from './models/Region';

export class RegionStore {
  regionAllLoader: boolean = false;

  regionAll: Region[] = [];

  private regionService: RegionService;

  constructor() {
    makeAutoObservable(this);

    this.regionService = new RegionService();
  }

  // FORMS

  // API
  getRegionAll = async () => {
    if (this.regionAllLoader) {
      return;
    }
    this.setLoadingRegionAll(true);

    try {
      const res = await this.regionService.getRegionAll()
      if (res?.length) {
        this.setRegionAll(res)

        return true;
      }
    } catch (error) {
      console.log("getRegionAll -> error", error)
    } finally {
      this.setLoadingRegionAll(false)
    }

    return false
  };

  //OTHERS

  // SETTERS

  setLoadingRegionAll = (value: boolean) => {
    this.regionAllLoader = value;
  };

  setRegionAll = (value: Region[]) => {
    this.regionAll = value;
  };
}
