import AbstractApiRepository from '../../base/api/AbstractApiRepository';
import LoginDto from './dto/LoginDto';
import RegistrationDto from './dto/RegistrationDto';

export default class AuthApiRepository extends AbstractApiRepository {
  login = (data: LoginDto) => {
    return this.apiClient.post({
      url: `/user/login`,
      data,
    });
  };

  registration = (data: RegistrationDto) => {
    return this.apiClient.post({
      url: `/user/registration`,
      data,
    });
  };

  isAuth = () => {
    return this.apiClient.get({
      url: `/user/is_auth`
    });
  };

  logout = () => {
    return this.apiClient.post({
      url: `/auth/logout`,
    });
  };
}
