import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { SentryLink } from 'apollo-link-sentry';

import { appConfig } from '../../../appConfig';
import Log from '../../log/Log';
import Notification from '../../ui/Notification';
import { ErrorType } from '../ApiClientTypes';
import IApiClient from '../IApiClient';

export default class GraphqlClient implements IApiClient {
  static readonly UN_AUTH = 'Not Authorised!';

  api!: ApolloClient<NormalizedCacheObject>;

  private uri!: string;
  private headers: any;

  constructor() {
    (async () => {
      await this.init();
    })();
  }

  init = async () => {
    this.uri = this.getDefaultBaseUrl() + '/graphql';

    this.setDefaultHeaders();
    await this.setDeviceId();

    Log.i('graphql request headers', this.headers);

    this.createClient();
  };

  setDefaultHeaders = () => {
    this.headers = {
      // 'App-Platform': Platform.OS,
      // 'App-Version': appConfig.version,
    };
  };

  getDefaultBaseUrl = () => {
    return appConfig.apiUrl.find(item => item.default)?.baseUrl;
  };

  setDeviceId = async () => {
    // this.headers['App-DeviceId'] = await appConfig.deviceId;
  };

  setBaseUrl = (value: string) => {
    this.uri = value;
    this.createClient();
  };

  setAccessToken = (token: string) => {
    this.headers['Authorization'] = token;
    this.createClient();

    Log.i('setAccessToken', this.headers, { token });
  };

  setLanguage = async (language: any) => {
    // this.headers['Request-Language'] = language;
    this.createClient();
  };

  clearAccessToken = () => {
    this.headers['Authorization'] = null;
    this.createClient();
  };

  query = <T extends {}>(data: any) => {
    return this.api
      .query<T>(data)
      .then(res => {
        // Log.i('QUERY: res', res, 'data', data);

        if (res.errors?.length) {
          this.handleError(res.errors[0], data);
        }

        return res;
      })
      .catch(err => {
        this.handleError(err, data);
      });
  };

  mutate = <T extends {}>(data: any) => {
    return this.api
      .mutate<T>(data)
      .then(res => {
        // Log.i('MUTATE: res', res, 'data', data);

        if (res.errors?.length) {
          this.handleError(res.errors[0], data);
        }

        return res;
      })
      .catch(err => {
        this.handleError(err, data);
      });
  };

  delete<T>(config: any): any {
    throw new Error('Unsupported method. Please, use Graphql queries or mutations');
  }

  get<T>(config: any): any {
    throw new Error('Unsupported method. Please, use Graphql queries or mutations');
  }

  post<T>(config: any): any {
    throw new Error('Unsupported method. Please, use Graphql queries or mutations');
  }

  put<T>(config: any): any {
    throw new Error('Unsupported method. Please, use Graphql queries or mutations');
  }

  private createClient = () => {
    this.api = new ApolloClient({
      uri: this.uri,
      cache: new InMemoryCache(),
      headers: this.headers,
      link: ApolloLink.from([
        new SentryLink({
          uri: this.uri,
          attachBreadcrumbs: { includeFetchResult: true, includeError: true },
        }),
        new HttpLink({
          uri: this.uri,
          headers: this.headers,
        }),
      ]),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
      },
    });
  };

  private handleError = (err: any, data: any) => {
    const isInGameError = err.graphQLErrors[0]?.extensions?.type === ErrorType.inGame;

    Log.e(err.message, 'DATA: ', data);

    if (isInGameError) {
      // ModalController.showModal(ModalType.InGameError, err.message);
    } else {
      Notification.showError(err.message);
    }

    throw new Error(err);
  };
}
