import AbstractApiRepository from '../../base/api/AbstractApiRepository';
import FileExportDto from './dto/FileExportDto';
import FileImportDto from './dto/FileImportDto';
import GetStatisticDto from './dto/GetStatisticDto';

export default class StatisticApiRepository extends AbstractApiRepository {

  getCategory = () => {
    return this.apiClient.get({
      url: `/statistic/category`,
    });
  };

  getStatisticMap = (data: GetStatisticDto) => {
    return this.apiClient.get({
      url: `/statistic/me`,
      data
    });
  };

  fileExport = (data: FileExportDto) => {
    return this.apiClient.post({
      url: `/file/export`,
      data
    });
  };

  fileImport = (data: any) => {
    return this.apiClient.post({
      url: `/file/upload`,
      data
    });
  };
}
