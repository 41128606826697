export const LoadingIcon = ({
    width = 22,
    height = 22,
    color = "#FFF"
}) => {
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke={color} strokeWidth="8" r="43" strokeDasharray="202.63272615654165 69.54424205218055">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.0204081632653061s" values="0 50 50;360 50 50" keyTimes="0;1"/>
        </circle>
        </svg>
    )
}