export const packRegions: any = {
  "Алтайский край": {
    "0": [
      [
        54.469437,
        85.079468
      ],
      [
        54.195824,
        85.44720500000001
      ],
      [
        54.243050000000004,
        85.60470500000001
      ],
      [
        53.984161,
        86.145537
      ],
      [
        53.532495,
        86.57665800000001
      ],
      [
        53.509439,
        87.00610100000002
      ],
      [
        53.297776,
        86.86970200000002
      ],
      [
        53.246705,
        86.66035300000001
      ],
      [
        53.081385,
        87.08166200000001
      ],
      [
        52.853609,
        86.93304200000001
      ],
      [
        52.581683,
        87.20503800000002
      ],
      [
        52.614162,
        86.79331900000001
      ],
      [
        52.374164,
        86.61109800000001
      ],
      [
        52.285835,
        86.81887700000001
      ],
      [
        52.105836,
        86.76191600000001
      ],
      [
        52.063053999999994,
        85.88025700000001
      ],
      [
        51.72948699999999,
        85.74533200000002
      ],
      [
        51.70277599999999,
        85.53579800000001
      ],
      [
        51.564997999999996,
        85.46832400000001
      ],
      [
        51.113617,
        84.13218900000001
      ],
      [
        51.137218999999995,
        84.00248900000001
      ],
      [
        50.94582799999999,
        84.526368
      ],
      [
        50.69054899999999,
        84.542764
      ],
      [
        50.62944899999999,
        84.083071
      ],
      [
        50.87470399999999,
        83.832308
      ],
      [
        51.018036999999985,
        83.42827799999999
      ],
      [
        51.01845299999999,
        83.11164299999999
      ],
      [
        50.93417599999999,
        82.73922199999998
      ],
      [
        50.745482999999986,
        82.49722499999999
      ],
      [
        50.761453999999986,
        81.45180699999999
      ],
      [
        50.98234399999998,
        81.40381199999999
      ],
      [
        50.94542599999998,
        81.07430699999999
      ],
      [
        51.20681699999999,
        81.13722499999999
      ],
      [
        51.30814999999998,
        80.68242299999999
      ],
      [
        51.20584299999998,
        80.44089099999998
      ],
      [
        50.97445499999998,
        80.46883799999998
      ],
      [
        50.84948299999998,
        80.06956199999998
      ],
      [
        50.757095999999976,
        80.07870199999998
      ],
      [
        52.031481999999976,
        79.09441999999999
      ],
      [
        53.348096999999974,
        77.80222199999999
      ],
      [
        53.46000099999998,
        78.05998799999999
      ],
      [
        53.578615999999975,
        77.93305099999999
      ],
      [
        53.61250499999998,
        78.20943399999999
      ],
      [
        53.488611999999975,
        78.394995
      ],
      [
        53.525278999999976,
        78.63081899999999
      ],
      [
        53.65610699999998,
        78.665821
      ],
      [
        53.71527999999998,
        79.521655
      ],
      [
        54.08036799999998,
        80.139269
      ],
      [
        54.25990299999998,
        80.845934
      ],
      [
        54.39621699999998,
        80.924913
      ],
      [
        54.13308799999998,
        81.122927
      ],
      [
        54.02277499999998,
        81.585267
      ],
      [
        53.928612999999984,
        81.555543
      ],
      [
        53.97138299999998,
        81.857483
      ],
      [
        53.84888599999998,
        81.718323
      ],
      [
        53.70777999999998,
        81.832489
      ],
      [
        53.70257699999998,
        82.204833
      ],
      [
        53.60083999999998,
        82.171921
      ],
      [
        53.466296999999976,
        82.48236
      ],
      [
        53.55527799999997,
        82.531371
      ],
      [
        53.796113999999974,
        82.73748699999999
      ],
      [
        54.06972699999997,
        83.19581399999998
      ],
      [
        53.94055399999997,
        83.40832199999998
      ],
      [
        54.13221899999997,
        83.57638199999998
      ],
      [
        54.08833599999997,
        83.90636899999998
      ],
      [
        54.13916899999997,
        84.45443399999998
      ],
      [
        54.456664999999965,
        85.04609399999998
      ]
    ]
  },
  "Республика Алтай": {
    "0": [
      [
        52.58168,
        87.20504
      ],
      [
        52.447488,
        87.641662
      ],
      [
        52.549164,
        87.922484
      ],
      [
        52.451386,
        88.428039
      ],
      [
        52.132212,
        88.350815
      ],
      [
        52.084437,
        88.089981
      ],
      [
        51.816386,
        87.84165999999999
      ],
      [
        51.756664,
        88.10775799999999
      ],
      [
        51.549442,
        87.86164899999999
      ],
      [
        51.318891,
        88.36442499999998
      ],
      [
        51.336109,
        88.55358699999998
      ],
      [
        51.559482,
        88.73585199999998
      ],
      [
        51.430827,
        88.96775399999999
      ],
      [
        51.051388,
        89.06275499999998
      ],
      [
        50.500005,
        89.86664899999998
      ],
      [
        50.383611,
        89.64304799999998
      ],
      [
        50.470272,
        89.50582699999998
      ],
      [
        50.209439,
        89.32971099999999
      ],
      [
        50.204717,
        89.51609599999999
      ],
      [
        49.722227000000004,
        89.69991099999999
      ],
      [
        49.653533,
        89.23997299999999
      ],
      [
        49.477120000000006,
        88.99171999999999
      ],
      [
        49.55581300000001,
        88.89111199999999
      ],
      [
        49.45017300000001,
        88.86327999999999
      ],
      [
        49.489869000000006,
        88.223221
      ],
      [
        49.374452000000005,
        88.096947
      ],
      [
        49.25406400000001,
        88.148865
      ],
      [
        49.084399000000005,
        87.377197
      ],
      [
        49.297426,
        86.952333
      ],
      [
        49.544176,
        86.80186599999999
      ],
      [
        49.581673,
        86.63416399999998
      ],
      [
        49.803172,
        86.80322399999999
      ],
      [
        49.803450000000005,
        86.61383299999999
      ],
      [
        49.461172000000005,
        86.16928299999998
      ],
      [
        49.58970000000001,
        85.27827699999997
      ],
      [
        49.92081100000001,
        84.98102899999998
      ],
      [
        50.05608800000001,
        84.99802699999998
      ],
      [
        50.29169800000001,
        84.24133699999997
      ],
      [
        50.51536400000001,
        84.23322799999997
      ],
      [
        50.629441000000014,
        84.08307399999997
      ],
      [
        50.69054100000002,
        84.54276699999997
      ],
      [
        50.83638000000002,
        84.60971399999997
      ],
      [
        51.13721100000002,
        84.00248999999997
      ],
      [
        51.113609000000025,
        84.13218999999997
      ],
      [
        51.56499000000002,
        85.46832499999996
      ],
      [
        51.70276800000002,
        85.53579899999997
      ],
      [
        51.72947900000002,
        85.74533299999997
      ],
      [
        52.06304600000002,
        85.88025799999997
      ],
      [
        52.105828000000024,
        86.76191699999997
      ],
      [
        52.285827000000026,
        86.81887799999997
      ],
      [
        52.37415600000003,
        86.61109899999997
      ],
      [
        52.61415400000003,
        86.79331999999997
      ],
      [
        52.58193500000003,
        87.19692899999997
      ]
    ]
  },
  "Курская область": {
    "0": [
      [
        51.845135,
        34.382568
      ],
      [
        51.933875,
        34.331939999999996
      ],
      [
        52.003324,
        34.391104999999996
      ],
      [
        52.074162,
        34.688596999999994
      ],
      [
        52.204434,
        34.676382
      ],
      [
        52.198598,
        34.840824999999995
      ],
      [
        52.353322,
        34.896938999999996
      ],
      [
        52.433057,
        35.553310999999994
      ],
      [
        52.248045,
        35.62665299999999
      ],
      [
        52.368320999999995,
        36.35138399999999
      ],
      [
        52.115269999999995,
        36.827212999999986
      ],
      [
        51.925824,
        37.38971199999999
      ],
      [
        52.015546,
        37.51110199999999
      ],
      [
        52.018323,
        37.75804199999999
      ],
      [
        51.883879,
        37.88471299999999
      ],
      [
        51.961087,
        38.298866999999994
      ],
      [
        51.914152,
        38.42165299999999
      ],
      [
        51.735823,
        38.50776499999999
      ],
      [
        51.624708000000005,
        38.13776299999999
      ],
      [
        51.370268,
        38.35498599999999
      ],
      [
        51.292571,
        38.29164599999999
      ],
      [
        51.422766,
        38.047208999999995
      ],
      [
        51.388328,
        37.369977999999996
      ],
      [
        51.345435,
        37.138197
      ],
      [
        51.076941000000005,
        36.617202
      ],
      [
        51.160544,
        35.979706
      ],
      [
        51.053878000000005,
        36.006652
      ],
      [
        50.904715,
        35.553299
      ],
      [
        50.960112,
        35.350055000000005
      ],
      [
        51.060468,
        35.41615900000001
      ],
      [
        51.081971,
        35.167381000000006
      ],
      [
        51.221859,
        35.12802200000001
      ],
      [
        51.255581,
        34.273576000000006
      ],
      [
        51.365912,
        34.338049000000005
      ],
      [
        51.505107,
        34.30230100000001
      ],
      [
        51.663382000000006,
        34.091635000000004
      ],
      [
        51.727244000000006,
        34.438688000000006
      ],
      [
        51.84508,
        34.38277300000001
      ]
    ]
  },
  "Тульская область": {
    "0": [
      [
        54.835854,
        37.259361
      ],
      [
        54.713609,
        37.671379
      ],
      [
        54.830093999999995,
        37.938309000000004
      ],
      [
        54.594711999999994,
        38.519439000000006
      ],
      [
        54.47137599999999,
        38.387772000000005
      ],
      [
        54.178048999999994,
        38.752219000000004
      ],
      [
        53.933603999999995,
        38.846938
      ],
      [
        53.771381,
        38.799995
      ],
      [
        53.725550999999996,
        38.924995
      ],
      [
        53.56538,
        38.895486000000005
      ],
      [
        53.509167999999995,
        38.697489000000004
      ],
      [
        53.374165999999995,
        38.758051
      ],
      [
        53.31221599999999,
        38.478046
      ],
      [
        53.05000199999999,
        38.608607
      ],
      [
        53.008551999999995,
        37.841091
      ],
      [
        53.136663999999996,
        37.583059
      ],
      [
        53.296108999999994,
        37.566672
      ],
      [
        53.297498,
        36.800002
      ],
      [
        53.582218999999995,
        36.014168
      ],
      [
        53.703053999999995,
        36.064446
      ],
      [
        53.849720999999995,
        35.903886
      ],
      [
        54.069996999999994,
        36.217218
      ],
      [
        54.186657999999994,
        36.0225
      ],
      [
        54.28777699999999,
        36.455559
      ],
      [
        54.29361399999999,
        36.926109000000004
      ],
      [
        54.60666299999999,
        36.801667
      ],
      [
        54.53277199999999,
        37.068893
      ],
      [
        54.83355299999999,
        37.258795
      ]
    ]
  },
  "Пермский край": {
    "0": [
      [
        60.8599,
        54.9799
      ],
      [
        61.0112,
        55.0504
      ],
      [
        60.9947,
        55.200399000000004
      ],
      [
        61.1484,
        55.272598
      ],
      [
        61.095601,
        55.733397000000004
      ],
      [
        61.249101,
        55.819997
      ],
      [
        61.193001,
        56.279197
      ],
      [
        61.444401,
        56.376497
      ],
      [
        61.421002,
        56.563897
      ],
      [
        61.494401,
        56.595197
      ],
      [
        61.489801,
        56.637296
      ],
      [
        61.5254,
        56.649995
      ],
      [
        61.476299999999995,
        57.080295
      ],
      [
        61.5023,
        57.091594
      ],
      [
        61.4961,
        58.844793
      ],
      [
        61.518699,
        58.845493000000005
      ],
      [
        61.537498,
        59.047292000000006
      ],
      [
        61.630698,
        59.15459200000001
      ],
      [
        61.662298,
        59.34849100000001
      ],
      [
        61.611098,
        59.40999100000001
      ],
      [
        61.535399,
        59.402192000000014
      ],
      [
        61.510898999999995,
        59.44499200000001
      ],
      [
        61.463798999999995,
        59.39939200000001
      ],
      [
        61.41609999999999,
        59.442892000000015
      ],
      [
        61.31249999999999,
        59.25319200000001
      ],
      [
        61.25899999999999,
        59.25129300000001
      ],
      [
        61.099799999999995,
        59.347493000000014
      ],
      [
        60.973400999999996,
        59.363092000000016
      ],
      [
        60.940200999999995,
        59.47849100000001
      ],
      [
        60.831602,
        59.48419100000001
      ],
      [
        60.750102,
        59.42389200000001
      ],
      [
        60.635701999999995,
        59.43009100000001
      ],
      [
        60.523402999999995,
        59.35689200000001
      ],
      [
        60.46800399999999,
        59.24139300000001
      ],
      [
        60.334303999999996,
        59.14079300000001
      ],
      [
        60.310204999999996,
        59.18049300000001
      ],
      [
        60.214405,
        59.14389400000001
      ],
      [
        60.016505,
        59.02999400000001
      ],
      [
        59.979706,
        58.96319400000001
      ],
      [
        59.905406,
        58.99069400000001
      ],
      [
        59.930905,
        58.84159500000001
      ],
      [
        59.843905,
        58.80069600000001
      ],
      [
        59.855105,
        58.69589700000001
      ],
      [
        59.692906,
        58.60399800000001
      ],
      [
        59.712906000000004,
        58.47969800000001
      ],
      [
        59.465306000000005,
        58.36659800000001
      ],
      [
        59.436806000000004,
        58.52429700000001
      ],
      [
        59.298807000000004,
        58.622996000000015
      ],
      [
        59.269108,
        58.681395000000016
      ],
      [
        59.235709,
        58.96919500000001
      ],
      [
        59.146609,
        59.19719400000001
      ],
      [
        59.000309,
        59.09119400000001
      ],
      [
        58.954709,
        59.20429400000001
      ],
      [
        58.909909,
        59.16719400000001
      ],
      [
        58.90621,
        59.07809500000001
      ],
      [
        58.79121,
        59.09879500000001
      ],
      [
        58.77521,
        59.145995000000006
      ],
      [
        58.717611,
        59.15349500000001
      ],
      [
        58.709911,
        59.397495000000006
      ],
      [
        58.586012,
        59.394696
      ],
      [
        58.477712999999994,
        59.453596000000005
      ],
      [
        58.43011299999999,
        59.384196
      ],
      [
        58.42011399999999,
        59.285697000000006
      ],
      [
        58.33751499999999,
        59.26329700000001
      ],
      [
        58.32721499999999,
        59.20739700000001
      ],
      [
        58.27761599999999,
        59.188297000000006
      ],
      [
        58.28721599999999,
        59.106098
      ],
      [
        58.25841699999999,
        59.139098000000004
      ],
      [
        58.28301699999999,
        58.924898000000006
      ],
      [
        58.24051799999999,
        58.91109900000001
      ],
      [
        58.24691699999999,
        58.87440000000001
      ],
      [
        58.21741799999999,
        58.81590100000001
      ],
      [
        58.14311799999999,
        58.76330100000001
      ],
      [
        58.104418999999986,
        58.66530200000001
      ],
      [
        58.051718999999984,
        58.650903000000014
      ],
      [
        58.04311899999998,
        58.58920300000001
      ],
      [
        58.00321899999998,
        58.64020200000001
      ],
      [
        57.90031899999998,
        58.67210200000001
      ],
      [
        57.89281999999998,
        58.74460100000001
      ],
      [
        57.85481999999998,
        58.74060200000001
      ],
      [
        57.839019999999984,
        58.87180100000001
      ],
      [
        57.71931999999998,
        58.846001000000015
      ],
      [
        57.725718999999984,
        58.80350100000002
      ],
      [
        57.56001899999998,
        58.51580100000002
      ],
      [
        57.58121899999998,
        58.46390200000002
      ],
      [
        57.67261899999998,
        58.44580300000002
      ],
      [
        57.696218999999985,
        58.14830300000002
      ],
      [
        57.65901899999999,
        58.10070300000002
      ],
      [
        57.59191899999998,
        58.12080200000002
      ],
      [
        57.608018999999985,
        58.03600200000002
      ],
      [
        57.56721899999999,
        58.02820300000002
      ],
      [
        57.556218999999984,
        57.985604000000016
      ],
      [
        57.48951899999999,
        57.96800500000002
      ],
      [
        57.48491999999999,
        58.01520400000002
      ],
      [
        57.31641999999999,
        57.94010500000002
      ],
      [
        57.29741999999999,
        57.94320500000002
      ],
      [
        57.248319999999985,
        58.05180500000002
      ],
      [
        57.22211999999998,
        57.99060600000002
      ],
      [
        57.190319999999986,
        57.998606000000024
      ],
      [
        57.193218999999985,
        58.047805000000025
      ],
      [
        57.150019999999984,
        58.03280600000002
      ],
      [
        57.14111999999999,
        58.11950600000002
      ],
      [
        57.11342099999999,
        58.03260700000002
      ],
      [
        57.09882099999999,
        58.08690700000002
      ],
      [
        57.047420999999986,
        58.06740700000002
      ],
      [
        57.041020999999986,
        57.96580700000002
      ],
      [
        57.07942099999999,
        58.011006000000016
      ],
      [
        57.05662199999999,
        57.874707000000015
      ],
      [
        56.96222199999999,
        57.82550800000001
      ],
      [
        56.99072199999999,
        57.78100900000001
      ],
      [
        56.94322199999999,
        57.75040900000001
      ],
      [
        56.94462199999999,
        57.663010000000014
      ],
      [
        56.90762299999999,
        57.65431000000002
      ],
      [
        56.90382299999999,
        57.51971000000002
      ],
      [
        56.95692299999999,
        57.36721100000002
      ],
      [
        56.88472299999999,
        57.24771100000002
      ],
      [
        56.853723999999985,
        57.226911000000015
      ],
      [
        56.79902399999999,
        57.27381000000002
      ],
      [
        56.74942399999999,
        57.34571000000002
      ],
      [
        56.69052399999999,
        57.32931000000002
      ],
      [
        56.67762499999999,
        57.44060900000002
      ],
      [
        56.609525999999995,
        57.34010900000003
      ],
      [
        56.608025999999995,
        57.41930900000003
      ],
      [
        56.579325999999995,
        57.42260900000003
      ],
      [
        56.389526999999994,
        57.31800900000003
      ],
      [
        56.34562699999999,
        57.415909000000035
      ],
      [
        56.319827,
        57.29641000000004
      ],
      [
        56.273126999999995,
        57.30181000000004
      ],
      [
        56.249728,
        57.197710000000036
      ],
      [
        56.228927999999996,
        57.22910900000004
      ],
      [
        56.196527999999994,
        57.201310000000035
      ],
      [
        56.193028999999996,
        57.234510000000036
      ],
      [
        56.169129,
        57.20251100000004
      ],
      [
        56.127528999999996,
        57.049411000000035
      ],
      [
        56.111529999999995,
        56.88411200000004
      ],
      [
        56.178529999999995,
        56.76691300000004
      ],
      [
        56.223929999999996,
        56.74721300000004
      ],
      [
        56.234128999999996,
        56.656014000000035
      ],
      [
        56.268829,
        56.648415000000035
      ],
      [
        56.313627999999994,
        56.536116000000035
      ],
      [
        56.344727999999996,
        56.504216000000035
      ],
      [
        56.389027,
        56.57511500000003
      ],
      [
        56.397227,
        56.53301600000003
      ],
      [
        56.365427000000004,
        56.461816000000034
      ],
      [
        56.404727,
        56.40671600000003
      ],
      [
        56.388828000000004,
        56.36201700000003
      ],
      [
        56.362628,
        56.37861700000003
      ],
      [
        56.304629,
        56.28451700000003
      ],
      [
        56.324329,
        56.21941800000003
      ],
      [
        56.29013,
        56.07591800000003
      ],
      [
        56.455729999999996,
        55.90721800000003
      ],
      [
        56.42052999999999,
        55.840118000000025
      ],
      [
        56.43682999999999,
        55.70631900000002
      ],
      [
        56.31752999999999,
        55.39291900000002
      ],
      [
        56.32662899999999,
        55.26322000000002
      ],
      [
        56.37122899999999,
        55.20762100000002
      ],
      [
        56.30442999999999,
        55.18262100000002
      ],
      [
        56.351629999999986,
        55.11912200000002
      ],
      [
        56.338630999999985,
        54.94392200000002
      ],
      [
        56.38073099999998,
        54.928822000000025
      ],
      [
        56.430230999999985,
        54.73432200000003
      ],
      [
        56.500230999999985,
        54.682422000000024
      ],
      [
        56.53373099999998,
        54.581822000000024
      ],
      [
        56.44503099999998,
        54.485122000000025
      ],
      [
        56.34653099999998,
        54.44862200000003
      ],
      [
        56.40093099999998,
        54.34882200000003
      ],
      [
        56.484530999999976,
        54.32762200000003
      ],
      [
        56.45853099999997,
        54.244423000000026
      ],
      [
        56.53453099999997,
        54.19062300000002
      ],
      [
        56.542330999999976,
        54.107724000000026
      ],
      [
        56.57963099999998,
        54.032124000000024
      ],
      [
        56.655130999999976,
        54.095724000000025
      ],
      [
        56.67403099999998,
        54.01282400000002
      ],
      [
        56.63203099999998,
        53.98022400000002
      ],
      [
        56.64632999999998,
        53.88882400000002
      ],
      [
        56.61752999999998,
        53.84352400000002
      ],
      [
        56.59412999999998,
        53.87262400000002
      ],
      [
        56.59742999999998,
        53.84112400000002
      ],
      [
        56.64302999999998,
        53.84442300000002
      ],
      [
        56.67932999999998,
        53.89892200000002
      ],
      [
        56.71842999999998,
        53.84942300000002
      ],
      [
        56.77852899999998,
        53.806223000000024
      ],
      [
        56.79732799999998,
        53.83222200000002
      ],
      [
        56.81982799999998,
        53.80822200000002
      ],
      [
        56.75392899999998,
        54.023222000000025
      ],
      [
        56.81102899999998,
        54.087622000000025
      ],
      [
        56.889027999999975,
        54.102822000000025
      ],
      [
        56.979827999999976,
        54.15312100000003
      ],
      [
        56.97702899999997,
        54.35342000000003
      ],
      [
        57.03542899999997,
        54.43411900000003
      ],
      [
        57.11482899999997,
        54.28912000000003
      ],
      [
        57.11132899999997,
        54.31952000000003
      ],
      [
        57.15872899999997,
        54.346019000000034
      ],
      [
        57.191328999999975,
        54.31282000000003
      ],
      [
        57.21352899999997,
        54.35631900000003
      ],
      [
        57.23942899999997,
        54.298320000000025
      ],
      [
        57.26762899999997,
        54.327319000000024
      ],
      [
        57.30552899999997,
        54.310520000000025
      ],
      [
        57.33372799999997,
        54.126321000000026
      ],
      [
        57.37132699999997,
        54.150921000000025
      ],
      [
        57.378226999999974,
        54.12062100000003
      ],
      [
        57.432426999999976,
        54.29242000000003
      ],
      [
        57.46102699999997,
        54.27432000000003
      ],
      [
        57.48452699999997,
        54.143420000000035
      ],
      [
        57.50672599999997,
        54.17261900000003
      ],
      [
        57.53262599999997,
        54.14431900000003
      ],
      [
        57.52472699999997,
        54.03332000000003
      ],
      [
        57.57112699999997,
        54.04341900000003
      ],
      [
        57.55932699999997,
        53.991620000000026
      ],
      [
        57.58732599999997,
        53.985620000000026
      ],
      [
        57.61552499999997,
        54.12641900000003
      ],
      [
        57.64442499999997,
        54.04941900000003
      ],
      [
        57.67922499999997,
        54.05641900000003
      ],
      [
        57.70522499999997,
        54.15501800000003
      ],
      [
        57.74172399999997,
        54.111718000000025
      ],
      [
        57.77482299999997,
        54.15531800000002
      ],
      [
        57.80972299999997,
        54.12001800000002
      ],
      [
        57.87412199999997,
        54.13811800000002
      ],
      [
        57.91042199999997,
        54.09121800000002
      ],
      [
        57.962720999999966,
        54.12741700000002
      ],
      [
        58.08791999999997,
        53.945818000000024
      ],
      [
        58.14591999999997,
        53.946217000000026
      ],
      [
        58.16491899999997,
        53.883517000000026
      ],
      [
        58.17971899999997,
        53.91061700000002
      ],
      [
        58.23551899999997,
        53.78761800000002
      ],
      [
        58.31431899999997,
        53.888617000000025
      ],
      [
        58.420317999999966,
        53.77551800000003
      ],
      [
        58.47721699999997,
        53.79031800000003
      ],
      [
        58.602816999999966,
        53.70401900000003
      ],
      [
        58.65471699999997,
        53.76531800000003
      ],
      [
        58.68221699999997,
        53.73771900000003
      ],
      [
        58.724216999999975,
        53.78011800000003
      ],
      [
        58.774916999999974,
        53.93231800000003
      ],
      [
        58.86041699999998,
        53.79501800000003
      ],
      [
        58.96621699999998,
        53.75221800000003
      ],
      [
        59.03341699999998,
        53.86031800000003
      ],
      [
        59.08681699999998,
        53.90311700000003
      ],
      [
        59.12171699999998,
        53.86681700000003
      ],
      [
        59.184716999999985,
        53.72411800000003
      ],
      [
        59.15261799999998,
        53.68511800000003
      ],
      [
        59.11881899999998,
        53.71741700000003
      ],
      [
        59.148018999999984,
        53.403517000000036
      ],
      [
        59.33361799999999,
        53.22361800000004
      ],
      [
        59.46231799999999,
        53.353417000000036
      ],
      [
        59.70411799999999,
        53.45151700000004
      ],
      [
        59.69391799999999,
        53.60311700000004
      ],
      [
        60.00031699999999,
        53.68811700000004
      ],
      [
        60.07391599999999,
        53.65161800000004
      ],
      [
        60.22521499999999,
        53.43351900000004
      ],
      [
        60.156814999999995,
        53.37171900000004
      ],
      [
        60.18641399999999,
        52.44581900000004
      ],
      [
        60.227613999999996,
        52.31921900000004
      ],
      [
        60.357513999999995,
        52.37121800000004
      ],
      [
        60.374013999999995,
        52.33141900000004
      ],
      [
        60.43741299999999,
        52.35331800000004
      ],
      [
        60.46431199999999,
        52.124919000000034
      ],
      [
        60.54101099999999,
        52.15561900000004
      ],
      [
        60.58001099999999,
        51.771820000000034
      ],
      [
        60.88461099999999,
        51.88981900000003
      ],
      [
        60.830710999999994,
        52.36161900000003
      ],
      [
        60.97800999999999,
        52.431818000000035
      ],
      [
        60.93870999999999,
        52.80731700000003
      ],
      [
        61.091708999999994,
        52.868716000000035
      ],
      [
        61.03370999999999,
        53.412416000000036
      ],
      [
        60.88161099999999,
        53.35411600000003
      ],
      [
        60.866111999999994,
        53.49381600000003
      ],
      [
        60.831911999999996,
        53.81311600000003
      ],
      [
        60.982811999999996,
        53.875316000000026
      ],
      [
        60.859911999999994,
        54.97991500000003
      ]
    ]
  },
  "Тверская область": {
    "0": [
      [
        56.965302,
        31.000206
      ],
      [
        56.935822,
        31.544998
      ],
      [
        57.10833,
        31.712771
      ],
      [
        57.083879,
        31.954439
      ],
      [
        57.464158000000005,
        32.816663
      ],
      [
        57.429438000000005,
        33.291379
      ],
      [
        57.554712,
        33.235822
      ],
      [
        57.916383,
        33.70332
      ],
      [
        58.053605000000005,
        33.530271
      ],
      [
        58.153878000000006,
        33.671658
      ],
      [
        58.222764000000005,
        34.00916
      ],
      [
        58.07721900000001,
        34.679433
      ],
      [
        58.221658000000005,
        34.710820000000005
      ],
      [
        58.220331,
        35.14169700000001
      ],
      [
        58.435288,
        35.39138400000001
      ],
      [
        58.531662,
        36.50138400000001
      ],
      [
        58.818053,
        36.78499100000001
      ],
      [
        58.870267999999996,
        37.09165500000001
      ],
      [
        58.45165899999999,
        37.65776200000001
      ],
      [
        58.35555199999999,
        37.370539000000015
      ],
      [
        58.19526699999999,
        37.37415600000001
      ],
      [
        58.08305399999999,
        37.67360200000002
      ],
      [
        57.98165899999999,
        37.371929000000016
      ],
      [
        57.903046999999994,
        37.50749500000001
      ],
      [
        57.83998999999999,
        37.37554500000001
      ],
      [
        57.62887799999999,
        37.83027100000001
      ],
      [
        57.43138299999999,
        37.78749400000001
      ],
      [
        57.37054599999999,
        38.28054300000001
      ],
      [
        57.27499699999999,
        38.30998500000001
      ],
      [
        57.196940999999995,
        38.14249000000001
      ],
      [
        57.07361099999999,
        38.26110400000001
      ],
      [
        56.86888499999999,
        38.11971000000001
      ],
      [
        56.76694199999999,
        38.24915700000001
      ],
      [
        56.944157999999995,
        37.65276800000001
      ],
      [
        56.780553,
        37.49193300000001
      ],
      [
        56.721381,
        37.11804800000001
      ],
      [
        56.514723000000004,
        37.05332000000001
      ],
      [
        56.584723000000004,
        36.81832800000001
      ],
      [
        56.341666000000004,
        36.089431000000005
      ],
      [
        56.46365900000001,
        35.469421000000004
      ],
      [
        56.252531000000005,
        35.519980000000004
      ],
      [
        56.233153,
        35.36525
      ],
      [
        55.948218000000004,
        35.17418
      ],
      [
        55.916389,
        34.631661
      ],
      [
        56.076948,
        33.911936000000004
      ],
      [
        55.953887,
        33.65971700000001
      ],
      [
        55.758339,
        33.51250100000001
      ],
      [
        55.625,
        32.923055000000005
      ],
      [
        55.663887,
        32.03944200000001
      ],
      [
        55.727554000000005,
        31.47074700000001
      ],
      [
        55.951942,
        31.34528300000001
      ],
      [
        56.052780000000006,
        31.513061000000008
      ],
      [
        56.29917100000001,
        31.496112000000007
      ],
      [
        56.474165000000006,
        31.02167100000001
      ],
      [
        56.67860900000001,
        31.061114000000007
      ],
      [
        56.76583500000001,
        30.778892000000006
      ],
      [
        56.96110900000001,
        31.000281000000005
      ]
    ]
  },
  "Липецкая область": {
    "0": [
      [
        53.008545,
        37.841087
      ],
      [
        53.049994999999996,
        38.608603
      ],
      [
        53.308043,
        38.476935000000005
      ],
      [
        53.374159,
        38.758047000000005
      ],
      [
        53.537495,
        38.733045000000004
      ],
      [
        53.549435,
        39.048047000000004
      ],
      [
        53.382764,
        39.181935
      ],
      [
        53.592213,
        39.73888
      ],
      [
        53.340544,
        40.160816000000004
      ],
      [
        52.809157,
        40.085820000000005
      ],
      [
        52.654434,
        39.951933000000004
      ],
      [
        52.213883,
        40.703046
      ],
      [
        51.953508,
        40.503934
      ],
      [
        51.924997,
        39.732205
      ],
      [
        52.086656,
        39.440267
      ],
      [
        52.105011,
        39.14105
      ],
      [
        51.987770999999995,
        39.024436
      ],
      [
        52.102219,
        38.682769
      ],
      [
        51.883880999999995,
        37.884719
      ],
      [
        52.160819,
        37.774161
      ],
      [
        52.352492,
        38.039991
      ],
      [
        52.611381,
        37.707496
      ],
      [
        52.780272000000004,
        37.874991
      ],
      [
        52.992766,
        37.837211
      ]
    ]
  },
  "Орловская область": {
    "0": [
      [
        53.310852,
        35.10347
      ],
      [
        53.282515999999994,
        35.357223000000005
      ],
      [
        53.44471599999999,
        35.447782000000004
      ],
      [
        53.649159999999995,
        35.930822000000006
      ],
      [
        53.297490999999994,
        36.799992
      ],
      [
        53.29610199999999,
        37.566662
      ],
      [
        53.13665699999999,
        37.583049
      ],
      [
        53.00854499999999,
        37.841081
      ],
      [
        52.780272999999994,
        37.874986
      ],
      [
        52.61138199999999,
        37.707491
      ],
      [
        52.361937999999995,
        38.034713999999994
      ],
      [
        52.253052,
        38.01887599999999
      ],
      [
        52.160819999999994,
        37.77415599999999
      ],
      [
        51.985825999999996,
        37.71582299999999
      ],
      [
        51.924713999999994,
        37.39665599999999
      ],
      [
        52.11527399999999,
        36.82721399999999
      ],
      [
        52.37082799999999,
        36.33749199999999
      ],
      [
        52.24665999999999,
        35.64526899999999
      ],
      [
        52.43306199999999,
        35.55331199999999
      ],
      [
        52.36082699999999,
        34.858884999999994
      ],
      [
        52.69467999999999,
        35.09241899999999
      ],
      [
        52.85888699999999,
        34.789716999999996
      ],
      [
        53.05865499999999,
        35.31550299999999
      ],
      [
        53.14200599999999,
        35.10329999999999
      ],
      [
        53.30837099999999,
        35.105611999999994
      ]
    ]
  },
  "Самарская область": {
    "0": [
      [
        54.51667,
        50.076881
      ],
      [
        54.488884999999996,
        50.388885
      ],
      [
        54.326387,
        50.5186
      ],
      [
        54.428331,
        50.703049
      ],
      [
        54.339991,
        50.936104
      ],
      [
        54.551660999999996,
        51.009719
      ],
      [
        54.677217,
        51.395545
      ],
      [
        54.536111999999996,
        51.927214
      ],
      [
        54.339434999999995,
        52.085264
      ],
      [
        54.478877,
        52.439428
      ],
      [
        54.377078999999995,
        52.537442999999996
      ],
      [
        54.297222,
        52.194433999999994
      ],
      [
        54.226659,
        52.346098999999995
      ],
      [
        54.113889,
        52.271933999999995
      ],
      [
        54.057776,
        52.487494
      ],
      [
        53.506392999999996,
        52.075272
      ],
      [
        53.37944099999999,
        52.196655
      ],
      [
        53.14250299999999,
        52.054711
      ],
      [
        53.09666699999999,
        52.159715
      ],
      [
        52.87194399999999,
        51.728044
      ],
      [
        52.66750799999999,
        51.733605
      ],
      [
        52.67417499999999,
        51.534166
      ],
      [
        52.34917099999999,
        51.40694
      ],
      [
        52.08972699999999,
        51.412493
      ],
      [
        51.76919399999999,
        50.773303
      ],
      [
        51.92917399999999,
        50.724162
      ],
      [
        52.021672999999986,
        50.162769
      ],
      [
        52.133892999999986,
        50.217211999999996
      ],
      [
        52.258060999999984,
        49.654711999999996
      ],
      [
        52.40250099999999,
        49.579997999999996
      ],
      [
        52.328060999999984,
        49.479161
      ],
      [
        52.51195199999999,
        49.220548
      ],
      [
        52.41111499999999,
        48.890836
      ],
      [
        52.645839999999986,
        48.699721
      ],
      [
        52.67417599999999,
        48.366941999999995
      ],
      [
        52.92222899999999,
        48.58888699999999
      ],
      [
        52.985559999999985,
        48.31833499999999
      ],
      [
        53.066392999999984,
        48.368886999999994
      ],
      [
        52.99722599999998,
        48.223608999999996
      ],
      [
        53.370280999999984,
        47.943054999999994
      ],
      [
        53.471384999999984,
        48.378333999999995
      ],
      [
        53.58139199999999,
        48.440833999999995
      ],
      [
        53.65583199999999,
        48.314164999999996
      ],
      [
        53.66944999999999,
        48.603334
      ],
      [
        53.77083599999999,
        48.472223
      ],
      [
        53.71277699999999,
        48.863335
      ],
      [
        53.85944399999999,
        49.03695
      ],
      [
        53.82528099999999,
        50.03778
      ],
      [
        53.96138899999999,
        49.974441
      ],
      [
        54.03722399999999,
        50.197494
      ],
      [
        54.28944399999999,
        50.262222
      ],
      [
        54.50941499999999,
        50.080047
      ]
    ]
  },
  "Кировская область": {
    "0": [
      [
        60,
        53.706173
      ],
      [
        59.699998,
        53.602493
      ],
      [
        59.689715,
        53.420548000000004
      ],
      [
        59.355273,
        53.204164000000006
      ],
      [
        59.155550999999996,
        53.38555100000001
      ],
      [
        59.09054999999999,
        53.884162
      ],
      [
        58.937495999999996,
        53.719154
      ],
      [
        58.781667,
        53.928328
      ],
      [
        58.603331,
        53.696097
      ],
      [
        58.439971,
        53.784629
      ],
      [
        58.389167,
        53.307212
      ],
      [
        58.54611,
        53.059432
      ],
      [
        58.403884,
        52.865272000000004
      ],
      [
        58.493331,
        52.172768000000005
      ],
      [
        58.358895,
        51.789993
      ],
      [
        58.171668999999994,
        51.672494
      ],
      [
        57.97471699999999,
        51.890832
      ],
      [
        57.63472899999999,
        51.856942000000004
      ],
      [
        57.43721899999999,
        51.587496
      ],
      [
        57.45388899999999,
        51.194156
      ],
      [
        57.30471999999999,
        51.11472
      ],
      [
        56.93333799999999,
        51.320834999999995
      ],
      [
        56.881114999999994,
        51.541942999999996
      ],
      [
        56.67778499999999,
        51.38943999999999
      ],
      [
        56.667781999999995,
        51.18971799999999
      ],
      [
        56.50333799999999,
        51.13833499999999
      ],
      [
        56.21306299999999,
        51.46944299999999
      ],
      [
        56.07223299999999,
        51.33277699999999
      ],
      [
        56.23305999999999,
        50.849439999999994
      ],
      [
        56.41333499999999,
        50.79194499999999
      ],
      [
        56.35778699999999,
        50.57999999999999
      ],
      [
        56.61639899999999,
        50.49333099999999
      ],
      [
        56.62267699999999,
        50.07221199999999
      ],
      [
        56.79417099999999,
        50.101668999999994
      ],
      [
        56.89667099999999,
        49.73444299999999
      ],
      [
        57.07361799999999,
        49.71194399999999
      ],
      [
        57.04362099999999,
        49.26833399999999
      ],
      [
        57.15972399999999,
        49.14361599999999
      ],
      [
        57.33139199999999,
        49.183334999999985
      ],
      [
        57.283059999999985,
        49.01611399999999
      ],
      [
        57.085557999999985,
        48.954720999999985
      ],
      [
        57.16861199999998,
        48.30777899999998
      ],
      [
        56.998339999999985,
        48.13916899999998
      ],
      [
        57.05527799999999,
        47.73361399999998
      ],
      [
        56.85139899999999,
        47.502504999999985
      ],
      [
        56.808613999999984,
        47.17084099999998
      ],
      [
        56.932507999999984,
        47.08306599999998
      ],
      [
        56.949170999999986,
        46.70055799999998
      ],
      [
        57.200009999999985,
        46.72639099999998
      ],
      [
        57.234447999999986,
        46.85194799999998
      ],
      [
        57.35000999999998,
        46.67611399999998
      ],
      [
        57.523333999999984,
        46.74444999999998
      ],
      [
        57.52861399999998,
        47.47111199999998
      ],
      [
        57.802500999999985,
        47.56111599999998
      ],
      [
        57.888339999999985,
        47.771113999999976
      ],
      [
        58.110559999999985,
        46.309725999999976
      ],
      [
        58.36389299999998,
        46.411387999999974
      ],
      [
        58.55805199999998,
        46.58417099999998
      ],
      [
        58.62249699999998,
        47.006113999999975
      ],
      [
        58.80083299999998,
        47.32500699999998
      ],
      [
        58.916668999999985,
        47.30722299999998
      ],
      [
        58.91499899999999,
        47.61167299999998
      ],
      [
        59.167501999999985,
        47.44500199999998
      ],
      [
        59.22610299999999,
        47.22333099999998
      ],
      [
        59.355558999999985,
        47.25250599999998
      ],
      [
        59.378605999999984,
        47.05639199999998
      ],
      [
        59.71888399999998,
        47.14167399999998
      ],
      [
        59.84666099999998,
        46.92084199999998
      ],
      [
        60.11832699999999,
        47.03695399999998
      ],
      [
        60.065271999999986,
        46.60611399999998
      ],
      [
        60.242494999999984,
        46.295010999999974
      ],
      [
        60.25638699999998,
        46.74500799999998
      ],
      [
        60.515273999999984,
        46.856671999999975
      ],
      [
        60.576658999999985,
        47.061673999999975
      ],
      [
        60.65526399999999,
        46.95278799999998
      ],
      [
        61.059986999999985,
        47.19722599999998
      ],
      [
        60.88665499999998,
        47.98750899999998
      ],
      [
        60.853856999999984,
        48.41925599999998
      ],
      [
        60.52499199999998,
        48.48111499999998
      ],
      [
        60.41110099999998,
        48.54139399999998
      ],
      [
        60.36499699999998,
        48.76306499999998
      ],
      [
        60.19415299999998,
        48.45917199999998
      ],
      [
        59.70388099999998,
        48.51000599999998
      ],
      [
        59.655549999999984,
        49.085557999999985
      ],
      [
        59.48443899999998,
        49.03417499999998
      ],
      [
        59.39305399999998,
        49.545008999999986
      ],
      [
        59.21776999999998,
        49.593333999999984
      ],
      [
        59.22221699999998,
        49.79555699999998
      ],
      [
        59.636941999999976,
        49.83278099999998
      ],
      [
        59.68749399999998,
        50.056390999999984
      ],
      [
        59.77887799999998,
        49.996667999999985
      ],
      [
        59.86277099999998,
        50.82861599999998
      ],
      [
        60.06166099999998,
        51.071114999999985
      ],
      [
        59.94721299999998,
        51.53221999999999
      ],
      [
        60.317496999999975,
        52.06055599999999
      ],
      [
        60.167769999999976,
        52.84722299999999
      ],
      [
        60.21887999999998,
        53.46833199999999
      ],
      [
        60.001106999999976,
        53.70666599999999
      ]
    ]
  },
  "Брянская область": {
    "0": [
      [
        53.432571,
        32.755412
      ],
      [
        53.820548,
        33.291938
      ],
      [
        53.958602,
        33.247771
      ],
      [
        54.030822,
        33.663603
      ],
      [
        53.871932,
        33.874707
      ],
      [
        53.886106,
        34.214153
      ],
      [
        53.596655999999996,
        34.533877000000004
      ],
      [
        53.426939999999995,
        34.50027
      ],
      [
        53.39586499999999,
        35.019229
      ],
      [
        53.142005999999995,
        35.103296
      ],
      [
        53.058654999999995,
        35.315499
      ],
      [
        52.858886999999996,
        34.789713000000006
      ],
      [
        52.69468,
        35.092415
      ],
      [
        52.426386,
        34.831377
      ],
      [
        52.233332000000004,
        34.886376000000006
      ],
      [
        52.20443900000001,
        34.67637800000001
      ],
      [
        52.014444000000005,
        34.403315000000006
      ],
      [
        51.904436000000004,
        34.44526200000001
      ],
      [
        51.845138000000006,
        34.38256400000001
      ],
      [
        52.14016900000001,
        34.12924600000001
      ],
      [
        52.362916000000006,
        33.81449500000001
      ],
      [
        52.368638000000004,
        33.19033100000001
      ],
      [
        52.243806000000006,
        32.90549600000001
      ],
      [
        52.33511000000001,
        32.388025000000006
      ],
      [
        52.10444300000001,
        32.296274000000004
      ],
      [
        52.10736100000001,
        31.796805000000003
      ],
      [
        52.319972000000014,
        31.609111000000002
      ],
      [
        52.48810800000001,
        31.572026
      ],
      [
        52.762971000000014,
        31.602055
      ],
      [
        53.03382900000001,
        31.256804
      ],
      [
        53.20810600000001,
        31.439498999999998
      ],
      [
        53.099799000000004,
        32.234663
      ],
      [
        53.427939,
        32.752995999999996
      ]
    ]
  },
  "Московская область": {
    "0": [
      [
        56.770264,
        38.256523
      ],
      [
        56.531938,
        38.292769
      ],
      [
        55.962213999999996,
        38.597487
      ],
      [
        55.758891,
        39.354994
      ],
      [
        55.825831,
        39.857495
      ],
      [
        55.619441,
        40.083599
      ],
      [
        55.449435,
        40.183872
      ],
      [
        55.309436,
        40.206653
      ],
      [
        55.126103,
        39.720264
      ],
      [
        55.080555000000004,
        39.888325
      ],
      [
        54.936382,
        39.479984
      ],
      [
        54.634999,
        39.232487
      ],
      [
        54.598882,
        38.811932
      ],
      [
        54.463614,
        38.790273
      ],
      [
        54.400833,
        38.917491999999996
      ],
      [
        54.250555999999996,
        38.697483999999996
      ],
      [
        54.471380999999994,
        38.387769999999996
      ],
      [
        54.636388999999994,
        38.476932999999995
      ],
      [
        54.83777499999999,
        37.89388099999999
      ],
      [
        54.71361399999999,
        37.67137699999999
      ],
      [
        54.854443999999994,
        37.203320999999995
      ],
      [
        55.230548999999996,
        36.909986999999994
      ],
      [
        55.321667,
        36.43332699999999
      ],
      [
        55.183056,
        36.13777199999999
      ],
      [
        55.295833,
        35.70027199999999
      ],
      [
        55.310465,
        35.29032999999999
      ],
      [
        55.555712,
        35.27665799999999
      ],
      [
        55.617036,
        35.39998699999999
      ],
      [
        55.95269,
        35.16773399999999
      ],
      [
        56.233146999999995,
        35.36525099999999
      ],
      [
        56.252525,
        35.51998099999999
      ],
      [
        56.459762,
        35.460258999999986
      ],
      [
        56.34166,
        36.08943199999999
      ],
      [
        56.584717,
        36.81832899999999
      ],
      [
        56.514717,
        37.05332099999999
      ],
      [
        56.746100999999996,
        37.15276999999999
      ],
      [
        56.780547,
        37.49193499999999
      ],
      [
        56.954162,
        37.71720799999999
      ],
      [
        56.768598999999995,
        38.25416399999999
      ]
    ]
  },
  "Смоленская область": {
    "0": [
      [
        55.616241,
        30.940512
      ],
      [
        55.770546,
        31.052218
      ],
      [
        55.663880000000006,
        32.039434
      ],
      [
        55.64554700000001,
        33.137769
      ],
      [
        55.764717000000005,
        33.525546999999996
      ],
      [
        55.95387900000001,
        33.659709
      ],
      [
        55.921935000000005,
        33.832765
      ],
      [
        56.07499500000001,
        33.900263
      ],
      [
        56.08443300000001,
        34.205544
      ],
      [
        55.91638100000001,
        34.631652
      ],
      [
        55.94016800000001,
        35.21051
      ],
      [
        55.617034000000004,
        35.399977
      ],
      [
        55.555710000000005,
        35.276648
      ],
      [
        55.234871000000005,
        35.369483
      ],
      [
        54.96398200000001,
        35.142097
      ],
      [
        54.88499500000001,
        34.865266
      ],
      [
        54.77304900000001,
        34.926094
      ],
      [
        54.68387700000001,
        34.406929
      ],
      [
        54.62694000000001,
        34.50138
      ],
      [
        54.42166300000001,
        34.381651999999995
      ],
      [
        54.56249300000001,
        33.728035
      ],
      [
        54.18860100000001,
        33.623039
      ],
      [
        53.97332200000001,
        33.480261999999996
      ],
      [
        53.95860600000001,
        33.247764999999994
      ],
      [
        53.82055200000001,
        33.291931999999996
      ],
      [
        53.412770000000016,
        32.765542999999994
      ],
      [
        53.57975300000002,
        32.45193999999999
      ],
      [
        53.732447000000015,
        32.465824999999995
      ],
      [
        53.79817000000001,
        31.770187999999994
      ],
      [
        54.04494600000001,
        31.887630999999995
      ],
      [
        54.25666900000001,
        31.323327999999997
      ],
      [
        54.465196000000006,
        31.236882999999995
      ],
      [
        54.50161000000001,
        31.087938999999995
      ],
      [
        54.65652800000001,
        31.212438999999996
      ],
      [
        54.80769200000001,
        30.760021999999996
      ],
      [
        55.05322100000001,
        31.026770999999997
      ],
      [
        55.289694000000004,
        30.830298999999997
      ],
      [
        55.608637,
        30.952187999999996
      ]
    ]
  },
  "Рязанская область": {
    "0": [
      [
        54.244728,
        38.67527
      ],
      [
        54.400826,
        38.917494999999995
      ],
      [
        54.463607,
        38.790276
      ],
      [
        54.59887500000001,
        38.811935
      ],
      [
        54.634992000000004,
        39.23249
      ],
      [
        54.936375000000005,
        39.479987
      ],
      [
        55.08054800000001,
        39.888328
      ],
      [
        55.126096000000004,
        39.720267
      ],
      [
        55.337765000000005,
        40.240828
      ],
      [
        55.330822000000005,
        40.53527
      ],
      [
        55.259716000000004,
        40.988324999999996
      ],
      [
        55.14304800000001,
        41.085544999999996
      ],
      [
        55.22248400000001,
        41.19665199999999
      ],
      [
        55.09026700000001,
        41.98137299999999
      ],
      [
        55.00387900000001,
        41.95693699999999
      ],
      [
        54.95054300000001,
        42.15720799999999
      ],
      [
        55.005550000000014,
        42.47387399999999
      ],
      [
        54.786656000000015,
        42.380543999999986
      ],
      [
        54.58276900000001,
        42.70248799999999
      ],
      [
        54.493048000000016,
        42.556094999999985
      ],
      [
        54.376655000000014,
        42.63387599999999
      ],
      [
        54.26721100000001,
        42.175265999999986
      ],
      [
        54.26110800000001,
        42.438876999999984
      ],
      [
        54.18860000000001,
        42.601930999999986
      ],
      [
        54.07499800000001,
        42.513049999999986
      ],
      [
        54.122490000000006,
        42.27859999999998
      ],
      [
        53.96082400000001,
        42.465541999999985
      ],
      [
        53.85305200000001,
        42.31832499999999
      ],
      [
        53.784441000000015,
        41.398327999999985
      ],
      [
        53.54333000000002,
        41.351933999999986
      ],
      [
        53.43333000000002,
        41.175268999999986
      ],
      [
        53.56777400000002,
        40.69915799999998
      ],
      [
        53.49666000000002,
        40.42305099999998
      ],
      [
        53.33888500000002,
        40.38193699999998
      ],
      [
        53.41638500000002,
        39.90860299999998
      ],
      [
        53.59276800000002,
        39.732211999999976
      ],
      [
        53.38721700000002,
        39.17277299999998
      ],
      [
        53.56749900000002,
        38.89194199999998
      ],
      [
        53.77138000000002,
        38.799999999999976
      ],
      [
        53.93360300000002,
        38.846942999999975
      ],
      [
        54.00305400000002,
        38.712773999999975
      ],
      [
        54.24110500000002,
        38.67361199999998
      ]
    ]
  },
  "Калужская область": {
    "0": [
      [
        55.238976,
        35.369766
      ],
      [
        55.183602,
        36.150268
      ],
      [
        55.317773,
        36.586104
      ],
      [
        55.223879000000004,
        36.923605
      ],
      [
        54.835857000000004,
        37.259358
      ],
      [
        54.532771000000004,
        37.068884
      ],
      [
        54.60666200000001,
        36.801657999999996
      ],
      [
        54.29361300000001,
        36.9261
      ],
      [
        54.28777600000001,
        36.455549999999995
      ],
      [
        54.18665700000001,
        36.022490999999995
      ],
      [
        54.06999600000001,
        36.217209
      ],
      [
        53.84972000000001,
        35.903876999999994
      ],
      [
        53.70305300000001,
        36.06443699999999
      ],
      [
        53.61837400000001,
        36.00978799999999
      ],
      [
        53.54639200000001,
        35.640547999999995
      ],
      [
        53.282522000000014,
        35.357223999999995
      ],
      [
        53.28504800000002,
        35.016724999999994
      ],
      [
        53.42694700000002,
        34.500277
      ],
      [
        53.59666300000002,
        34.533884
      ],
      [
        53.88611300000002,
        34.21416
      ],
      [
        54.04166900000002,
        33.425831
      ],
      [
        54.18860400000002,
        33.62305
      ],
      [
        54.56249600000002,
        33.728046
      ],
      [
        54.423330000000014,
        34.386111
      ],
      [
        54.62694300000001,
        34.501391
      ],
      [
        54.68388000000001,
        34.40694
      ],
      [
        54.71194100000001,
        34.828052
      ],
      [
        54.77777400000001,
        34.928049
      ],
      [
        54.884997000000006,
        34.865276
      ],
      [
        54.963984,
        35.142107
      ],
      [
        55.234873,
        35.369493000000006
      ]
    ]
  },
  "Тамбовская область": {
    "0": [
      [
        53.342087,
        40.160362
      ],
      [
        53.34166,
        40.388884
      ],
      [
        53.496657,
        40.423047999999994
      ],
      [
        53.567771,
        40.699155
      ],
      [
        53.430824,
        41.168048999999996
      ],
      [
        53.543327,
        41.35193099999999
      ],
      [
        53.784437999999994,
        41.39832499999999
      ],
      [
        53.812200999999995,
        42.13439999999999
      ],
      [
        53.52193499999999,
        42.097480999999995
      ],
      [
        53.120543999999995,
        42.54526499999999
      ],
      [
        52.65082699999999,
        43.241651999999995
      ],
      [
        52.40287099999999,
        43.079786999999996
      ],
      [
        52.18582399999999,
        42.753316999999996
      ],
      [
        52.01027999999999,
        42.811094
      ],
      [
        51.60916499999999,
        42.493314999999996
      ],
      [
        51.59749299999999,
        42.265541
      ],
      [
        51.728604999999995,
        42.191651
      ],
      [
        51.80332599999999,
        41.501099
      ],
      [
        51.73999399999999,
        40.883042
      ],
      [
        51.89027699999999,
        40.937759
      ],
      [
        51.95350799999999,
        40.50393
      ],
      [
        52.281105999999994,
        40.693313999999994
      ],
      [
        52.654433999999995,
        39.95192899999999
      ],
      [
        52.80915699999999,
        40.085815999999994
      ],
      [
        53.340543999999994,
        40.16081199999999
      ]
    ]
  },
  "Ростовская область": {
    "0": [
      [
        49.612617,
        40.151634
      ],
      [
        49.693877,
        40.900542
      ],
      [
        50.083327,
        41.350828
      ],
      [
        50.226285999999995,
        41.362203
      ],
      [
        49.963606999999996,
        41.613605
      ],
      [
        49.731933999999995,
        42.139713
      ],
      [
        49.153878,
        42.064435
      ],
      [
        49.15249,
        42.344433
      ],
      [
        48.795275000000004,
        42.747211
      ],
      [
        48.546946000000005,
        42.630543
      ],
      [
        48.408885000000005,
        42.072492000000004
      ],
      [
        48.28221500000001,
        42.151928000000005
      ],
      [
        48.259167000000005,
        42.044994
      ],
      [
        48.03500100000001,
        42.019154
      ],
      [
        48.016394000000005,
        42.486653
      ],
      [
        47.51361800000001,
        42.93971
      ],
      [
        47.44972200000001,
        43.224987999999996
      ],
      [
        47.47656200000001,
        43.73177
      ],
      [
        47.28221200000001,
        43.801655
      ],
      [
        47.26722000000001,
        43.941928999999995
      ],
      [
        47.58194800000001,
        44.24415199999999
      ],
      [
        47.45694900000001,
        44.334979999999995
      ],
      [
        46.90694700000001,
        44.26582799999999
      ],
      [
        46.58472700000001,
        43.81219999999999
      ],
      [
        46.342220000000005,
        43.97192099999999
      ],
      [
        46.289174,
        43.86415599999999
      ],
      [
        46.409435,
        43.77165699999999
      ],
      [
        46.186115,
        43.61915299999999
      ],
      [
        46.170842,
        43.36137999999999
      ],
      [
        46.438611,
        42.93386699999999
      ],
      [
        46.369073,
        42.71844399999999
      ],
      [
        46.555562,
        42.03526999999999
      ],
      [
        46.31445,
        42.21053899999999
      ],
      [
        46.302228,
        41.96304999999999
      ],
      [
        46.176671,
        41.65193099999999
      ],
      [
        45.97888,
        41.67943499999999
      ],
      [
        45.953489999999995,
        41.16255999999999
      ],
      [
        46.196104,
        40.94804399999999
      ],
      [
        46.299177,
        40.99553699999999
      ],
      [
        46.231672,
        40.75638699999999
      ],
      [
        46.348890000000004,
        40.23110299999999
      ],
      [
        46.530553000000005,
        40.21305199999999
      ],
      [
        46.55083200000001,
        40.04138299999999
      ],
      [
        46.72305000000001,
        40.13581899999999
      ],
      [
        46.77208300000001,
        40.05311699999999
      ],
      [
        46.807781000000006,
        39.43386999999999
      ],
      [
        46.770557000000004,
        39.23276099999999
      ],
      [
        46.618328000000005,
        39.17416799999999
      ],
      [
        46.59,
        38.85666299999999
      ],
      [
        46.821949000000004,
        38.91137999999999
      ],
      [
        46.871058000000005,
        38.67398799999999
      ],
      [
        47.019926000000005,
        39.30047399999999
      ],
      [
        47.284676000000005,
        39.201391999999984
      ],
      [
        47.115369,
        38.23436299999999
      ],
      [
        47.313118,
        38.224417999999986
      ],
      [
        47.561675,
        38.31075199999999
      ],
      [
        47.684145,
        38.764780999999985
      ],
      [
        47.862062,
        38.83475399999998
      ],
      [
        47.872228,
        39.78261399999998
      ],
      [
        48.251088,
        40.01805699999998
      ],
      [
        48.322087,
        39.850305999999975
      ],
      [
        48.370059000000005,
        39.94603099999998
      ],
      [
        48.57080800000001,
        39.85005699999998
      ],
      [
        48.61905900000001,
        39.66655799999998
      ],
      [
        48.83777700000001,
        39.80036199999998
      ],
      [
        48.86700100000001,
        40.070887999999975
      ],
      [
        49.04464000000001,
        39.669165999999976
      ],
      [
        49.05286000000001,
        39.931692999999974
      ],
      [
        49.24311100000001,
        40.19963599999998
      ],
      [
        49.499668000000014,
        40.067358999999975
      ],
      [
        49.59616900000002,
        40.16202799999998
      ]
    ]
  },
  "Республика Марий Эл": {
    "0": [
      [
        56.966339,
        46.715096
      ],
      [
        56.91694,
        47.132767
      ],
      [
        56.80860199999999,
        47.170829
      ],
      [
        56.851386999999995,
        47.502493
      ],
      [
        57.055265999999996,
        47.733602000000005
      ],
      [
        56.998327999999994,
        48.139157000000004
      ],
      [
        57.16859999999999,
        48.307767000000005
      ],
      [
        57.085545999999994,
        48.95470900000001
      ],
      [
        57.283047999999994,
        49.01610200000001
      ],
      [
        57.331379999999996,
        49.18332300000001
      ],
      [
        57.159712,
        49.14360400000001
      ],
      [
        57.043609,
        49.26832200000001
      ],
      [
        57.073606,
        49.71193200000001
      ],
      [
        56.896659,
        49.734431000000015
      ],
      [
        56.843323,
        50.056651000000016
      ],
      [
        56.609719,
        50.064433000000015
      ],
      [
        56.588044,
        50.205545000000015
      ],
      [
        56.438044,
        49.981096000000015
      ],
      [
        56.537774999999996,
        49.75832500000001
      ],
      [
        56.483881,
        49.41498700000001
      ],
      [
        56.348330999999995,
        49.347209000000014
      ],
      [
        56.400828,
        49.101101000000014
      ],
      [
        56.262218,
        48.92304700000001
      ],
      [
        56.203053,
        49.05193000000001
      ],
      [
        55.952222,
        48.693601000000015
      ],
      [
        55.814192,
        48.40457700000002
      ],
      [
        55.943327,
        47.981932000000015
      ],
      [
        56.133055,
        47.896659000000014
      ],
      [
        56.178334,
        47.44165800000001
      ],
      [
        56.333332,
        47.38804600000001
      ],
      [
        56.034169,
        46.14499800000001
      ],
      [
        56.25861,
        45.873323000000006
      ],
      [
        56.438328,
        45.949715000000005
      ],
      [
        56.52055,
        45.621658000000004
      ],
      [
        56.650546,
        45.927773
      ],
      [
        56.872766999999996,
        45.957771
      ],
      [
        56.9657,
        46.709258
      ]
    ]
  },
  "Курганская область": {
    "0": [
      [
        56.133606,
        62.071938
      ],
      [
        56.281661,
        62.066101
      ],
      [
        56.577773,
        62.54222
      ],
      [
        56.598043,
        63.001105
      ],
      [
        56.508604999999996,
        63.354994000000005
      ],
      [
        56.62221399999999,
        63.501661000000006
      ],
      [
        56.45665699999999,
        64.209715
      ],
      [
        56.588598999999995,
        64.206939
      ],
      [
        56.747212999999995,
        64.322494
      ],
      [
        56.845265,
        64.830825
      ],
      [
        56.768324,
        64.99552800000001
      ],
      [
        56.495543,
        64.966384
      ],
      [
        56.372765,
        65.21137800000001
      ],
      [
        56.285829,
        65.167753
      ],
      [
        56.273607,
        65.667204
      ],
      [
        55.988047,
        66.020262
      ],
      [
        56.068606,
        66.19636200000001
      ],
      [
        55.949435,
        66.439694
      ],
      [
        56.049441,
        66.696926
      ],
      [
        55.959164,
        67.310528
      ],
      [
        55.727491,
        67.451092
      ],
      [
        55.704718,
        68.193859
      ],
      [
        55.581115,
        68.170529
      ],
      [
        55.351867,
        68.742497
      ],
      [
        55.194035,
        68.663327
      ],
      [
        55.187146,
        68.17777799999999
      ],
      [
        55.069176,
        68.28793799999998
      ],
      [
        54.911229999999996,
        68.08816299999998
      ],
      [
        54.977092999999996,
        67.86855199999998
      ],
      [
        54.618092999999995,
        66.02005799999998
      ],
      [
        54.712590999999996,
        65.94569399999997
      ],
      [
        54.603483999999995,
        65.75524999999998
      ],
      [
        54.650285,
        65.49099899999997
      ],
      [
        54.524590999999994,
        65.20077699999997
      ],
      [
        54.34161999999999,
        65.22247499999997
      ],
      [
        54.421232999999994,
        64.96089399999997
      ],
      [
        54.20174299999999,
        63.32500799999997
      ],
      [
        54.31972699999999,
        63.08694099999997
      ],
      [
        54.479997999999995,
        63.13333399999997
      ],
      [
        54.524446999999995,
        63.30721499999997
      ],
      [
        54.688050999999994,
        63.19332399999997
      ],
      [
        54.732772999999995,
        62.548610999999966
      ],
      [
        54.715278999999995,
        62.01916099999997
      ],
      [
        54.810553999999996,
        62.128328999999965
      ],
      [
        55.016943,
        61.964716999999965
      ],
      [
        54.995833,
        62.205271999999965
      ],
      [
        55.13111,
        62.27638499999996
      ],
      [
        55.31333,
        62.04166699999996
      ],
      [
        55.340276,
        62.23665799999996
      ],
      [
        55.504719,
        62.590546999999965
      ],
      [
        55.565272,
        62.450546999999965
      ],
      [
        55.734438,
        62.52137799999996
      ],
      [
        55.836656,
        62.253052999999966
      ],
      [
        55.921661,
        62.35888699999997
      ],
      [
        56.129432,
        62.08638099999997
      ]
    ]
  },
  "Мурманская область": {
    "0": [
      [
        69.057388,
        28.926945
      ],
      [
        69.073363,
        29.185611
      ],
      [
        69.302779,
        29.314944
      ],
      [
        69.462721,
        30.109887
      ],
      [
        69.546774,
        30.194665
      ],
      [
        69.663389,
        30.111278000000002
      ],
      [
        69.54166199999999,
        30.820138000000004
      ],
      [
        69.58621699999999,
        30.945554000000005
      ],
      [
        69.78602299999999,
        30.842750000000006
      ],
      [
        69.69377699999998,
        31.442054000000006
      ],
      [
        69.57366799999998,
        31.256000000000007
      ],
      [
        69.64888699999999,
        31.803276000000007
      ],
      [
        69.805495,
        31.722220000000007
      ],
      [
        69.74686299999999,
        32.05886000000001
      ],
      [
        69.91002599999999,
        31.89622200000001
      ],
      [
        69.94105299999998,
        32.08272000000001
      ],
      [
        69.72102999999998,
        33.11658200000001
      ],
      [
        69.56252999999998,
        32.852025000000005
      ],
      [
        69.62852299999999,
        32.195471000000005
      ],
      [
        69.74060599999999,
        32.110721000000005
      ],
      [
        69.62888899999999,
        31.962943000000006
      ],
      [
        69.54636299999999,
        32.01547000000001
      ],
      [
        69.50044299999999,
        32.521471000000005
      ],
      [
        69.41405599999999,
        32.211192000000004
      ],
      [
        69.45555999999999,
        32.891359
      ],
      [
        69.449891,
        33.074498
      ],
      [
        69.32450299999999,
        32.812776
      ],
      [
        69.28844699999999,
        32.89322
      ],
      [
        69.39147399999999,
        33.502637
      ],
      [
        69.34258599999998,
        33.48322
      ],
      [
        69.25066699999998,
        33.224748000000005
      ],
      [
        69.24505999999998,
        33.524579
      ],
      [
        69.21861799999998,
        33.281526
      ],
      [
        69.15019699999998,
        33.487524
      ],
      [
        69.05058899999997,
        33.044996
      ],
      [
        69.12730999999998,
        33.576913999999995
      ],
      [
        69.29295199999999,
        33.574273999999996
      ],
      [
        69.30883599999999,
        33.951775
      ],
      [
        69.12064299999999,
        35.149525999999994
      ],
      [
        69.19386999999999,
        35.27582999999999
      ],
      [
        69.24130799999999,
        35.15771999999999
      ],
      [
        69.19089299999999,
        35.70130199999999
      ],
      [
        69.155898,
        35.79755099999999
      ],
      [
        69.00614999999999,
        35.72730399999999
      ],
      [
        69.18344799999998,
        35.81893999999999
      ],
      [
        69.09692299999999,
        36.28549599999999
      ],
      [
        68.64356299999999,
        37.797133999999986
      ],
      [
        68.29056699999998,
        38.44432699999999
      ],
      [
        68.24834599999998,
        38.78716099999999
      ],
      [
        68.36778399999999,
        38.64354999999999
      ],
      [
        68.01017999999999,
        39.65535499999999
      ],
      [
        68.032175,
        39.84421699999999
      ],
      [
        68.16006499999999,
        39.75324499999999
      ],
      [
        67.88067699999999,
        40.39074599999999
      ],
      [
        67.837143,
        40.27896799999999
      ],
      [
        67.717316,
        40.470105999999994
      ],
      [
        67.797646,
        40.653161999999995
      ],
      [
        67.703317,
        40.965298999999995
      ],
      [
        67.40490199999999,
        41.113161
      ],
      [
        67.256237,
        41.075216
      ],
      [
        67.112736,
        41.381215999999995
      ],
      [
        66.81773199999999,
        41.200689
      ],
      [
        66.40993399999999,
        40.468607999999996
      ],
      [
        66.05359499999999,
        38.675078
      ],
      [
        66.38268199999999,
        35.519854
      ],
      [
        66.59798299999999,
        34.829414
      ],
      [
        66.53726199999998,
        34.483609
      ],
      [
        66.61751399999999,
        34.388246
      ],
      [
        66.68512499999999,
        34.378528
      ],
      [
        66.67417799999998,
        34.110862000000004
      ],
      [
        66.78398699999998,
        34.169107000000004
      ],
      [
        66.68414899999998,
        33.933249
      ],
      [
        66.84470899999998,
        33.62147
      ],
      [
        66.73870699999998,
        33.6435
      ],
      [
        66.75862699999998,
        33.536887
      ],
      [
        66.70618299999998,
        33.637472
      ],
      [
        66.71315599999998,
        33.51697
      ],
      [
        67.02653999999998,
        32.786775
      ],
      [
        67.08737699999999,
        32.963193
      ],
      [
        67.153821,
        32.366997999999995
      ],
      [
        67.129179,
        31.854108999999994
      ],
      [
        67.020293,
        32.513524
      ],
      [
        66.93454,
        32.423939999999995
      ],
      [
        66.913795,
        32.550411
      ],
      [
        66.87621299999999,
        32.408134999999994
      ],
      [
        66.73376499999999,
        32.943082
      ],
      [
        66.61534999999999,
        33.30444
      ],
      [
        66.586396,
        32.590544
      ],
      [
        66.35777499999999,
        32.336654
      ],
      [
        66.35249499999999,
        32.059157000000006
      ],
      [
        66.36250399999999,
        31.855552000000007
      ],
      [
        66.66082799999998,
        31.527495000000005
      ],
      [
        66.62759499999999,
        29.362127000000005
      ],
      [
        66.96076199999999,
        29.039168000000004
      ],
      [
        67.66937099999998,
        30.034527000000004
      ],
      [
        68.07470499999998,
        29.334668000000004
      ],
      [
        68.19512699999999,
        28.659806000000003
      ],
      [
        68.54767999999999,
        28.450362000000002
      ],
      [
        68.87495899999999,
        28.803528000000004
      ],
      [
        68.90842799999999,
        28.434751000000002
      ],
      [
        68.94961799999999,
        28.570890000000002
      ]
    ]
  },
  "Республика Дагестан": {
    "0": [
      [
        44.975815,
        45.706078
      ],
      [
        44.788047999999996,
        46.88137
      ],
      [
        44.570057999999996,
        46.691002
      ],
      [
        44.417306999999994,
        46.712889999999994
      ],
      [
        44.225058999999995,
        47.22699899999999
      ],
      [
        43.77692,
        47.521747999999995
      ],
      [
        43.91453,
        47.738831
      ],
      [
        43.504473,
        47.50147
      ],
      [
        43.005252,
        47.465526
      ],
      [
        42.870391,
        47.690107
      ],
      [
        42.672283,
        47.729219
      ],
      [
        41.9497,
        48.356942000000004
      ],
      [
        41.885033,
        48.533803000000006
      ],
      [
        41.621534,
        48.411469000000004
      ],
      [
        41.488504,
        48.045027000000005
      ],
      [
        41.22531,
        47.891139
      ],
      [
        41.291533,
        47.325054
      ],
      [
        41.556031000000004,
        47.009750000000004
      ],
      [
        41.860116000000005,
        46.754639000000005
      ],
      [
        41.807782,
        46.560944000000006
      ],
      [
        42.214589000000004,
        45.60838700000001
      ],
      [
        42.477612,
        45.76437800000001
      ],
      [
        42.721103,
        46.16471000000001
      ],
      [
        42.912769,
        46.198043000000006
      ],
      [
        42.93555,
        46.53998500000001
      ],
      [
        43.114717999999996,
        46.40193200000001
      ],
      [
        43.42888,
        46.32387600000001
      ],
      [
        43.811929,
        46.677773000000016
      ],
      [
        43.876938,
        46.45249300000002
      ],
      [
        44.000542,
        46.43359500000002
      ],
      [
        43.820267,
        46.047212000000016
      ],
      [
        43.934433,
        46.04609900000002
      ],
      [
        44.015181999999996,
        45.50941700000002
      ],
      [
        44.187200999999995,
        45.51748800000002
      ],
      [
        44.204429,
        45.10415800000002
      ],
      [
        44.307767999999996,
        45.39833200000002
      ],
      [
        44.402767999999995,
        45.44415400000002
      ],
      [
        44.464701999999996,
        45.224153000000015
      ],
      [
        44.57026199999999,
        45.25054200000002
      ],
      [
        44.77608699999999,
        45.629432000000016
      ],
      [
        44.95026599999999,
        45.68748400000002
      ]
    ]
  },
  "Республика Карелия": {
    "0": [
      [
        61.746418,
        30
      ],
      [
        62.452751,
        31.162722
      ],
      [
        62.908027,
        31.580942999999998
      ],
      [
        63.225832999999994,
        31.231250999999997
      ],
      [
        63.75447,
        29.980943999999997
      ],
      [
        63.818278,
        30.239803999999996
      ],
      [
        64.125892,
        30.550303999999997
      ],
      [
        64.40424999999999,
        30.052997999999995
      ],
      [
        64.59172,
        30.018276999999994
      ],
      [
        64.77502299999999,
        30.085858999999996
      ],
      [
        64.890166,
        29.632692999999996
      ],
      [
        65.059164,
        29.622831999999995
      ],
      [
        65.103444,
        29.892858999999994
      ],
      [
        65.213246,
        29.877581999999993
      ],
      [
        65.25108,
        29.609526999999993
      ],
      [
        65.466077,
        29.74196999999999
      ],
      [
        65.564083,
        29.881219999999992
      ],
      [
        65.62791,
        29.734415999999992
      ],
      [
        65.734913,
        30.127775999999994
      ],
      [
        66.12782700000001,
        29.917581999999992
      ],
      [
        66.62757500000001,
        29.362126999999994
      ],
      [
        66.660808,
        31.527494999999995
      ],
      [
        66.36248400000001,
        31.855551999999996
      ],
      [
        66.35247500000001,
        32.059157
      ],
      [
        66.35775500000001,
        32.336653999999996
      ],
      [
        66.58637600000002,
        32.590543999999994
      ],
      [
        66.57668800000002,
        32.963193
      ],
      [
        66.53646700000002,
        33.178025
      ],
      [
        66.52494700000001,
        32.868109
      ],
      [
        66.40944700000001,
        33.710722
      ],
      [
        66.38747500000001,
        33.48847
      ],
      [
        66.253725,
        33.009887
      ],
      [
        66.304002,
        33.609526
      ],
      [
        65.982416,
        34.700666000000005
      ],
      [
        65.868807,
        34.68888700000001
      ],
      [
        65.85934,
        34.852582000000005
      ],
      [
        65.815974,
        34.745664000000005
      ],
      [
        65.78228299999999,
        34.644941
      ],
      [
        65.68772499999999,
        34.764524
      ],
      [
        65.58572799999999,
        34.798136
      ],
      [
        65.425007,
        34.647276
      ],
      [
        65.406781,
        34.360220999999996
      ],
      [
        65.370871,
        34.28072399999999
      ],
      [
        65.32839799999999,
        34.49488699999999
      ],
      [
        65.014754,
        34.82835999999999
      ],
      [
        64.953116,
        34.68235999999999
      ],
      [
        64.832343,
        34.95677699999999
      ],
      [
        64.742812,
        34.736302999999985
      ],
      [
        64.61634,
        34.971496999999985
      ],
      [
        64.516785,
        34.777802999999984
      ],
      [
        64.297067,
        35.33274499999998
      ],
      [
        64.353036,
        35.73355199999998
      ],
      [
        64.131235,
        36.23999899999998
      ],
      [
        63.953166,
        36.507899999999985
      ],
      [
        63.67639500000001,
        36.47192699999999
      ],
      [
        63.59833900000001,
        35.856098999999986
      ],
      [
        63.41833900000001,
        36.22053899999999
      ],
      [
        63.20250500000001,
        36.42054299999999
      ],
      [
        63.031394000000006,
        36.25749599999999
      ],
      [
        62.815009,
        36.51526899999999
      ],
      [
        62.638618,
        37.51693199999999
      ],
      [
        62.362786,
        37.57998899999999
      ],
      [
        62.201952,
        37.43137699999999
      ],
      [
        62.198343,
        37.73887999999999
      ],
      [
        61.896403,
        37.94387299999999
      ],
      [
        61.522236,
        37.77304399999999
      ],
      [
        61.550014,
        36.61220999999999
      ],
      [
        61.405567999999995,
        36.47248599999999
      ],
      [
        61.156667,
        35.424435999999986
      ],
      [
        61.289181,
        35.341101999999985
      ],
      [
        61.323344,
        34.740546999999985
      ],
      [
        61.145289999999996,
        34.41666499999999
      ],
      [
        61.221675,
        33.53360799999999
      ],
      [
        61.155567,
        33.439430999999985
      ],
      [
        61.075016999999995,
        33.55249099999998
      ],
      [
        61.070012,
        33.86804999999998
      ],
      [
        60.940563999999995,
        33.73832799999998
      ],
      [
        61.00307099999999,
        33.42193799999998
      ],
      [
        60.74612099999999,
        33.112771999999985
      ],
      [
        60.64529199999999,
        32.704996999999985
      ],
      [
        61.30391499999999,
        29.276836999999986
      ],
      [
        61.74579699999999,
        29.998557999999985
      ]
    ],
    "1": [
      [
        66.618362,
        33.222026
      ],
      [
        66.517441,
        33.530692
      ],
      [
        66.577225,
        33.057723
      ],
      [
        66.61525,
        33.225665
      ]
    ],
    "2": [
      [
        65.706024,
        34.970085
      ],
      [
        65.790336,
        34.674862
      ],
      [
        65.81794599999999,
        34.893639
      ],
      [
        65.754302,
        34.835305
      ],
      [
        65.70730499999999,
        34.974111
      ]
    ]
  },
  "Ивановская область": {
    "0": [
      [
        57.276299,
        40.445206
      ],
      [
        57.455551,
        41.551102
      ],
      [
        57.751388,
        41.606383
      ],
      [
        57.559432,
        42.174993
      ],
      [
        57.704992000000004,
        42.777212
      ],
      [
        57.384162,
        42.873045
      ],
      [
        57.427488000000004,
        43.15582
      ],
      [
        57.627766,
        43.203044999999996
      ],
      [
        56.862734,
        43.19953099999999
      ],
      [
        56.80332000000001,
        42.827769999999994
      ],
      [
        56.651931000000005,
        42.90109499999999
      ],
      [
        56.465828,
        42.87942899999999
      ],
      [
        56.4786,
        42.272761999999986
      ],
      [
        56.364442,
        42.064709999999984
      ],
      [
        56.540549999999996,
        41.91081699999999
      ],
      [
        56.469437,
        41.07443799999999
      ],
      [
        56.599714999999996,
        40.72637799999999
      ],
      [
        56.43166299999999,
        40.16026999999999
      ],
      [
        56.61027299999999,
        39.82332499999999
      ],
      [
        56.782765999999995,
        39.85609999999999
      ],
      [
        56.793324,
        39.39166199999999
      ],
      [
        56.969434,
        39.58526399999999
      ],
      [
        57.025266,
        39.51554699999999
      ],
      [
        57.281934,
        40.405545999999994
      ]
    ]
  },
  "Кемеровская область": {
    "0": [
      [
        56.833153,
        88.624146
      ],
      [
        56.698326,
        88.74054
      ],
      [
        56.626381,
        88.507217
      ],
      [
        56.442491000000004,
        88.588318
      ],
      [
        56.30277400000001,
        89.066665
      ],
      [
        55.86721200000001,
        89.451658
      ],
      [
        55.26777200000001,
        88.39220999999999
      ],
      [
        54.808887000000006,
        88.76054099999999
      ],
      [
        54.746104,
        88.62498299999999
      ],
      [
        54.422772,
        88.53220999999999
      ],
      [
        54.383885,
        88.37692299999999
      ],
      [
        54.282773,
        88.57887199999999
      ],
      [
        54.41583,
        88.836103
      ],
      [
        54.287221,
        89.011381
      ],
      [
        54.334996000000004,
        89.176084
      ],
      [
        54.148886000000005,
        89.196089
      ],
      [
        54.06610800000001,
        88.979154
      ],
      [
        53.841111000000005,
        89.277203
      ],
      [
        53.586114,
        88.828871
      ],
      [
        53.359728000000004,
        88.75915300000001
      ],
      [
        53.343058000000006,
        88.97637700000001
      ],
      [
        53.13083100000001,
        89.05386200000001
      ],
      [
        52.985279000000006,
        88.891096
      ],
      [
        52.80222800000001,
        89.239148
      ],
      [
        52.708898000000005,
        89.04054000000001
      ],
      [
        52.596396000000006,
        89.12025100000001
      ],
      [
        52.34056100000001,
        88.65775800000002
      ],
      [
        52.244454000000005,
        88.67914900000001
      ],
      [
        52.168023000000005,
        88.36488000000001
      ],
      [
        52.455284000000006,
        88.42220700000001
      ],
      [
        52.41194900000001,
        88.20860000000002
      ],
      [
        52.549178000000005,
        87.92248200000002
      ],
      [
        52.44750200000001,
        87.64166000000002
      ],
      [
        52.581694000000006,
        87.20503800000002
      ],
      [
        52.85362000000001,
        86.93304200000001
      ],
      [
        53.081396000000005,
        87.08166200000001
      ],
      [
        53.246716000000006,
        86.66035300000001
      ],
      [
        53.29778700000001,
        86.86970200000002
      ],
      [
        53.50945000000001,
        87.00610100000002
      ],
      [
        53.532506000000005,
        86.57665800000001
      ],
      [
        53.98417200000001,
        86.145537
      ],
      [
        54.24306100000001,
        85.60470500000001
      ],
      [
        54.19583500000001,
        85.44720500000001
      ],
      [
        54.46944800000001,
        85.079468
      ],
      [
        54.60417400000001,
        84.948593
      ],
      [
        54.73221800000001,
        84.936097
      ],
      [
        54.87333200000001,
        85.079437
      ],
      [
        54.986117000000014,
        84.879701
      ],
      [
        55.170000000000016,
        84.91832099999999
      ],
      [
        55.38861100000002,
        84.875536
      ],
      [
        55.408889000000016,
        84.632753
      ],
      [
        55.54055600000002,
        84.771653
      ],
      [
        55.728048000000015,
        84.543047
      ],
      [
        55.76583500000002,
        84.637208
      ],
      [
        55.86666500000002,
        84.50193900000001
      ],
      [
        56.01694000000002,
        84.551652
      ],
      [
        56.04426900000002,
        84.410677
      ],
      [
        56.23888700000002,
        85.68803500000001
      ],
      [
        56.29972300000002,
        85.56776500000001
      ],
      [
        56.62555000000002,
        86.20721400000001
      ],
      [
        56.54583200000002,
        86.39359900000001
      ],
      [
        56.534441000000015,
        87.026655
      ],
      [
        56.54722000000002,
        87.14776300000001
      ],
      [
        56.674165000000016,
        87.14803700000002
      ],
      [
        56.65915800000002,
        87.51276800000002
      ],
      [
        56.54361200000002,
        87.78442000000003
      ],
      [
        56.826943000000014,
        88.61080400000003
      ]
    ]
  },
  "Вологодская область": {
    "0": [
      [
        59.115959,
        45
      ],
      [
        59.26096999999999,
        43.750198
      ],
      [
        59.16944099999999,
        43.5261
      ],
      [
        59.30165799999999,
        43.488328
      ],
      [
        59.20759499999999,
        43.338204000000005
      ],
      [
        59.16499199999999,
        42.810549
      ],
      [
        59.31860299999999,
        42.612216000000004
      ],
      [
        59.26166499999999,
        42.276104000000004
      ],
      [
        59.42860299999999,
        42.049169000000006
      ],
      [
        59.283332999999985,
        41.95721900000001
      ],
      [
        59.23693799999999,
        42.09415900000001
      ],
      [
        59.12054399999999,
        42.033057000000014
      ],
      [
        58.96527099999999,
        41.16694600000002
      ],
      [
        58.876938999999986,
        41.24777800000002
      ],
      [
        58.724991999999986,
        41.224997000000016
      ],
      [
        58.579247999999986,
        40.92623800000002
      ],
      [
        58.54805099999999,
        40.17972600000002
      ],
      [
        58.90277099999999,
        39.61028300000002
      ],
      [
        58.95721299999999,
        39.101396000000015
      ],
      [
        58.93887999999999,
        38.932504000000016
      ],
      [
        58.73888299999999,
        38.92138900000002
      ],
      [
        58.735824999999984,
        38.04695500000002
      ],
      [
        58.49035699999998,
        37.62643000000002
      ],
      [
        58.870269999999984,
        37.091672000000024
      ],
      [
        58.81805499999999,
        36.785008000000026
      ],
      [
        58.531663999999985,
        36.50140100000002
      ],
      [
        58.51082899999999,
        36.22417100000002
      ],
      [
        58.70443899999999,
        36.11972600000002
      ],
      [
        58.83777099999999,
        35.71694800000002
      ],
      [
        58.76304899999999,
        35.266310000000026
      ],
      [
        58.81330399999999,
        35.119575000000026
      ],
      [
        58.93384899999999,
        35.179008000000024
      ],
      [
        59.05526899999999,
        34.88445400000003
      ],
      [
        59.10336499999999,
        34.72989700000003
      ],
      [
        59.25444099999999,
        34.91195000000003
      ],
      [
        59.302482999999995,
        35.39138100000003
      ],
      [
        59.425543999999995,
        35.45861000000003
      ],
      [
        59.49387899999999,
        35.28363100000003
      ],
      [
        59.48471599999999,
        35.493340000000025
      ],
      [
        59.650265999999995,
        35.49472900000003
      ],
      [
        59.65359899999999,
        35.64112100000003
      ],
      [
        59.71220699999999,
        35.40500600000003
      ],
      [
        59.97601499999999,
        35.41474100000003
      ],
      [
        60.01720599999999,
        35.08473300000003
      ],
      [
        60.593040999999985,
        35.26667900000003
      ],
      [
        60.778868999999986,
        35.14278600000003
      ],
      [
        60.871366999999985,
        35.47472600000003
      ],
      [
        61.217992999999986,
        35.71907200000003
      ],
      [
        61.55469999999998,
        36.64278500000003
      ],
      [
        61.52220699999998,
        37.77306300000003
      ],
      [
        61.14248499999998,
        37.964172000000026
      ],
      [
        61.12275599999998,
        38.18805700000003
      ],
      [
        60.919432999999984,
        38.21528500000003
      ],
      [
        60.95831899999998,
        38.38167300000003
      ],
      [
        60.70776199999998,
        38.642506000000026
      ],
      [
        60.65345699999998,
        39.186634000000026
      ],
      [
        60.74970899999998,
        39.645557000000025
      ],
      [
        60.64776499999998,
        39.96750100000003
      ],
      [
        60.78776399999998,
        40.42583700000003
      ],
      [
        60.784711999999985,
        40.743891000000026
      ],
      [
        60.645819999999986,
        41.35389200000002
      ],
      [
        60.852209999999985,
        41.37833500000002
      ],
      [
        60.91970699999999,
        41.92111200000002
      ],
      [
        60.767760999999986,
        42.40583000000002
      ],
      [
        60.866652999999985,
        42.641113000000026
      ],
      [
        60.734428999999984,
        42.989723000000026
      ],
      [
        60.76554199999998,
        43.521109000000024
      ],
      [
        61.021926999999984,
        43.77416700000003
      ],
      [
        61.082205999999985,
        44.070553000000025
      ],
      [
        61.082487999999984,
        44.17277200000002
      ],
      [
        60.969427999999986,
        44.15360800000002
      ],
      [
        60.90386799999999,
        45.22916100000002
      ],
      [
        61.20776099999999,
        45.34083200000002
      ],
      [
        61.16750099999999,
        45.79594100000002
      ],
      [
        61.02415199999999,
        45.75944200000002
      ],
      [
        60.984982999999986,
        46.05749900000002
      ],
      [
        61.06386999999999,
        46.52083100000002
      ],
      [
        60.82470499999999,
        47.08526800000002
      ],
      [
        60.655256999999985,
        46.95277600000002
      ],
      [
        60.57665199999998,
        47.06166200000002
      ],
      [
        60.51526699999998,
        46.85666000000002
      ],
      [
        60.25637999999998,
        46.74499600000002
      ],
      [
        60.24248799999998,
        46.29499900000002
      ],
      [
        60.06526499999998,
        46.60610200000002
      ],
      [
        60.11831999999998,
        47.036942000000025
      ],
      [
        59.784435999999985,
        46.923050000000025
      ],
      [
        59.76137999999999,
        47.12304600000002
      ],
      [
        59.61137999999999,
        47.11351700000002
      ],
      [
        59.63248899999999,
        46.40526500000002
      ],
      [
        59.34248899999999,
        46.355552000000024
      ],
      [
        59.30971399999999,
        46.03137900000002
      ],
      [
        59.18166399999999,
        45.94915700000002
      ],
      [
        59.11554799999999,
        45.018883000000024
      ]
    ]
  },
  "Магаданская область": {
    "0": [
      [
        64.521378,
        150
      ],
      [
        64.369706,
        150.022766
      ],
      [
        64.24971199999999,
        150.129394
      ],
      [
        64.16470499999998,
        150.60412399999998
      ],
      [
        64.34304999999998,
        150.66525
      ],
      [
        64.29165899999998,
        151.393307
      ],
      [
        64.43637399999999,
        151.479121
      ],
      [
        64.50665499999998,
        152.02330899999998
      ],
      [
        64.36526799999999,
        152.09410999999997
      ],
      [
        64.39305399999999,
        152.41219399999997
      ],
      [
        64.525545,
        152.32497499999997
      ],
      [
        64.68637199999999,
        152.74999199999996
      ],
      [
        64.93803499999999,
        152.49855799999997
      ],
      [
        65.03442399999999,
        152.65523399999998
      ],
      [
        65.23220699999999,
        152.67940299999998
      ],
      [
        65.35998399999998,
        153.463002
      ],
      [
        65.41970699999999,
        153.526082
      ],
      [
        65.706099,
        153.534138
      ],
      [
        65.823607,
        153.47579000000002
      ],
      [
        65.829161,
        154.25441400000003
      ],
      [
        65.944425,
        154.38271000000003
      ],
      [
        66.06442,
        154.21245200000004
      ],
      [
        66.242199,
        154.51079200000004
      ],
      [
        66.20837,
        155.19932900000003
      ],
      [
        66.059414,
        156.32939500000003
      ],
      [
        66.206936,
        156.61967700000002
      ],
      [
        66.033048,
        156.97551200000004
      ],
      [
        65.91303699999999,
        157.13359200000005
      ],
      [
        66.11275799999999,
        157.70439300000004
      ],
      [
        66.11387199999999,
        158.48826600000004
      ],
      [
        66.24886699999999,
        158.39024400000005
      ],
      [
        66.359478,
        158.52922000000007
      ],
      [
        66.203046,
        159.20384100000007
      ],
      [
        65.737195,
        158.92106600000008
      ],
      [
        65.517759,
        160.02744900000008
      ],
      [
        65.160262,
        160.53358200000008
      ],
      [
        65.129974,
        161.3471800000001
      ],
      [
        64.813874,
        161.8591120000001
      ],
      [
        64.648333,
        162.9646710000001
      ],
      [
        64.76582499999999,
        163.1663610000001
      ],
      [
        64.715549,
        163.2644140000001
      ],
      [
        64.555545,
        163.1649580000001
      ],
      [
        64.386935,
        163.4660430000001
      ],
      [
        64.180821,
        163.1507980000001
      ],
      [
        64.138601,
        162.7785750000001
      ],
      [
        63.940273999999995,
        162.9305220000001
      ],
      [
        63.815273999999995,
        162.7149460000001
      ],
      [
        63.626386999999994,
        162.9780070000001
      ],
      [
        63.500555999999996,
        162.9705010000001
      ],
      [
        63.38778,
        162.6760670000001
      ],
      [
        63.181115999999996,
        162.7674680000001
      ],
      [
        63.069452,
        162.2899600000001
      ],
      [
        62.915276999999996,
        162.2760750000001
      ],
      [
        62.708056,
        162.8266110000001
      ],
      [
        62.578342,
        162.6916310000001
      ],
      [
        62.295559,
        162.73576000000008
      ],
      [
        62.182226,
        162.15772600000008
      ],
      [
        62.093337,
        162.53828100000007
      ],
      [
        61.971672,
        162.50690800000007
      ],
      [
        61.935562999999995,
        162.30329600000007
      ],
      [
        61.655705999999995,
        162.51145700000006
      ],
      [
        61.43010099999999,
        161.89129500000007
      ],
      [
        61.337292999999995,
        161.91120600000008
      ],
      [
        61.250738999999996,
        161.55250400000008
      ],
      [
        60.896685999999995,
        160.93331800000007
      ],
      [
        60.71588199999999,
        160.80158900000006
      ],
      [
        60.74690699999999,
        160.42840600000005
      ],
      [
        60.57074099999999,
        160.16389500000005
      ],
      [
        60.71451999999999,
        160.26339600000006
      ],
      [
        60.821906999999996,
        160.18720900000005
      ],
      [
        61.03279499999999,
        160.43984800000004
      ],
      [
        60.92818499999999,
        159.79945200000003
      ],
      [
        61.109573999999995,
        160.00784100000004
      ],
      [
        61.251076,
        159.92709300000004
      ],
      [
        61.236019,
        159.78597900000005
      ],
      [
        61.310963,
        159.91203200000007
      ],
      [
        61.54674,
        160.28193500000006
      ],
      [
        61.689657,
        160.27723600000007
      ],
      [
        61.763543999999996,
        160.4237660000001
      ],
      [
        61.937543999999995,
        160.33143500000008
      ],
      [
        61.65310099999999,
        159.58645600000008
      ],
      [
        61.83051799999999,
        159.5893690000001
      ],
      [
        61.76457399999999,
        159.4700150000001
      ],
      [
        61.88390399999999,
        159.3794240000001
      ],
      [
        61.920737999999986,
        159.02548100000013
      ],
      [
        61.78604199999999,
        157.49806100000012
      ],
      [
        61.64151599999999,
        157.0436560000001
      ],
      [
        61.51326599999999,
        156.6844650000001
      ],
      [
        61.19573899999999,
        156.6567400000001
      ],
      [
        60.943212999999986,
        155.9400190000001
      ],
      [
        60.71324199999999,
        155.9152400000001
      ],
      [
        60.29899299999999,
        154.8265400000001
      ],
      [
        59.93268699999999,
        154.48474400000012
      ],
      [
        60.09621399999999,
        154.57853900000012
      ],
      [
        60.06749099999999,
        154.41507300000012
      ],
      [
        59.88104699999999,
        154.4871240000001
      ],
      [
        59.88943599999999,
        154.47659600000011
      ],
      [
        59.87407499999999,
        154.2232090000001
      ],
      [
        59.57751999999999,
        154.3705930000001
      ],
      [
        59.66715799999999,
        154.2188750000001
      ],
      [
        59.41227299999999,
        154.0640760000001
      ],
      [
        59.536745999999994,
        154.3924600000001
      ],
      [
        59.41774399999999,
        154.52649400000013
      ],
      [
        59.55088299999999,
        154.41798800000012
      ],
      [
        59.497550999999994,
        154.96335300000013
      ],
      [
        59.34588299999999,
        155.20888100000013
      ],
      [
        59.172771999999995,
        155.17519000000013
      ],
      [
        59.21660599999999,
        154.46169000000012
      ],
      [
        59.04599399999999,
        154.04318900000013
      ],
      [
        59.18271599999999,
        153.68404300000012
      ],
      [
        59.24896499999999,
        153.75502600000013
      ],
      [
        59.24202299999999,
        153.39040300000013
      ],
      [
        59.08679899999999,
        153.33515100000014
      ],
      [
        59.06202199999999,
        153.01415300000014
      ],
      [
        58.90638299999999,
        152.91396400000014
      ],
      [
        59.05699399999999,
        152.35643900000014
      ],
      [
        58.89152099999999,
        152.08933500000015
      ],
      [
        58.83335399999999,
        151.35543400000014
      ],
      [
        59.09568599999999,
        151.11916800000014
      ],
      [
        59.223020999999996,
        152.32052200000015
      ],
      [
        59.299215,
        151.74482400000016
      ],
      [
        59.600908,
        151.41541800000016
      ],
      [
        59.561243,
        150.91375400000015
      ],
      [
        59.444410999999995,
        150.93357500000016
      ],
      [
        59.434524999999994,
        150.77241300000017
      ],
      [
        59.490272999999995,
        150.49322400000017
      ],
      [
        59.56424199999999,
        150.76310500000017
      ],
      [
        59.76001899999999,
        149.42442100000017
      ],
      [
        59.644521999999995,
        149.07607800000017
      ],
      [
        59.461966999999994,
        149.22657500000017
      ],
      [
        59.48040999999999,
        148.75994600000016
      ],
      [
        59.36118999999999,
        148.74088800000015
      ],
      [
        59.36824299999999,
        148.99894400000017
      ],
      [
        59.23402399999999,
        148.93133300000017
      ],
      [
        59.24963299999999,
        148.43293700000015
      ],
      [
        59.37727299999999,
        148.40370100000015
      ],
      [
        59.40863299999999,
        148.21002100000015
      ],
      [
        59.37024599999999,
        147.83454900000015
      ],
      [
        59.239519999999985,
        147.48581000000016
      ],
      [
        59.367513999999986,
        146.88553000000016
      ],
      [
        59.503072999999986,
        147.13107300000016
      ],
      [
        59.84945399999999,
        147.14331000000016
      ],
      [
        59.936953999999986,
        147.03857300000016
      ],
      [
        59.90389599999999,
        146.76803500000017
      ],
      [
        60.09945199999999,
        146.68246400000018
      ],
      [
        60.13000699999999,
        146.44830300000018
      ],
      [
        60.27250699999999,
        146.3763440000002
      ],
      [
        60.19362099999999,
        145.79663300000018
      ],
      [
        60.302789999999995,
        145.5977510000002
      ],
      [
        60.468613,
        145.50388000000018
      ],
      [
        60.558899,
        145.61801500000018
      ],
      [
        60.739172999999994,
        146.0574690000002
      ],
      [
        60.70362099999999,
        146.45996400000018
      ],
      [
        60.92278199999999,
        146.47662600000018
      ],
      [
        61.00195199999999,
        146.73523100000017
      ],
      [
        61.10695499999999,
        146.74939200000017
      ],
      [
        61.30055899999999,
        146.67831700000016
      ],
      [
        61.40527899999999,
        146.77276900000015
      ],
      [
        61.43513999999999,
        146.60818800000015
      ],
      [
        61.63194699999999,
        146.58887000000016
      ],
      [
        61.82389499999999,
        146.36246100000017
      ],
      [
        62.004177999999996,
        145.97440100000017
      ],
      [
        61.962506999999995,
        145.17609200000018
      ],
      [
        62.080563999999995,
        144.71246900000017
      ],
      [
        62.27139,
        145.18054700000016
      ],
      [
        62.491671999999994,
        145.12079500000016
      ],
      [
        62.54861699999999,
        145.48969100000016
      ],
      [
        62.771944999999995,
        145.30664700000017
      ],
      [
        63.196664999999996,
        145.24719900000017
      ],
      [
        63.667778999999996,
        145.64472000000018
      ],
      [
        63.825005,
        145.54776600000017
      ],
      [
        63.969161,
        146.17304100000015
      ],
      [
        64.212776,
        146.40802500000015
      ],
      [
        64.050545,
        147.24301600000015
      ],
      [
        64.173042,
        147.62079400000016
      ],
      [
        64.04137399999999,
        147.60413200000016
      ],
      [
        63.94388599999999,
        147.95941700000017
      ],
      [
        64.239983,
        148.26025900000016
      ],
      [
        64.433875,
        148.00943600000016
      ],
      [
        64.557761,
        148.30246600000015
      ],
      [
        64.413612,
        148.61163800000014
      ],
      [
        64.53028,
        149.96051500000013
      ]
    ],
    "1": [
      [
        59.108444,
        149.006134
      ],
      [
        59.202445,
        149.153884
      ],
      [
        59.114611999999994,
        149.011672
      ]
    ],
    "2": [
      [
        59.082138,
        150.634003
      ],
      [
        59.092998,
        150.787384
      ],
      [
        59.003665,
        150.47458
      ],
      [
        59.087804999999996,
        150.619644
      ]
    ]
  },
  "Республика Татарстан": {
    "0": [
      [
        54.706123,
        47.30336
      ],
      [
        54.894439,
        47.423880999999994
      ],
      [
        54.809714,
        47.8486
      ],
      [
        54.937208,
        47.781935999999995
      ],
      [
        54.968877,
        47.997769999999996
      ],
      [
        55.062205999999996,
        47.913602999999995
      ],
      [
        55.14637999999999,
        48.08915399999999
      ],
      [
        55.278870999999995,
        48.020542999999996
      ],
      [
        55.211932,
        47.756941
      ],
      [
        55.316095999999995,
        47.686932999999996
      ],
      [
        55.53498999999999,
        48.098043
      ],
      [
        55.687203999999994,
        48.079992
      ],
      [
        55.905815999999994,
        48.421101
      ],
      [
        55.952208999999996,
        48.6936
      ],
      [
        56.203039999999994,
        49.051929
      ],
      [
        56.262204999999994,
        48.923046
      ],
      [
        56.360263999999994,
        49.035266
      ],
      [
        56.34831799999999,
        49.347208
      ],
      [
        56.53165099999999,
        49.651094
      ],
      [
        56.43803099999999,
        49.981096
      ],
      [
        56.58803099999999,
        50.205545
      ],
      [
        56.62247799999999,
        50.069437
      ],
      [
        56.676371999999986,
        50.303041
      ],
      [
        56.616373999999986,
        50.493317
      ],
      [
        56.35776199999999,
        50.579986
      ],
      [
        56.390261999999986,
        50.851379
      ],
      [
        56.241092999999985,
        50.839424
      ],
      [
        56.124980999999984,
        51.076376
      ],
      [
        56.148592999999984,
        51.644705
      ],
      [
        55.944422999999986,
        51.414703
      ],
      [
        55.88386999999999,
        51.788879
      ],
      [
        55.97914399999999,
        51.960814
      ],
      [
        55.89303899999999,
        52.169149
      ],
      [
        56.08414699999999,
        52.204701
      ],
      [
        56.14136599999999,
        52.700535
      ],
      [
        56.09220199999999,
        52.836926000000005
      ],
      [
        56.202476999999995,
        52.816930000000006
      ],
      [
        56.262480999999994,
        52.55831800000001
      ],
      [
        56.441641999999995,
        52.95276400000001
      ],
      [
        56.52719799999999,
        52.82748300000001
      ],
      [
        56.52692299999999,
        53.095259000000006
      ],
      [
        56.36692099999999,
        52.938591
      ],
      [
        56.135254999999994,
        53.047484000000004
      ],
      [
        56.084703999999995,
        53.344985
      ],
      [
        56.248864999999995,
        53.255264000000004
      ],
      [
        56.273309999999995,
        53.339148
      ],
      [
        56.18165199999999,
        53.566092000000005
      ],
      [
        55.91997899999999,
        53.187201
      ],
      [
        55.88025999999999,
        53.868590000000005
      ],
      [
        55.69136499999999,
        54.24192600000001
      ],
      [
        55.20776099999999,
        53.586096000000005
      ],
      [
        55.14387299999999,
        53.138876
      ],
      [
        54.90137299999999,
        53.642485
      ],
      [
        54.38693099999999,
        53.344429
      ],
      [
        54.04518899999999,
        53.470191
      ],
      [
        53.96858999999999,
        53.380538
      ],
      [
        54.06998499999999,
        53.060538
      ],
      [
        54.213599999999985,
        52.936927000000004
      ],
      [
        54.249425999999985,
        53.115821000000004
      ],
      [
        54.34164999999999,
        53.047486000000006
      ],
      [
        54.273870999999986,
        52.886376000000006
      ],
      [
        54.478864999999985,
        52.439423000000005
      ],
      [
        54.33942299999998,
        52.08525900000001
      ],
      [
        54.53609999999998,
        51.927209000000005
      ],
      [
        54.67720499999999,
        51.395540000000004
      ],
      [
        54.55164899999998,
        51.009714
      ],
      [
        54.341923999999985,
        50.943873
      ],
      [
        54.42831899999999,
        50.703045
      ],
      [
        54.326374999999985,
        50.518596
      ],
      [
        54.488872999999984,
        50.388881000000005
      ],
      [
        54.56943099999999,
        49.559429
      ],
      [
        54.89165099999999,
        49.281377
      ],
      [
        54.64541299999999,
        48.822897
      ],
      [
        54.76858899999999,
        48.07749
      ],
      [
        54.643314999999994,
        47.838598999999995
      ],
      [
        54.73609499999999,
        47.750274999999995
      ],
      [
        54.50053799999999,
        47.41054499999999
      ],
      [
        54.693034999999995,
        47.30304799999999
      ]
    ]
  },
  "Воронежская область": {
    "0": [
      [
        51.96109,
        38.298874
      ],
      [
        52.102219,
        38.68277
      ],
      [
        51.988046,
        39.036384999999996
      ],
      [
        52.107772999999995,
        39.269439999999996
      ],
      [
        51.92499599999999,
        39.732206999999995
      ],
      [
        51.91721499999999,
        40.86832199999999
      ],
      [
        51.73999299999999,
        40.88304699999999
      ],
      [
        51.709993999999995,
        41.06110199999999
      ],
      [
        51.803324999999994,
        41.50110399999999
      ],
      [
        51.640834999999996,
        42.09943199999999
      ],
      [
        51.728604,
        42.191655999999995
      ],
      [
        51.599720999999995,
        42.260273999999995
      ],
      [
        51.483608999999994,
        42.728595999999996
      ],
      [
        51.240835,
        42.91220499999999
      ],
      [
        51.188604999999995,
        41.854991999999996
      ],
      [
        51.01721799999999,
        41.72859699999999
      ],
      [
        50.77638799999999,
        41.15637699999999
      ],
      [
        50.60166799999999,
        41.528324999999995
      ],
      [
        50.50527899999999,
        41.417494
      ],
      [
        50.38111199999999,
        41.512495
      ],
      [
        50.16555099999999,
        41.276655
      ],
      [
        49.95861099999998,
        41.088325
      ],
      [
        49.79889099999998,
        41.034996
      ],
      [
        49.59778099999998,
        40.527772
      ],
      [
        49.563844999999986,
        39.836389
      ],
      [
        49.81326099999998,
        39.225446999999996
      ],
      [
        50.05750399999998,
        39.275552
      ],
      [
        50.43555499999998,
        38.984994
      ],
      [
        50.61361599999998,
        39.117493
      ],
      [
        50.78666599999998,
        38.725267
      ],
      [
        50.900548999999984,
        38.731934
      ],
      [
        50.900274999999986,
        38.873321000000004
      ],
      [
        51.03639099999999,
        38.849433000000005
      ],
      [
        51.07277399999999,
        38.39721600000001
      ],
      [
        51.18943499999999,
        38.50277500000001
      ],
      [
        51.281384999999986,
        38.29499700000001
      ],
      [
        51.37027399999999,
        38.35499500000001
      ],
      [
        51.62083099999999,
        38.13749700000001
      ],
      [
        51.73582899999999,
        38.50777400000001
      ],
      [
        51.914157999999986,
        38.42166200000001
      ],
      [
        51.95694299999999,
        38.29999700000001
      ]
    ]
  },
  "Белгородская область": {
    "0": [
      [
        51.292572,
        38.291656
      ],
      [
        51.18943,
        38.502776000000004
      ],
      [
        51.072769,
        38.397217000000005
      ],
      [
        51.036386,
        38.849434
      ],
      [
        50.900544000000004,
        38.731935
      ],
      [
        50.783609000000006,
        38.726938
      ],
      [
        50.613611000000006,
        39.11749399999999
      ],
      [
        50.43194100000001,
        38.98555199999999
      ],
      [
        50.05749900000001,
        39.27555299999999
      ],
      [
        49.831506000000005,
        39.10572399999999
      ],
      [
        50.086144000000004,
        38.328307999999986
      ],
      [
        49.955144000000004,
        38.199505999999985
      ],
      [
        49.909449,
        38.03678199999999
      ],
      [
        50.441477,
        37.48933899999999
      ],
      [
        50.232893,
        36.613255999999986
      ],
      [
        50.450700999999995,
        36.13072799999998
      ],
      [
        50.376647,
        35.62403299999998
      ],
      [
        50.590759,
        35.40844999999998
      ],
      [
        50.761924,
        35.49328599999998
      ],
      [
        50.960121,
        35.350070999999986
      ],
      [
        50.939995,
        35.81444599999998
      ],
      [
        51.053887,
        36.00666799999998
      ],
      [
        51.160553,
        35.97972199999998
      ],
      [
        51.076950000000004,
        36.61721799999998
      ],
      [
        51.234725000000005,
        37.13527599999998
      ],
      [
        51.345443,
        37.13821199999998
      ],
      [
        51.420279,
        37.823056999999984
      ],
      [
        51.2975,
        38.26805599999999
      ]
    ]
  },
  "Ярославская область": {
    "0": [
      [
        58.490356,
        37.626419
      ],
      [
        58.735824,
        38.046943999999996
      ],
      [
        58.738882000000004,
        38.921378
      ],
      [
        58.93887900000001,
        38.932492999999994
      ],
      [
        58.957212000000006,
        39.10138499999999
      ],
      [
        58.902770000000004,
        39.610271999999995
      ],
      [
        58.54805,
        40.179714999999995
      ],
      [
        58.581665,
        40.91998699999999
      ],
      [
        58.356941,
        41.171930999999994
      ],
      [
        57.919442,
        40.60082599999999
      ],
      [
        57.581942,
        40.66414899999999
      ],
      [
        57.500277999999994,
        40.37942899999999
      ],
      [
        57.27630299999999,
        40.44520099999999
      ],
      [
        57.02527299999999,
        39.51554399999999
      ],
      [
        56.96944099999999,
        39.58526099999999
      ],
      [
        56.84388399999999,
        39.389712999999986
      ],
      [
        56.53833399999999,
        39.28360399999999
      ],
      [
        56.541110999999994,
        38.55637799999999
      ],
      [
        56.868883999999994,
        38.11970999999999
      ],
      [
        57.073609999999995,
        38.26110399999999
      ],
      [
        57.22943299999999,
        38.15192899999999
      ],
      [
        57.36832499999999,
        38.28776899999999
      ],
      [
        57.43138199999999,
        37.78749499999999
      ],
      [
        57.628876999999996,
        37.830271999999994
      ],
      [
        57.89055,
        37.338595999999995
      ],
      [
        57.971931999999995,
        37.362491
      ],
      [
        58.06999199999999,
        37.671374
      ],
      [
        58.24887799999999,
        37.344149
      ],
      [
        58.46610199999999,
        37.6486
      ]
    ]
  },
  "Ульяновская область": {
    "0": [
      [
        54.772118,
        46.441467
      ],
      [
        54.629433,
        46.85305
      ],
      [
        54.706122,
        47.303359
      ],
      [
        54.500549,
        47.410543
      ],
      [
        54.736106,
        47.750273
      ],
      [
        54.643326,
        47.838597
      ],
      [
        54.7686,
        48.077488
      ],
      [
        54.645424,
        48.822895
      ],
      [
        54.891662,
        49.281375000000004
      ],
      [
        54.569441999999995,
        49.55942700000001
      ],
      [
        54.519988,
        50.070825000000006
      ],
      [
        54.276938,
        50.26110200000001
      ],
      [
        54.037215,
        50.19748200000001
      ],
      [
        53.961380000000005,
        49.97442900000001
      ],
      [
        53.822495,
        50.03110000000001
      ],
      [
        53.859435000000005,
        49.036938000000006
      ],
      [
        53.712768000000004,
        48.86332300000001
      ],
      [
        53.770827000000004,
        48.47221100000001
      ],
      [
        53.669441000000006,
        48.603322000000006
      ],
      [
        53.655823000000005,
        48.314153000000005
      ],
      [
        53.581383,
        48.440822000000004
      ],
      [
        53.471376,
        48.378322000000004
      ],
      [
        53.477708,
        48.048519000000006
      ],
      [
        53.370272,
        47.943043
      ],
      [
        53.14583,
        48.212208000000004
      ],
      [
        53.059160999999996,
        48.14471
      ],
      [
        52.985552999999996,
        48.318324000000004
      ],
      [
        52.947216,
        48.563318
      ],
      [
        52.813886,
        48.499712
      ],
      [
        52.80138899999999,
        48.109431
      ],
      [
        52.74721999999999,
        47.984989
      ],
      [
        52.72721699999999,
        47.844989999999996
      ],
      [
        52.57916999999999,
        47.724706
      ],
      [
        52.56638999999999,
        47.068601
      ],
      [
        52.64833699999999,
        46.818326
      ],
      [
        52.76666799999999,
        46.990825
      ],
      [
        53.172496999999986,
        46.871097
      ],
      [
        53.346384999999984,
        46.902766
      ],
      [
        53.50972199999998,
        46.490543
      ],
      [
        53.926659999999984,
        46.197208
      ],
      [
        53.989899999999984,
        45.805134
      ],
      [
        54.159987999999984,
        46.003322000000004
      ],
      [
        54.31166099999999,
        46.722483000000004
      ],
      [
        54.44887499999999,
        46.510822000000005
      ],
      [
        54.49332399999999,
        46.476933
      ],
      [
        54.579436999999984,
        46.435262
      ],
      [
        54.64582799999999,
        46.529156
      ],
      [
        54.768599999999985,
        46.444989
      ]
    ]
  },
  "Владимирская область": {
    "0": [
      [
        56.696587,
        38.374146
      ],
      [
        56.542497000000004,
        38.548332
      ],
      [
        56.53694300000001,
        39.278603000000004
      ],
      [
        56.77221800000001,
        39.356383
      ],
      [
        56.82388300000001,
        39.663878000000004
      ],
      [
        56.77971700000001,
        39.858885
      ],
      [
        56.61027600000001,
        39.823325000000004
      ],
      [
        56.48333200000001,
        39.947768
      ],
      [
        56.431666000000014,
        40.16026900000001
      ],
      [
        56.59887900000001,
        40.509161000000006
      ],
      [
        56.46944000000001,
        41.074437
      ],
      [
        56.54222400000001,
        41.901096
      ],
      [
        56.38138200000001,
        41.965541
      ],
      [
        56.47860300000001,
        42.272760000000005
      ],
      [
        56.41138000000001,
        42.642762000000005
      ],
      [
        56.22471300000001,
        42.771927000000005
      ],
      [
        56.18721500000001,
        42.97692800000001
      ],
      [
        56.13889000000001,
        42.68581400000001
      ],
      [
        56.00417200000001,
        42.71331800000001
      ],
      [
        55.65027600000001,
        42.09053800000001
      ],
      [
        55.442498000000015,
        42.13803800000001
      ],
      [
        55.15479400000002,
        41.876022000000006
      ],
      [
        55.14306100000002,
        41.08554000000001
      ],
      [
        55.259729000000014,
        40.98832000000001
      ],
      [
        55.330835000000015,
        40.53526500000001
      ],
      [
        55.309443000000016,
        40.20665100000001
      ],
      [
        55.51999900000001,
        40.139711000000005
      ],
      [
        55.52138700000001,
        40.02665100000001
      ],
      [
        55.825837000000014,
        39.857493000000005
      ],
      [
        55.75889700000001,
        39.354992
      ],
      [
        55.96222000000001,
        38.597485000000006
      ],
      [
        56.53194400000001,
        38.292767000000005
      ],
      [
        56.69222100000001,
        38.37304400000001
      ]
    ]
  },
  "Новосибирская область": {
    "0": [
      [
        57.250275,
        76.108871
      ],
      [
        57.161377,
        78.44442699999999
      ],
      [
        56.930550000000004,
        79.587493
      ],
      [
        56.429711000000005,
        80.286497
      ],
      [
        56.540031000000006,
        81.179913
      ],
      [
        56.253327000000006,
        81.535809
      ],
      [
        56.53611000000001,
        82.839977
      ],
      [
        56.55332200000001,
        83.090816
      ],
      [
        56.45499500000001,
        83.25942500000001
      ],
      [
        56.23472000000001,
        83.06525800000001
      ],
      [
        56.20860500000001,
        83.22275800000001
      ],
      [
        56.13721800000001,
        83.11248300000001
      ],
      [
        56.096660000000014,
        83.29552600000001
      ],
      [
        56.04583400000001,
        83.22053000000001
      ],
      [
        55.893056000000016,
        83.41581200000002
      ],
      [
        55.755003000000016,
        83.23081600000002
      ],
      [
        55.680281000000015,
        83.29108800000002
      ],
      [
        55.696103000000015,
        83.64136900000001
      ],
      [
        56.01721600000001,
        83.96859300000001
      ],
      [
        56.04388700000001,
        84.53081800000001
      ],
      [
        55.86666500000001,
        84.50193300000001
      ],
      [
        55.76583500000001,
        84.637202
      ],
      [
        55.72804800000001,
        84.543041
      ],
      [
        55.525557000000006,
        84.775538
      ],
      [
        55.40888900000001,
        84.632747
      ],
      [
        55.38861100000001,
        84.87553
      ],
      [
        55.17000000000001,
        84.91831499999999
      ],
      [
        54.98611700000001,
        84.879695
      ],
      [
        54.873332000000005,
        85.079431
      ],
      [
        54.732218,
        84.936091
      ],
      [
        54.604174,
        84.948587
      ],
      [
        54.469448,
        85.079462
      ],
      [
        54.139172,
        84.454431
      ],
      [
        54.088339000000005,
        83.906366
      ],
      [
        54.132222000000006,
        83.576379
      ],
      [
        53.940557000000005,
        83.408319
      ],
      [
        54.06973000000001,
        83.195811
      ],
      [
        53.79611700000001,
        82.73748400000001
      ],
      [
        53.55528100000001,
        82.53136800000001
      ],
      [
        53.46630000000001,
        82.48235700000002
      ],
      [
        53.60084300000001,
        82.17191800000002
      ],
      [
        53.70258000000001,
        82.20483000000002
      ],
      [
        53.70778300000001,
        81.83248600000002
      ],
      [
        53.848889000000014,
        81.71832000000002
      ],
      [
        53.97138600000002,
        81.85748000000002
      ],
      [
        53.92861600000002,
        81.55554000000002
      ],
      [
        54.02277800000002,
        81.58526400000002
      ],
      [
        54.133091000000015,
        81.12292400000003
      ],
      [
        54.396220000000014,
        80.92491000000003
      ],
      [
        54.259906000000015,
        80.84593100000002
      ],
      [
        54.080371000000014,
        80.13926600000002
      ],
      [
        53.715283000000014,
        79.52165200000002
      ],
      [
        53.65611000000001,
        78.66581800000002
      ],
      [
        53.52528200000001,
        78.63081600000001
      ],
      [
        53.48861500000001,
        78.39499200000002
      ],
      [
        53.61250800000001,
        78.20943100000001
      ],
      [
        53.57861900000001,
        77.93304800000001
      ],
      [
        53.46000400000001,
        78.05998500000001
      ],
      [
        53.34810000000001,
        77.80221900000001
      ],
      [
        54.13173400000001,
        76.43105700000001
      ],
      [
        54.16037500000001,
        76.74864500000001
      ],
      [
        54.30959400000001,
        76.87150000000001
      ],
      [
        54.37801000000001,
        76.78242000000002
      ],
      [
        54.45792700000001,
        76.92825500000002
      ],
      [
        54.12929500000001,
        75.69085100000002
      ],
      [
        54.534174000000014,
        75.67776700000002
      ],
      [
        54.69305700000002,
        75.80887000000001
      ],
      [
        54.91416400000002,
        75.34027400000001
      ],
      [
        55.02805600000002,
        75.400272
      ],
      [
        55.38222000000002,
        75.091374
      ],
      [
        55.50722600000002,
        75.325001
      ],
      [
        55.59888500000002,
        75.131367
      ],
      [
        55.659996000000014,
        75.237766
      ],
      [
        55.76361900000001,
        75.094152
      ],
      [
        55.76667100000001,
        75.37025899999999
      ],
      [
        55.89167100000001,
        75.31219899999999
      ],
      [
        56.14111300000001,
        75.748875
      ],
      [
        56.18555300000001,
        76.318882
      ],
      [
        56.31416100000001,
        76.116657
      ],
      [
        56.451107000000015,
        76.141941
      ],
      [
        56.450001000000015,
        75.844151
      ],
      [
        56.726939000000016,
        76.289433
      ],
      [
        57.24610300000002,
        76.109426
      ]
    ]
  },
  "Ленинградская область": {
    "0": [
      [
        59.959415,
        30.237806
      ],
      [
        60.010306,
        29.971666
      ],
      [
        60.163886,
        29.873862
      ],
      [
        60.161724,
        29.144334999999998
      ],
      [
        60.352306,
        28.620918
      ],
      [
        60.547165,
        28.447502
      ],
      [
        60.44,
        28.718224
      ],
      [
        60.601501,
        28.554641999999998
      ],
      [
        60.592999,
        28.707641
      ],
      [
        60.66397,
        28.754194
      ],
      [
        60.77236,
        28.712277999999998
      ],
      [
        60.511663999999996,
        28.242082999999997
      ],
      [
        60.534803999999994,
        27.794332999999998
      ],
      [
        61.303892999999995,
        29.276833999999997
      ],
      [
        60.64527,
        32.704994
      ],
      [
        60.746098999999994,
        33.112769
      ],
      [
        61.003049,
        33.421935
      ],
      [
        60.940542,
        33.738324999999996
      ],
      [
        61.069990000000004,
        33.868047
      ],
      [
        61.074995,
        33.552488
      ],
      [
        61.155545000000004,
        33.439428
      ],
      [
        61.221653,
        33.533605
      ],
      [
        61.145268,
        34.416662
      ],
      [
        61.323322000000005,
        34.740544
      ],
      [
        61.289159000000005,
        35.341099
      ],
      [
        61.154425,
        35.405262
      ],
      [
        61.218,
        35.71905
      ],
      [
        60.871374,
        35.474704
      ],
      [
        60.778876000000004,
        35.142764
      ],
      [
        60.593048,
        35.266657
      ],
      [
        60.035272000000006,
        35.083322
      ],
      [
        59.97602200000001,
        35.41472
      ],
      [
        59.71221400000001,
        35.404985
      ],
      [
        59.64027700000001,
        35.637766000000006
      ],
      [
        59.650272000000015,
        35.494708
      ],
      [
        59.48472200000001,
        35.493319
      ],
      [
        59.49388500000001,
        35.28361
      ],
      [
        59.425550000000015,
        35.458589
      ],
      [
        59.302489000000016,
        35.391360000000006
      ],
      [
        59.25444700000001,
        34.91192900000001
      ],
      [
        59.09833600000001,
        34.80609400000001
      ],
      [
        59.14749900000001,
        34.004993000000006
      ],
      [
        59.37527400000001,
        33.52943100000001
      ],
      [
        59.32361600000001,
        33.243322000000006
      ],
      [
        59.43527200000001,
        33.008605
      ],
      [
        59.12249700000001,
        32.665267
      ],
      [
        59.20278100000001,
        32.286659
      ],
      [
        59.39805400000001,
        32.131104
      ],
      [
        59.36888700000001,
        31.550831000000002
      ],
      [
        59.20861000000001,
        31.558887000000002
      ],
      [
        58.984443000000006,
        31.315
      ],
      [
        59.077498000000006,
        31.003611000000003
      ],
      [
        58.74888500000001,
        30.713331000000004
      ],
      [
        58.73333800000001,
        30.095832000000005
      ],
      [
        58.53277700000001,
        30.174167000000004
      ],
      [
        58.44305700000001,
        30.041110000000003
      ],
      [
        58.47527600000001,
        29.543614
      ],
      [
        58.82222900000001,
        28.960279
      ],
      [
        58.84888600000001,
        28.280556999999998
      ],
      [
        58.979439000000006,
        27.702344999999998
      ],
      [
        59.24376000000001,
        27.904253999999998
      ],
      [
        59.36031400000001,
        28.205503999999998
      ],
      [
        59.47081300000001,
        28.048892999999996
      ],
      [
        59.79525700000001,
        28.110308999999997
      ],
      [
        59.657452000000006,
        28.354086999999996
      ],
      [
        59.834228,
        28.485836999999997
      ],
      [
        59.814537,
        28.988528999999996
      ],
      [
        59.884285,
        29.084583999999996
      ],
      [
        59.98737,
        29.130387999999996
      ],
      [
        59.857091,
        30.166748999999996
      ],
      [
        59.957094,
        30.236192999999997
      ]
    ],
    "1": [
      [
        60.261723,
        28.699194
      ],
      [
        60.34955600000001,
        28.545555
      ],
      [
        60.263527,
        28.705359
      ]
    ]
  },
  "Астраханская область": {
    "0": [
      [
        48.295681,
        45
      ],
      [
        48.486381,
        45.190268
      ],
      [
        48.463881,
        45.375547000000005
      ],
      [
        48.557211,
        45.244437000000005
      ],
      [
        48.594992000000005,
        45.619155000000006
      ],
      [
        48.86749100000001,
        46.01444000000001
      ],
      [
        48.84082600000001,
        46.30859900000001
      ],
      [
        48.67057200000001,
        46.616583000000006
      ],
      [
        48.443000000000005,
        46.49185800000001
      ],
      [
        48.26549800000001,
        47.11377600000001
      ],
      [
        48.11500100000001,
        47.09183500000001
      ],
      [
        48.07430500000001,
        47.20744300000001
      ],
      [
        47.99999900000001,
        47.01763900000001
      ],
      [
        47.76075000000001,
        47.17430500000001
      ],
      [
        47.67625000000001,
        47.37697100000001
      ],
      [
        47.81502800000001,
        47.408220000000014
      ],
      [
        47.76272100000001,
        48.05233100000002
      ],
      [
        47.433582000000015,
        48.38069100000001
      ],
      [
        47.41447200000002,
        48.60774600000001
      ],
      [
        46.767167000000015,
        49.00532900000001
      ],
      [
        46.68408400000001,
        48.80088500000001
      ],
      [
        46.773947000000014,
        48.581221000000006
      ],
      [
        46.65766700000001,
        48.480692000000005
      ],
      [
        46.292696000000014,
        49.220915000000005
      ],
      [
        46.36475100000001,
        49.04577400000001
      ],
      [
        46.13400100000001,
        49.008860000000006
      ],
      [
        46.14308400000001,
        48.86435900000001
      ],
      [
        46.06405900000001,
        48.81352400000001
      ],
      [
        45.91455800000001,
        48.71397300000001
      ],
      [
        45.61319700000001,
        48.70141500000001
      ],
      [
        45.71755900000001,
        48.61369300000001
      ],
      [
        45.800948000000005,
        48.737946000000015
      ],
      [
        45.956362000000006,
        48.54763800000001
      ],
      [
        45.85139000000001,
        48.44850300000002
      ],
      [
        45.67994600000001,
        48.51741900000002
      ],
      [
        45.82300400000001,
        48.35889200000002
      ],
      [
        45.71322600000001,
        48.33152900000002
      ],
      [
        45.59586400000001,
        48.418168000000016
      ],
      [
        45.74731100000001,
        48.268946000000014
      ],
      [
        45.72281000000001,
        47.854140000000015
      ],
      [
        45.47417300000001,
        47.865862000000014
      ],
      [
        45.68681200000001,
        47.754947000000016
      ],
      [
        45.49470000000001,
        47.748032000000016
      ],
      [
        45.64597900000001,
        47.66006200000002
      ],
      [
        45.47285400000001,
        47.58796500000002
      ],
      [
        45.689430000000016,
        47.35971700000002
      ],
      [
        45.574722000000015,
        46.89305000000002
      ],
      [
        46.105559000000014,
        47.174993000000015
      ],
      [
        46.07276800000001,
        46.83360900000002
      ],
      [
        46.24805200000001,
        46.866658000000015
      ],
      [
        46.27555600000001,
        46.47833100000002
      ],
      [
        46.42804500000001,
        46.66027700000002
      ],
      [
        46.42860000000001,
        46.96194200000002
      ],
      [
        46.55526400000001,
        46.91332000000002
      ],
      [
        46.74082500000001,
        47.22777300000002
      ],
      [
        46.996387000000006,
        46.79639200000002
      ],
      [
        47.391932000000004,
        46.515554000000016
      ],
      [
        47.576098,
        46.83054900000002
      ],
      [
        47.632486,
        46.68082200000002
      ],
      [
        47.43111,
        46.33138900000002
      ],
      [
        47.489985999999995,
        46.14193600000002
      ],
      [
        47.83637399999999,
        45.736113000000024
      ],
      [
        48.03388399999999,
        45.71887900000002
      ],
      [
        47.99361,
        45.54610400000002
      ],
      [
        48.292773,
        44.99778000000002
      ]
    ]
  },
  "Удмуртская Республика": {
    "0": [
      [
        58.439964,
        53.78463
      ],
      [
        58.322221000000006,
        53.889159
      ],
      [
        58.22887500000001,
        53.809715
      ],
      [
        57.97276500000001,
        54.107215
      ],
      [
        57.70249400000001,
        54.156651999999994
      ],
      [
        57.57110800000001,
        53.98749399999999
      ],
      [
        57.46444200000001,
        54.26471499999999
      ],
      [
        57.31027500000001,
        54.11638499999999
      ],
      [
        57.286662000000014,
        54.31999099999999
      ],
      [
        57.10083400000001,
        54.275550999999986
      ],
      [
        57.03656500000001,
        54.40267799999999
      ],
      [
        56.96249900000001,
        54.09804399999999
      ],
      [
        56.74916700000001,
        54.010550999999985
      ],
      [
        56.811941000000004,
        53.82833199999998
      ],
      [
        56.635558,
        53.83554899999998
      ],
      [
        56.662772000000004,
        54.10082999999998
      ],
      [
        56.584167,
        54.02665799999998
      ],
      [
        56.459442,
        54.328330999999984
      ],
      [
        56.262222,
        54.358885999999984
      ],
      [
        56.025834,
        54.018050999999986
      ],
      [
        55.852533,
        53.297249999999984
      ],
      [
        55.92917,
        53.180551999999985
      ],
      [
        56.24389,
        53.55609999999999
      ],
      [
        56.254723,
        53.25888999999999
      ],
      [
        56.084725,
        53.34499499999999
      ],
      [
        56.135276,
        53.04749399999999
      ],
      [
        56.366941999999995,
        52.93860099999999
      ],
      [
        56.52694399999999,
        53.095268999999995
      ],
      [
        56.544720999999996,
        52.94443699999999
      ],
      [
        56.521939999999994,
        52.82555599999999
      ],
      [
        56.44166299999999,
        52.95277499999999
      ],
      [
        56.26250199999999,
        52.558328999999986
      ],
      [
        56.161382999999994,
        52.84471199999999
      ],
      [
        56.01833299999999,
        52.66944299999999
      ],
      [
        56.08638599999999,
        52.22304699999999
      ],
      [
        55.89305799999999,
        52.169161999999986
      ],
      [
        55.93083099999999,
        51.42583099999999
      ],
      [
        56.14028099999999,
        51.65304899999999
      ],
      [
        56.144446999999985,
        51.43667199999999
      ],
      [
        56.289999999999985,
        51.424717999999984
      ],
      [
        56.503331999999986,
        51.138333999999986
      ],
      [
        56.66777599999999,
        51.18971699999999
      ],
      [
        56.67777899999999,
        51.38943899999999
      ],
      [
        56.88110899999999,
        51.54194199999999
      ],
      [
        56.933331999999986,
        51.32083399999999
      ],
      [
        57.30471399999998,
        51.114718999999994
      ],
      [
        57.45388299999998,
        51.194154999999995
      ],
      [
        57.437212999999986,
        51.587495
      ],
      [
        57.63472299999999,
        51.856941
      ],
      [
        57.974710999999985,
        51.890831
      ],
      [
        58.17166299999999,
        51.672492999999996
      ],
      [
        58.35888899999999,
        51.789992
      ],
      [
        58.49332499999999,
        52.172767
      ],
      [
        58.40387799999999,
        52.865271
      ],
      [
        58.54610399999999,
        53.059431
      ],
      [
        58.389160999999994,
        53.307210999999995
      ],
      [
        58.44054499999999,
        53.763608999999995
      ]
    ]
  },
  "Архангельская область": {
    "0": [
      [
        63.953156,
        36.507904
      ],
      [
        63.785111,
        37.446445000000004
      ],
      [
        64,
        38.07811
      ],
      [
        64.202247,
        37.89389
      ],
      [
        64.289084,
        38.049945
      ],
      [
        64.326528,
        38.042193
      ],
      [
        64.370802,
        37.788723
      ],
      [
        64.447523,
        37.844165
      ],
      [
        64.342414,
        37.330774999999996
      ],
      [
        64.71411,
        36.536471
      ],
      [
        64.91527500000001,
        36.432109
      ],
      [
        64.95980600000001,
        36.809332999999995
      ],
      [
        65.15002100000001,
        36.839831
      ],
      [
        64.83891100000001,
        38.370444
      ],
      [
        64.75749900000001,
        38.01636
      ],
      [
        64.67366700000001,
        38.121305
      ],
      [
        64.58233600000001,
        38.016334
      ],
      [
        64.73580900000002,
        38.499052
      ],
      [
        64.80897400000002,
        38.438608
      ],
      [
        64.54296900000001,
        39.559525
      ],
      [
        64.58405200000001,
        39.843026
      ],
      [
        64.66813600000002,
        39.813329
      ],
      [
        64.52569500000001,
        40.545997
      ],
      [
        64.66750200000001,
        40.663967
      ],
      [
        64.56136200000002,
        40.5028
      ],
      [
        64.74422300000002,
        40.427998
      ],
      [
        64.70185700000002,
        40.602661000000005
      ],
      [
        65.33888700000001,
        39.722634000000006
      ],
      [
        65.53810500000002,
        39.74596900000001
      ],
      [
        65.98286000000002,
        40.73849500000001
      ],
      [
        66.11118700000002,
        41.49769200000001
      ],
      [
        66.51502600000002,
        42.25921900000001
      ],
      [
        66.41585900000003,
        42.637690000000006
      ],
      [
        66.37799500000003,
        42.523495000000004
      ],
      [
        66.40530000000003,
        43.271910000000005
      ],
      [
        66.23743800000003,
        43.68024200000001
      ],
      [
        66.03855500000003,
        43.34721600000001
      ],
      [
        66.18499400000003,
        43.84738400000001
      ],
      [
        66.06324400000003,
        44.07796600000001
      ],
      [
        65.88549500000002,
        44.13210500000001
      ],
      [
        66.22952700000002,
        44.06288300000001
      ],
      [
        66.41499000000002,
        44.28915500000001
      ],
      [
        65.81442000000001,
        47.12859300000001
      ],
      [
        65.81860100000002,
        47.72692800000001
      ],
      [
        65.94135700000001,
        47.75248700000001
      ],
      [
        66.13192300000001,
        48.14414800000001
      ],
      [
        66.11921400000001,
        49.006209000000005
      ],
      [
        65.23275600000001,
        48.98803600000001
      ],
      [
        65.29914600000001,
        49.61331700000001
      ],
      [
        64.847485,
        50.10887600000001
      ],
      [
        64.799147,
        50.47025700000001
      ],
      [
        64.50387400000001,
        50.33192900000001
      ],
      [
        64.59832600000001,
        49.58636900000001
      ],
      [
        64.45609800000001,
        49.46275900000001
      ],
      [
        64.49470300000002,
        48.93942000000001
      ],
      [
        64.34248300000002,
        48.82915300000001
      ],
      [
        64.35914400000001,
        48.505263000000014
      ],
      [
        64.21638300000002,
        48.43220400000001
      ],
      [
        64.23414300000002,
        48.189422000000015
      ],
      [
        64.35386300000002,
        48.147767000000016
      ],
      [
        64.36526000000002,
        47.40192500000001
      ],
      [
        64.17557800000002,
        45.39795700000001
      ],
      [
        63.747211000000014,
        46.81998500000001
      ],
      [
        63.55943000000001,
        47.033043000000006
      ],
      [
        63.33582100000001,
        46.94220800000001
      ],
      [
        63.15138000000001,
        47.56470600000001
      ],
      [
        62.97248700000001,
        47.436372000000006
      ],
      [
        62.84777100000001,
        47.07304500000001
      ],
      [
        62.64110600000001,
        47.245264000000006
      ],
      [
        62.34526900000001,
        47.224154000000006
      ],
      [
        62.33638200000001,
        47.40859400000001
      ],
      [
        62.18721200000001,
        47.39387800000001
      ],
      [
        62.17943900000001,
        47.63360000000001
      ],
      [
        62.33082900000001,
        47.655817000000006
      ],
      [
        62.31444100000001,
        48.30498600000001
      ],
      [
        62.46277100000001,
        48.232758000000004
      ],
      [
        62.73804700000001,
        48.347480000000004
      ],
      [
        62.70027400000001,
        48.668045000000006
      ],
      [
        62.84916100000001,
        48.72803400000001
      ],
      [
        62.77027400000001,
        49.60915100000001
      ],
      [
        62.09666000000001,
        49.21414800000001
      ],
      [
        61.65721400000001,
        49.03971000000001
      ],
      [
        61.61138600000001,
        49.486098000000005
      ],
      [
        61.16916400000001,
        49.309982000000005
      ],
      [
        61.04833800000001,
        48.50943
      ],
      [
        60.853865000000006,
        48.419236000000005
      ],
      [
        60.886663000000006,
        47.987489000000004
      ],
      [
        61.064443000000004,
        47.228319000000006
      ],
      [
        60.856947000000005,
        47.07609800000001
      ],
      [
        60.855003,
        46.92721100000001
      ],
      [
        61.063887,
        46.520824000000005
      ],
      [
        60.985,
        46.057492
      ],
      [
        61.024169,
        45.759435
      ],
      [
        61.167518,
        45.795934
      ],
      [
        61.208053,
        45.353879
      ],
      [
        60.903884,
        45.229154
      ],
      [
        60.969443999999996,
        44.153601
      ],
      [
        61.08250399999999,
        44.172765000000005
      ],
      [
        61.082221999999994,
        44.07054600000001
      ],
      [
        61.02194299999999,
        43.77416000000001
      ],
      [
        60.76555799999999,
        43.521102000000006
      ],
      [
        60.734444999999994,
        42.98971600000001
      ],
      [
        60.866668999999995,
        42.64110600000001
      ],
      [
        60.767776999999995,
        42.405823000000005
      ],
      [
        60.919723,
        41.921105000000004
      ],
      [
        60.852225999999995,
        41.378328
      ],
      [
        60.645835999999996,
        41.353885000000005
      ],
      [
        60.784727999999994,
        40.74388400000001
      ],
      [
        60.64666799999999,
        39.956103000000006
      ],
      [
        60.74972599999999,
        39.64555000000001
      ],
      [
        60.707779999999985,
        38.64249800000001
      ],
      [
        60.958336999999986,
        38.38166500000001
      ],
      [
        61.127220999999984,
        38.18360700000001
      ],
      [
        61.286391999999985,
        37.84777700000001
      ],
      [
        61.64666599999998,
        37.75778000000001
      ],
      [
        61.96388799999998,
        37.93082900000001
      ],
      [
        62.19833099999998,
        37.73889000000001
      ],
      [
        62.20193999999998,
        37.431387000000015
      ],
      [
        62.36277399999998,
        37.579999000000015
      ],
      [
        62.63416599999998,
        37.521383000000014
      ],
      [
        62.81499699999998,
        36.515279000000014
      ],
      [
        63.03138199999998,
        36.257506000000014
      ],
      [
        63.20249299999998,
        36.42055300000001
      ],
      [
        63.418326999999984,
        36.22054900000001
      ],
      [
        63.59832699999998,
        35.85610900000001
      ],
      [
        63.67638299999998,
        36.47193700000001
      ],
      [
        63.94665299999998,
        36.50583500000001
      ]
    ],
    "1": [
      [
        66.706085,
        42.489307
      ],
      [
        66.767524,
        42.404582999999995
      ],
      [
        66.67227799999999,
        42.613834
      ]
    ],
    "2": [
      [
        65,
        35.850945
      ],
      [
        65.118057,
        35.497418
      ],
      [
        65.16669399999999,
        35.7805
      ],
      [
        65.03602599999999,
        35.847305000000006
      ],
      [
        65.039054,
        35.75105800000001
      ],
      [
        65.015526,
        35.86436200000001
      ]
    ],
    "3": [
      [
        64.550751,
        40.377167
      ],
      [
        64.63938900000001,
        40.287945
      ],
      [
        64.578004,
        40.474888
      ],
      [
        64.551141,
        40.377861
      ]
    ],
    "4": [
      [
        64.669197,
        40
      ],
      [
        64.680053,
        39.813027
      ],
      [
        64.596724,
        40.176277999999996
      ],
      [
        64.67011099999999,
        40.010808999999995
      ]
    ],
    "5": [
      [
        64.765136,
        40.304054
      ],
      [
        64.629668,
        40.400443
      ],
      [
        64.74216299999999,
        40.111831
      ],
      [
        64.76513499999999,
        40.303968000000005
      ]
    ]
  },
  "Чувашская Республика": {
    "0": [
      [
        56.049515,
        46.213539
      ],
      [
        56.333327,
        47.388045
      ],
      [
        56.178329,
        47.441657
      ],
      [
        56.13305,
        47.896658
      ],
      [
        55.943321999999995,
        47.981931
      ],
      [
        55.814187,
        48.404576000000006
      ],
      [
        55.534997999999995,
        48.098043000000004
      ],
      [
        55.316103999999996,
        47.686933
      ],
      [
        55.21194,
        47.756941000000005
      ],
      [
        55.278878999999996,
        48.020543
      ],
      [
        55.146387999999995,
        48.089154
      ],
      [
        55.062214,
        47.913603
      ],
      [
        54.968885,
        47.99777
      ],
      [
        54.937216,
        47.781936
      ],
      [
        54.809722,
        47.848600000000005
      ],
      [
        54.894447,
        47.423881
      ],
      [
        54.657502,
        47.204712
      ],
      [
        54.629441,
        46.853051
      ],
      [
        54.795006,
        46.40777
      ],
      [
        55.054717000000004,
        46.2625
      ],
      [
        55.042221000000005,
        46.055828000000005
      ],
      [
        55.445548,
        46.383610000000004
      ],
      [
        55.686659,
        45.921383000000006
      ],
      [
        56.038892,
        46.20638600000001
      ]
    ]
  },
  "Республика Калмыкия": {
    "0": [
      [
        47.476555,
        43.731773
      ],
      [
        47.559151,
        43.629433
      ],
      [
        47.684432,
        43.705826
      ],
      [
        47.910811,
        44.065818
      ],
      [
        47.868317000000005,
        44.389152
      ],
      [
        48.040832,
        44.466375
      ],
      [
        48.059988000000004,
        44.323043
      ],
      [
        48.259992000000004,
        44.309432
      ],
      [
        48.20638,
        44.535818
      ],
      [
        48.058324000000006,
        44.549711
      ],
      [
        48.052487000000006,
        44.800534
      ],
      [
        48.21193300000001,
        44.956652999999996
      ],
      [
        48.03749500000001,
        45.713595
      ],
      [
        47.72303500000001,
        45.829158
      ],
      [
        47.43664300000001,
        46.296649
      ],
      [
        47.63248000000001,
        46.680811000000006
      ],
      [
        47.57609200000001,
        46.830538000000004
      ],
      [
        47.39192600000001,
        46.515543
      ],
      [
        46.996381000000014,
        46.796381000000004
      ],
      [
        46.740819000000016,
        47.227762000000006
      ],
      [
        46.555258000000016,
        46.913309000000005
      ],
      [
        46.42859400000002,
        46.96193100000001
      ],
      [
        46.42803900000002,
        46.66026600000001
      ],
      [
        46.27555000000002,
        46.478320000000004
      ],
      [
        46.248046000000016,
        46.866647
      ],
      [
        46.07276200000002,
        46.833598
      ],
      [
        46.11331900000002,
        47.166376
      ],
      [
        45.57833200000002,
        46.889148
      ],
      [
        45.68942300000002,
        47.359705999999996
      ],
      [
        45.40227900000002,
        47.578686999999995
      ],
      [
        45.37153000000002,
        47.456607
      ],
      [
        45.17605800000002,
        47.30955
      ],
      [
        44.79611500000002,
        47.154331
      ],
      [
        44.743602000000024,
        46.910484
      ],
      [
        44.93610700000002,
        46.43970899999999
      ],
      [
        44.97581800000002,
        45.70607499999999
      ],
      [
        45.17943800000002,
        45.53887699999999
      ],
      [
        45.60304400000002,
        44.219984999999994
      ],
      [
        45.941093000000016,
        43.891372
      ],
      [
        46.242210000000014,
        42.922768
      ],
      [
        45.989426000000016,
        42.517213
      ],
      [
        45.96943700000001,
        42.318888
      ],
      [
        46.12776200000001,
        42.333605
      ],
      [
        46.10415600000001,
        42.176103999999995
      ],
      [
        45.92638500000001,
        42.10444199999999
      ],
      [
        45.99597300000001,
        41.71278699999999
      ],
      [
        46.17332500000001,
        41.64666299999999
      ],
      [
        46.302216000000016,
        41.96305399999999
      ],
      [
        46.31443800000002,
        42.210542999999994
      ],
      [
        46.55555000000002,
        42.035273999999994
      ],
      [
        46.369061000000016,
        42.718447999999995
      ],
      [
        46.43859900000002,
        42.933870999999996
      ],
      [
        46.17083000000002,
        43.361383999999994
      ],
      [
        46.18610300000002,
        43.619156999999994
      ],
      [
        46.40942300000002,
        43.771660999999995
      ],
      [
        46.338326000000016,
        43.967484999999996
      ],
      [
        46.584716000000014,
        43.812203999999994
      ],
      [
        46.89610200000001,
        44.26054499999999
      ],
      [
        47.48165700000001,
        44.33387799999999
      ],
      [
        47.542760000000015,
        44.11943099999999
      ],
      [
        47.265546000000015,
        43.93664599999999
      ],
      [
        47.28693900000002,
        43.78998599999999
      ],
      [
        47.46914300000002,
        43.74137999999999
      ]
    ]
  },
  "Республика Башкортостан": {
    "0": [
      [
        55.91552,
        53.63842
      ],
      [
        56.029433,
        54.024436
      ],
      [
        56.346655,
        54.317495
      ],
      [
        56.539159999999995,
        54.554158
      ],
      [
        56.320271999999996,
        54.958043
      ],
      [
        56.46638299999999,
        55.905263000000005
      ],
      [
        56.287496999999995,
        56.064710000000005
      ],
      [
        56.383329999999994,
        56.52859900000001
      ],
      [
        56.100829999999995,
        56.89415300000001
      ],
      [
        56.329184999999995,
        57.40867900000001
      ],
      [
        56.099715999999994,
        57.536936000000004
      ],
      [
        56.057770999999995,
        58.342760000000006
      ],
      [
        56.168327999999995,
        58.569711000000005
      ],
      [
        56.13665999999999,
        59.290826
      ],
      [
        56.00666199999999,
        59.17054
      ],
      [
        55.78833199999999,
        59.157761
      ],
      [
        55.77777399999999,
        59.316093
      ],
      [
        55.643886999999985,
        59.348319000000004
      ],
      [
        55.58443999999999,
        59.236373
      ],
      [
        55.55943899999998,
        59.643873
      ],
      [
        55.44916399999998,
        59.194144
      ],
      [
        55.32083799999998,
        58.852768000000005
      ],
      [
        55.27805399999998,
        58.92331600000001
      ],
      [
        55.266944999999986,
        58.72664600000001
      ],
      [
        55.15555599999998,
        58.745261000000006
      ],
      [
        55.161940999999985,
        58.577209
      ],
      [
        55.02305599999998,
        58.816374
      ],
      [
        54.956107999999986,
        58.529707
      ],
      [
        55.17610899999999,
        58.300261
      ],
      [
        54.91667099999999,
        57.999992
      ],
      [
        55.16777699999999,
        57.961373
      ],
      [
        55.21944399999999,
        58.142213000000005
      ],
      [
        55.32833699999999,
        57.44248400000001
      ],
      [
        55.22305199999999,
        57.20693500000001
      ],
      [
        55.08000099999999,
        57.13499100000001
      ],
      [
        54.82639199999999,
        57.15832100000001
      ],
      [
        54.38805399999999,
        57.974987000000006
      ],
      [
        54.54972899999999,
        58.361102
      ],
      [
        54.46777399999999,
        58.631372
      ],
      [
        54.61221999999999,
        59.249712
      ],
      [
        54.91305499999999,
        59.640267
      ],
      [
        54.794723999999995,
        59.979706
      ],
      [
        54.489442,
        59.675545
      ],
      [
        54.341111999999995,
        59.658876
      ],
      [
        54.24416599999999,
        59.808595
      ],
      [
        54.138056999999996,
        59.736650999999995
      ],
      [
        54.183612,
        59.325267999999994
      ],
      [
        53.925281999999996,
        58.91666099999999
      ],
      [
        53.542508999999995,
        58.90359999999999
      ],
      [
        53.204449999999994,
        58.82249199999999
      ],
      [
        53.015012999999996,
        59.02776799999999
      ],
      [
        53.011678999999994,
        58.83582099999999
      ],
      [
        52.64778799999999,
        58.72165599999999
      ],
      [
        52.53639899999999,
        58.84470999999999
      ],
      [
        52.29389899999999,
        58.94387599999999
      ],
      [
        52.18083899999999,
        58.605825999999986
      ],
      [
        52.04973699999999,
        58.69498999999998
      ],
      [
        51.78806399999999,
        58.57360699999998
      ],
      [
        51.73973199999999,
        58.15499099999998
      ],
      [
        51.86778299999999,
        57.65499199999998
      ],
      [
        51.56973399999999,
        57.20277599999998
      ],
      [
        51.75751699999999,
        56.82193999999998
      ],
      [
        51.61889899999999,
        56.88916199999998
      ],
      [
        51.58973199999999,
        56.77471499999998
      ],
      [
        51.77362299999999,
        56.75278099999998
      ],
      [
        51.817789999999995,
        56.60388699999998
      ],
      [
        51.904726,
        56.73943699999998
      ],
      [
        52.128341999999996,
        56.63249699999998
      ],
      [
        52.174454,
        56.18943499999998
      ],
      [
        52.308341999999996,
        56.45277899999998
      ],
      [
        52.596396,
        56.427777999999975
      ],
      [
        52.646673,
        56.05193799999998
      ],
      [
        52.366942,
        55.853620999999976
      ],
      [
        52.389731000000005,
        55.52720599999998
      ],
      [
        52.60255300000001,
        55.53771899999998
      ],
      [
        52.656774000000006,
        55.246535999999985
      ],
      [
        52.712605,
        55.42071399999998
      ],
      [
        52.837627000000005,
        55.40735499999998
      ],
      [
        52.82323100000001,
        55.15396099999998
      ],
      [
        53.22417000000001,
        54.92361399999998
      ],
      [
        53.41750600000001,
        54.10610999999998
      ],
      [
        53.74888700000001,
        53.65444299999998
      ],
      [
        54.38166800000001,
        53.34361499999998
      ],
      [
        54.90139000000001,
        53.64250299999998
      ],
      [
        55.14389000000001,
        53.13889399999998
      ],
      [
        55.20777800000001,
        53.58611399999998
      ],
      [
        55.69138200000001,
        54.24194399999998
      ],
      [
        55.91527300000001,
        53.64695099999998
      ]
    ]
  },
  "Республика Саха (Якутия)": {
    "0": [
      [
        69.539154,
        106.383041
      ],
      [
        69.674988,
        107.902206
      ],
      [
        69.856644,
        108.263046
      ],
      [
        69.768601,
        109.214431
      ],
      [
        70.03498900000001,
        109.543868
      ],
      [
        70.224427,
        109.32804800000001
      ],
      [
        70.384996,
        109.64166100000001
      ],
      [
        70.434144,
        110.29886300000001
      ],
      [
        70.483048,
        110.13498500000001
      ],
      [
        70.63610899999999,
        110.12359500000001
      ],
      [
        71.104706,
        112.59748700000002
      ],
      [
        71.163879,
        112.52192600000002
      ],
      [
        71.24832199999999,
        112.72329500000002
      ],
      [
        71.40693599999999,
        112.01109100000002
      ],
      [
        72.13332899999999,
        112.01220500000002
      ],
      [
        72.14498599999999,
        111.77498500000003
      ],
      [
        72.372205,
        111.08831800000003
      ],
      [
        72.508038,
        111.31860300000002
      ],
      [
        72.64471,
        110.73026900000002
      ],
      [
        72.786648,
        110.63220100000002
      ],
      [
        72.829982,
        110.73165700000003
      ],
      [
        72.990259,
        110.77192500000002
      ],
      [
        73.038873,
        110.94164700000002
      ],
      [
        73.173029,
        110.79775800000002
      ],
      [
        73.189965,
        110.42302500000001
      ],
      [
        73.319435,
        110.56667000000002
      ],
      [
        73.402472,
        110.16442700000002
      ],
      [
        73.58803300000001,
        111.10859400000001
      ],
      [
        73.71826600000001,
        110.84166400000001
      ],
      [
        73.85922600000002,
        111.29341500000001
      ],
      [
        73.72601700000003,
        111.87357700000001
      ],
      [
        73.73634800000002,
        112.90394200000001
      ],
      [
        73.79620800000002,
        112.99219100000002
      ],
      [
        73.99213000000002,
        112.81785900000001
      ],
      [
        73.67709800000002,
        113.41041200000001
      ],
      [
        73.59138200000001,
        113.453692
      ],
      [
        73.44451600000001,
        113.200328
      ],
      [
        73.33932300000001,
        113.536242
      ],
      [
        73.23685200000001,
        113.500332
      ],
      [
        73.35607700000001,
        114.094745
      ],
      [
        73.50938200000002,
        113.50044
      ],
      [
        73.69707300000002,
        115.909717
      ],
      [
        73.55548600000002,
        118.721355
      ],
      [
        73.45741100000002,
        118.970774
      ],
      [
        73.42838300000003,
        118.45119100000001
      ],
      [
        73.22779800000002,
        118.409496
      ],
      [
        73.00190800000001,
        119.69511
      ],
      [
        72.93041300000002,
        121.083469
      ],
      [
        72.95124100000001,
        122.807277
      ],
      [
        73.030296,
        122.647527
      ],
      [
        72.919021,
        123.316631
      ],
      [
        73.117049,
        123.508158
      ],
      [
        73.101821,
        123.73777299999999
      ],
      [
        73.156797,
        123.471217
      ],
      [
        73.20724299999999,
        123.75146699999999
      ],
      [
        73.352048,
        123.38263199999999
      ],
      [
        73.385319,
        123.53888099999999
      ],
      [
        73.40217899999999,
        123.28002299999999
      ],
      [
        73.39699199999998,
        123.52807799999998
      ],
      [
        73.47867999999998,
        123.34716299999998
      ],
      [
        73.54815299999999,
        123.47155199999997
      ],
      [
        73.56407599999999,
        123.52885499999998
      ],
      [
        73.65490399999999,
        123.44968499999997
      ],
      [
        73.60085,
        124.01735699999998
      ],
      [
        73.72157,
        124.27807599999997
      ],
      [
        73.800847,
        124.39546099999997
      ],
      [
        73.74454200000001,
        124.76326599999997
      ],
      [
        73.64923600000002,
        124.75601799999997
      ],
      [
        73.70318300000001,
        125.04768099999997
      ],
      [
        73.600851,
        125.04265499999997
      ],
      [
        73.684019,
        125.16115399999997
      ],
      [
        73.639159,
        125.24265799999996
      ],
      [
        73.55632600000001,
        125.14770999999996
      ],
      [
        73.61807100000001,
        125.27521199999997
      ],
      [
        73.51435000000002,
        125.20479299999997
      ],
      [
        73.58762900000002,
        125.47765899999996
      ],
      [
        73.41651800000002,
        125.82760299999995
      ],
      [
        73.45513000000003,
        125.92868499999996
      ],
      [
        73.55209900000003,
        126.33518499999995
      ],
      [
        73.51290800000002,
        126.46096399999995
      ],
      [
        73.37404500000002,
        126.33937399999995
      ],
      [
        73.35115700000003,
        126.45176199999995
      ],
      [
        73.45379500000003,
        126.84648399999995
      ],
      [
        73.37626600000003,
        126.96412799999995
      ],
      [
        73.52718300000004,
        127.05434499999994
      ],
      [
        73.46493600000004,
        127.92015099999995
      ],
      [
        73.41638300000004,
        127.79354199999995
      ],
      [
        73.41277600000004,
        128.12443699999994
      ],
      [
        73.40272000000003,
        128.16571199999996
      ],
      [
        73.35513600000003,
        128.45008899999996
      ],
      [
        73.29580200000002,
        128.50689599999995
      ],
      [
        73.25141500000002,
        128.32798699999995
      ],
      [
        73.22155400000003,
        128.88770999999994
      ],
      [
        73.17130000000003,
        128.78048699999994
      ],
      [
        73.12039000000003,
        128.75337299999993
      ],
      [
        72.96127900000003,
        129.59001199999992
      ],
      [
        72.97086000000003,
        129.1242829999999
      ],
      [
        72.85133100000003,
        129.4518119999999
      ],
      [
        72.78333000000003,
        129.49587899999992
      ],
      [
        72.73602900000003,
        129.16184999999993
      ],
      [
        72.62921700000003,
        129.50608899999992
      ],
      [
        72.60353000000002,
        129.31163099999992
      ],
      [
        72.52155200000001,
        128.8207859999999
      ],
      [
        72.43469100000002,
        129.45123199999992
      ],
      [
        72.30588400000002,
        129.55334299999993
      ],
      [
        72.26396800000002,
        129.38422999999992
      ],
      [
        72.21250200000001,
        129.5974549999999
      ],
      [
        72.15844700000001,
        129.3864869999999
      ],
      [
        72.138276,
        129.5786719999999
      ],
      [
        72.084863,
        129.3992279999999
      ],
      [
        72.010805,
        128.6220679999999
      ],
      [
        71.92367,
        128.8591429999999
      ],
      [
        71.958918,
        128.5043929999999
      ],
      [
        71.709834,
        128.9814889999999
      ],
      [
        71.804445,
        129.2762269999999
      ],
      [
        72.010949,
        129.1128979999999
      ],
      [
        71.70030299999999,
        129.60865499999989
      ],
      [
        71.681254,
        128.93081399999988
      ],
      [
        71.57808899999999,
        128.9147009999999
      ],
      [
        71.596308,
        129.2363409999999
      ],
      [
        71.295116,
        129.4785589999999
      ],
      [
        70.898945,
        130.36706299999992
      ],
      [
        70.8532,
        130.78950099999992
      ],
      [
        70.961751,
        130.8608199999999
      ],
      [
        70.704176,
        131.0624189999999
      ],
      [
        70.92169700000001,
        131.69017999999988
      ],
      [
        71.64475700000001,
        132.2542059999999
      ],
      [
        71.94681100000001,
        132.7774749999999
      ],
      [
        71.88255600000001,
        133.3350619999999
      ],
      [
        71.948001,
        132.9982239999999
      ],
      [
        71.91225,
        132.7866149999999
      ],
      [
        71.795528,
        132.7600969999999
      ],
      [
        71.540951,
        133.3021179999999
      ],
      [
        71.362836,
        134.2734459999999
      ],
      [
        71.630452,
        135.9140549999999
      ],
      [
        71.52611300000001,
        136.8548959999999
      ],
      [
        71.35542000000001,
        137.58994199999992
      ],
      [
        71.209647,
        138.01859199999993
      ],
      [
        71.235228,
        138.19238899999993
      ],
      [
        71.37184,
        137.84873099999993
      ],
      [
        71.57500200000001,
        138.16373299999992
      ],
      [
        71.55378500000002,
        138.69766899999993
      ],
      [
        71.62900300000003,
        138.85678799999994
      ],
      [
        71.57659000000002,
        139.13875399999995
      ],
      [
        71.39828300000002,
        139.24255899999994
      ],
      [
        71.43984000000002,
        139.86655199999996
      ],
      [
        71.50950300000002,
        140.09828699999994
      ],
      [
        71.68716800000003,
        139.77347399999994
      ],
      [
        71.85428300000002,
        139.83875099999995
      ],
      [
        71.94950500000003,
        139.38161299999996
      ],
      [
        72.15489500000002,
        139.96811499999995
      ],
      [
        72.17197600000003,
        140.29324799999995
      ],
      [
        72.26191800000002,
        140.07394899999994
      ],
      [
        72.14364100000002,
        139.42342099999993
      ],
      [
        72.27688700000002,
        139.17535899999993
      ],
      [
        72.48666500000002,
        139.61766399999993
      ],
      [
        72.58766900000002,
        141.32766999999993
      ],
      [
        72.88192000000002,
        140.75966199999993
      ],
      [
        72.54397700000003,
        145.61180499999995
      ],
      [
        72.29706000000003,
        147.01350699999995
      ],
      [
        72.31292100000003,
        148.52810899999994
      ],
      [
        72.14905600000003,
        149.58342099999993
      ],
      [
        71.85908600000003,
        150.10664399999993
      ],
      [
        71.74316500000003,
        149.62719899999993
      ],
      [
        71.88044700000003,
        149.33664199999993
      ],
      [
        71.67314200000003,
        149.08339299999992
      ],
      [
        71.67038900000003,
        149.9312459999999
      ],
      [
        71.51884000000003,
        150.6364759999999
      ],
      [
        71.35786700000003,
        150.7783509999999
      ],
      [
        71.31661500000003,
        151.5744179999999
      ],
      [
        70.99514400000002,
        152.13908399999988
      ],
      [
        70.97195100000002,
        151.7217419999999
      ],
      [
        70.83869700000002,
        152.39599699999988
      ],
      [
        71.09089400000002,
        156.66769499999987
      ],
      [
        70.83478300000002,
        159.13247599999985
      ],
      [
        70.67050700000001,
        159.65568499999986
      ],
      [
        70.35044600000002,
        160.07385199999987
      ],
      [
        70.07484200000002,
        159.97576899999987
      ],
      [
        69.87689700000001,
        159.68893399999988
      ],
      [
        69.81795200000002,
        159.77377199999987
      ],
      [
        69.62245000000001,
        160.97247199999987
      ],
      [
        69.22278400000002,
        161.10258299999987
      ],
      [
        69.26041900000001,
        161.22393699999986
      ],
      [
        69.462528,
        161.13201899999987
      ],
      [
        69.530505,
        161.39549099999988
      ],
      [
        69.391391,
        161.33122199999988
      ],
      [
        69.362918,
        161.4904619999999
      ],
      [
        69.652682,
        162.4374819999999
      ],
      [
        69.611926,
        162.33022899999992
      ],
      [
        69.420536,
        162.3543689999999
      ],
      [
        69.194143,
        162.7824379999999
      ],
      [
        69.117209,
        162.4990829999999
      ],
      [
        69.003607,
        162.5307909999999
      ],
      [
        68.86971100000001,
        162.8493639999999
      ],
      [
        68.80554900000001,
        162.5924359999999
      ],
      [
        68.60637700000001,
        162.7240889999999
      ],
      [
        68.30555100000001,
        162.4352109999999
      ],
      [
        68.41138500000001,
        161.3657539999999
      ],
      [
        68.07054900000001,
        158.2546699999999
      ],
      [
        67.85749100000001,
        158.0524299999999
      ],
      [
        67.74860400000001,
        158.3274229999999
      ],
      [
        67.70610900000001,
        157.8041079999999
      ],
      [
        67.53943900000002,
        157.6885389999999
      ],
      [
        67.44109700000001,
        157.8874529999999
      ],
      [
        67.34194500000001,
        157.7782919999999
      ],
      [
        67.27083900000001,
        157.8599269999999
      ],
      [
        67.05555400000001,
        158.7043789999999
      ],
      [
        66.81277200000001,
        158.9016139999999
      ],
      [
        66.44860600000001,
        158.3527249999999
      ],
      [
        66.35949600000001,
        158.5292069999999
      ],
      [
        66.248885,
        158.3902309999999
      ],
      [
        66.11389,
        158.48825299999987
      ],
      [
        66.112776,
        157.70437999999987
      ],
      [
        65.913055,
        157.13357899999988
      ],
      [
        66.033066,
        156.97549899999987
      ],
      [
        66.20695400000001,
        156.61966399999986
      ],
      [
        66.05943200000002,
        156.32938199999987
      ],
      [
        66.20838800000001,
        155.19931599999987
      ],
      [
        66.24221700000001,
        154.51077899999987
      ],
      [
        66.06443800000001,
        154.21243899999988
      ],
      [
        65.944443,
        154.38269699999987
      ],
      [
        65.82917900000001,
        154.25440099999986
      ],
      [
        65.823625,
        153.47577699999985
      ],
      [
        65.706117,
        153.53412499999985
      ],
      [
        65.419725,
        153.52606899999984
      ],
      [
        65.360002,
        153.46298899999982
      ],
      [
        65.232225,
        152.6793899999998
      ],
      [
        65.034442,
        152.6552209999998
      ],
      [
        64.938053,
        152.4985449999998
      ],
      [
        64.680286,
        152.7474459999998
      ],
      [
        64.525562,
        152.3249619999998
      ],
      [
        64.39696099999999,
        152.4196879999998
      ],
      [
        64.35916499999999,
        152.2238269999998
      ],
      [
        64.50612199999999,
        152.0110889999998
      ],
      [
        64.43638999999999,
        151.4791079999998
      ],
      [
        64.29167499999998,
        151.3932939999998
      ],
      [
        64.33972399999999,
        150.6435689999998
      ],
      [
        64.16472099999999,
        150.6041109999998
      ],
      [
        64.24972799999999,
        150.1293809999998
      ],
      [
        64.369722,
        150.0227529999998
      ],
      [
        64.489716,
        150.0874489999998
      ],
      [
        64.563888,
        149.8507859999998
      ],
      [
        64.404724,
        149.2166299999998
      ],
      [
        64.41362,
        148.61161899999982
      ],
      [
        64.557769,
        148.30244699999983
      ],
      [
        64.433883,
        148.00941699999984
      ],
      [
        64.23999099999999,
        148.26023999999984
      ],
      [
        63.94084199999999,
        147.92439399999984
      ],
      [
        64.04138199999998,
        147.60411299999984
      ],
      [
        64.17304999999999,
        147.62077499999984
      ],
      [
        64.050553,
        147.24299699999983
      ],
      [
        64.207229,
        146.38969499999982
      ],
      [
        63.969169,
        146.17302199999983
      ],
      [
        63.825013,
        145.54774699999984
      ],
      [
        63.667787,
        145.64470099999986
      ],
      [
        63.196673,
        145.24717999999984
      ],
      [
        62.771952999999996,
        145.30662799999985
      ],
      [
        62.548624999999994,
        145.48967199999984
      ],
      [
        62.491679999999995,
        145.12077599999984
      ],
      [
        62.271398,
        145.18052799999984
      ],
      [
        61.859457,
        144.54802199999983
      ],
      [
        61.728065,
        144.21882999999983
      ],
      [
        61.756682,
        143.93413099999984
      ],
      [
        61.991125,
        143.92994999999985
      ],
      [
        61.870008999999996,
        143.63496799999984
      ],
      [
        62.028898,
        143.60384099999985
      ],
      [
        62.11862,
        143.21578099999985
      ],
      [
        61.907233,
        142.99162999999984
      ],
      [
        61.969733,
        142.66496999999984
      ],
      [
        61.866682999999995,
        142.41692399999985
      ],
      [
        62.088620999999996,
        142.17607899999985
      ],
      [
        62.028625,
        141.84606299999984
      ],
      [
        62.170294,
        141.55553599999985
      ],
      [
        62.440846,
        141.29412199999985
      ],
      [
        62.576405,
        140.69774899999985
      ],
      [
        62.415846,
        140.62496499999983
      ],
      [
        62.384184000000005,
        140.34191599999983
      ],
      [
        62.058905,
        140.29302799999982
      ],
      [
        61.975843000000005,
        139.9891339999998
      ],
      [
        61.477790000000006,
        139.54552999999981
      ],
      [
        61.448066000000004,
        139.0969219999998
      ],
      [
        61.34445900000001,
        138.73272599999981
      ],
      [
        61.18834600000001,
        138.70245399999982
      ],
      [
        61.11168600000001,
        138.2872019999998
      ],
      [
        60.99529300000001,
        138.3444219999998
      ],
      [
        60.88584900000001,
        138.1799639999998
      ],
      [
        60.680299000000005,
        138.43887499999983
      ],
      [
        60.494181000000005,
        138.23886399999984
      ],
      [
        60.3203,
        138.32553399999983
      ],
      [
        60.24224400000001,
        138.20108399999984
      ],
      [
        59.948071000000006,
        138.28665599999985
      ],
      [
        59.830578,
        138.17108699999986
      ],
      [
        59.716688000000005,
        138.26944499999985
      ],
      [
        59.684461000000006,
        138.03360499999985
      ],
      [
        59.73724000000001,
        137.59692899999985
      ],
      [
        59.550022000000006,
        137.46274299999985
      ],
      [
        59.34502200000001,
        136.66165699999985
      ],
      [
        59.530575000000006,
        135.75415499999986
      ],
      [
        59.387800000000006,
        135.42108699999986
      ],
      [
        59.11363800000001,
        135.18610099999987
      ],
      [
        59.29724600000001,
        133.54913799999986
      ],
      [
        59.10613000000001,
        132.83441799999986
      ],
      [
        58.880019000000004,
        132.81387899999984
      ],
      [
        58.888631000000004,
        132.59164999999985
      ],
      [
        58.483076000000004,
        132.42468899999986
      ],
      [
        58.487799,
        132.12051999999986
      ],
      [
        58.237243,
        132.19635499999987
      ],
      [
        58.115028,
        132.00247699999986
      ],
      [
        58.049469,
        132.13025399999987
      ],
      [
        58.157524,
        131.45636599999986
      ],
      [
        57.920861,
        131.79999299999986
      ],
      [
        57.649469,
        132.04776399999986
      ],
      [
        57.565576,
        131.69302899999985
      ],
      [
        57.241969,
        131.50720699999985
      ],
      [
        57.233636999999995,
        131.09329699999986
      ],
      [
        57.07308499999999,
        131.49051299999985
      ],
      [
        56.71586299999999,
        131.80359199999984
      ],
      [
        56.65057799999999,
        131.66665999999984
      ],
      [
        56.454472999999986,
        131.69998499999983
      ],
      [
        56.55030499999999,
        131.49939399999982
      ],
      [
        56.41975199999999,
        131.40137199999984
      ],
      [
        56.49863999999999,
        131.30664699999983
      ],
      [
        56.400578999999986,
        131.11637099999982
      ],
      [
        55.898640999999984,
        130.8583139999998
      ],
      [
        55.67870199999999,
        130.92294899999982
      ],
      [
        55.73030699999999,
        129.4799569999998
      ],
      [
        55.65308299999999,
        129.0380329999998
      ],
      [
        55.528083999999986,
        129.08829499999982
      ],
      [
        55.46197499999999,
        128.9596949999998
      ],
      [
        55.688360999999986,
        127.7213769999998
      ],
      [
        55.54920099999999,
        127.47555099999981
      ],
      [
        55.69364099999999,
        127.1785999999998
      ],
      [
        55.57975799999999,
        126.05305199999981
      ],
      [
        55.65530299999999,
        126.12916099999981
      ],
      [
        55.79447799999999,
        125.83249999999981
      ],
      [
        55.87780499999999,
        125.27027599999981
      ],
      [
        55.87057999999999,
        124.5113959999998
      ],
      [
        56.38113899999999,
        123.91278599999981
      ],
      [
        56.42891399999999,
        123.2449939999998
      ],
      [
        56.597522999999995,
        122.8972309999998
      ],
      [
        56.47446899999999,
        122.66915799999981
      ],
      [
        56.737528999999995,
        122.17111099999981
      ],
      [
        56.738634999999995,
        121.60000599999981
      ],
      [
        56.975297999999995,
        121.4197229999998
      ],
      [
        57.037248,
        120.6688839999998
      ],
      [
        56.891411999999995,
        119.87944799999981
      ],
      [
        57.028077999999994,
        119.61610399999981
      ],
      [
        57.16669499999999,
        119.69194799999981
      ],
      [
        57.43113699999999,
        119.4005579999998
      ],
      [
        57.591131999999995,
        119.49638299999981
      ],
      [
        57.50224899999999,
        119.1858219999998
      ],
      [
        57.70029999999999,
        119.1663989999998
      ],
      [
        57.72696399999999,
        119.0458399999998
      ],
      [
        58.47751299999999,
        119.1330589999998
      ],
      [
        58.61252099999999,
        118.7630639999998
      ],
      [
        58.81473899999999,
        118.8969599999998
      ],
      [
        58.88752199999999,
        118.7866709999998
      ],
      [
        59.01443699999999,
        118.8478129999998
      ],
      [
        59.065567999999985,
        118.7024959999998
      ],
      [
        59.213073999999985,
        118.70141199999979
      ],
      [
        59.28613199999999,
        118.85139699999979
      ],
      [
        59.40584399999999,
        118.7608359999998
      ],
      [
        59.61056999999999,
        118.2836179999998
      ],
      [
        59.441678999999986,
        117.8836319999998
      ],
      [
        59.527790999999986,
        117.23611699999981
      ],
      [
        59.58723099999999,
        117.0844449999998
      ],
      [
        59.82945499999999,
        117.2274949999998
      ],
      [
        59.90889899999999,
        117.0572299999998
      ],
      [
        60.01945599999999,
        117.2975089999998
      ],
      [
        60.03584299999999,
        117.0486149999998
      ],
      [
        60.35723099999999,
        116.60082299999979
      ],
      [
        60.524176999999995,
        115.7202769999998
      ],
      [
        60.429733999999996,
        115.18916399999979
      ],
      [
        60.220003,
        114.69665599999979
      ],
      [
        59.749736,
        114.1730429999998
      ],
      [
        59.576404,
        113.6024939999998
      ],
      [
        59.376675999999996,
        113.42111399999979
      ],
      [
        59.260296999999994,
        113.46305999999979
      ],
      [
        59.07170599999999,
        112.57423699999978
      ],
      [
        58.87139599999999,
        112.52000799999978
      ],
      [
        59.12501199999999,
        112.44972699999978
      ],
      [
        59.31084099999999,
        112.63222099999979
      ],
      [
        59.30306599999999,
        112.2838869999998
      ],
      [
        59.504457999999985,
        112.2739009999998
      ],
      [
        59.20611799999998,
        111.6461249999998
      ],
      [
        59.18334599999998,
        111.18223499999979
      ],
      [
        59.26001399999998,
        110.7014149999998
      ],
      [
        59.036961999999974,
        110.4997329999998
      ],
      [
        58.974179999999976,
        110.18195499999979
      ],
      [
        59.06168099999998,
        109.64057399999979
      ],
      [
        59.29195899999998,
        109.51668899999979
      ],
      [
        59.31695999999998,
        109.26056199999978
      ],
      [
        59.41668399999998,
        109.35584599999979
      ],
      [
        59.45112299999998,
        109.25945599999979
      ],
      [
        59.64556499999998,
        109.5338929999998
      ],
      [
        59.750292999999985,
        109.4947399999998
      ],
      [
        60.02889399999999,
        109.76946699999979
      ],
      [
        60.226112999999984,
        109.7264059999998
      ],
      [
        60.579734999999985,
        110.1177789999998
      ],
      [
        60.669173999999984,
        110.0400049999998
      ],
      [
        60.691946999999985,
        110.2677869999998
      ],
      [
        60.819172999999985,
        110.2300069999998
      ],
      [
        61.081395999999984,
        110.5208539999998
      ],
      [
        61.327229999999986,
        109.7875169999998
      ],
      [
        61.53334499999998,
        109.8741789999998
      ],
      [
        61.82333699999998,
        109.5864059999998
      ],
      [
        62.28778299999998,
        110.0008409999998
      ],
      [
        62.410828999999985,
        109.9139049999998
      ],
      [
        62.52333199999998,
        109.2641859999998
      ],
      [
        62.81889399999998,
        109.6497449999998
      ],
      [
        62.93388299999998,
        109.4683499999998
      ],
      [
        63.32944299999998,
        109.3886309999998
      ],
      [
        63.55416599999998,
        109.04334899999981
      ],
      [
        63.56777699999998,
        108.1313929999998
      ],
      [
        63.80804799999998,
        108.2886189999998
      ],
      [
        63.85860699999998,
        108.7694689999998
      ],
      [
        64.28915699999997,
        108.4522379999998
      ],
      [
        64.18054599999998,
        107.8508359999998
      ],
      [
        64.29664899999997,
        107.6208479999998
      ],
      [
        64.51054699999997,
        106.6336429999998
      ],
      [
        64.39915799999997,
        106.15723999999979
      ],
      [
        64.42608799999996,
        106.06445299999979
      ],
      [
        64.47470199999997,
        105.80196399999978
      ],
      [
        64.58221499999996,
        105.72057499999978
      ],
      [
        64.65803499999996,
        105.87722899999979
      ],
      [
        64.79748399999995,
        105.85223599999979
      ],
      [
        64.81805199999995,
        106.00975299999979
      ],
      [
        64.89498599999995,
        105.94917499999978
      ],
      [
        64.85720599999995,
        106.22334399999978
      ],
      [
        65.02804399999995,
        105.98251599999979
      ],
      [
        65.30886499999995,
        106.44223199999979
      ],
      [
        65.49941699999995,
        106.96530299999979
      ],
      [
        65.67164299999995,
        106.4183519999998
      ],
      [
        66.00471199999994,
        106.4889079999998
      ],
      [
        66.18857999999994,
        106.1175099999998
      ],
      [
        66.37803199999995,
        106.10083999999979
      ],
      [
        66.47553499999995,
        106.32695999999979
      ],
      [
        66.79831999999995,
        106.19528499999979
      ],
      [
        66.89525799999994,
        106.06836399999979
      ],
      [
        67.01831999999995,
        105.52086299999979
      ],
      [
        67.34359099999995,
        106.80279099999979
      ],
      [
        68.86025299999994,
        106.89085699999978
      ],
      [
        69.40497599999995,
        106.13139799999978
      ],
      [
        69.52969999999995,
        106.36390999999978
      ]
    ],
    "1": [
      [
        74.269554,
        111.194191
      ],
      [
        74.314475,
        111.063584
      ],
      [
        74.269302,
        111.203416
      ]
    ],
    "2": [
      [
        74.196861,
        113.113083
      ],
      [
        74.08661,
        112.674469
      ],
      [
        74.29467,
        111.489807
      ],
      [
        74.36966699999999,
        112.04097
      ],
      [
        74.55191699999999,
        112.060974
      ],
      [
        74.43113699999999,
        113.431503
      ],
      [
        74.19072,
        113.159332
      ]
    ],
    "3": [
      [
        74.272415,
        116.022552
      ],
      [
        74.31077499999999,
        115.947861
      ],
      [
        74.337751,
        116.18499800000001
      ],
      [
        74.271804,
        116.046304
      ]
    ],
    "4": [
      [
        70.711998,
        161.738724
      ],
      [
        70.77533,
        161.532105
      ],
      [
        70.712915,
        161.744217
      ]
    ],
    "5": [
      [
        70.872276,
        161.613007
      ],
      [
        70.917892,
        161.460159
      ],
      [
        70.875695,
        161.619964
      ]
    ],
    "6": [
      [
        69.514221,
        161.614365
      ],
      [
        69.40805800000001,
        161.34347499999998
      ],
      [
        69.57016700000001,
        161.48091
      ],
      [
        69.525169,
        161.62550199999998
      ]
    ],
    "7": [
      [
        70.786667,
        160.651505
      ],
      [
        70.873413,
        160.58961499999998
      ],
      [
        70.8965,
        160.46827699999997
      ],
      [
        70.801332,
        160.79283099999998
      ],
      [
        70.79297100000001,
        160.68722599999998
      ]
    ],
    "8": [
      [
        76.957275,
        156.610031
      ],
      [
        76.981781,
        156.522614
      ],
      [
        76.988419,
        156.75808700000002
      ],
      [
        76.95558199999999,
        156.65530500000003
      ]
    ],
    "9": [
      [
        76.180969,
        152.650757
      ],
      [
        76.128281,
        152.888442
      ],
      [
        76.17314,
        152.640503
      ]
    ],
    "10": [
      [
        70.884941,
        152.300659
      ],
      [
        70.933029,
        152.10810999999998
      ],
      [
        70.88625400000001,
        152.30850199999998
      ]
    ],
    "11": [
      [
        71.217697,
        151.821548
      ],
      [
        71.268859,
        151.68600600000002
      ],
      [
        71.23341400000001,
        151.800247
      ]
    ],
    "12": [
      [
        72.993309,
        122.462776
      ],
      [
        73.04474499999999,
        122.255554
      ],
      [
        73.033806,
        122.567085
      ],
      [
        72.995332,
        122.470612
      ]
    ],
    "13": [
      [
        76.632774,
        149.197189
      ],
      [
        76.640029,
        148.47575500000002
      ],
      [
        76.755973,
        149.47253500000002
      ],
      [
        76.635024,
        149.21330500000002
      ]
    ],
    "14": [
      [
        73.837471,
        123.249084
      ],
      [
        73.87435799999999,
        123.132226
      ],
      [
        73.88021799999999,
        123.162361
      ],
      [
        73.84139299999998,
        123.285163
      ]
    ],
    "15": [
      [
        74.80275,
        150
      ],
      [
        74.775445,
        148.227615
      ],
      [
        75.22036,
        146.17446999999999
      ],
      [
        75.389084,
        146.241562
      ],
      [
        75.59144599999999,
        146.51397599999999
      ],
      [
        75.430917,
        146.65997099999998
      ],
      [
        75.330393,
        146.93235499999997
      ],
      [
        75.35294400000001,
        147.31924099999998
      ],
      [
        75.44278000000001,
        147.63116099999996
      ],
      [
        75.37302500000001,
        148.57854799999996
      ],
      [
        75.30183500000001,
        148.51185299999995
      ],
      [
        75.19930500000001,
        148.62263199999995
      ],
      [
        75.26544400000002,
        149.36480399999996
      ],
      [
        75.13169300000001,
        150.98305899999997
      ],
      [
        74.86658800000001,
        150.66657699999996
      ],
      [
        74.80764400000001,
        150.04343999999995
      ]
    ],
    "16": [
      [
        73.923248,
        123.315498
      ],
      [
        73.965164,
        123.162193
      ],
      [
        73.925614,
        123.315886
      ]
    ],
    "17": [
      [
        74.236191,
        140.288086
      ],
      [
        74.177025,
        141.12863099999998
      ],
      [
        73.99572,
        141.02883799999998
      ],
      [
        73.91777800000001,
        140.58880599999998
      ],
      [
        73.90041400000001,
        140.59072899999998
      ],
      [
        74.07411100000002,
        140.20303299999998
      ],
      [
        74.23516700000002,
        140.28396499999997
      ]
    ],
    "18": [
      [
        73.430969,
        139.849274
      ],
      [
        73.51380900000001,
        140.620285
      ],
      [
        73.86589000000001,
        141.165115
      ],
      [
        73.91752400000001,
        142.09027099999997
      ],
      [
        73.55969000000002,
        143.436492
      ],
      [
        73.45525200000002,
        143.598357
      ],
      [
        73.34852400000001,
        143.49908299999998
      ],
      [
        73.21739000000001,
        143.616056
      ],
      [
        73.42935600000001,
        140.727172
      ],
      [
        73.42671800000001,
        139.836104
      ]
    ],
    "19": [
      [
        71.641167,
        138.656052
      ],
      [
        71.661391,
        138.484941
      ],
      [
        71.70393899999999,
        138.895616
      ],
      [
        71.64530099999999,
        138.678285
      ]
    ],
    "20": [
      [
        71.494247,
        137.32103
      ],
      [
        71.535835,
        137.00288500000002
      ],
      [
        71.520195,
        138.03544700000003
      ],
      [
        71.403664,
        137.78080800000004
      ],
      [
        71.50011300000001,
        137.32449500000004
      ]
    ],
    "21": [
      [
        76.195831,
        139.000671
      ],
      [
        76.198386,
        139.166199
      ],
      [
        76.115524,
        139.154022
      ],
      [
        75.946694,
        140.019867
      ],
      [
        75.89394499999999,
        139.831117
      ],
      [
        75.84314099999999,
        139.957841
      ],
      [
        75.78244899999999,
        140.50972000000002
      ],
      [
        75.66241599999998,
        140.55779900000002
      ],
      [
        75.63608699999997,
        141.073698
      ],
      [
        75.77116399999997,
        140.901671
      ],
      [
        76.05844099999997,
        141.061216
      ],
      [
        75.99866499999997,
        141.726941
      ],
      [
        76.17500299999998,
        141.412214
      ],
      [
        75.85558299999998,
        142.639158
      ],
      [
        75.83280299999998,
        143.85883800000002
      ],
      [
        75.49647599999999,
        145.41372400000003
      ],
      [
        75.42966599999998,
        144.87197600000002
      ],
      [
        75.37430799999998,
        144.79177700000002
      ],
      [
        75.27302899999998,
        144.988355
      ],
      [
        75.05519499999998,
        144.42288000000002
      ],
      [
        75.05938999999998,
        143.522825
      ],
      [
        74.95966599999998,
        143.71861
      ],
      [
        74.90166799999999,
        143.55718800000002
      ],
      [
        74.83033299999998,
        142.64646800000003
      ],
      [
        74.80225799999998,
        142.51908900000004
      ],
      [
        74.92402999999999,
        141.96997200000004
      ],
      [
        74.92400899999998,
        142.38855000000004
      ],
      [
        75.01884199999998,
        142.25061000000005
      ],
      [
        74.79781199999998,
        139.98663300000004
      ],
      [
        74.81161299999998,
        140.12774600000003
      ],
      [
        74.97327899999998,
        139.69097900000003
      ],
      [
        74.92191899999997,
        139.51025400000003
      ],
      [
        74.85683999999998,
        139.71977100000004
      ],
      [
        74.63686999999997,
        139.17971800000004
      ],
      [
        74.75931299999998,
        138.20060800000005
      ],
      [
        75.03372699999997,
        137.78419600000004
      ],
      [
        75.06147499999997,
        137.37635900000004
      ],
      [
        75.23489099999998,
        137.02302800000004
      ],
      [
        75.26225699999998,
        136.96448100000003
      ],
      [
        75.46397699999997,
        137.30406400000004
      ],
      [
        75.54939499999998,
        137.20122000000003
      ],
      [
        75.61831099999998,
        137.27661200000003
      ],
      [
        75.59019699999997,
        137.03875800000003
      ],
      [
        75.58600099999998,
        137.00694500000003
      ],
      [
        75.65080399999998,
        137.23703100000003
      ],
      [
        75.73014199999999,
        137.17520300000004
      ],
      [
        75.79291599999999,
        137.62445200000005
      ],
      [
        75.93880599999999,
        137.48466700000006
      ],
      [
        76.19577899999999,
        138.97223000000005
      ]
    ],
    "22": [
      [
        73.581223,
        125.784302
      ],
      [
        73.65216699999999,
        125.910529
      ],
      [
        73.53097399999999,
        125.7705
      ],
      [
        73.50772099999999,
        125.890334
      ],
      [
        73.55644099999999,
        125.689635
      ],
      [
        73.57572099999999,
        125.792998
      ]
    ],
    "23": [
      [
        75.364998,
        135.651581
      ],
      [
        75.440276,
        135.511475
      ],
      [
        75.870276,
        135.718506
      ],
      [
        75.62297000000001,
        136.238585
      ],
      [
        75.485442,
        135.977188
      ],
      [
        75.44078,
        136.069443
      ],
      [
        75.36286100000001,
        135.720383
      ]
    ],
    "24": [
      [
        74.104668,
        135.720062
      ],
      [
        74.238585,
        135.463501
      ],
      [
        73.92169200000001,
        136.33836300000002
      ],
      [
        74.09774700000001,
        135.73669400000003
      ]
    ],
    "25": [
      [
        71.89817,
        128.751724
      ],
      [
        71.95005699999999,
        128.609422
      ],
      [
        71.90161099999999,
        128.773727
      ]
    ],
    "26": [
      [
        71.981918,
        128.865783
      ],
      [
        71.94683199999999,
        128.95632799999998
      ],
      [
        72.01149799999999,
        128.679107
      ],
      [
        71.99891799999999,
        128.784728
      ]
    ]
  },
  "Кабардино-Балкарская Республика": {
    "0": [
      [
        43.807327,
        42.683411
      ],
      [
        43.951377,
        43.177773
      ],
      [
        43.804993,
        43.332497000000004
      ],
      [
        43.840827000000004,
        43.747208
      ],
      [
        43.993048,
        43.890549
      ],
      [
        44.000539,
        44.419159
      ],
      [
        43.755269000000006,
        44.278871
      ],
      [
        43.649153000000005,
        44.436371
      ],
      [
        43.427482000000005,
        44.404984
      ],
      [
        43.43027300000001,
        44.106377
      ],
      [
        43.20749500000001,
        44.019707000000004
      ],
      [
        43.314710000000005,
        43.837769
      ],
      [
        42.930815,
        43.558327
      ],
      [
        42.889997,
        43.34314
      ],
      [
        43.179082,
        42.893471
      ],
      [
        43.244298,
        42.376003
      ],
      [
        43.607487,
        42.452208
      ],
      [
        43.679431,
        42.664985
      ],
      [
        43.807315,
        42.68341
      ]
    ]
  },
  "Республика Северная Осетия-Алания": {
    "0": [
      [
        43.818832,
        44.28508
      ],
      [
        43.796388,
        44.814712
      ],
      [
        43.584162,
        44.838592
      ],
      [
        43.596376,
        44.536941999999996
      ],
      [
        43.39638,
        44.497491
      ],
      [
        43.224437,
        44.577766999999994
      ],
      [
        43.069988,
        44.916655999999996
      ],
      [
        42.924436,
        44.93888
      ],
      [
        42.884664,
        44.630233999999994
      ],
      [
        42.74766,
        44.63875099999999
      ],
      [
        42.701338,
        44.50347099999999
      ],
      [
        42.587672,
        43.77016899999999
      ],
      [
        42.750088999999996,
        43.79085999999999
      ],
      [
        42.895377999999994,
        43.389313999999985
      ],
      [
        42.93860299999999,
        43.56666699999998
      ],
      [
        43.31471599999999,
        43.83776999999998
      ],
      [
        43.20750099999999,
        44.01970799999998
      ],
      [
        43.43027899999999,
        44.10637799999998
      ],
      [
        43.42748799999999,
        44.404984999999975
      ],
      [
        43.64915899999999,
        44.43637199999998
      ],
      [
        43.81444099999999,
        44.283876999999976
      ]
    ]
  },
  "Республика Тыва": {
    "0": [
      [
        50.009556,
        90
      ],
      [
        49.916664000000004,
        89.598878
      ],
      [
        50.204711,
        89.516099
      ],
      [
        50.209433000000004,
        89.329714
      ],
      [
        50.470266,
        89.50582999999999
      ],
      [
        50.383605,
        89.64305099999999
      ],
      [
        50.499999,
        89.86665199999999
      ],
      [
        51.047216000000006,
        89.06693899999999
      ],
      [
        51.43082100000001,
        88.96775699999999
      ],
      [
        51.55947600000001,
        88.73585499999999
      ],
      [
        51.66304400000001,
        89.19775399999999
      ],
      [
        51.54638400000001,
        89.256103
      ],
      [
        51.58137900000001,
        89.735259
      ],
      [
        51.75110100000001,
        90.106093
      ],
      [
        51.98498700000001,
        90.026657
      ],
      [
        52.19220900000001,
        90.349716
      ],
      [
        52.073321000000014,
        90.737761
      ],
      [
        51.90609300000001,
        90.841368
      ],
      [
        51.828603000000015,
        92.8486
      ],
      [
        52.21748700000001,
        93.678038
      ],
      [
        52.83915300000001,
        94.142484
      ],
      [
        52.90720700000001,
        94.515819
      ],
      [
        53.233042000000005,
        94.704707
      ],
      [
        53.310816,
        94.582484
      ],
      [
        53.459154000000005,
        95.219431
      ],
      [
        53.369151,
        95.39332
      ],
      [
        53.522760000000005,
        95.75277100000001
      ],
      [
        53.449709000000006,
        96.09414000000001
      ],
      [
        53.61748600000001,
        96.241921
      ],
      [
        53.50721000000001,
        96.446922
      ],
      [
        53.72554000000001,
        96.807196
      ],
      [
        53.59999000000001,
        97.332205
      ],
      [
        53.37136800000001,
        97.599707
      ],
      [
        53.22720300000001,
        98.285803
      ],
      [
        53.10054100000001,
        98.299978
      ],
      [
        53.15386900000001,
        98.923588
      ],
      [
        53.102486000000006,
        99.06525099999999
      ],
      [
        52.95377500000001,
        99.24995799999999
      ],
      [
        52.85248700000001,
        99.21442099999999
      ],
      [
        52.92915500000001,
        98.947195
      ],
      [
        52.83776400000001,
        98.84720399999999
      ],
      [
        52.660267000000005,
        98.93524699999999
      ],
      [
        52.424161000000005,
        98.62552399999998
      ],
      [
        52.271933000000004,
        98.63997399999998
      ],
      [
        52.14972100000001,
        98.94819399999999
      ],
      [
        51.82980500000001,
        98.71090499999998
      ],
      [
        51.71102700000001,
        98.30849199999999
      ],
      [
        51.46222200000001,
        98.23424299999999
      ],
      [
        51.01222100000001,
        97.82829799999999
      ],
      [
        50.867027000000014,
        98.01962799999998
      ],
      [
        50.65280600000001,
        98.04884899999999
      ],
      [
        50.54744500000001,
        98.323185
      ],
      [
        50.25547600000001,
        98.25437699999999
      ],
      [
        49.948226000000005,
        97.583434
      ],
      [
        49.788116,
        97.42996099999999
      ],
      [
        49.981947000000005,
        96.63407799999999
      ],
      [
        49.898390000000006,
        96.427101
      ],
      [
        50.04105500000001,
        95.83434999999999
      ],
      [
        49.92064100000001,
        95.49699499999998
      ],
      [
        50.03394500000001,
        94.61905099999998
      ],
      [
        50.21375000000001,
        94.40702299999998
      ],
      [
        50.57530700000001,
        94.27627099999998
      ],
      [
        50.566809000000006,
        93.10402199999999
      ],
      [
        50.63050200000001,
        92.98305099999999
      ],
      [
        50.784001,
        93.00924299999998
      ],
      [
        50.705167,
        92.61613799999998
      ],
      [
        50.894638,
        92.37179999999998
      ],
      [
        50.716221,
        92.27580099999997
      ],
      [
        50.720416,
        91.75663699999997
      ],
      [
        50.457722,
        91.42916799999998
      ],
      [
        50.487913999999996,
        90.73772399999997
      ],
      [
        50.336777,
        90.66803099999997
      ],
      [
        50.168251,
        90.67541599999997
      ],
      [
        50.144417,
        90.36822599999996
      ],
      [
        50.011528,
        90.00466999999996
      ]
    ]
  },
  "Карачаево-Черкесская Республика": {
    "0": [
      [
        44.458649,
        41.729332
      ],
      [
        44.361489,
        42.03818
      ],
      [
        44.289163,
        42.557207
      ],
      [
        44.097773000000004,
        42.291658999999996
      ],
      [
        43.967212,
        42.386106
      ],
      [
        44.042503,
        42.599717
      ],
      [
        43.807331000000005,
        42.683408
      ],
      [
        43.67944000000001,
        42.664983
      ],
      [
        43.607496000000005,
        42.452206
      ],
      [
        43.246657000000006,
        42.379431
      ],
      [
        43.222896000000006,
        41.630663
      ],
      [
        43.53364800000001,
        40.698595
      ],
      [
        43.920835000000004,
        40.771657999999995
      ],
      [
        43.981107,
        40.958042999999996
      ],
      [
        44.120954000000005,
        40.855573
      ],
      [
        43.987768,
        41.439709
      ],
      [
        44.201101,
        41.696087
      ],
      [
        44.375263000000004,
        41.594991
      ],
      [
        44.456096,
        41.728864
      ]
    ]
  },
  "Еврейская автономная область": {
    "0": [
      [
        48.877632,
        130.626358
      ],
      [
        48.964714,
        130.97439500000002
      ],
      [
        49.24916,
        131.174406
      ],
      [
        49.201660000000004,
        131.457455
      ],
      [
        49.328605,
        131.79330000000002
      ],
      [
        49.233329000000005,
        131.989406
      ],
      [
        49.49027100000001,
        132.142207
      ],
      [
        49.41026900000001,
        132.38915500000002
      ],
      [
        49.289435000000005,
        132.39580700000002
      ],
      [
        49.34748700000001,
        132.77831300000003
      ],
      [
        49.233047000000006,
        132.81078300000001
      ],
      [
        49.175552,
        133.19384000000002
      ],
      [
        48.81694,
        133.31218600000003
      ],
      [
        48.638130000000004,
        133.92522200000002
      ],
      [
        48.743064000000004,
        134.39189700000003
      ],
      [
        48.592429,
        134.56270400000002
      ],
      [
        48.609640000000006,
        134.99565600000003
      ],
      [
        48.371657000000006,
        134.79051600000003
      ],
      [
        48.335387000000004,
        134.05768300000003
      ],
      [
        48.074443,
        133.50093600000002
      ],
      [
        48.105692000000005,
        133.16030000000003
      ],
      [
        47.946581,
        132.66965400000004
      ],
      [
        47.715219000000005,
        132.56204900000003
      ],
      [
        47.68233300000001,
        131.05318500000004
      ],
      [
        48.10177300000001,
        130.65102500000003
      ],
      [
        48.30007900000001,
        130.82552400000003
      ],
      [
        48.49319000000001,
        130.75618800000004
      ],
      [
        48.61382800000001,
        130.52450000000005
      ],
      [
        48.879159000000016,
        130.63868100000005
      ]
    ]
  },
  "Томская область": {
    "0": [
      [
        60,
        84.546089
      ],
      [
        59.90027,
        84.693862
      ],
      [
        59.881662,
        87.09776199999999
      ],
      [
        59.684993,
        87.18275299999999
      ],
      [
        59.672771,
        87.522764
      ],
      [
        59.263887999999994,
        87.903318
      ],
      [
        59.30138699999999,
        88.63053599999999
      ],
      [
        59.02582999999999,
        88.834424
      ],
      [
        58.91110599999999,
        88.384153
      ],
      [
        58.52555599999999,
        87.929975
      ],
      [
        58.232770999999985,
        88.019711
      ],
      [
        58.10388799999998,
        88.158322
      ],
      [
        57.94804999999998,
        89.348599
      ],
      [
        57.63444399999998,
        89.392484
      ],
      [
        57.61777299999998,
        89.14637599999999
      ],
      [
        57.12388499999998,
        88.52470199999999
      ],
      [
        57.08499899999998,
        88.73248099999999
      ],
      [
        56.77999999999998,
        88.53748999999999
      ],
      [
        56.83416899999998,
        88.39971899999999
      ],
      [
        56.54361999999998,
        87.78442499999998
      ],
      [
        56.659165999999985,
        87.51277299999998
      ],
      [
        56.67417299999998,
        87.14804199999998
      ],
      [
        56.54722799999998,
        87.14776799999997
      ],
      [
        56.53444899999998,
        87.02665999999996
      ],
      [
        56.545839999999984,
        86.39360399999997
      ],
      [
        56.625557999999984,
        86.20721899999997
      ],
      [
        56.29973099999999,
        85.56776999999997
      ],
      [
        56.238894999999985,
        85.68803999999997
      ],
      [
        56.22555899999998,
        85.13916699999997
      ],
      [
        55.98250199999998,
        84.36775999999998
      ],
      [
        56.01916799999998,
        83.97666299999997
      ],
      [
        55.696109999999976,
        83.64138299999998
      ],
      [
        55.678891999999976,
        83.30166099999998
      ],
      [
        55.75500999999998,
        83.23082999999998
      ],
      [
        55.89306299999998,
        83.41582599999998
      ],
      [
        56.045840999999974,
        83.22054399999998
      ],
      [
        56.096666999999975,
        83.29553999999997
      ],
      [
        56.13722499999997,
        83.11249699999998
      ],
      [
        56.208611999999974,
        83.22277199999998
      ],
      [
        56.23472699999997,
        83.06527199999998
      ],
      [
        56.45500199999997,
        83.25943899999997
      ],
      [
        56.55332899999997,
        83.09082999999997
      ],
      [
        56.53611699999997,
        82.83999099999997
      ],
      [
        56.25333399999997,
        81.53582299999997
      ],
      [
        56.54003799999997,
        81.17992699999996
      ],
      [
        56.429717999999966,
        80.28651099999996
      ],
      [
        56.930556999999965,
        79.58750699999996
      ],
      [
        57.16138399999996,
        78.44444099999996
      ],
      [
        57.25000599999996,
        76.10722199999995
      ],
      [
        57.64638999999996,
        75.58277899999995
      ],
      [
        57.93387999999996,
        75.56443799999994
      ],
      [
        58.11832699999996,
        75.06639199999994
      ],
      [
        58.21998799999996,
        75.23971599999993
      ],
      [
        58.34471199999996,
        75.05416999999993
      ],
      [
        58.47276299999996,
        75.35556199999994
      ],
      [
        58.58110699999996,
        75.10527199999993
      ],
      [
        58.67804499999996,
        75.15028499999993
      ],
      [
        59.011937999999965,
        75.69055199999993
      ],
      [
        59.241100999999965,
        75.61528099999992
      ],
      [
        59.54360599999997,
        76.16305599999993
      ],
      [
        59.58027099999997,
        76.64834599999993
      ],
      [
        60.107767999999965,
        76.68720899999994
      ],
      [
        60.268044999999965,
        76.85166799999993
      ],
      [
        60.47664799999996,
        76.77444299999993
      ],
      [
        60.534989999999965,
        77.03750499999994
      ],
      [
        60.70998399999996,
        76.96777299999994
      ],
      [
        60.85331699999996,
        77.10499499999993
      ],
      [
        60.74664999999996,
        77.92138499999993
      ],
      [
        60.83859099999996,
        79.18554599999993
      ],
      [
        60.64859699999996,
        79.38667199999993
      ],
      [
        60.80015499999996,
        80.69772099999993
      ],
      [
        60.65053599999996,
        81.86166099999993
      ],
      [
        60.51831899999996,
        82.16666799999993
      ],
      [
        60.903311999999964,
        82.80277699999994
      ],
      [
        61.04915499999996,
        83.50944099999994
      ],
      [
        60.82470599999996,
        83.99387599999994
      ],
      [
        60.85540499999996,
        84.25965399999994
      ],
      [
        60.46637599999996,
        84.70194399999994
      ],
      [
        60.35637599999996,
        84.78527199999994
      ],
      [
        60.00082399999996,
        84.54721999999994
      ]
    ]
  },
  "Амурская область": {
    "0": [
      [
        55.678665,
        130.922943
      ],
      [
        55.612770000000005,
        131.816924
      ],
      [
        55.706382000000005,
        132.395262
      ],
      [
        55.37554900000001,
        132.950804
      ],
      [
        55.36943900000001,
        132.681364
      ],
      [
        55.20249400000001,
        132.614134
      ],
      [
        55.19249200000001,
        132.35330000000002
      ],
      [
        55.06360900000001,
        132.389158
      ],
      [
        55.05027400000001,
        132.076354
      ],
      [
        54.89388700000001,
        131.983031
      ],
      [
        54.71721300000001,
        131.382171
      ],
      [
        54.605557000000005,
        131.187468
      ],
      [
        54.316106000000005,
        131.113281
      ],
      [
        54.120549000000004,
        130.50607300000001
      ],
      [
        53.881384000000004,
        130.44107200000002
      ],
      [
        53.749725000000005,
        131.43802000000002
      ],
      [
        53.30889200000001,
        131.53442400000003
      ],
      [
        53.214723000000006,
        131.44940200000002
      ],
      [
        53.23388700000001,
        131.86135800000002
      ],
      [
        53.12471900000001,
        131.89025700000002
      ],
      [
        53.228051000000015,
        132.888303
      ],
      [
        53.425834000000016,
        133.214962
      ],
      [
        53.54583700000001,
        133.564143
      ],
      [
        53.43250300000001,
        134.006372
      ],
      [
        53.62277300000001,
        134.554955
      ],
      [
        53.42778100000001,
        134.916648
      ],
      [
        53.33833500000001,
        134.810509
      ],
      [
        53.26250700000001,
        134.961082
      ],
      [
        52.927226000000005,
        134.652459
      ],
      [
        52.718608,
        134.659966
      ],
      [
        52.647229,
        134.791924
      ],
      [
        52.419454,
        134.627161
      ],
      [
        52.681951000000005,
        133.315791
      ],
      [
        52.453894000000005,
        133.241053
      ],
      [
        52.27000400000001,
        133.437738
      ],
      [
        52.200562000000005,
        133.341638
      ],
      [
        52.179734,
        132.82635
      ],
      [
        51.790293000000005,
        132.281093
      ],
      [
        51.68722700000001,
        131.412747
      ],
      [
        51.35834800000001,
        131.502193
      ],
      [
        51.243899000000006,
        131.060788
      ],
      [
        50.876400000000004,
        130.844113
      ],
      [
        50.66278500000001,
        130.64413199999998
      ],
      [
        50.656957000000006,
        130.95245
      ],
      [
        50.475568,
        130.869686
      ],
      [
        50.384183,
        130.922755
      ],
      [
        50.328626,
        131.173579
      ],
      [
        50.369458,
        131.30468299999998
      ],
      [
        50.187513,
        131.444696
      ],
      [
        49.976958,
        131.290247
      ],
      [
        49.902794,
        131.502466
      ],
      [
        49.731126,
        131.4844
      ],
      [
        49.70891,
        131.35134299999999
      ],
      [
        49.604189000000005,
        131.50469299999997
      ],
      [
        49.41418,
        131.50109199999997
      ],
      [
        49.243496,
        131.39583799999997
      ],
      [
        49.240575,
        131.15664199999998
      ],
      [
        48.964743,
        130.97439199999997
      ],
      [
        48.863639,
        130.23418899999996
      ],
      [
        49.422224,
        129.50043999999997
      ],
      [
        49.34875,
        129.11813199999997
      ],
      [
        49.478055000000005,
        128.74989099999996
      ],
      [
        49.58580500000001,
        128.77602799999997
      ],
      [
        49.499612000000006,
        128.25060699999997
      ],
      [
        49.58610900000001,
        127.81527499999997
      ],
      [
        49.817859000000006,
        127.52300199999998
      ],
      [
        50.216831000000006,
        127.59813599999998
      ],
      [
        50.31710700000001,
        127.34139299999998
      ],
      [
        50.74710700000001,
        127.29458599999998
      ],
      [
        51.062025000000006,
        126.92016599999998
      ],
      [
        51.31991300000001,
        126.97433499999998
      ],
      [
        51.272330000000004,
        126.82544699999998
      ],
      [
        51.385801,
        126.91583199999998
      ],
      [
        51.526577,
        126.83277899999997
      ],
      [
        51.713440000000006,
        126.72355699999997
      ],
      [
        51.942744000000005,
        126.46186099999997
      ],
      [
        52.120909000000005,
        126.55644199999998
      ],
      [
        52.206437,
        126.29880599999997
      ],
      [
        52.284741000000004,
        126.43961399999998
      ],
      [
        52.607907000000004,
        125.97413799999998
      ],
      [
        52.763851,
        126.11277899999999
      ],
      [
        52.857517,
        125.67138899999999
      ],
      [
        52.97938,
        125.73872599999999
      ],
      [
        53.069381,
        125.61274899999998
      ],
      [
        53.197406,
        125.12300399999998
      ],
      [
        53.094295,
        124.87508699999998
      ],
      [
        53.547184,
        123.58559099999998
      ],
      [
        53.420418,
        122.02572999999998
      ],
      [
        53.821124,
        121.93054599999998
      ],
      [
        54.014177,
        121.63388499999998
      ],
      [
        54.145013999999996,
        121.76193499999998
      ],
      [
        54.407509999999995,
        121.70276999999999
      ],
      [
        54.445007999999994,
        122.13971299999999
      ],
      [
        54.572509999999994,
        121.92221599999999
      ],
      [
        54.803892999999995,
        121.90082299999999
      ],
      [
        54.741392999999995,
        121.65138699999999
      ],
      [
        55.18556099999999,
        122.02916399999998
      ],
      [
        55.608059999999995,
        121.89722099999997
      ],
      [
        55.510009,
        121.33527799999997
      ],
      [
        55.808614,
        121.36110199999997
      ],
      [
        56.020558,
        121.20526399999997
      ],
      [
        55.868062,
        120.18166799999997
      ],
      [
        56.130558,
        120.05081599999997
      ],
      [
        56.287227,
        120.53859299999996
      ],
      [
        56.489443,
        119.96555699999996
      ],
      [
        56.602502,
        119.80637799999997
      ],
      [
        56.692498,
        119.87552899999997
      ],
      [
        56.768058,
        119.66499699999997
      ],
      [
        57.008895,
        120.33110399999997
      ],
      [
        56.975273,
        121.41971199999996
      ],
      [
        56.73861,
        121.59999499999996
      ],
      [
        56.737504,
        122.17109999999997
      ],
      [
        56.474444,
        122.66914699999997
      ],
      [
        56.597498,
        122.89721999999996
      ],
      [
        56.428889,
        123.24498299999996
      ],
      [
        56.381114,
        123.91277499999997
      ],
      [
        55.870554999999996,
        124.51138499999996
      ],
      [
        55.877779999999994,
        125.27026499999997
      ],
      [
        55.794453,
        125.83248899999997
      ],
      [
        55.655277999999996,
        126.12914999999997
      ],
      [
        55.579733,
        126.05304099999996
      ],
      [
        55.693616,
        127.17858899999996
      ],
      [
        55.549175999999996,
        127.47553999999997
      ],
      [
        55.68833599999999,
        127.72136599999996
      ],
      [
        55.461949999999995,
        128.95968399999995
      ],
      [
        55.52805899999999,
        129.08828399999996
      ],
      [
        55.653057999999994,
        129.03802199999996
      ],
      [
        55.730281999999995,
        129.47994599999996
      ],
      [
        55.67889099999999,
        130.89721199999997
      ]
    ]
  },
  "Пензенская область": {
    "0": [
      [
        52.347549,
        45
      ],
      [
        52.552489,
        44.54805
      ],
      [
        52.316101,
        44.346383
      ],
      [
        52.402869,
        43.079797000000006
      ],
      [
        52.502494000000006,
        42.99833
      ],
      [
        52.704162000000004,
        43.206941
      ],
      [
        53.120542,
        42.545275000000004
      ],
      [
        53.366102,
        42.256389000000006
      ],
      [
        53.436375,
        42.31777400000001
      ],
      [
        53.521932,
        42.097491000000005
      ],
      [
        53.737491,
        42.084438000000006
      ],
      [
        53.847483,
        42.30781100000001
      ],
      [
        53.728595,
        42.52916200000001
      ],
      [
        53.833872,
        42.571107000000005
      ],
      [
        53.822215,
        43.14110600000001
      ],
      [
        54.002771,
        43.16276500000001
      ],
      [
        54.019707000000004,
        43.536941000000006
      ],
      [
        53.74637800000001,
        44.00971800000001
      ],
      [
        53.66304300000001,
        44.58554600000001
      ],
      [
        53.71054200000001,
        44.739430000000006
      ],
      [
        53.89331900000001,
        44.61554400000001
      ],
      [
        53.96637800000001,
        44.85416000000001
      ],
      [
        53.883600000000015,
        45.331936000000006
      ],
      [
        54.00832500000001,
        45.959712
      ],
      [
        53.919153000000016,
        46.207492
      ],
      [
        53.509715000000014,
        46.490542000000005
      ],
      [
        53.27832400000001,
        46.943315000000005
      ],
      [
        53.17804300000001,
        46.869708
      ],
      [
        53.18304700000001,
        46.973871
      ],
      [
        52.88888100000001,
        46.899989000000005
      ],
      [
        52.76665900000001,
        46.990823000000006
      ],
      [
        52.61639900000001,
        46.83076700000001
      ],
      [
        52.73387600000001,
        46.58859500000001
      ],
      [
        52.68054800000001,
        46.29832800000001
      ],
      [
        52.407210000000006,
        45.98081700000001
      ],
      [
        52.520269000000006,
        45.64110300000001
      ],
      [
        52.330825000000004,
        45.01499700000001
      ]
    ]
  },
  "Республика Бурятия": {
    "0": [
      [
        56.524437,
        113.900818
      ],
      [
        56.692763,
        114.172211
      ],
      [
        56.693602,
        114.77304000000001
      ],
      [
        57.122762,
        115.084151
      ],
      [
        57.229428,
        116.159987
      ],
      [
        57.126852,
        116.272628
      ],
      [
        56.893607,
        115.608322
      ],
      [
        56.621894000000005,
        115.432374
      ],
      [
        56.429672000000004,
        115.79879799999999
      ],
      [
        56.296944,
        115.68414399999999
      ],
      [
        56.149438,
        115.83693
      ],
      [
        56.041109000000006,
        115.75861499999999
      ],
      [
        55.916667000000004,
        115.89804199999999
      ],
      [
        55.39694600000001,
        115.92997799999999
      ],
      [
        55.37360900000001,
        116.37608399999999
      ],
      [
        55.11389000000001,
        116.77777199999998
      ],
      [
        54.70833500000001,
        116.87803699999998
      ],
      [
        54.464081000000014,
        116.52275299999998
      ],
      [
        54.405000000000015,
        115.61554299999997
      ],
      [
        53.99250300000001,
        114.97581099999998
      ],
      [
        53.61644300000001,
        113.81470199999998
      ],
      [
        53.38972900000001,
        113.83221199999998
      ],
      [
        53.19666700000001,
        114.19832199999999
      ],
      [
        53.07195100000001,
        114.12692799999999
      ],
      [
        53.067785000000015,
        114.32527599999999
      ],
      [
        52.967222000000014,
        114.31721099999999
      ],
      [
        52.718336000000015,
        114.12360099999998
      ],
      [
        52.450285000000015,
        113.57916899999998
      ],
      [
        52.34972900000002,
        112.53888499999998
      ],
      [
        52.20695300000002,
        112.40221199999998
      ],
      [
        52.28334600000002,
        112.07471399999997
      ],
      [
        51.92778700000002,
        111.60776599999997
      ],
      [
        51.78222600000002,
        111.01500799999997
      ],
      [
        51.502793000000025,
        110.69610099999997
      ],
      [
        51.620003000000025,
        110.04915899999997
      ],
      [
        51.34667400000003,
        109.37997799999998
      ],
      [
        51.50667600000003,
        109.07416899999998
      ],
      [
        51.509736000000025,
        108.59722599999998
      ],
      [
        51.270844000000025,
        108.35278099999998
      ],
      [
        51.10973500000002,
        108.57084299999998
      ],
      [
        51.048900000000025,
        108.33221899999998
      ],
      [
        50.833958000000024,
        108.25146899999999
      ],
      [
        50.75806900000003,
        108.06554899999999
      ],
      [
        50.504186000000026,
        108.68526899999999
      ],
      [
        50.33723200000003,
        107.98553999999999
      ],
      [
        49.95610600000003,
        107.70448799999998
      ],
      [
        50.01968600000003,
        107.14648299999999
      ],
      [
        50.32143600000003,
        106.75790299999998
      ],
      [
        50.47924100000003,
        105.38770899999999
      ],
      [
        50.15438300000003,
        104.06101199999999
      ],
      [
        50.20210500000003,
        103.320488
      ],
      [
        50.31432500000003,
        102.91571
      ],
      [
        50.59979700000003,
        102.301736
      ],
      [
        50.841350000000034,
        102.224603
      ],
      [
        51.32265800000003,
        102.213076
      ],
      [
        51.39265800000003,
        102.083354
      ],
      [
        51.45843400000003,
        101.377353
      ],
      [
        51.75040700000003,
        100.515658
      ],
      [
        51.75332400000003,
        99.993487
      ],
      [
        52.07198900000003,
        99.04855
      ],
      [
        52.27195200000003,
        98.640004
      ],
      [
        52.42418000000003,
        98.62555400000001
      ],
      [
        52.66028600000003,
        98.93527700000001
      ],
      [
        52.831954000000025,
        98.84445700000002
      ],
      [
        52.92500800000003,
        98.93111000000002
      ],
      [
        52.85250600000003,
        99.21445000000001
      ],
      [
        53.10944800000003,
        99.36305500000002
      ],
      [
        53.29639900000003,
        99.86194000000002
      ],
      [
        53.37611100000003,
        100.26556600000002
      ],
      [
        52.93944200000003,
        100.60306000000003
      ],
      [
        52.634733000000026,
        101.43777600000003
      ],
      [
        52.224447000000026,
        101.94610700000003
      ],
      [
        52.23417300000003,
        102.55943400000002
      ],
      [
        51.985280000000024,
        102.76972200000003
      ],
      [
        51.81250500000002,
        103.21112800000003
      ],
      [
        51.61250800000002,
        103.17666600000003
      ],
      [
        51.44000100000002,
        103.32084600000003
      ],
      [
        51.41147500000002,
        103.81388800000003
      ],
      [
        51.13187400000002,
        103.81381300000004
      ],
      [
        51.26845500000002,
        104.60943700000004
      ],
      [
        51.62102400000002,
        104.63600100000004
      ],
      [
        51.85250700000002,
        105.43998700000003
      ],
      [
        52.44000100000002,
        106.19194800000002
      ],
      [
        53.186948000000015,
        108.04055800000002
      ],
      [
        54.420560000000016,
        109.00556200000001
      ],
      [
        54.49000200000002,
        109.05027600000001
      ],
      [
        54.54306500000002,
        108.55804400000001
      ],
      [
        54.66945200000002,
        108.69638800000001
      ],
      [
        55.16444600000002,
        108.78611700000002
      ],
      [
        55.45166900000002,
        108.61749300000002
      ],
      [
        55.86694400000002,
        108.85360700000003
      ],
      [
        55.964165000000015,
        109.25834600000003
      ],
      [
        56.28861300000001,
        108.56639800000004
      ],
      [
        56.58388500000001,
        108.69860700000004
      ],
      [
        56.70361300000001,
        109.76777400000003
      ],
      [
        56.97971900000001,
        110.08250100000004
      ],
      [
        56.80750200000001,
        111.15249300000004
      ],
      [
        56.89500300000001,
        111.37165400000004
      ],
      [
        57.10750300000001,
        111.58555200000004
      ],
      [
        56.90193700000001,
        111.82471800000003
      ],
      [
        56.86111200000001,
        112.44054600000003
      ],
      [
        56.961109000000015,
        112.66250000000002
      ],
      [
        56.68527000000002,
        112.90387800000002
      ],
      [
        56.681944000000016,
        113.51111500000002
      ],
      [
        56.525832000000015,
        113.88026400000001
      ]
    ]
  },
  "Республика Хакасия": {
    "0": [
      [
        51.746254,
        90
      ],
      [
        51.55249,
        89.601929
      ],
      [
        51.546388,
        89.256105
      ],
      [
        51.663048,
        89.197756
      ],
      [
        51.562211000000005,
        88.742479
      ],
      [
        51.316942000000004,
        88.51500200000001
      ],
      [
        51.54943900000001,
        87.861653
      ],
      [
        51.73499400000001,
        88.10416000000001
      ],
      [
        51.79361000000001,
        87.833607
      ],
      [
        52.07110500000001,
        88.07249900000001
      ],
      [
        52.24443700000001,
        88.679156
      ],
      [
        52.340544000000016,
        88.65776500000001
      ],
      [
        52.59637900000001,
        89.120258
      ],
      [
        52.70888100000001,
        89.040547
      ],
      [
        52.805819000000014,
        89.240544
      ],
      [
        52.98526200000001,
        88.891103
      ],
      [
        53.130814000000015,
        89.053869
      ],
      [
        53.343041000000014,
        88.97638400000001
      ],
      [
        53.35971100000001,
        88.75916000000001
      ],
      [
        53.58609700000001,
        88.828878
      ],
      [
        53.84109400000001,
        89.27721
      ],
      [
        54.066091000000014,
        88.97916099999999
      ],
      [
        54.14886900000001,
        89.196096
      ],
      [
        54.31859200000001,
        89.196934
      ],
      [
        54.41581300000001,
        88.836109
      ],
      [
        54.282756000000006,
        88.57887799999999
      ],
      [
        54.39387000000001,
        88.37442599999999
      ],
      [
        54.42275500000001,
        88.53221699999999
      ],
      [
        54.74608700000001,
        88.62498999999998
      ],
      [
        54.80887000000001,
        88.76054799999999
      ],
      [
        55.25720300000001,
        88.39166799999998
      ],
      [
        55.46582400000001,
        88.63380099999998
      ],
      [
        54.974415000000015,
        89.46914999999997
      ],
      [
        55.08109300000002,
        89.65555099999997
      ],
      [
        55.144424000000015,
        90.16526999999998
      ],
      [
        55.08399600000001,
        90.48761099999997
      ],
      [
        54.90747500000001,
        90.49664399999997
      ],
      [
        54.85081500000001,
        90.94303899999997
      ],
      [
        54.65886300000001,
        90.93692099999997
      ],
      [
        54.51747500000001,
        91.24497299999997
      ],
      [
        54.06803500000001,
        91.56944399999998
      ],
      [
        53.86664700000001,
        91.43775999999997
      ],
      [
        53.64025600000001,
        91.54386999999997
      ],
      [
        53.38719700000001,
        91.96441699999997
      ],
      [
        53.17914700000001,
        91.73165899999996
      ],
      [
        53.146924000000006,
        91.47164999999997
      ],
      [
        52.974146000000005,
        91.51138299999997
      ],
      [
        52.634429000000004,
        91.25832399999997
      ],
      [
        52.594699000000006,
        90.89887199999997
      ],
      [
        52.18196100000001,
        90.49149299999996
      ],
      [
        52.13692800000001,
        90.11943099999996
      ],
      [
        51.97275100000001,
        90.02387999999996
      ],
      [
        51.75664900000001,
        90.10859599999996
      ],
      [
        51.74414600000001,
        90.00443899999996
      ]
    ]
  },
  "Республика Мордовия": {
    "0": [
      [
        55.042213,
        46.055824
      ],
      [
        55.031296,
        46.296599
      ],
      [
        54.645829,
        46.529158
      ],
      [
        54.585267,
        46.541937000000004
      ],
      [
        54.493326,
        46.476935000000005
      ],
      [
        54.448877,
        46.51082400000001
      ],
      [
        54.306940000000004,
        46.718045000000004
      ],
      [
        54.15999000000001,
        46.003324000000006
      ],
      [
        53.91624000000001,
        45.723524000000005
      ],
      [
        53.916666000000006,
        44.679991
      ],
      [
        53.825275000000005,
        44.615546
      ],
      [
        53.704441,
        44.737494000000005
      ],
      [
        53.663052,
        44.585548
      ],
      [
        53.746387,
        44.00972
      ],
      [
        54.019715999999995,
        43.536943
      ],
      [
        54.002779999999994,
        43.162767
      ],
      [
        53.826663999999994,
        43.156939
      ],
      [
        53.83388099999999,
        42.571109
      ],
      [
        53.73027299999999,
        42.519993
      ],
      [
        53.84749099999999,
        42.307813
      ],
      [
        53.96082499999999,
        42.465551000000005
      ],
      [
        54.095825999999995,
        42.263617
      ],
      [
        54.074999,
        42.513059000000005
      ],
      [
        54.188601,
        42.601940000000006
      ],
      [
        54.261109,
        42.438886000000004
      ],
      [
        54.267212,
        42.175275000000006
      ],
      [
        54.381935,
        42.63694400000001
      ],
      [
        54.463051,
        42.546392000000004
      ],
      [
        54.594154,
        42.69888
      ],
      [
        54.736381,
        42.419447000000005
      ],
      [
        54.871383,
        42.466910000000006
      ],
      [
        54.782211000000004,
        43.06667100000001
      ],
      [
        54.948325000000004,
        43.410275000000006
      ],
      [
        54.536658,
        44.43804600000001
      ],
      [
        54.585546,
        44.625271000000005
      ],
      [
        54.469709,
        44.598333000000004
      ],
      [
        54.472485,
        44.93166900000001
      ],
      [
        54.515544999999996,
        45.14833600000001
      ],
      [
        54.566379,
        45.022222000000006
      ],
      [
        54.752773,
        45.053608000000004
      ],
      [
        54.880542999999996,
        45.425830000000005
      ],
      [
        55.163599999999995,
        45.59666000000001
      ],
      [
        55.115824999999994,
        45.78361000000001
      ],
      [
        54.96998299999999,
        45.71277200000001
      ],
      [
        55.033877999999994,
        46.04471800000001
      ]
    ]
  },
  "Саратовская область": {
    "0": [
      [
        52.616402,
        46.830772
      ],
      [
        52.579164,
        47.724709000000004
      ],
      [
        52.727211,
        47.844993
      ],
      [
        52.723045,
        48.094993
      ],
      [
        52.799163,
        48.101934
      ],
      [
        52.803878,
        48.366934
      ],
      [
        52.674164,
        48.366934
      ],
      [
        52.645827999999995,
        48.699713
      ],
      [
        52.411103,
        48.890828000000006
      ],
      [
        52.50666,
        49.24915800000001
      ],
      [
        52.328049,
        49.47915300000001
      ],
      [
        52.193604,
        49.809711000000014
      ],
      [
        52.133881,
        50.21720400000002
      ],
      [
        52.021661,
        50.16276100000002
      ],
      [
        51.929162000000005,
        50.72415400000002
      ],
      [
        51.598501000000006,
        50.78074500000002
      ],
      [
        51.64244600000001,
        50.567633000000015
      ],
      [
        51.47489000000001,
        50.511581000000014
      ],
      [
        51.34030800000001,
        50.36763400000002
      ],
      [
        51.11472700000001,
        49.735996000000014
      ],
      [
        51.129585000000006,
        49.420499000000014
      ],
      [
        50.99794800000001,
        49.323332000000015
      ],
      [
        50.86380900000001,
        49.41819200000001
      ],
      [
        50.60034000000001,
        48.82474700000001
      ],
      [
        50.66394900000001,
        48.578387000000006
      ],
      [
        50.02336700000001,
        48.87755500000001
      ],
      [
        49.807089000000005,
        48.42455600000001
      ],
      [
        50.452952,
        47.58922200000001
      ],
      [
        50.365553000000006,
        47.250550000000004
      ],
      [
        50.51055600000001,
        47.205271
      ],
      [
        50.485555000000005,
        46.938883000000004
      ],
      [
        50.643613,
        46.836939
      ],
      [
        50.705555000000004,
        46.575275
      ],
      [
        50.547779000000006,
        46.479717
      ],
      [
        50.52555600000001,
        46.091108
      ],
      [
        50.664440000000006,
        46.067220999999996
      ],
      [
        50.77527200000001,
        45.836386999999995
      ],
      [
        50.59471500000001,
        45.67417199999999
      ],
      [
        50.587773000000006,
        45.22388599999999
      ],
      [
        50.72166000000001,
        45.17055599999999
      ],
      [
        50.84777400000001,
        45.31333299999999
      ],
      [
        51.048609000000006,
        45.204446999999995
      ],
      [
        51.21332700000001,
        44.450556999999996
      ],
      [
        51.177491,
        43.987224999999995
      ],
      [
        51.01221700000001,
        43.343334999999996
      ],
      [
        51.23142300000001,
        42.87309
      ],
      [
        51.381660000000004,
        42.861677
      ],
      [
        51.607886,
        42.489523
      ],
      [
        52.010275,
        42.811116
      ],
      [
        52.185819,
        42.753339
      ],
      [
        52.465543000000004,
        43.18112
      ],
      [
        52.316099,
        44.346395
      ],
      [
        52.552487,
        44.548062
      ],
      [
        52.317213,
        45.050557000000005
      ],
      [
        52.520268,
        45.64111700000001
      ],
      [
        52.407209,
        45.98083100000001
      ],
      [
        52.601933,
        46.15333100000001
      ],
      [
        52.732487,
        46.58028600000001
      ],
      [
        52.616657,
        46.82417500000001
      ]
    ]
  },
  "Костромская область": {
    "0": [
      [
        59.611382,
        47.113518
      ],
      [
        59.378602,
        47.056381
      ],
      [
        59.355555,
        47.252495
      ],
      [
        59.226099000000005,
        47.22332
      ],
      [
        59.167498,
        47.444991
      ],
      [
        58.914995000000005,
        47.611662
      ],
      [
        58.916665,
        47.307212
      ],
      [
        58.800829,
        47.324996
      ],
      [
        58.622493,
        47.006102999999996
      ],
      [
        58.558048,
        46.58416
      ],
      [
        58.431943,
        46.59166
      ],
      [
        58.074743999999995,
        46.341324
      ],
      [
        57.931113999999994,
        45.582772
      ],
      [
        58.07749799999999,
        44.853051
      ],
      [
        57.70638999999999,
        44.747216
      ],
      [
        57.436660999999994,
        43.985551
      ],
      [
        57.56388799999999,
        43.524713
      ],
      [
        57.65328099999999,
        43.580743
      ],
      [
        57.411382999999994,
        43.229158
      ],
      [
        57.627773999999995,
        43.203052
      ],
      [
        57.427496,
        43.155827
      ],
      [
        57.38417,
        42.873052
      ],
      [
        57.705,
        42.777219
      ],
      [
        57.559439999999995,
        42.175000000000004
      ],
      [
        57.75139599999999,
        41.606390000000005
      ],
      [
        57.455558999999994,
        41.551109000000004
      ],
      [
        57.27388799999999,
        40.50695
      ],
      [
        57.40750099999999,
        40.395279
      ],
      [
        57.55610599999999,
        40.435273
      ],
      [
        57.581945999999995,
        40.664161
      ],
      [
        57.91944599999999,
        40.600838
      ],
      [
        58.356944999999996,
        41.171943000000006
      ],
      [
        58.591381,
        40.924446
      ],
      [
        58.724995,
        41.22499800000001
      ],
      [
        58.876942,
        41.24777900000001
      ],
      [
        58.965274,
        41.16694700000001
      ],
      [
        59.120547,
        42.033058000000004
      ],
      [
        59.236941,
        42.09416
      ],
      [
        59.426943,
        42.039442
      ],
      [
        59.259166,
        42.285276
      ],
      [
        59.316386,
        42.62138
      ],
      [
        59.164996,
        42.81055
      ],
      [
        59.207599,
        43.338205
      ],
      [
        59.301662,
        43.488329
      ],
      [
        59.169445,
        43.526101
      ],
      [
        59.260974000000004,
        43.750198999999995
      ],
      [
        59.115551,
        45.01888399999999
      ],
      [
        59.181667000000004,
        45.94915799999999
      ],
      [
        59.309717000000006,
        46.03137999999999
      ],
      [
        59.34249200000001,
        46.35555299999999
      ],
      [
        59.632492000000006,
        46.40526599999999
      ],
      [
        59.61055100000001,
        47.10777299999999
      ]
    ]
  },
  "Республика Ингушетия": {
    "0": [
      [
        42.726807,
        45
      ],
      [
        42.625585,
        44.767945
      ],
      [
        42.781937,
        44.610268999999995
      ],
      [
        42.884658,
        44.630233999999994
      ],
      [
        42.834159,
        44.84692999999999
      ],
      [
        42.954711,
        44.94526499999999
      ],
      [
        43.174429,
        44.81219999999999
      ],
      [
        43.228598000000005,
        44.57443099999999
      ],
      [
        43.596372,
        44.53694099999999
      ],
      [
        43.619977000000006,
        44.77582399999999
      ],
      [
        43.369786000000005,
        45.295621999999995
      ],
      [
        42.70124500000001,
        45.035056
      ]
    ]
  },
  "Чеченская Республика": {
    "0": [
      [
        43.509502,
        45
      ],
      [
        43.661071,
        44.838837
      ],
      [
        43.77082,
        45.073601
      ],
      [
        43.953871,
        45.083321
      ],
      [
        43.878318,
        45.447204
      ],
      [
        44.01518,
        45.509413
      ],
      [
        43.978598,
        45.869978
      ],
      [
        43.819151,
        46.064153000000005
      ],
      [
        44.00054,
        46.43359100000001
      ],
      [
        43.876936,
        46.45248900000001
      ],
      [
        43.811927,
        46.677769000000005
      ],
      [
        43.428878,
        46.323872
      ],
      [
        43.114715999999994,
        46.401928
      ],
      [
        42.935548,
        46.539981
      ],
      [
        42.912766999999995,
        46.198038999999994
      ],
      [
        42.721101,
        46.164705999999995
      ],
      [
        42.477487999999994,
        45.79693999999999
      ],
      [
        42.52991699999999,
        45.34013399999999
      ],
      [
        42.69637899999999,
        45.09120699999999
      ],
      [
        43.36979099999999,
        45.29562099999999
      ],
      [
        43.50923399999999,
        45.00543299999999
      ]
    ]
  },
  "Калининградская область": {
    "0": [
      [
        55.272251,
        20.988361
      ],
      [
        54.960609999999996,
        20.529946000000002
      ],
      [
        54.923863999999995,
        21.215556000000003
      ],
      [
        55.193054999999994,
        21.179834000000003
      ],
      [
        55.290668999999994,
        21.388640000000002
      ],
      [
        55.026196999999996,
        22.071057000000003
      ],
      [
        55.074112,
        22.574833
      ],
      [
        54.813722,
        22.892805000000003
      ],
      [
        54.588445,
        22.686083000000004
      ],
      [
        54.368226,
        22.793433000000004
      ],
      [
        54.436723,
        19.786054000000004
      ],
      [
        54.68089,
        20.412054000000005
      ],
      [
        54.634336,
        19.904497000000006
      ],
      [
        54.96436,
        19.984330000000007
      ],
      [
        54.957865,
        20.449859000000007
      ],
      [
        55.277223,
        20.945885000000008
      ]
    ],
    "1": [
      [
        54.460251,
        19.638166
      ],
      [
        54.628692,
        19.89286
      ],
      [
        54.458804,
        19.644502
      ]
    ]
  },
  "Псковская область": {
    "0": [
      [
        58.019573,
        30
      ],
      [
        57.958603000000004,
        29.919995
      ],
      [
        57.875825000000006,
        30.543331
      ],
      [
        57.775270000000006,
        30.407771999999998
      ],
      [
        57.681383000000004,
        30.465270999999998
      ],
      [
        57.615550000000006,
        30.60916
      ],
      [
        57.36027800000001,
        30.441383
      ],
      [
        57.25999900000001,
        30.773888
      ],
      [
        57.18416200000001,
        30.639439
      ],
      [
        56.964718000000005,
        30.651384999999998
      ],
      [
        56.96530500000001,
        31.000204999999998
      ],
      [
        56.765834000000005,
        30.778885
      ],
      [
        56.678608000000004,
        31.061107
      ],
      [
        56.474164,
        31.021664
      ],
      [
        56.299170000000004,
        31.496105
      ],
      [
        56.052779,
        31.513054
      ],
      [
        55.951941,
        31.345276000000002
      ],
      [
        55.727553,
        31.470740000000003
      ],
      [
        55.770551,
        31.052219000000004
      ],
      [
        55.59234,
        30.774222000000005
      ],
      [
        55.807392,
        30.488862000000005
      ],
      [
        55.878059,
        30.013057000000003
      ],
      [
        55.701251,
        29.501307000000004
      ],
      [
        55.76356,
        29.367004000000005
      ],
      [
        55.964361,
        29.425640000000005
      ],
      [
        56.055721999999996,
        29.095641000000004
      ],
      [
        56.097333,
        28.664113000000004
      ],
      [
        56.075223,
        28.282142000000004
      ],
      [
        56.165807,
        28.151337000000005
      ],
      [
        56.282556,
        28.241170000000004
      ],
      [
        56.577169,
        28.133532000000002
      ],
      [
        56.747166,
        27.908116000000003
      ],
      [
        56.823776,
        27.971810000000005
      ],
      [
        56.837166,
        27.666866000000006
      ],
      [
        57.304862,
        27.851032000000007
      ],
      [
        57.44086,
        27.521143000000006
      ],
      [
        57.538859,
        27.551199000000004
      ],
      [
        57.548388,
        27.322116000000005
      ],
      [
        57.820330000000006,
        27.542559000000004
      ],
      [
        57.85849900000001,
        27.826892000000004
      ],
      [
        58.38047200000001,
        27.437059000000005
      ],
      [
        58.861666000000014,
        27.548169000000005
      ],
      [
        59.017766000000016,
        27.870277000000005
      ],
      [
        58.848875000000014,
        28.280554000000006
      ],
      [
        58.822218000000014,
        28.960276000000007
      ],
      [
        58.475265000000014,
        29.54361100000001
      ],
      [
        58.44470900000002,
        29.97527300000001
      ],
      [
        58.218322000000015,
        29.65277500000001
      ],
      [
        58.06916000000002,
        29.67943900000001
      ],
      [
        58.02471300000002,
        30.004718000000008
      ]
    ]
  },
  "Республика Коми": {
    "0": [
      [
        68.430542,
        65.480545
      ],
      [
        68.219987,
        65.267762
      ],
      [
        68.011934,
        65.28498900000001
      ],
      [
        67.93442,
        66.086105
      ],
      [
        67.793047,
        66.018051
      ],
      [
        67.696094,
        66.21054
      ],
      [
        67.56972,
        65.79165499999999
      ],
      [
        67.576937,
        66.039701
      ],
      [
        67.420824,
        66.021926
      ],
      [
        67.15082,
        65.18248
      ],
      [
        66.890551,
        65.101379
      ],
      [
        66.481097,
        63.40915799999999
      ],
      [
        66.326389,
        63.22332199999999
      ],
      [
        66.232763,
        63.29055099999999
      ],
      [
        66.071111,
        62.853608999999985
      ],
      [
        65.867482,
        62.846659999999986
      ],
      [
        65.702214,
        61.87027299999998
      ],
      [
        65.318884,
        61.249164999999984
      ],
      [
        65.28888599999999,
        61.33360599999998
      ],
      [
        65.18553999999999,
        61.23943599999998
      ],
      [
        64.883614,
        60.63138099999998
      ],
      [
        65.05193399999999,
        60.427492999999984
      ],
      [
        65.06722199999999,
        60.15804699999998
      ],
      [
        64.78333299999998,
        59.65416399999998
      ],
      [
        64.65166599999999,
        59.70249499999998
      ],
      [
        64.48194299999999,
        59.479715999999975
      ],
      [
        64.460276,
        59.604715999999975
      ],
      [
        64.23249299999999,
        59.588046999999975
      ],
      [
        64.13750699999999,
        59.85972099999997
      ],
      [
        63.91916899999998,
        59.56582999999997
      ],
      [
        63.33278199999998,
        59.28249899999997
      ],
      [
        63.07389499999998,
        59.222218999999974
      ],
      [
        62.89778699999998,
        59.48721699999997
      ],
      [
        62.72972699999998,
        59.39138499999997
      ],
      [
        62.51722599999998,
        59.65054799999997
      ],
      [
        62.14223499999998,
        59.40443999999997
      ],
      [
        61.988059999999976,
        59.485555999999974
      ],
      [
        61.68222899999998,
        59.34888399999998
      ],
      [
        61.50556999999998,
        58.80972299999998
      ],
      [
        61.52778599999998,
        56.675559999999976
      ],
      [
        61.439445999999975,
        56.38861199999997
      ],
      [
        61.203064999999974,
        56.290284999999976
      ],
      [
        61.081474999999976,
        55.723237999999974
      ],
      [
        61.129608999999974,
        55.27922299999997
      ],
      [
        60.86390099999998,
        54.979723999999976
      ],
      [
        60.98751099999998,
        53.865558999999976
      ],
      [
        60.84778799999998,
        53.80917099999998
      ],
      [
        60.86501399999998,
        53.57389699999998
      ],
      [
        60.89389799999998,
        53.33944699999998
      ],
      [
        61.03389599999998,
        53.385008999999975
      ],
      [
        61.094167999999975,
        52.888343999999975
      ],
      [
        60.944342999999975,
        52.79500599999997
      ],
      [
        60.97805699999998,
        52.42195099999997
      ],
      [
        60.83500499999998,
        52.343337999999974
      ],
      [
        60.88361899999998,
        51.90222899999998
      ],
      [
        60.596952999999985,
        51.773337999999974
      ],
      [
        60.44639499999999,
        52.34306299999997
      ],
      [
        60.24172099999999,
        52.29817299999997
      ],
      [
        60.30361799999999,
        52.00890399999997
      ],
      [
        59.94722699999999,
        51.53222699999997
      ],
      [
        60.061674999999994,
        51.07112199999997
      ],
      [
        59.862784999999995,
        50.828622999999965
      ],
      [
        59.77889199999999,
        49.99667499999997
      ],
      [
        59.687507999999994,
        50.056397999999966
      ],
      [
        59.63695599999999,
        49.832787999999965
      ],
      [
        59.21945399999999,
        49.787789999999966
      ],
      [
        59.24167799999999,
        49.51251399999997
      ],
      [
        59.393066999999995,
        49.545013999999966
      ],
      [
        59.484452,
        49.034179999999964
      ],
      [
        59.659172,
        49.076956999999965
      ],
      [
        59.703894,
        48.51001099999996
      ],
      [
        60.102506,
        48.455567999999964
      ],
      [
        60.36501,
        48.76306999999996
      ],
      [
        60.525006,
        48.481118999999964
      ],
      [
        61.013341,
        48.463624999999965
      ],
      [
        61.169169999999994,
        49.310005999999966
      ],
      [
        61.611391999999995,
        49.486121999999966
      ],
      [
        61.657219999999995,
        49.03973399999997
      ],
      [
        62.09666599999999,
        49.21417199999997
      ],
      [
        62.77027999999999,
        49.60917499999997
      ],
      [
        62.849166999999994,
        48.72805799999997
      ],
      [
        62.70027999999999,
        48.66806899999997
      ],
      [
        62.738052999999994,
        48.347503999999965
      ],
      [
        62.462776999999996,
        48.232781999999965
      ],
      [
        62.314446999999994,
        48.30500999999997
      ],
      [
        62.33083499999999,
        47.65584099999997
      ],
      [
        62.179444999999994,
        47.63362399999997
      ],
      [
        62.187217999999994,
        47.39390199999997
      ],
      [
        62.33638799999999,
        47.40861799999997
      ],
      [
        62.345274999999994,
        47.22417799999997
      ],
      [
        62.64111199999999,
        47.24528799999997
      ],
      [
        62.847776999999994,
        47.07306899999997
      ],
      [
        62.97249299999999,
        47.43639599999997
      ],
      [
        63.151385999999995,
        47.56472999999997
      ],
      [
        63.335826999999995,
        46.94223199999997
      ],
      [
        63.559436,
        47.03306699999997
      ],
      [
        63.747217,
        46.82000899999997
      ],
      [
        64.175584,
        45.39798099999997
      ],
      [
        64.365266,
        47.40194899999997
      ],
      [
        64.353869,
        48.14779099999998
      ],
      [
        64.234149,
        48.189445999999975
      ],
      [
        64.216389,
        48.432227999999974
      ],
      [
        64.35915,
        48.505286999999974
      ],
      [
        64.342489,
        48.82917699999997
      ],
      [
        64.494709,
        48.93944399999997
      ],
      [
        64.456104,
        49.46278299999997
      ],
      [
        64.598332,
        49.58639299999997
      ],
      [
        64.50388,
        50.33195299999997
      ],
      [
        64.79915299999999,
        50.47028099999997
      ],
      [
        64.84749099999999,
        50.10889999999997
      ],
      [
        65.29915199999999,
        49.61334099999997
      ],
      [
        65.232762,
        48.98805999999997
      ],
      [
        66.11922,
        49.006232999999966
      ],
      [
        66.787204,
        51.54917899999997
      ],
      [
        66.927203,
        51.51917199999997
      ],
      [
        67.093326,
        52.153890999999966
      ],
      [
        67.00888400000001,
        61.640843999999966
      ],
      [
        67.38888700000001,
        62.830837999999964
      ],
      [
        67.57943800000001,
        62.869449999999965
      ],
      [
        67.68997200000001,
        63.133059999999965
      ],
      [
        67.835265,
        63.87222599999996
      ],
      [
        67.89414900000001,
        63.94805499999996
      ],
      [
        68.03275900000001,
        63.83306599999996
      ],
      [
        68.19914000000001,
        64.53721299999997
      ],
      [
        68.35998300000001,
        64.54749699999996
      ],
      [
        68.43275300000002,
        65.43554399999996
      ]
    ]
  },
  "Оренбургская область": {
    "0": [
      [
        52.184555,
        60
      ],
      [
        52.083329000000006,
        59.912492
      ],
      [
        51.862443000000006,
        60.000832
      ],
      [
        51.798559000000004,
        60.519416
      ],
      [
        51.688501,
        60.356224
      ],
      [
        51.610835,
        60.919554999999995
      ],
      [
        51.465114,
        61.002722
      ],
      [
        51.414363,
        61.509969999999996
      ],
      [
        51.264808,
        61.676331
      ],
      [
        50.807308,
        61.450829999999996
      ],
      [
        50.664421,
        60.584638999999996
      ],
      [
        50.865586,
        60.06899799999999
      ],
      [
        50.584309,
        59.832719999999995
      ],
      [
        50.514950999999996,
        59.523638999999996
      ],
      [
        50.62675599999999,
        59.480414999999994
      ],
      [
        50.82547799999999,
        58.613361999999995
      ],
      [
        51.07205899999999,
        58.50658599999999
      ],
      [
        51.156336999999986,
        58.32128099999999
      ],
      [
        51.12269999999999,
        57.790893999999994
      ],
      [
        50.918030999999985,
        57.724864999999994
      ],
      [
        50.87933799999998,
        57.49352999999999
      ],
      [
        51.117699999999985,
        57.173114999999996
      ],
      [
        51.099034999999986,
        56.771255999999994
      ],
      [
        50.96983799999999,
        56.739143999999996
      ],
      [
        51.06503399999999,
        56.442085999999996
      ],
      [
        50.91894999999999,
        56.375395
      ],
      [
        50.918587999999986,
        56.173922
      ],
      [
        50.713365999999986,
        56.121147
      ],
      [
        50.55825699999998,
        55.709454
      ],
      [
        50.803812999999984,
        55.097119
      ],
      [
        50.932062999999985,
        55.051621
      ],
      [
        50.894255999999984,
        54.706315
      ],
      [
        50.62881499999998,
        54.726231999999996
      ],
      [
        50.53870499999998,
        54.520315999999994
      ],
      [
        50.63292299999998,
        54.422647999999995
      ],
      [
        50.84839699999998,
        54.51278799999999
      ],
      [
        50.88484099999998,
        54.29742699999999
      ],
      [
        51.16292399999998,
        54.03353699999999
      ],
      [
        51.227060999999985,
        53.67731699999999
      ],
      [
        51.38900499999998,
        53.63461999999999
      ],
      [
        51.51400499999998,
        53.37878799999999
      ],
      [
        51.464505999999986,
        52.550594999999994
      ],
      [
        51.76608899999999,
        52.328151999999996
      ],
      [
        51.683283999999986,
        51.857347
      ],
      [
        51.473784999999985,
        51.670012
      ],
      [
        51.56109099999998,
        51.56596
      ],
      [
        51.49014599999998,
        51.31357199999999
      ],
      [
        51.55572799999998,
        51.24204199999999
      ],
      [
        51.65333899999998,
        51.35856999999999
      ],
      [
        51.76619199999998,
        50.77165599999999
      ],
      [
        52.08971899999998,
        51.412504999999996
      ],
      [
        52.34916299999998,
        51.406952
      ],
      [
        52.67416699999998,
        51.534178
      ],
      [
        52.66749999999998,
        51.733616999999995
      ],
      [
        52.871935999999984,
        51.728055999999995
      ],
      [
        53.09665899999998,
        52.159727
      ],
      [
        53.14249499999998,
        52.054722999999996
      ],
      [
        53.379432999999985,
        52.196667
      ],
      [
        53.50638499999999,
        52.075283999999996
      ],
      [
        54.05776799999999,
        52.487505999999996
      ],
      [
        54.11388099999999,
        52.27194599999999
      ],
      [
        54.22665099999999,
        52.34611099999999
      ],
      [
        54.29721399999999,
        52.19444599999999
      ],
      [
        54.37707099999999,
        52.537454999999994
      ],
      [
        54.341655999999986,
        53.04750299999999
      ],
      [
        54.249431999999985,
        53.11583799999999
      ],
      [
        54.213605999999984,
        52.93694399999999
      ],
      [
        54.06999099999999,
        53.06055499999999
      ],
      [
        54.03249299999999,
        53.497222999999984
      ],
      [
        53.74887799999999,
        53.654440999999984
      ],
      [
        53.42054799999999,
        54.09888299999999
      ],
      [
        53.22416099999999,
        54.923611999999984
      ],
      [
        52.82322199999999,
        55.153958999999986
      ],
      [
        52.837617999999985,
        55.407352999999986
      ],
      [
        52.712595999999984,
        55.42071199999999
      ],
      [
        52.656764999999986,
        55.24653399999999
      ],
      [
        52.60254399999999,
        55.53771699999999
      ],
      [
        52.389721999999985,
        55.52720399999998
      ],
      [
        52.36693299999998,
        55.85361899999998
      ],
      [
        52.64666399999998,
        56.05193599999998
      ],
      [
        52.59638699999998,
        56.42777599999998
      ],
      [
        52.308332999999976,
        56.45277699999998
      ],
      [
        52.17444499999998,
        56.18943299999998
      ],
      [
        52.12833299999998,
        56.63249499999998
      ],
      [
        51.90471699999998,
        56.73943499999998
      ],
      [
        51.817780999999975,
        56.60388499999998
      ],
      [
        51.773613999999974,
        56.752778999999975
      ],
      [
        51.58972299999997,
        56.77471299999998
      ],
      [
        51.61888999999997,
        56.889159999999976
      ],
      [
        51.75750799999997,
        56.821937999999975
      ],
      [
        51.56972499999997,
        57.20277399999998
      ],
      [
        51.86777399999997,
        57.65498999999998
      ],
      [
        51.77083499999997,
        58.545544999999976
      ],
      [
        52.04972699999997,
        58.69498899999998
      ],
      [
        52.18082899999997,
        58.60582499999998
      ],
      [
        52.287502999999965,
        58.94276099999998
      ],
      [
        52.361667999999966,
        58.81193299999998
      ],
      [
        52.45610299999996,
        58.87276199999998
      ],
      [
        52.27555499999996,
        59.18276699999998
      ],
      [
        52.49888099999996,
        59.250271999999974
      ],
      [
        52.42749399999996,
        60.14554199999998
      ],
      [
        52.27471699999996,
        60.15248399999998
      ],
      [
        52.18472099999996,
        60.00387799999998
      ]
    ]
  },
  "Новгородская область": {
    "0": [
      [
        58.039326,
        30
      ],
      [
        58.069159,
        29.67944
      ],
      [
        58.231932,
        29.653886
      ],
      [
        58.532766,
        30.174165
      ],
      [
        58.729428000000006,
        30.090276
      ],
      [
        58.74887400000001,
        30.713328999999998
      ],
      [
        59.077487000000005,
        31.003608999999997
      ],
      [
        58.984432000000005,
        31.314997999999996
      ],
      [
        59.20859900000001,
        31.558884999999997
      ],
      [
        59.36887600000001,
        31.550828999999997
      ],
      [
        59.39804300000001,
        32.131102
      ],
      [
        59.20277000000001,
        32.286657
      ],
      [
        59.12248600000001,
        32.665265
      ],
      [
        59.42832500000001,
        32.959987999999996
      ],
      [
        59.37526400000001,
        33.52943
      ],
      [
        59.15637800000001,
        33.966097999999995
      ],
      [
        59.05915600000001,
        34.878595
      ],
      [
        58.98638000000001,
        34.824991999999995
      ],
      [
        58.93384500000001,
        35.17898699999999
      ],
      [
        58.81330000000001,
        35.119553999999994
      ],
      [
        58.76304500000001,
        35.26628899999999
      ],
      [
        58.837767000000014,
        35.71692699999999
      ],
      [
        58.59471000000001,
        36.240264999999994
      ],
      [
        58.424438000000016,
        35.956649999999996
      ],
      [
        58.43528600000002,
        35.391380999999996
      ],
      [
        58.38531500000002,
        35.25389199999999
      ],
      [
        58.19477900000002,
        35.05030099999999
      ],
      [
        58.22748600000002,
        34.72525999999999
      ],
      [
        58.07721800000002,
        34.67942999999999
      ],
      [
        58.22276300000002,
        34.00915699999999
      ],
      [
        58.15387700000002,
        33.67165499999999
      ],
      [
        58.05360400000002,
        33.530267999999985
      ],
      [
        57.91638200000002,
        33.703316999999984
      ],
      [
        57.55471100000002,
        33.235818999999985
      ],
      [
        57.42943700000002,
        33.291375999999985
      ],
      [
        57.46415700000002,
        32.816659999999985
      ],
      [
        57.08387800000002,
        31.954435999999983
      ],
      [
        57.10832900000002,
        31.712767999999983
      ],
      [
        56.93582100000002,
        31.544994999999982
      ],
      [
        56.96471300000002,
        30.65138299999998
      ],
      [
        57.18415700000002,
        30.639436999999983
      ],
      [
        57.25999400000002,
        30.773885999999983
      ],
      [
        57.36360700000002,
        30.439993999999984
      ],
      [
        57.615545000000026,
        30.609158999999984
      ],
      [
        57.681378000000024,
        30.465269999999983
      ],
      [
        57.775265000000026,
        30.407770999999983
      ],
      [
        57.875820000000026,
        30.543329999999983
      ],
      [
        57.949710000000024,
        29.927771999999983
      ],
      [
        58.03915600000003,
        30.001107999999984
      ]
    ]
  },
  "Челябинская область": {
    "0": [
      [
        52.430378,
        60
      ],
      [
        52.498877,
        59.250276
      ],
      [
        52.275826,
        59.173883
      ],
      [
        52.437767,
        58.79638799999999
      ],
      [
        52.647774,
        58.72165799999999
      ],
      [
        53.011665,
        58.83582299999999
      ],
      [
        53.014999,
        59.02776999999999
      ],
      [
        53.204436,
        58.82249399999999
      ],
      [
        53.542495,
        58.90360199999999
      ],
      [
        53.925268,
        58.91666299999999
      ],
      [
        54.183598,
        59.325269999999996
      ],
      [
        54.138043,
        59.736653
      ],
      [
        54.244152,
        59.808597
      ],
      [
        54.341098,
        59.658878
      ],
      [
        54.489428000000004,
        59.675547
      ],
      [
        54.615268,
        59.875261
      ],
      [
        54.857492,
        59.944147
      ],
      [
        54.911095,
        59.631654
      ],
      [
        54.612206,
        59.249713
      ],
      [
        54.46776,
        58.631372999999996
      ],
      [
        54.549715,
        58.361103
      ],
      [
        54.388323,
        57.965543
      ],
      [
        54.821931,
        57.161099
      ],
      [
        55.079987,
        57.134992
      ],
      [
        55.223038,
        57.206936
      ],
      [
        55.336372000000004,
        57.522486
      ],
      [
        55.275262000000005,
        58.031657
      ],
      [
        55.203873,
        58.145540000000004
      ],
      [
        55.167763,
        57.961374000000006
      ],
      [
        54.916657,
        57.999993
      ],
      [
        55.176095000000004,
        58.300262000000004
      ],
      [
        54.956094,
        58.52970800000001
      ],
      [
        55.023042,
        58.81637500000001
      ],
      [
        55.161927,
        58.57721000000001
      ],
      [
        55.155542,
        58.74526200000001
      ],
      [
        55.266931,
        58.726647000000014
      ],
      [
        55.27804,
        58.92331700000001
      ],
      [
        55.320823999999995,
        58.85276900000001
      ],
      [
        55.449149999999996,
        59.194145000000006
      ],
      [
        55.559425,
        59.643874000000004
      ],
      [
        55.584426,
        59.236374000000005
      ],
      [
        55.643873,
        59.34832000000001
      ],
      [
        55.77776,
        59.31609400000001
      ],
      [
        55.788318000000004,
        59.157762000000005
      ],
      [
        56.006648000000006,
        59.17054100000001
      ],
      [
        56.13442500000001,
        59.29943200000001
      ],
      [
        56.21275500000001,
        58.99526500000001
      ],
      [
        56.20303600000001,
        59.13665200000001
      ],
      [
        56.359423000000014,
        59.206094000000014
      ],
      [
        56.18692200000002,
        60.91859500000002
      ],
      [
        56.22164400000002,
        61.166375000000016
      ],
      [
        56.375810000000016,
        61.15026200000002
      ],
      [
        56.37192000000002,
        61.38220200000002
      ],
      [
        56.129421000000015,
        62.13109600000002
      ],
      [
        55.94136500000001,
        62.21137100000002
      ],
      [
        55.92165100000001,
        62.35887700000002
      ],
      [
        55.83664600000001,
        62.25304300000002
      ],
      [
        55.56526100000001,
        62.45053600000002
      ],
      [
        55.50470800000001,
        62.59053600000002
      ],
      [
        55.34026500000001,
        62.23664700000002
      ],
      [
        55.31331900000001,
        62.04165600000002
      ],
      [
        55.131099000000006,
        62.27637400000002
      ],
      [
        54.995822000000004,
        62.20526100000002
      ],
      [
        55.016932000000004,
        61.96470600000002
      ],
      [
        54.810543,
        62.12831800000002
      ],
      [
        54.715268,
        62.019150000000025
      ],
      [
        54.732762,
        62.54860000000002
      ],
      [
        54.68804,
        63.193313000000025
      ],
      [
        54.524436,
        63.30720400000003
      ],
      [
        54.479987,
        63.133323000000026
      ],
      [
        54.314155,
        63.088593000000024
      ],
      [
        54.226653,
        63.343315000000025
      ],
      [
        54.115832,
        62.81613100000003
      ],
      [
        53.899833,
        62.55066000000003
      ],
      [
        54.056135,
        62.42082700000003
      ],
      [
        54.055665999999995,
        62.07313200000003
      ],
      [
        53.945803999999995,
        61.98207600000003
      ],
      [
        53.95419199999999,
        61.61313500000003
      ],
      [
        54.089167999999994,
        61.39469100000003
      ],
      [
        53.917472999999994,
        61.25641300000003
      ],
      [
        53.951888,
        61.02771900000003
      ],
      [
        53.796695,
        61.21811000000003
      ],
      [
        53.6175,
        60.91830200000003
      ],
      [
        53.525556,
        61.59066500000003
      ],
      [
        53.504225,
        61.25985800000003
      ],
      [
        53.296946,
        61.18507900000003
      ],
      [
        53.252642,
        61.66963800000003
      ],
      [
        53.117586,
        62.112970000000026
      ],
      [
        53.006530000000005,
        62.139608000000024
      ],
      [
        52.98078100000001,
        61.02752600000002
      ],
      [
        52.65475500000001,
        60.71291600000002
      ],
      [
        52.33461400000001,
        61.07016700000002
      ],
      [
        52.00122500000001,
        60.23141500000002
      ],
      [
        52.06415900000001,
        59.91388000000002
      ],
      [
        52.28027500000001,
        60.15499200000002
      ],
      [
        52.42332600000001,
        60.14972100000002
      ],
      [
        52.43221400000001,
        60.03694400000002
      ]
    ]
  },
  "Ставропольский край": {
    "0": [
      [
        43.704681,
        45
      ],
      [
        43.662209000000004,
        44.832498
      ],
      [
        43.818831,
        44.285082
      ],
      [
        44.000541000000005,
        44.419160000000005
      ],
      [
        43.993050000000004,
        43.890550000000005
      ],
      [
        43.84082900000001,
        43.747209000000005
      ],
      [
        43.804995000000005,
        43.33249800000001
      ],
      [
        43.951379,
        43.17777400000001
      ],
      [
        43.807329,
        42.683412000000004
      ],
      [
        44.042501,
        42.599721
      ],
      [
        43.96721,
        42.38611
      ],
      [
        44.092221,
        42.291114
      ],
      [
        44.289162000000005,
        42.557211
      ],
      [
        44.361488,
        42.038184
      ],
      [
        44.458648000000004,
        41.729336
      ],
      [
        44.652205,
        41.431936
      ],
      [
        44.969435000000004,
        41.657765
      ],
      [
        44.988882000000004,
        41.463055999999995
      ],
      [
        45.24832000000001,
        41.28972399999999
      ],
      [
        45.217375000000004,
        41.02691399999999
      ],
      [
        45.697202000000004,
        40.85860199999999
      ],
      [
        45.751386000000004,
        41.27998799999999
      ],
      [
        45.954427,
        41.16054299999999
      ],
      [
        45.926381,
        42.10444299999999
      ],
      [
        46.104152,
        42.17610499999999
      ],
      [
        46.127758,
        42.333605999999996
      ],
      [
        45.969433,
        42.318889
      ],
      [
        45.989422000000005,
        42.517213999999996
      ],
      [
        46.24331300000001,
        42.91414699999999
      ],
      [
        45.941089000000005,
        43.891372999999994
      ],
      [
        45.59721100000001,
        44.23110199999999
      ],
      [
        45.16081000000001,
        45.58193899999999
      ],
      [
        45.00860500000001,
        45.71776399999999
      ],
      [
        44.80555000000001,
        45.661374999999985
      ],
      [
        44.57026800000001,
        45.250540999999984
      ],
      [
        44.464708000000016,
        45.22415199999998
      ],
      [
        44.402774000000015,
        45.444152999999986
      ],
      [
        44.307774000000016,
        45.398330999999985
      ],
      [
        44.209989000000014,
        45.10277699999998
      ],
      [
        44.187207000000015,
        45.51748699999998
      ],
      [
        44.01267100000001,
        45.50952199999998
      ],
      [
        43.87832500000001,
        45.447206999999985
      ],
      [
        43.95221400000001,
        45.076380999999984
      ],
      [
        43.70884500000001,
        45.00466499999998
      ]
    ]
  },
  "Свердловская область": {
    "0": [
      [
        56.246735,
        60
      ],
      [
        56.359435,
        59.206101
      ],
      [
        56.212768,
        58.995272
      ],
      [
        56.057769,
        58.925554
      ],
      [
        56.168326,
        58.56972
      ],
      [
        56.057769,
        58.342769
      ],
      [
        56.101934,
        57.52666
      ],
      [
        56.373875,
        57.312496
      ],
      [
        56.662487,
        57.430828000000005
      ],
      [
        56.847209,
        57.218884
      ],
      [
        56.932764999999996,
        57.753330000000005
      ],
      [
        57.049158999999996,
        58.07444400000001
      ],
      [
        57.325824,
        57.936108000000004
      ],
      [
        57.606378,
        58.035831
      ],
      [
        57.682489,
        58.153879
      ],
      [
        57.684960999999994,
        58.343630000000005
      ],
      [
        57.566934999999994,
        58.532495000000004
      ],
      [
        57.72998199999999,
        58.856941000000006
      ],
      [
        57.991097999999994,
        58.603326
      ],
      [
        58.095260999999994,
        58.661935
      ],
      [
        58.493041999999996,
        59.458329
      ],
      [
        58.680541,
        59.421937
      ],
      [
        58.763044,
        59.082215
      ],
      [
        58.953039000000004,
        59.181380999999995
      ],
      [
        59.167486000000004,
        59.18443299999999
      ],
      [
        59.462759000000005,
        58.30776999999999
      ],
      [
        59.865818000000004,
        58.68276999999999
      ],
      [
        59.926373000000005,
        59.00110599999999
      ],
      [
        60.36414800000001,
        59.159986999999994
      ],
      [
        60.57748000000001,
        59.392492
      ],
      [
        60.94552800000001,
        59.471097
      ],
      [
        61.28136600000001,
        59.257215
      ],
      [
        61.423310000000015,
        59.42027
      ],
      [
        61.826087000000015,
        59.333051000000005
      ],
      [
        61.99330800000001,
        59.48554600000001
      ],
      [
        61.90330500000001,
        59.98526400000001
      ],
      [
        61.71747700000001,
        60.04082900000001
      ],
      [
        61.68775400000001,
        61.14582400000001
      ],
      [
        61.21220000000001,
        62.62359900000001
      ],
      [
        60.99859200000001,
        62.86276500000001
      ],
      [
        60.84831600000001,
        62.76360700000001
      ],
      [
        60.23248000000001,
        63.19053900000001
      ],
      [
        60.12553900000001,
        63.639987000000005
      ],
      [
        59.47831500000001,
        63.820268000000006
      ],
      [
        59.33026600000001,
        65.049417
      ],
      [
        58.66414500000001,
        65.666649
      ],
      [
        58.61436300000001,
        65.934593
      ],
      [
        58.03220200000001,
        66.20470300000001
      ],
      [
        57.83553900000001,
        65.399421
      ],
      [
        57.638320000000014,
        64.82637700000001
      ],
      [
        57.58637200000001,
        65.01110000000001
      ],
      [
        57.30693000000001,
        64.82553800000001
      ],
      [
        57.24443000000001,
        64.992468
      ],
      [
        56.90970700000001,
        65.059698
      ],
      [
        56.86582300000001,
        65.236913
      ],
      [
        56.76832000000001,
        65.168035
      ],
      [
        56.84526100000001,
        64.830817
      ],
      [
        56.75026900000001,
        64.329154
      ],
      [
        56.58859500000001,
        64.206931
      ],
      [
        56.45665300000001,
        64.209707
      ],
      [
        56.62221000000001,
        63.501653
      ],
      [
        56.50860100000001,
        63.354986
      ],
      [
        56.598039000000014,
        63.001096999999994
      ],
      [
        56.57776900000002,
        62.54221199999999
      ],
      [
        56.28165700000002,
        62.066092999999995
      ],
      [
        56.13360200000002,
        62.071929999999995
      ],
      [
        56.37192800000002,
        61.382203
      ],
      [
        56.34999300000002,
        61.095819
      ],
      [
        56.22165300000002,
        61.166376
      ],
      [
        56.19832200000002,
        61.073603
      ],
      [
        56.246380000000016,
        60.001101999999996
      ]
    ]
  },
  "Волгоградская область": {
    "0": [
      [
        48.295681,
        45
      ],
      [
        48.097673,
        45.300818
      ],
      [
        48.211937,
        44.956657
      ],
      [
        48.052490999999996,
        44.800538
      ],
      [
        48.058327999999996,
        44.549715000000006
      ],
      [
        48.20638399999999,
        44.535822
      ],
      [
        48.259995999999994,
        44.309436000000005
      ],
      [
        48.059991999999994,
        44.323047
      ],
      [
        48.04083599999999,
        44.466379
      ],
      [
        47.868320999999995,
        44.38915600000001
      ],
      [
        47.91081499999999,
        44.065822000000004
      ],
      [
        47.68443599999999,
        43.705830000000006
      ],
      [
        47.55915499999999,
        43.629437
      ],
      [
        47.44221899999999,
        43.653317
      ],
      [
        47.45305299999999,
        43.209164
      ],
      [
        47.51361499999999,
        42.939717
      ],
      [
        48.010553999999985,
        42.498608000000004
      ],
      [
        48.03027499999998,
        42.021939
      ],
      [
        48.259163999999984,
        42.045001000000006
      ],
      [
        48.28221199999999,
        42.15193500000001
      ],
      [
        48.408881999999984,
        42.07249900000001
      ],
      [
        48.546942999999985,
        42.63055000000001
      ],
      [
        48.806378999999986,
        42.74610400000001
      ],
      [
        49.15248699999999,
        42.34444000000001
      ],
      [
        49.153874999999985,
        42.064442000000014
      ],
      [
        49.68610099999999,
        42.180278000000015
      ],
      [
        50.110545999999985,
        41.460827000000016
      ],
      [
        50.226283999999985,
        41.362211000000016
      ],
      [
        50.50526799999999,
        41.417501000000016
      ],
      [
        50.60165699999999,
        41.52833200000001
      ],
      [
        50.77637699999999,
        41.15638400000001
      ],
      [
        51.20693499999999,
        41.94443900000001
      ],
      [
        51.131655999999985,
        42.26721600000001
      ],
      [
        51.237208999999986,
        42.84332700000001
      ],
      [
        51.012211999999984,
        43.34332600000001
      ],
      [
        51.17748599999998,
        43.98721600000001
      ],
      [
        51.213321999999984,
        44.45054800000001
      ],
      [
        51.04860399999998,
        45.20443800000001
      ],
      [
        50.847768999999985,
        45.31332400000001
      ],
      [
        50.721654999999984,
        45.170547000000006
      ],
      [
        50.58776799999998,
        45.22387700000001
      ],
      [
        50.594709999999985,
        45.67416300000001
      ],
      [
        50.77499199999998,
        45.81666400000001
      ],
      [
        50.66443499999998,
        46.06721200000001
      ],
      [
        50.525550999999986,
        46.091099000000014
      ],
      [
        50.70554999999999,
        46.57526700000001
      ],
      [
        50.69221499999999,
        46.71637900000001
      ],
      [
        50.48554999999999,
        46.93887400000001
      ],
      [
        50.51055099999999,
        47.20526200000001
      ],
      [
        50.368599999999994,
        47.24443100000001
      ],
      [
        50.376943,
        47.408729000000015
      ],
      [
        50.007585999999996,
        47.266244000000015
      ],
      [
        49.839307999999996,
        46.89288100000002
      ],
      [
        49.33266999999999,
        46.78265900000002
      ],
      [
        49.164919999999995,
        47.052827000000015
      ],
      [
        48.670576999999994,
        46.61657800000002
      ],
      [
        48.867495999999996,
        46.01443500000002
      ],
      [
        48.59499699999999,
        45.61915000000002
      ],
      [
        48.54083599999999,
        45.20804000000002
      ],
      [
        48.46915799999999,
        45.37831900000002
      ],
      [
        48.486385999999996,
        45.19026200000002
      ],
      [
        48.297498,
        45.00138300000002
      ]
    ]
  },
  "Республика Адыгея": {
    "0": [
      [
        45,
        39.856293
      ],
      [
        44.939431,
        39.629163
      ],
      [
        45.01777,
        39.66444
      ],
      [
        45.04166,
        39.500831999999996
      ],
      [
        44.868881,
        39.63915899999999
      ],
      [
        44.792221000000005,
        39.47165999999999
      ],
      [
        44.96137900000001,
        38.67137799999999
      ],
      [
        44.99526900000001,
        39.30942699999999
      ],
      [
        45.19192700000001,
        39.639427999999995
      ],
      [
        44.99776700000001,
        40.41943799999999
      ],
      [
        44.47053900000001,
        40.75277799999999
      ],
      [
        44.47193100000001,
        40.616097999999994
      ],
      [
        44.72998800000001,
        40.55053899999999
      ],
      [
        44.72748900000001,
        40.388887
      ],
      [
        44.29277000000001,
        40.439426999999995
      ],
      [
        43.96999000000001,
        40.458318
      ],
      [
        43.75555000000001,
        40.308039
      ],
      [
        43.94248900000001,
        39.720267
      ],
      [
        44.18220800000001,
        39.727209
      ],
      [
        44.06444200000001,
        39.960267
      ],
      [
        44.235271000000004,
        40.080547
      ],
      [
        44.35749800000001,
        39.791657
      ],
      [
        44.72916000000001,
        40.026375
      ],
      [
        45.007220000000004,
        39.878036
      ]
    ]
  },
  "Ханты-Мансийский автономный округ": {
    "0": [
      [
        58.71981,
        75
      ],
      [
        58.861664000000005,
        73.018326
      ],
      [
        58.951935000000006,
        73.098037
      ],
      [
        59.139160000000004,
        72.93664600000001
      ],
      [
        59.151381,
        72.52998400000001
      ],
      [
        59.563604,
        72.01110900000002
      ],
      [
        59.915822,
        71.17415100000002
      ],
      [
        59.806654,
        70.99498200000002
      ],
      [
        59.816938,
        70.37915300000002
      ],
      [
        59.992765,
        69.38415800000001
      ],
      [
        59.896941,
        69.06637900000001
      ],
      [
        59.773323,
        69.28109900000001
      ],
      [
        59.580827,
        68.561648
      ],
      [
        59.280824,
        68.369983
      ],
      [
        59.210824,
        67.72331600000001
      ],
      [
        58.930352,
        66.76950500000001
      ],
      [
        58.67888,
        66.789432
      ],
      [
        58.583056,
        66.379154
      ],
      [
        58.664156,
        65.66666
      ],
      [
        59.333329,
        65.04305
      ],
      [
        59.47416,
        63.82500199999999
      ],
      [
        60.12555,
        63.63999799999999
      ],
      [
        60.232490999999996,
        63.190549999999995
      ],
      [
        60.848327,
        62.763617999999994
      ],
      [
        60.998602999999996,
        62.862776
      ],
      [
        61.212210999999996,
        62.62361
      ],
      [
        61.687765,
        61.145835
      ],
      [
        61.717487999999996,
        60.040839999999996
      ],
      [
        61.903316,
        59.985274999999994
      ],
      [
        61.993319,
        59.48555699999999
      ],
      [
        62.142215,
        59.40444099999999
      ],
      [
        62.517206,
        59.65054899999999
      ],
      [
        62.729707000000005,
        59.39138599999999
      ],
      [
        62.897767,
        59.48721799999999
      ],
      [
        63.073875,
        59.22221999999999
      ],
      [
        63.332762,
        59.28249999999999
      ],
      [
        63.919149000000004,
        59.56583099999999
      ],
      [
        64.14414000000001,
        59.85999799999999
      ],
      [
        64.23247300000001,
        59.58804899999999
      ],
      [
        64.46025600000002,
        59.60471799999999
      ],
      [
        64.48802600000002,
        59.47832999999999
      ],
      [
        64.65164600000001,
        59.702496999999994
      ],
      [
        64.81163400000001,
        59.68443899999999
      ],
      [
        65.046923,
        60.09361099999999
      ],
      [
        65.05191300000001,
        60.427494999999986
      ],
      [
        64.88359300000002,
        60.631382999999985
      ],
      [
        65.18551900000001,
        61.239437999999986
      ],
      [
        65.28886500000002,
        61.333607999999984
      ],
      [
        65.31886300000002,
        61.249166999999986
      ],
      [
        65.55163500000002,
        61.58221899999999
      ],
      [
        65.71716100000002,
        62.01862099999999
      ],
      [
        65.51469200000003,
        62.42444299999999
      ],
      [
        65.33969000000002,
        62.47832999999999
      ],
      [
        65.30163500000002,
        62.81554799999999
      ],
      [
        64.87635700000001,
        62.67555599999999
      ],
      [
        64.734969,
        62.814159999999994
      ],
      [
        64.55830300000001,
        62.526104999999994
      ],
      [
        64.46469,
        63.45833199999999
      ],
      [
        64.328034,
        63.40860399999999
      ],
      [
        64.265534,
        63.59054899999999
      ],
      [
        64.44218500000001,
        65.18609899999998
      ],
      [
        64.59551900000001,
        66.01860299999998
      ],
      [
        64.51747200000001,
        66.89082499999998
      ],
      [
        64.22525100000001,
        66.80304299999997
      ],
      [
        64.02885600000002,
        67.79081899999997
      ],
      [
        64.30912800000002,
        68.45305099999997
      ],
      [
        64.22191000000002,
        68.88527099999997
      ],
      [
        64.32997300000002,
        68.99331799999997
      ],
      [
        64.43663200000002,
        68.85525599999997
      ],
      [
        64.47857700000002,
        69.03637799999997
      ],
      [
        64.34886300000001,
        70.27110299999997
      ],
      [
        64.191636,
        70.79386899999997
      ],
      [
        64.007478,
        70.55497699999997
      ],
      [
        63.68609000000001,
        70.91442799999997
      ],
      [
        63.59053300000001,
        71.56553399999997
      ],
      [
        63.185252000000006,
        71.60802899999997
      ],
      [
        63.273591,
        72.66360199999997
      ],
      [
        63.436364000000005,
        73.10497699999996
      ],
      [
        63.19664100000001,
        73.35165099999996
      ],
      [
        63.173868000000006,
        74.24322099999996
      ],
      [
        63.042756000000004,
        74.48470699999996
      ],
      [
        63.11719600000001,
        75.79941899999996
      ],
      [
        62.947756000000005,
        76.28580699999996
      ],
      [
        63.043597000000005,
        76.66553699999996
      ],
      [
        62.97608400000001,
        77.03331799999995
      ],
      [
        62.79136900000001,
        77.15775399999995
      ],
      [
        62.45831500000001,
        78.00664499999995
      ],
      [
        62.616647000000015,
        78.80496899999994
      ],
      [
        62.60525600000001,
        79.84720599999994
      ],
      [
        62.79303800000001,
        79.91414599999995
      ],
      [
        63.08316700000001,
        80.58031399999994
      ],
      [
        63.12030700000001,
        81.11698099999994
      ],
      [
        62.82386800000001,
        81.33609599999994
      ],
      [
        62.68136700000001,
        81.79274599999994
      ],
      [
        62.81414200000001,
        82.14247699999994
      ],
      [
        62.75442600000001,
        82.75609399999995
      ],
      [
        62.66442200000001,
        82.74608499999995
      ],
      [
        62.45303600000001,
        83.29080799999996
      ],
      [
        62.566927000000014,
        83.78359099999996
      ],
      [
        62.37275300000002,
        84.24385699999996
      ],
      [
        61.80608700000002,
        84.68747499999996
      ],
      [
        61.53332100000002,
        85.97302699999996
      ],
      [
        61.38248100000002,
        85.63719699999996
      ],
      [
        61.29748300000002,
        85.71053099999996
      ],
      [
        60.85540600000002,
        84.25963399999996
      ],
      [
        60.824707000000025,
        83.99385599999997
      ],
      [
        61.032760000000025,
        83.14497999999996
      ],
      [
        60.51831900000003,
        82.16664799999997
      ],
      [
        60.650536000000024,
        81.86164099999996
      ],
      [
        60.800155000000025,
        80.69770099999997
      ],
      [
        60.648597000000024,
        79.38665199999997
      ],
      [
        60.83859100000002,
        79.18552599999997
      ],
      [
        60.746650000000024,
        77.92136499999997
      ],
      [
        60.853317000000025,
        77.10497499999997
      ],
      [
        60.70998400000003,
        76.96775299999997
      ],
      [
        60.53499000000003,
        77.03748499999998
      ],
      [
        60.476648000000026,
        76.77442299999997
      ],
      [
        60.26804500000003,
        76.85164799999997
      ],
      [
        60.10776800000003,
        76.68718899999998
      ],
      [
        59.58027100000003,
        76.64832599999997
      ],
      [
        59.43720600000003,
        75.89748699999997
      ],
      [
        59.24110000000003,
        75.61526099999998
      ],
      [
        59.01193700000003,
        75.69053199999998
      ],
      [
        58.65015100000003,
        75.15973999999997
      ],
      [
        58.71220800000003,
        75.02498999999997
      ]
    ]
  },
  "Тюменская область": {
    "0": [
      [
        58.530251,
        75
      ],
      [
        58.133607,
        74.206376
      ],
      [
        58.149162,
        73.084429
      ],
      [
        58.006105,
        72.87221
      ],
      [
        58.023049,
        72.121646
      ],
      [
        58.12471,
        71.988879
      ],
      [
        58.071106,
        71.23859
      ],
      [
        58.39749,
        71.30386800000001
      ],
      [
        58.542494,
        70.87303600000001
      ],
      [
        57.917128,
        70.37865200000002
      ],
      [
        57.566665,
        70.69109000000002
      ],
      [
        57.383881,
        70.42720500000001
      ],
      [
        57.193047,
        70.69443100000001
      ],
      [
        57.328049,
        70.811649
      ],
      [
        57.340828,
        71.10248
      ],
      [
        57.220543,
        71.090274
      ],
      [
        56.826385,
        71.675539
      ],
      [
        56.669159,
        71.348879
      ],
      [
        56.710273,
        71.05582
      ],
      [
        56.555549,
        71.090822
      ],
      [
        56.461104,
        70.759722
      ],
      [
        56.411659,
        70.941362
      ],
      [
        56.28666,
        70.762775
      ],
      [
        56.122491,
        70.91665900000001
      ],
      [
        55.98527,
        70.86220200000001
      ],
      [
        55.891666,
        70.47415600000001
      ],
      [
        55.86333,
        70.75915800000001
      ],
      [
        55.702777,
        70.54220900000001
      ],
      [
        55.597773999999994,
        70.76361400000002
      ],
      [
        55.136337,
        70.22858800000002
      ],
      [
        55.451198,
        68.96036200000002
      ],
      [
        55.275921,
        68.96538900000002
      ],
      [
        55.40083,
        68.47720700000002
      ],
      [
        55.581112,
        68.17053700000002
      ],
      [
        55.704715,
        68.19386700000003
      ],
      [
        55.727488,
        67.45110000000003
      ],
      [
        55.959161,
        67.31053600000003
      ],
      [
        56.049438,
        66.69693400000003
      ],
      [
        55.949432,
        66.43970200000003
      ],
      [
        56.068603,
        66.19637000000003
      ],
      [
        55.988044,
        66.02027000000002
      ],
      [
        56.273604,
        65.66721200000002
      ],
      [
        56.285826,
        65.16776100000003
      ],
      [
        56.372762,
        65.21138600000003
      ],
      [
        56.49554,
        64.96639200000003
      ],
      [
        56.735262999999996,
        64.97693600000002
      ],
      [
        56.772211999999996,
        65.17388000000003
      ],
      [
        56.929429999999996,
        65.24137000000003
      ],
      [
        56.909707999999995,
        65.05971400000003
      ],
      [
        57.24443099999999,
        64.99248400000003
      ],
      [
        57.310264999999994,
        64.82500500000003
      ],
      [
        57.586372999999995,
        65.01111600000003
      ],
      [
        57.638321,
        64.82639300000002
      ],
      [
        57.835539999999995,
        65.39943700000002
      ],
      [
        58.032202999999996,
        66.20471900000003
      ],
      [
        58.614363999999995,
        65.93460900000002
      ],
      [
        58.678869999999996,
        66.78943700000002
      ],
      [
        58.930341999999996,
        66.76951000000003
      ],
      [
        59.210814,
        67.72332100000003
      ],
      [
        59.280814,
        68.36998800000002
      ],
      [
        59.580816999999996,
        68.56165300000002
      ],
      [
        59.782757999999994,
        69.29721800000002
      ],
      [
        59.896930999999995,
        69.06638400000001
      ],
      [
        59.989703999999996,
        69.28638500000001
      ],
      [
        59.816928,
        70.379158
      ],
      [
        59.806644,
        70.99498700000001
      ],
      [
        59.915811999999995,
        71.17415600000001
      ],
      [
        59.563593999999995,
        72.011114
      ],
      [
        59.151371,
        72.529989
      ],
      [
        59.13915,
        72.936651
      ],
      [
        58.951925,
        73.09804199999999
      ],
      [
        58.861654,
        73.01833099999999
      ],
      [
        58.650145,
        75.15974899999999
      ],
      [
        58.535544,
        75.016943
      ]
    ]
  },
  "Омская область": {
    "0": [
      [
        53.803307,
        75
      ],
      [
        53.683665999999995,
        74.471864
      ],
      [
        53.461082999999995,
        74.403948
      ],
      [
        53.64844599999999,
        73.90336
      ],
      [
        53.44480699999999,
        73.44897800000001
      ],
      [
        53.68108599999999,
        73.25941800000001
      ],
      [
        53.882417,
        73.46508200000001
      ],
      [
        53.862111,
        73.691308
      ],
      [
        54.054333,
        73.750359
      ],
      [
        53.953335,
        73.28933
      ],
      [
        54.133998000000005,
        72.60094500000001
      ],
      [
        54.046916,
        72.520638
      ],
      [
        53.949470000000005,
        72.722054
      ],
      [
        53.909667000000006,
        72.480447
      ],
      [
        54.088499000000006,
        72.363169
      ],
      [
        54.139387000000006,
        72.492387
      ],
      [
        54.363831000000005,
        72.13788799999999
      ],
      [
        54.356748,
        72.03819499999999
      ],
      [
        54.131636,
        72.16413999999999
      ],
      [
        54.248552000000004,
        71.76711499999999
      ],
      [
        54.102693,
        71.65564299999998
      ],
      [
        54.201386,
        71.07303199999998
      ],
      [
        54.340438999999996,
        71.01514099999999
      ],
      [
        54.35602599999999,
        71.22172799999998
      ],
      [
        54.585272999999994,
        71.19891799999998
      ],
      [
        54.625440999999995,
        71.30711699999998
      ],
      [
        54.89346499999999,
        70.94836799999997
      ],
      [
        55.10332599999999,
        71.01569699999997
      ],
      [
        55.29907699999999,
        70.81633399999997
      ],
      [
        55.18280899999999,
        70.32767999999997
      ],
      [
        55.59776299999999,
        70.76361499999997
      ],
      [
        55.702766,
        70.54220999999997
      ],
      [
        55.863319,
        70.75915899999997
      ],
      [
        55.891655,
        70.47415699999996
      ],
      [
        55.973311,
        70.85554999999997
      ],
      [
        56.12248,
        70.91665999999996
      ],
      [
        56.286649000000004,
        70.76277599999996
      ],
      [
        56.41164800000001,
        70.94136299999995
      ],
      [
        56.461093000000005,
        70.75972299999995
      ],
      [
        56.555538000000006,
        71.09082299999996
      ],
      [
        56.71026200000001,
        71.05582099999995
      ],
      [
        56.66914800000001,
        71.34887999999995
      ],
      [
        56.82637400000001,
        71.67553999999996
      ],
      [
        57.220532000000006,
        71.09027499999995
      ],
      [
        57.34081700000001,
        71.10248099999995
      ],
      [
        57.32803800000001,
        70.81164999999996
      ],
      [
        57.193036000000006,
        70.69443199999996
      ],
      [
        57.38387000000001,
        70.42720599999997
      ],
      [
        57.56665400000001,
        70.69109099999997
      ],
      [
        57.917117000000005,
        70.37865299999997
      ],
      [
        58.542483000000004,
        70.87303699999997
      ],
      [
        58.397479000000004,
        71.30386899999996
      ],
      [
        58.07109500000001,
        71.23859099999996
      ],
      [
        58.12469900000001,
        71.98887999999995
      ],
      [
        58.007763000000004,
        72.73304999999995
      ],
      [
        58.149150000000006,
        73.08442999999995
      ],
      [
        58.13359500000001,
        74.20637699999996
      ],
      [
        58.58109600000001,
        75.10525599999995
      ],
      [
        58.47275200000001,
        75.35554599999996
      ],
      [
        58.34470100000001,
        75.05415399999995
      ],
      [
        58.21997700000001,
        75.23969999999996
      ],
      [
        58.11831600000001,
        75.06637599999996
      ],
      [
        57.93386900000001,
        75.56442199999996
      ],
      [
        57.64637900000001,
        75.58276299999997
      ],
      [
        57.25027000000001,
        76.10886899999997
      ],
      [
        56.726932000000005,
        76.28942599999996
      ],
      [
        56.449994000000004,
        75.84414399999996
      ],
      [
        56.451100000000004,
        76.14193399999996
      ],
      [
        56.314154,
        76.11664999999996
      ],
      [
        56.185546,
        76.31887499999996
      ],
      [
        56.141106,
        75.74886799999996
      ],
      [
        55.891664,
        75.31219199999995
      ],
      [
        55.766664,
        75.37025199999995
      ],
      [
        55.755272999999995,
        75.08276199999995
      ],
      [
        55.659988999999996,
        75.23775899999994
      ],
      [
        55.598878,
        75.13135999999994
      ],
      [
        55.540278,
        75.32221699999994
      ],
      [
        55.382213,
        75.09136699999993
      ],
      [
        55.028049,
        75.40026499999993
      ],
      [
        54.914157,
        75.34026699999994
      ],
      [
        54.901936000000006,
        75.54081299999994
      ],
      [
        54.60471700000001,
        75.83498599999994
      ],
      [
        54.53416800000001,
        75.67775999999995
      ],
      [
        54.12928900000001,
        75.69084399999996
      ],
      [
        54.08519900000001,
        75.40724499999996
      ],
      [
        53.97833900000001,
        75.46357999999996
      ],
      [
        53.78723100000001,
        75.01441599999997
      ]
    ]
  },
  "Приморский край": {
    "0": [
      [
        47.3755,
        139.015301
      ],
      [
        47.481397,
        138.529404
      ],
      [
        47.601898,
        138.620802
      ],
      [
        47.676897,
        138.510904
      ],
      [
        47.855194999999995,
        138.825806
      ],
      [
        48.032495,
        138.570704
      ],
      [
        48.203395,
        138.70530100000002
      ],
      [
        48.212394,
        138.52750400000002
      ],
      [
        48.376195,
        138.49770100000003
      ],
      [
        48.459794,
        138.29620300000005
      ],
      [
        48.218549,
        137.88880000000006
      ],
      [
        48.093877000000006,
        137.31079600000007
      ],
      [
        47.893606000000005,
        137.61136300000007
      ],
      [
        47.779990000000005,
        137.43915200000006
      ],
      [
        47.6912,
        138.05040300000007
      ],
      [
        47.596101000000004,
        137.96942600000008
      ],
      [
        47.511101000000004,
        138.0778060000001
      ],
      [
        47.200832000000005,
        137.4669240000001
      ],
      [
        47.298334000000004,
        137.0694330000001
      ],
      [
        47.020274,
        136.9838620000001
      ],
      [
        46.777492,
        136.5930250000001
      ],
      [
        46.7875,
        135.9508140000001
      ],
      [
        46.917771,
        135.3571870000001
      ],
      [
        47.040834000000004,
        135.42386800000008
      ],
      [
        47.199998,
        135.08857200000008
      ],
      [
        47.105271,
        134.6669410000001
      ],
      [
        46.951104,
        134.4394340000001
      ],
      [
        46.680552,
        134.42609700000008
      ],
      [
        46.581447,
        133.91300500000008
      ],
      [
        46.265254,
        133.9098940000001
      ],
      [
        46.146504,
        133.68815300000009
      ],
      [
        45.858701,
        133.48017600000009
      ],
      [
        45.612645,
        133.46712900000009
      ],
      [
        45.4652,
        133.15595800000008
      ],
      [
        45.127116,
        133.13557200000008
      ],
      [
        45.048208,
        133.01701100000008
      ],
      [
        45.336035,
        131.8914900000001
      ],
      [
        44.967607,
        131.47841000000008
      ],
      [
        44.856006,
        130.9633100000001
      ],
      [
        44.058607,
        131.30291100000008
      ],
      [
        43.558911,
        131.19441600000007
      ],
      [
        43.399409,
        131.30841500000008
      ],
      [
        42.91341,
        131.10871500000007
      ],
      [
        42.719910999999996,
        130.40121500000006
      ],
      [
        42.669712,
        130.59161200000005
      ],
      [
        42.296107,
        130.70831500000006
      ],
      [
        42.450806,
        130.78781600000005
      ],
      [
        42.544208,
        130.84791300000006
      ],
      [
        42.661404999999995,
        130.65601600000005
      ],
      [
        42.633905999999996,
        130.80911400000005
      ],
      [
        42.673505,
        131.11771400000006
      ],
      [
        42.556508,
        131.21961100000007
      ],
      [
        42.772006,
        131.26271200000008
      ],
      [
        42.880806,
        131.4787100000001
      ],
      [
        42.946704,
        131.3843130000001
      ],
      [
        43.001006,
        131.5816100000001
      ],
      [
        43.179209,
        131.78110400000008
      ],
      [
        43.378408,
        131.7631010000001
      ],
      [
        43.31981,
        132.07440000000008
      ],
      [
        43.079462,
        131.84129800000008
      ],
      [
        43.338705,
        132.29671200000007
      ],
      [
        43.187407,
        132.40801500000006
      ],
      [
        43.076504,
        132.28251700000007
      ],
      [
        42.846102,
        132.32082100000008
      ],
      [
        42.954203,
        132.4056180000001
      ],
      [
        42.843802,
        132.58151700000008
      ],
      [
        42.921502,
        132.72911800000008
      ],
      [
        42.737505999999996,
        132.8579180000001
      ],
      [
        42.871004,
        133.0112210000001
      ],
      [
        42.7455,
        133.0958250000001
      ],
      [
        42.710601,
        133.00292500000012
      ],
      [
        42.688803,
        133.1488240000001
      ],
      [
        42.910202,
        133.6676230000001
      ],
      [
        42.808299999999996,
        133.7211260000001
      ],
      [
        42.871500999999995,
        133.9279250000001
      ],
      [
        43.502798,
        135.1435250000001
      ],
      [
        43.751301,
        135.1751200000001
      ],
      [
        43.762301,
        135.4310190000001
      ],
      [
        43.896901,
        135.5328210000001
      ],
      [
        43.9512,
        135.4572240000001
      ],
      [
        44.361397,
        135.82742500000012
      ],
      [
        44.495298,
        136.1474260000001
      ],
      [
        44.774195,
        136.4535230000001
      ],
      [
        45.189352,
        136.8034160000001
      ],
      [
        45.799573,
        137.6905310000001
      ],
      [
        46.536791,
        138.3458230000001
      ],
      [
        47.045886,
        138.5799180000001
      ],
      [
        47.373584,
        139.0138160000001
      ]
    ]
  },
  "Хабаровский край": {
    "0": [
      [
        59.638329,
        147.165802
      ],
      [
        59.882491,
        147.11969100000002
      ],
      [
        59.903875,
        146.76803700000002
      ],
      [
        60.099431,
        146.68246600000003
      ],
      [
        60.129986,
        146.44830500000003
      ],
      [
        60.272486,
        146.37634600000004
      ],
      [
        60.1936,
        145.79663500000004
      ],
      [
        60.325268,
        145.57718400000005
      ],
      [
        60.415538,
        145.52304600000005
      ],
      [
        60.739152999999995,
        146.05747000000005
      ],
      [
        60.66582,
        146.40469800000005
      ],
      [
        60.938317999999995,
        146.49106100000006
      ],
      [
        61.001932,
        146.73523200000005
      ],
      [
        61.106935,
        146.74939300000005
      ],
      [
        61.300539,
        146.67831800000005
      ],
      [
        61.405259,
        146.77277000000004
      ],
      [
        61.428597,
        146.61523800000003
      ],
      [
        61.631927000000005,
        146.58887100000004
      ],
      [
        61.906929000000005,
        146.21329200000005
      ],
      [
        62.061369000000006,
        145.74552000000006
      ],
      [
        61.953872000000004,
        145.48136100000005
      ],
      [
        62.071654,
        144.84775600000006
      ],
      [
        61.855545,
        144.54108500000007
      ],
      [
        61.72609,
        143.99829900000006
      ],
      [
        61.991096,
        143.92997000000005
      ],
      [
        61.86998,
        143.63498800000005
      ],
      [
        62.028869,
        143.60386100000005
      ],
      [
        62.118591,
        143.21580100000006
      ],
      [
        61.907204,
        142.99165000000005
      ],
      [
        61.969704,
        142.66499000000005
      ],
      [
        61.866654,
        142.41694400000006
      ],
      [
        62.085257999999996,
        142.18415600000006
      ],
      [
        62.02859599999999,
        141.84608300000005
      ],
      [
        62.17026499999999,
        141.55555600000005
      ],
      [
        62.440816999999996,
        141.29414200000005
      ],
      [
        62.40276299999999,
        141.09526000000005
      ],
      [
        62.57887199999999,
        140.70720000000006
      ],
      [
        62.41581799999999,
        140.62498600000006
      ],
      [
        62.38415599999999,
        140.34193700000006
      ],
      [
        62.05887699999999,
        140.29304900000005
      ],
      [
        61.97581499999999,
        139.98915500000004
      ],
      [
        61.47776199999999,
        139.54555100000005
      ],
      [
        61.44803799999999,
        139.09694300000004
      ],
      [
        61.34443099999999,
        138.73274700000005
      ],
      [
        61.188317999999995,
        138.70247500000005
      ],
      [
        61.111658,
        138.28722300000004
      ],
      [
        60.995264999999996,
        138.34444300000004
      ],
      [
        60.88582099999999,
        138.17998500000004
      ],
      [
        60.68027099999999,
        138.43889600000006
      ],
      [
        60.49415299999999,
        138.23888500000007
      ],
      [
        60.32027199999999,
        138.32555500000007
      ],
      [
        60.24221599999999,
        138.20110500000007
      ],
      [
        59.94804299999999,
        138.28667700000008
      ],
      [
        59.83054999999999,
        138.1711080000001
      ],
      [
        59.71276899999999,
        138.2627820000001
      ],
      [
        59.684432999999984,
        138.0336260000001
      ],
      [
        59.737211999999985,
        137.5969500000001
      ],
      [
        59.549993999999984,
        137.4627640000001
      ],
      [
        59.344993999999986,
        136.6616780000001
      ],
      [
        59.530546999999984,
        135.75417600000011
      ],
      [
        59.387771999999984,
        135.42110800000012
      ],
      [
        59.11360999999999,
        135.18612200000013
      ],
      [
        59.29721799999999,
        133.54915900000012
      ],
      [
        59.106101999999986,
        132.83443900000012
      ],
      [
        58.87999099999998,
        132.8139000000001
      ],
      [
        58.88860299999998,
        132.5916710000001
      ],
      [
        58.48304799999998,
        132.42471000000012
      ],
      [
        58.48777099999998,
        132.12054100000012
      ],
      [
        58.23721499999998,
        132.19637600000013
      ],
      [
        58.11499999999998,
        132.00249800000012
      ],
      [
        58.04944099999998,
        132.13027500000013
      ],
      [
        58.15749599999998,
        131.45638700000012
      ],
      [
        57.92083299999998,
        131.80001400000012
      ],
      [
        57.64944099999998,
        132.04778500000012
      ],
      [
        57.56554799999998,
        131.6930500000001
      ],
      [
        57.241940999999976,
        131.5072280000001
      ],
      [
        57.23360899999997,
        131.09331800000012
      ],
      [
        57.07305699999997,
        131.4905340000001
      ],
      [
        56.71583499999997,
        131.8036130000001
      ],
      [
        56.65054999999997,
        131.6666810000001
      ],
      [
        56.454444999999964,
        131.7000060000001
      ],
      [
        56.550276999999966,
        131.49941500000008
      ],
      [
        56.41972399999997,
        131.4013930000001
      ],
      [
        56.45139299999997,
        131.16222800000008
      ],
      [
        55.89861299999997,
        130.8583350000001
      ],
      [
        55.67778199999997,
        130.9230620000001
      ],
      [
        55.61250499999997,
        131.1033280000001
      ],
      [
        55.70639199999997,
        132.39528900000008
      ],
      [
        55.375558999999974,
        132.95083100000008
      ],
      [
        55.369448999999975,
        132.68139100000008
      ],
      [
        55.202503999999976,
        132.61416100000008
      ],
      [
        55.192501999999976,
        132.3533270000001
      ],
      [
        55.063618999999974,
        132.38918500000008
      ],
      [
        55.04528699999997,
        132.0619460000001
      ],
      [
        54.89389799999997,
        131.98305800000009
      ],
      [
        54.60973299999997,
        131.1927750000001
      ],
      [
        54.32028999999997,
        131.1185880000001
      ],
      [
        54.28167799999997,
        130.7408110000001
      ],
      [
        53.91555999999997,
        130.39111100000008
      ],
      [
        53.75390099999997,
        131.43362200000007
      ],
      [
        53.30890299999997,
        131.53445100000008
      ],
      [
        53.21834299999997,
        131.4436000000001
      ],
      [
        53.23389799999997,
        131.8613850000001
      ],
      [
        53.12472999999997,
        131.8902840000001
      ],
      [
        53.28973799999997,
        133.1614010000001
      ],
      [
        53.425844999999974,
        133.2149890000001
      ],
      [
        53.54584799999997,
        133.56417000000008
      ],
      [
        53.43251399999997,
        134.00639900000007
      ],
      [
        53.62278399999997,
        134.55498200000008
      ],
      [
        53.42779199999997,
        134.91667500000008
      ],
      [
        53.338345999999966,
        134.81053600000007
      ],
      [
        53.262517999999965,
        134.96110900000008
      ],
      [
        52.92723699999996,
        134.65248600000007
      ],
      [
        52.71861899999996,
        134.65999300000007
      ],
      [
        52.64723999999996,
        134.79195100000007
      ],
      [
        52.41946499999996,
        134.62718800000007
      ],
      [
        52.67529299999996,
        133.2941500000001
      ],
      [
        52.45390399999996,
        133.2410800000001
      ],
      [
        52.254739999999956,
        133.4266570000001
      ],
      [
        52.08529199999995,
        132.5910860000001
      ],
      [
        51.96084999999995,
        132.3961110000001
      ],
      [
        51.79030299999995,
        132.2811200000001
      ],
      [
        51.69057099999995,
        131.4186030000001
      ],
      [
        51.348355999999946,
        131.4941330000001
      ],
      [
        51.364467999999945,
        131.2894530000001
      ],
      [
        50.99724199999994,
        130.7836250000001
      ],
      [
        50.876408999999946,
        130.8441400000001
      ],
      [
        50.65196099999994,
        130.6461120000001
      ],
      [
        50.65696599999994,
        130.9524770000001
      ],
      [
        50.47557699999994,
        130.8697130000001
      ],
      [
        50.384191999999935,
        130.9227820000001
      ],
      [
        50.328634999999935,
        131.1736060000001
      ],
      [
        50.369466999999936,
        131.30471000000009
      ],
      [
        50.146132999999935,
        131.46999200000008
      ],
      [
        49.98279599999994,
        131.28999900000008
      ],
      [
        49.902802999999935,
        131.50249300000007
      ],
      [
        49.73113499999994,
        131.48442700000007
      ],
      [
        49.70891899999994,
        131.35137000000006
      ],
      [
        49.60419799999994,
        131.50472000000005
      ],
      [
        49.24350399999994,
        131.39586400000005
      ],
      [
        49.32864099999994,
        131.79332300000004
      ],
      [
        49.23336499999994,
        131.98942900000003
      ],
      [
        49.48946699999994,
        132.15166000000002
      ],
      [
        49.410304999999944,
        132.38917800000002
      ],
      [
        49.28947099999994,
        132.39583000000002
      ],
      [
        49.347522999999946,
        132.77833600000002
      ],
      [
        49.233082999999944,
        132.810806
      ],
      [
        49.17558799999994,
        133.19386300000002
      ],
      [
        48.81697599999994,
        133.31220900000002
      ],
      [
        48.63816599999994,
        133.92524500000002
      ],
      [
        48.74309999999994,
        134.39192000000003
      ],
      [
        48.59246499999994,
        134.56272700000002
      ],
      [
        48.60967599999994,
        134.99567900000002
      ],
      [
        48.371692999999944,
        134.79053900000002
      ],
      [
        48.38570399999995,
        134.38978300000002
      ],
      [
        48.275839999999945,
        134.71185000000003
      ],
      [
        47.99367199999995,
        134.55134400000003
      ],
      [
        47.729172999999946,
        134.77392300000002
      ],
      [
        47.32345399999995,
        134.18125600000002
      ],
      [
        47.118646999999946,
        134.22146300000003
      ],
      [
        46.972148999999945,
        134.06612900000002
      ],
      [
        46.61947699999995,
        134.02470300000002
      ],
      [
        46.68058799999994,
        134.42609900000002
      ],
      [
        46.951139999999945,
        134.43943600000003
      ],
      [
        47.200033999999945,
        135.08857400000002
      ],
      [
        47.04086999999995,
        135.42387000000002
      ],
      [
        46.91780699999995,
        135.35718900000003
      ],
      [
        46.787535999999946,
        135.95081600000003
      ],
      [
        46.77752799999995,
        136.59302700000003
      ],
      [
        47.020309999999945,
        136.98386400000004
      ],
      [
        47.29836999999995,
        137.06943500000003
      ],
      [
        47.20086799999995,
        137.46692600000003
      ],
      [
        47.51113999999995,
        138.07780900000003
      ],
      [
        47.72443899999995,
        137.98951000000002
      ],
      [
        47.780027999999945,
        137.43915700000002
      ],
      [
        47.893643999999945,
        137.61136800000003
      ],
      [
        48.093914999999946,
        137.31080100000003
      ],
      [
        48.228534999999944,
        137.92811000000003
      ],
      [
        48.459833999999944,
        138.29621000000003
      ],
      [
        48.376234999999944,
        138.49770800000002
      ],
      [
        48.212433999999945,
        138.527511
      ],
      [
        48.20343499999994,
        138.705308
      ],
      [
        48.03253499999994,
        138.570711
      ],
      [
        47.85523499999994,
        138.82581299999998
      ],
      [
        47.67693699999994,
        138.510911
      ],
      [
        47.60193799999994,
        138.62080899999998
      ],
      [
        47.48143699999994,
        138.52941099999998
      ],
      [
        47.375539999999944,
        139.01530799999998
      ],
      [
        47.80994199999994,
        139.27381099999997
      ],
      [
        48.44453999999994,
        140.17320899999996
      ],
      [
        48.75493599999994,
        140.19400599999994
      ],
      [
        48.97563599999994,
        140.39400099999995
      ],
      [
        49.00943499999994,
        140.18990299999996
      ],
      [
        49.04363299999994,
        140.33430199999995
      ],
      [
        49.070933999999944,
        140.23950399999995
      ],
      [
        49.56543399999995,
        140.54839699999997
      ],
      [
        49.880134999999946,
        140.39799699999998
      ],
      [
        50.092203999999946,
        140.68716199999997
      ],
      [
        50.14817599999994,
        140.51113799999996
      ],
      [
        50.71281199999994,
        140.43508899999995
      ],
      [
        50.962537999999945,
        140.65188599999996
      ],
      [
        51.32320099999995,
        140.67040999999998
      ],
      [
        51.42361799999995,
        140.88294999999997
      ],
      [
        51.46475499999995,
        140.74797099999998
      ],
      [
        51.49558799999995,
        140.88272099999998
      ],
      [
        51.95131099999995,
        141.41822699999997
      ],
      [
        52.10041999999995,
        141.33871499999998
      ],
      [
        52.15200499999995,
        141.54630899999998
      ],
      [
        52.42164299999995,
        141.10411
      ],
      [
        52.658167999999954,
        141.284391
      ],
      [
        52.86564199999995,
        141.232496
      ],
      [
        53.10641799999995,
        140.73435899999998
      ],
      [
        52.99703199999995,
        141.18668899999997
      ],
      [
        53.162001999999944,
        141.44160199999996
      ],
      [
        53.22291799999994,
        141.32418599999997
      ],
      [
        53.28936099999994,
        141.41186199999996
      ],
      [
        53.42502699999994,
        140.90777299999996
      ],
      [
        53.446026999999944,
        141.12229599999998
      ],
      [
        53.643722999999945,
        140.54658299999997
      ],
      [
        53.870524999999944,
        140.24803099999997
      ],
      [
        53.955721999999945,
        140.31388699999997
      ],
      [
        54.29347099999995,
        139.73069699999996
      ],
      [
        54.173497999999945,
        139.24633699999995
      ],
      [
        54.28513399999994,
        138.65866099999997
      ],
      [
        54.089383999999946,
        138.76032999999995
      ],
      [
        53.98466299999995,
        138.76679999999996
      ],
      [
        53.52063699999995,
        138.46031199999996
      ],
      [
        53.53024899999995,
        138.25785899999997
      ],
      [
        53.80749699999995,
        138.57153299999996
      ],
      [
        53.99007999999995,
        138.57525499999997
      ],
      [
        53.57483199999995,
        137.95554999999996
      ],
      [
        53.52877699999995,
        137.31892399999995
      ],
      [
        53.58582899999995,
        137.22102499999994
      ],
      [
        53.651357999999945,
        137.50318899999994
      ],
      [
        53.96549799999995,
        137.86125099999992
      ],
      [
        54.05655099999995,
        137.3027179999999
      ],
      [
        54.31788499999995,
        137.73763899999992
      ],
      [
        54.210497999999944,
        137.19761599999993
      ],
      [
        54.125663999999944,
        137.09191799999994
      ],
      [
        54.02799999999994,
        137.30850099999995
      ],
      [
        53.841942999999944,
        137.19541799999996
      ],
      [
        53.75938899999994,
        136.78799399999997
      ],
      [
        53.91808299999994,
        136.67832899999996
      ],
      [
        54.14288699999994,
        136.81597899999997
      ],
      [
        54.64155599999994,
        136.83883699999998
      ],
      [
        54.555469999999936,
        135.75016899999997
      ],
      [
        54.713611999999934,
        135.26049899999998
      ],
      [
        54.852080999999934,
        135.18241999999998
      ],
      [
        55.284998999999935,
        136.14508099999998
      ],
      [
        55.574469999999934,
        136.44592299999996
      ],
      [
        55.94435799999994,
        137.39582699999997
      ],
      [
        56.37194299999994,
        137.92141499999997
      ],
      [
        56.42480299999994,
        138.22547699999996
      ],
      [
        56.56902399999994,
        138.10868599999995
      ],
      [
        56.84104999999994,
        138.43613899999994
      ],
      [
        56.77810899999994,
        138.56588299999993
      ],
      [
        56.95446999999994,
        138.61765699999992
      ],
      [
        57.140911999999936,
        138.94205699999992
      ],
      [
        57.48410899999993,
        139.7920469999999
      ],
      [
        57.84282799999993,
        140.48949599999992
      ],
      [
        58.29927499999993,
        140.7733849999999
      ],
      [
        58.601188999999934,
        141.5448529999999
      ],
      [
        59.17243899999993,
        142.3892429999999
      ],
      [
        59.396273999999934,
        143.23370899999992
      ],
      [
        59.38996899999994,
        144.24632699999992
      ],
      [
        59.37352499999994,
        144.52074099999993
      ],
      [
        59.36896999999993,
        144.87956699999992
      ],
      [
        59.37249499999994,
        144.70180299999993
      ],
      [
        59.42327199999993,
        145.69670499999992
      ],
      [
        59.406021999999936,
        145.96345899999992
      ],
      [
        59.237690999999934,
        145.8329659999999
      ],
      [
        59.14105399999993,
        146.04140099999992
      ],
      [
        59.19885799999993,
        146.3467449999999
      ],
      [
        59.38958099999993,
        146.30531699999992
      ],
      [
        59.45858099999993,
        146.43399499999992
      ],
      [
        59.405290999999934,
        147.08885599999994
      ],
      [
        59.63223399999993,
        147.16191499999994
      ]
    ],
    "1": [
      [
        55.022221,
        138.223465
      ],
      [
        55.180945,
        137.55732700000002
      ],
      [
        54.759498,
        137.240967
      ],
      [
        54.861525,
        137.476256
      ],
      [
        54.608639000000004,
        137.716078
      ],
      [
        54.779834,
        138.003171
      ],
      [
        55.013052,
        138.209881
      ]
    ],
    "2": [
      [
        55.103138,
        137.199005
      ],
      [
        54.891110000000005,
        136.67659
      ],
      [
        54.90997300000001,
        137.065369
      ],
      [
        55.100029000000006,
        137.196303
      ]
    ],
    "3": [
      [
        54.458916,
        137.957367
      ],
      [
        54.357639,
        137.713716
      ],
      [
        54.455611,
        137.954225
      ]
    ]
  },
  "Сахалинская область": {
    "0": [
      [
        48.644801,
        144.749099
      ],
      [
        48.864102,
        144.688804
      ],
      [
        49.252703000000004,
        144.276506
      ],
      [
        51.224303000000006,
        143.525732
      ],
      [
        51.21677700000001,
        143.462179
      ],
      [
        51.33244100000001,
        143.372032
      ],
      [
        51.34194400000001,
        143.469596
      ],
      [
        51.62502400000001,
        143.370919
      ],
      [
        51.511415000000014,
        143.20295099999998
      ],
      [
        51.69677500000002,
        143.316781
      ],
      [
        51.89791300000002,
        143.208476
      ],
      [
        51.72658100000002,
        143.026209
      ],
      [
        51.83658000000002,
        143.18815
      ],
      [
        52.35819400000002,
        143.116373
      ],
      [
        52.64549700000002,
        143.28536300000002
      ],
      [
        52.32933200000002,
        143.17473700000002
      ],
      [
        52.42224600000002,
        143.26615200000003
      ],
      [
        52.83880200000002,
        143.32631700000005
      ],
      [
        52.94679900000002,
        143.28061700000003
      ],
      [
        52.84271900000002,
        143.23564900000002
      ],
      [
        53.03816100000002,
        143.09075200000004
      ],
      [
        53.251216000000014,
        143.02340100000004
      ],
      [
        53.363219000000015,
        143.14721100000003
      ],
      [
        52.851939000000016,
        143.31911500000004
      ],
      [
        53.312078000000014,
        143.20047800000003
      ],
      [
        53.36340900000001,
        143.06105800000003
      ],
      [
        53.423994000000015,
        143.13747400000003
      ],
      [
        53.565412000000016,
        143.04680700000003
      ],
      [
        53.86835600000001,
        142.80931900000002
      ],
      [
        54.118051000000015,
        142.98101100000002
      ],
      [
        54.41357600000001,
        142.70464400000003
      ],
      [
        54.31227300000001,
        142.59958800000004
      ],
      [
        54.249133000000015,
        142.70497900000004
      ],
      [
        54.209578000000015,
        142.56745300000003
      ],
      [
        54.300021000000015,
        142.26181900000003
      ],
      [
        53.94813400000002,
        142.68351100000004
      ],
      [
        53.691414000000016,
        142.58289500000004
      ],
      [
        53.84385600000002,
        142.68987300000003
      ],
      [
        53.702773000000015,
        142.78964900000003
      ],
      [
        53.65507800000002,
        142.48264200000003
      ],
      [
        53.509579000000016,
        142.67153000000002
      ],
      [
        53.372219000000015,
        142.45789200000002
      ],
      [
        53.37532800000002,
        142.230949
      ],
      [
        53.532108000000015,
        142.24587200000002
      ],
      [
        53.37282900000002,
        141.757224
      ],
      [
        53.05185700000002,
        141.919868
      ],
      [
        52.55458200000002,
        141.821403
      ],
      [
        52.319245000000016,
        141.62339
      ],
      [
        51.88738700000002,
        141.62609
      ],
      [
        51.77611000000002,
        141.825004
      ],
      [
        51.748083000000015,
        141.67134900000002
      ],
      [
        51.641081000000014,
        141.93401300000002
      ],
      [
        51.146582000000016,
        142.24942700000003
      ],
      [
        50.55152800000002,
        142.04016800000002
      ],
      [
        50.32927700000002,
        142.149558
      ],
      [
        49.51890100000002,
        142.142605
      ],
      [
        48.77260400000002,
        141.843904
      ],
      [
        48.33760400000002,
        142.1321
      ],
      [
        47.97750900000002,
        142.195501
      ],
      [
        47.61620900000002,
        141.959802
      ],
      [
        47.07740700000002,
        142.048798
      ],
      [
        46.59890800000002,
        141.803404
      ],
      [
        45.926011000000024,
        142.017906
      ],
      [
        46.017110000000024,
        142.175799
      ],
      [
        46.628609000000026,
        142.4384
      ],
      [
        46.75700800000003,
        142.676298
      ],
      [
        46.601308000000024,
        142.774599
      ],
      [
        46.54840900000003,
        143.37269899999998
      ],
      [
        46.01940800000003,
        143.40679699999998
      ],
      [
        46.37130700000003,
        143.614806
      ],
      [
        46.81880700000003,
        143.482803
      ],
      [
        46.911204000000026,
        143.09590599999999
      ],
      [
        47.254211000000026,
        143.01960499999998
      ],
      [
        47.78461000000003,
        142.523406
      ],
      [
        49.15310900000003,
        143.012905
      ],
      [
        49.28530900000003,
        143.2011
      ],
      [
        49.255807000000026,
        144.078497
      ],
      [
        48.64380500000003,
        144.746398
      ]
    ],
    "1": [
      [
        45.5037,
        148.894599
      ],
      [
        45.536899000000005,
        148.654799
      ],
      [
        45.25779800000001,
        148.176406
      ],
      [
        45.42669700000001,
        147.90650799999997
      ],
      [
        45.212001000000015,
        147.85851199999996
      ],
      [
        44.99259400000002,
        147.51641499999997
      ],
      [
        44.81459500000002,
        147.09891699999997
      ],
      [
        44.69829600000002,
        147.19081499999996
      ],
      [
        44.63179900000002,
        146.94231299999996
      ],
      [
        44.44349400000002,
        146.85461099999995
      ],
      [
        44.56509200000002,
        147.20931199999995
      ],
      [
        44.99809200000002,
        147.66760999999997
      ],
      [
        44.95859300000002,
        147.90670599999996
      ],
      [
        45.21659200000002,
        148.30819999999997
      ],
      [
        45.33889600000002,
        148.84679299999996
      ],
      [
        45.49289300000002,
        148.89169199999995
      ]
    ],
    "2": [
      [
        50.598751,
        156.169189
      ],
      [
        50.753639,
        156.144334
      ],
      [
        50.745804,
        155.97792299999998
      ],
      [
        50.378057,
        155.64074899999997
      ],
      [
        50.293915999999996,
        155.21153599999997
      ],
      [
        50.070446999999994,
        155.21110899999996
      ],
      [
        50.000001,
        155.39847099999997
      ],
      [
        50.19103,
        155.79408599999996
      ],
      [
        50.588361,
        156.16586599999997
      ]
    ],
    "3": [
      [
        46.2262,
        150.567599
      ],
      [
        46.188300999999996,
        150.213004
      ],
      [
        45.57529399999999,
        149.396699
      ],
      [
        45.619885999999994,
        149.664197
      ],
      [
        46.222486999999994,
        150.56249400000002
      ]
    ],
    "4": [
      [
        44.4511,
        146.5757
      ],
      [
        44.499795999999996,
        146.1227
      ],
      [
        43.837199999999996,
        145.39880100000002
      ],
      [
        43.724202,
        145.424201
      ],
      [
        43.658401999999995,
        145.544903
      ],
      [
        43.864,
        145.582203
      ],
      [
        44.144397,
        145.93300299999999
      ],
      [
        44.449295,
        146.57570199999998
      ]
    ],
    "5": [
      [
        49.6097,
        154.9195
      ],
      [
        49.5886,
        154.777599
      ],
      [
        49.277695,
        154.628998
      ],
      [
        49.297195,
        154.830298
      ],
      [
        49.603396000000004,
        154.912293
      ]
    ],
    "6": [
      [
        50.766251,
        156.5103
      ],
      [
        50.871029,
        156.470979
      ],
      [
        50.703363,
        156.169634
      ],
      [
        50.634862000000005,
        156.399812
      ],
      [
        50.76261100000001,
        156.509858
      ]
    ],
    "7": [
      [
        47.151199,
        152.291301
      ],
      [
        46.839402,
        151.704108
      ],
      [
        46.774903,
        151.818308
      ],
      [
        47.143007000000004,
        152.28610700000002
      ]
    ],
    "8": [
      [
        48.8815,
        154.2275
      ],
      [
        48.736498000000005,
        153.97379999999998
      ],
      [
        48.879796000000006,
        154.227501
      ]
    ]
  },
  "Чукотский автономный округ": {
    "0": [
      [
        68.979865,
        179.999999
      ],
      [
        68.979865,
        179.999979
      ],
      [
        68.98047600000001,
        179.998316
      ],
      [
        69.386115,
        178.82087099999998
      ],
      [
        69.636978,
        176.890482
      ],
      [
        69.8767,
        176.111613
      ],
      [
        69.803809,
        173.790904
      ],
      [
        69.93412000000001,
        173.50603900000002
      ],
      [
        69.83334300000001,
        173.149502
      ],
      [
        69.96420100000002,
        172.76243200000002
      ],
      [
        70.12775300000001,
        170.56431300000003
      ],
      [
        70.10848200000001,
        170.46726700000002
      ],
      [
        70.071143,
        170.617397
      ],
      [
        69.75801000000001,
        170.63425800000002
      ],
      [
        69.58692200000002,
        170.20254200000002
      ],
      [
        69.56556100000002,
        170.681957
      ],
      [
        69.28714900000001,
        170.94039500000002
      ],
      [
        69.050317,
        171.065838
      ],
      [
        68.80620800000001,
        170.361478
      ],
      [
        68.75732,
        169.741118
      ],
      [
        68.81087000000001,
        169.488815
      ],
      [
        69.084704,
        169.34228399999998
      ],
      [
        69.228647,
        168.28428699999998
      ],
      [
        69.54606799999999,
        168.27987799999997
      ],
      [
        69.6792,
        168.09721599999997
      ],
      [
        69.763199,
        167.80676599999998
      ],
      [
        69.474144,
        166.964024
      ],
      [
        69.57386699999999,
        164.597829
      ],
      [
        69.74958699999999,
        164.049566
      ],
      [
        69.66545099999999,
        162.589818
      ],
      [
        69.64997899999999,
        162.498525
      ],
      [
        69.53333399999998,
        162.309958
      ],
      [
        69.19414799999998,
        162.78246199999998
      ],
      [
        69.11721399999999,
        162.49910699999998
      ],
      [
        69.00361199999999,
        162.530815
      ],
      [
        68.869716,
        162.84938799999998
      ],
      [
        68.805554,
        162.59246
      ],
      [
        68.606382,
        162.724113
      ],
      [
        68.305556,
        162.43523499999998
      ],
      [
        68.41139,
        161.36577799999998
      ],
      [
        68.127485,
        158.499689
      ],
      [
        67.91694399999999,
        158.05718399999998
      ],
      [
        67.74860899999999,
        158.32744699999998
      ],
      [
        67.70611399999999,
        157.80413199999998
      ],
      [
        67.54527299999998,
        157.69024199999998
      ],
      [
        67.44110199999999,
        157.887477
      ],
      [
        67.34194999999998,
        157.778316
      ],
      [
        67.26695399999998,
        157.867458
      ],
      [
        66.97749599999999,
        158.806087
      ],
      [
        66.79194999999999,
        158.903315
      ],
      [
        66.44861199999998,
        158.352748
      ],
      [
        66.21786999999998,
        159.194514
      ],
      [
        65.74306199999998,
        158.911921
      ],
      [
        65.51778199999998,
        160.02746000000002
      ],
      [
        65.16362699999998,
        160.522759
      ],
      [
        65.13306399999998,
        161.33413000000002
      ],
      [
        64.82973599999998,
        161.797752
      ],
      [
        64.65889999999999,
        162.749991
      ],
      [
        64.65310199999999,
        163.043204
      ],
      [
        64.801402,
        163.11577400000002
      ],
      [
        64.93278,
        163.494099
      ],
      [
        64.93999699999999,
        164.167743
      ],
      [
        64.68444299999999,
        164.874652
      ],
      [
        64.69361299999998,
        165.021349
      ],
      [
        64.85445499999999,
        165.044695
      ],
      [
        64.76252199999999,
        165.66996999999998
      ],
      [
        64.550563,
        165.86412199999998
      ],
      [
        64.626109,
        167.24549899999997
      ],
      [
        64.336681,
        167.71882699999998
      ],
      [
        64.317516,
        168.500533
      ],
      [
        64.023067,
        168.86134299999998
      ],
      [
        63.825022999999995,
        169.539686
      ],
      [
        63.65612399999999,
        169.59995899999998
      ],
      [
        63.57279599999999,
        169.50217999999998
      ],
      [
        63.08279899999999,
        168.44441099999997
      ],
      [
        62.973348999999985,
        169.13188099999996
      ],
      [
        62.659185999999984,
        170.09272599999997
      ],
      [
        62.542799999999986,
        169.97828599999997
      ],
      [
        62.28530199999999,
        170.26023799999996
      ],
      [
        62.24418699999999,
        170.85215699999995
      ],
      [
        62.30974599999999,
        171.48939299999995
      ],
      [
        62.46779699999999,
        172.04133399999995
      ],
      [
        62.32836199999999,
        172.84632599999995
      ],
      [
        62.53835999999999,
        173.67829499999993
      ],
      [
        62.44724199999999,
        174.08188899999993
      ],
      [
        62.27835899999999,
        174.00940999999995
      ],
      [
        62.07363999999999,
        174.10383099999996
      ],
      [
        62.09363599999999,
        174.33133999999995
      ],
      [
        61.80986599999999,
        174.49469999999997
      ],
      [
        62.531727999999994,
        177.12383499999996
      ],
      [
        62.52092199999999,
        178.07991999999996
      ],
      [
        62.26969799999999,
        179.09778699999995
      ],
      [
        62.45072699999999,
        179.13841999999997
      ],
      [
        62.68153099999999,
        179.62833399999997
      ],
      [
        62.97347799999999,
        179.27005799999998
      ],
      [
        63.107808999999996,
        179.452003
      ],
      [
        63.418975999999994,
        178.892509
      ],
      [
        64.00003099999999,
        178.68409
      ],
      [
        64.439056,
        178.230111
      ],
      [
        64.33594599999999,
        178.334083
      ],
      [
        64.2297,
        178.120004
      ],
      [
        64.17889699999999,
        178.25360899999998
      ],
      [
        64.40614599999999,
        177.50241799999998
      ],
      [
        64.515001,
        177.42028
      ],
      [
        64.737672,
        177.519783
      ],
      [
        64.534396,
        176.77519999999998
      ],
      [
        64.660173,
        176.56200399999997
      ],
      [
        64.585085,
        176.18622699999997
      ],
      [
        64.69542100000001,
        176.45325599999998
      ],
      [
        64.89077800000001,
        176.156366
      ],
      [
        64.72489200000001,
        175.726892
      ],
      [
        64.733833,
        175.218698
      ],
      [
        64.762282,
        175.774652
      ],
      [
        64.94261,
        176.059641
      ],
      [
        64.749861,
        177.081537
      ],
      [
        64.80399799999999,
        177.348504
      ],
      [
        64.920278,
        177.501869
      ],
      [
        64.81156,
        177.413643
      ],
      [
        64.692221,
        177.64292
      ],
      [
        64.642859,
        178.811254
      ],
      [
        64.557891,
        178.561164
      ],
      [
        65.027,
        179.998618
      ],
      [
        65.02745,
        179.999975
      ],
      [
        65.146126,
        179.999995
      ]
    ],
    "1": [
      [
        66.0852,
        -169.656302
      ],
      [
        66.159497,
        -169.813902
      ],
      [
        66.154398,
        -170.23239900000002
      ],
      [
        66.194698,
        -170.04830500000003
      ],
      [
        66.303897,
        -170.28830100000002
      ],
      [
        66.2227,
        -170.61189800000002
      ],
      [
        66.31160100000001,
        -170.38750300000004
      ],
      [
        66.31329900000001,
        -170.35150400000003
      ],
      [
        66.67780000000002,
        -171.36280000000002
      ],
      [
        66.94539900000002,
        -171.67970200000002
      ],
      [
        67.03699500000002,
        -172.95539700000003
      ],
      [
        67.04829300000002,
        -173.03049500000003
      ],
      [
        66.93609400000001,
        -172.36030200000002
      ],
      [
        66.99849300000001,
        -173.07589800000002
      ],
      [
        66.90899300000001,
        -173.24399800000003
      ],
      [
        66.84349200000001,
        -173.08630400000004
      ],
      [
        66.82409100000001,
        -173.30560500000004
      ],
      [
        66.97649100000001,
        -173.31510300000005
      ],
      [
        66.99889100000001,
        -173.14270100000005
      ],
      [
        67.07159000000001,
        -173.34020200000003
      ],
      [
        67.04949100000002,
        -173.06040800000002
      ],
      [
        67.09018900000002,
        -173.53049900000002
      ],
      [
        67.11998800000002,
        -173.64990400000002
      ],
      [
        67.05028800000002,
        -174.627405
      ],
      [
        67.07829000000002,
        -174.149708
      ],
      [
        67.03069100000002,
        -174.564709
      ],
      [
        67.01738700000001,
        -174.197303
      ],
      [
        66.97958900000002,
        -174.284105
      ],
      [
        66.98248600000002,
        -174.01400600000002
      ],
      [
        66.66618600000002,
        -173.88900400000003
      ],
      [
        66.64038700000002,
        -174.01350800000003
      ],
      [
        66.50278500000002,
        -174.27141100000003
      ],
      [
        66.43289000000001,
        -173.69300900000002
      ],
      [
        66.30758900000002,
        -173.80800900000003
      ],
      [
        66.22908800000002,
        -173.98221000000004
      ],
      [
        66.45138800000002,
        -173.95041000000003
      ],
      [
        66.47179000000003,
        -174.20860700000003
      ],
      [
        66.31718900000003,
        -174.30340600000002
      ],
      [
        66.28058800000002,
        -174.41860800000003
      ],
      [
        66.53399500000002,
        -174.44291200000004
      ],
      [
        66.67139100000001,
        -174.98400700000005
      ],
      [
        66.72558900000001,
        -174.70281000000006
      ],
      [
        66.92098900000002,
        -174.68330500000005
      ],
      [
        67.08748800000002,
        -174.91571100000004
      ],
      [
        67.27708600000003,
        -174.86760900000004
      ],
      [
        67.24608700000003,
        -174.72530900000004
      ],
      [
        67.47178500000003,
        -175.05731000000003
      ],
      [
        67.43718400000003,
        -174.96681200000003
      ],
      [
        67.35978400000003,
        -175.05431000000004
      ],
      [
        67.30928100000003,
        -175.36070800000005
      ],
      [
        67.42798300000003,
        -175.16960900000004
      ],
      [
        67.52438300000003,
        -175.22111200000003
      ],
      [
        67.47178000000002,
        -175.07361000000003
      ],
      [
        67.66888100000003,
        -175.28081100000003
      ],
      [
        67.81078400000003,
        -175.82230400000003
      ],
      [
        67.72598400000003,
        -176.02600900000004
      ],
      [
        67.54088000000003,
        -175.41421300000005
      ],
      [
        67.51818200000002,
        -175.52101000000005
      ],
      [
        67.44478100000002,
        -175.40860700000005
      ],
      [
        67.63758500000002,
        -175.92150600000005
      ],
      [
        67.72538500000002,
        -176.74691000000004
      ],
      [
        67.72348600000002,
        -176.06110800000005
      ],
      [
        67.84338600000002,
        -176.06971000000004
      ],
      [
        67.84788600000002,
        -175.80620600000003
      ],
      [
        67.89688800000002,
        -176.17700400000004
      ],
      [
        67.97358800000002,
        -176.95170900000005
      ],
      [
        68.02659000000003,
        -176.72921000000005
      ],
      [
        68.10138800000003,
        -176.88450500000005
      ],
      [
        68.00548700000003,
        -176.53290600000005
      ],
      [
        68.19408900000003,
        -177.22180200000005
      ],
      [
        68.24519000000004,
        -177.32320600000006
      ],
      [
        68.16619000000004,
        -177.58970300000004
      ],
      [
        68.24549100000004,
        -177.74939800000004
      ],
      [
        68.31569100000004,
        -177.65169800000004
      ],
      [
        68.24389000000005,
        -178.03960000000004
      ],
      [
        68.31889100000005,
        -177.90700000000004
      ],
      [
        68.41229100000005,
        -177.99139600000004
      ],
      [
        68.53449300000005,
        -178.72689900000003
      ],
      [
        68.73619700000006,
        -178.80659500000004
      ],
      [
        68.87929800000006,
        -179.44129400000006
      ],
      [
        68.78119700000006,
        -178.96509500000005
      ],
      [
        68.93950000000007,
        -179.88609800000006
      ],
      [
        68.97985800000006,
        -179.99997600000006
      ],
      [
        65.14609700000007,
        -179.99997600000006
      ],
      [
        65.14609700000007,
        -179.66689600000007
      ],
      [
        65.53660000000006,
        -179.27229800000006
      ],
      [
        65.63499800000007,
        -179.28170000000006
      ],
      [
        65.69900000000007,
        -179.55190000000005
      ],
      [
        65.78639900000007,
        -179.76139700000004
      ],
      [
        66.09530100000008,
        -179.78709800000004
      ],
      [
        66.12169900000008,
        -179.52909600000004
      ],
      [
        66.13200000000008,
        -179.29159900000005
      ],
      [
        66.19710000000008,
        -179.19619700000004
      ],
      [
        66.32869900000007,
        -179.38939700000003
      ],
      [
        66.26649900000007,
        -179.15969800000002
      ],
      [
        66.36960200000007,
        -179.07999700000002
      ],
      [
        66.24840300000007,
        -179.104395
      ],
      [
        66.15880100000007,
        -178.848997
      ],
      [
        66.39240500000007,
        -178.479697
      ],
      [
        66.13750400000006,
        -178.480988
      ],
      [
        65.98310400000007,
        -178.755987
      ],
      [
        66.04420300000007,
        -178.926688
      ],
      [
        65.91020400000006,
        -178.878587
      ],
      [
        65.70740400000007,
        -178.410685
      ],
      [
        65.49600500000007,
        -178.541688
      ],
      [
        65.47310200000007,
        -177.431887
      ],
      [
        65.66819900000007,
        -176.922387
      ],
      [
        65.60139900000007,
        -176.806388
      ],
      [
        65.58290200000008,
        -177.15779
      ],
      [
        65.46819800000007,
        -176.161994
      ],
      [
        65.46639800000007,
        -176.03249399999999
      ],
      [
        65.28660000000006,
        -175.866493
      ],
      [
        65.15439700000006,
        -175.748596
      ],
      [
        65.00829600000006,
        -175.894095
      ],
      [
        64.85549900000005,
        -175.339594
      ],
      [
        64.78300100000006,
        -175.43929500000002
      ],
      [
        64.77380300000006,
        -175.00579900000002
      ],
      [
        64.86910400000006,
        -174.94580000000002
      ],
      [
        64.63670700000006,
        -174.33990200000002
      ],
      [
        64.32871000000006,
        -173.65440500000003
      ],
      [
        64.44130900000006,
        -173.31020800000002
      ],
      [
        64.60650700000006,
        -173.35370500000002
      ],
      [
        64.44130700000007,
        -173.166705
      ],
      [
        64.29540600000007,
        -173.382204
      ],
      [
        64.24530800000007,
        -173.094707
      ],
      [
        64.26570700000006,
        -172.939206
      ],
      [
        64.40910900000006,
        -172.933107
      ],
      [
        64.28450700000006,
        -172.928208
      ],
      [
        64.35980600000006,
        -172.81940600000001
      ],
      [
        64.45940400000006,
        -172.82320700000002
      ],
      [
        64.49380600000006,
        -172.96960400000003
      ],
      [
        64.40460900000006,
        -172.23029700000004
      ],
      [
        64.54670900000006,
        -172.42509600000002
      ],
      [
        64.56650800000007,
        -172.985192
      ],
      [
        64.66010600000007,
        -172.835296
      ],
      [
        64.64880400000007,
        -173.088798
      ],
      [
        64.76260300000007,
        -172.754798
      ],
      [
        64.83820300000006,
        -172.919994
      ],
      [
        64.76060300000006,
        -173.196394
      ],
      [
        64.91199800000005,
        -172.521797
      ],
      [
        65.08609700000005,
        -172.088199
      ],
      [
        65.22529500000005,
        -172.242999
      ],
      [
        65.21229400000004,
        -172.701196
      ],
      [
        65.21319300000005,
        -172.20509800000002
      ],
      [
        65.48379300000005,
        -172.09139800000003
      ],
      [
        65.43759600000004,
        -172.39439700000003
      ],
      [
        65.45809600000004,
        -172.28739800000002
      ],
      [
        65.57829700000003,
        -172.368398
      ],
      [
        65.69029300000004,
        -172.65069400000002
      ],
      [
        65.67999400000004,
        -172.318395
      ],
      [
        65.50419500000004,
        -171.72440400000002
      ],
      [
        65.47969600000003,
        -172.08080200000003
      ],
      [
        65.46830200000004,
        -171.06020300000003
      ],
      [
        65.59249900000003,
        -170.98750100000004
      ],
      [
        65.82439600000004,
        -171.45740200000003
      ],
      [
        65.60360000000003,
        -170.62340300000002
      ],
      [
        65.67439900000002,
        -170.50290200000003
      ],
      [
        65.85679600000002,
        -170.55689900000004
      ],
      [
        66.07969500000002,
        -169.65909600000003
      ]
    ],
    "2": [
      [
        71.53931,
        179.999999
      ],
      [
        71.53931,
        179.999999
      ],
      [
        71.074038,
        178.612078
      ],
      [
        70.78170300000001,
        178.749468
      ],
      [
        70.821398,
        178.94175900000002
      ],
      [
        70.86384100000001,
        179.36023000000003
      ],
      [
        70.97473300000001,
        179.99836800000003
      ],
      [
        70.97473300000001,
        179.99999900000003
      ]
    ],
    "3": [
      [
        71.221601,
        -177.413302
      ],
      [
        71.37130300000001,
        -177.83669999999998
      ],
      [
        71.51350700000002,
        -178.303297
      ],
      [
        71.49610800000002,
        -178.150097
      ],
      [
        71.55470900000002,
        -178.496895
      ],
      [
        71.52980900000001,
        -179.875398
      ],
      [
        71.53931000000001,
        -179.999999
      ],
      [
        71.53931000000001,
        -179.999999
      ],
      [
        70.97473400000001,
        -179.999999
      ],
      [
        70.97473400000001,
        -179.999999
      ],
      [
        70.88280800000001,
        -179.455106
      ],
      [
        70.89230900000001,
        -179.185805
      ],
      [
        70.88670900000001,
        -179.18970199999998
      ],
      [
        70.97270700000001,
        -178.482001
      ],
      [
        71.02500700000002,
        -177.90630099999998
      ],
      [
        71.21801000000002,
        -177.41830299999998
      ]
    ],
    "4": [
      [
        69.810219,
        169.45842
      ],
      [
        70.018691,
        168.35308899999998
      ],
      [
        69.810775,
        167.77716199999998
      ],
      [
        69.55205500000001,
        168.96993999999998
      ],
      [
        69.55403000000001,
        169.21513299999998
      ],
      [
        69.74758000000001,
        169.27033899999998
      ],
      [
        69.79714000000001,
        169.44749299999998
      ]
    ],
    "5": [
      [
        64.7603,
        -172.069801
      ],
      [
        64.840698,
        -172.403398
      ],
      [
        64.691101,
        -172.666
      ],
      [
        64.756798,
        -172.08470499999999
      ]
    ],
    "6": [
      [
        68.261599,
        -177.4397
      ],
      [
        68.56999900000001,
        -178.442098
      ],
      [
        68.26070000000001,
        -177.44210199999998
      ]
    ],
    "7": [
      [
        65.535,
        -177.1759
      ],
      [
        65.479297,
        -178.744099
      ],
      [
        65.532301,
        -177.177607
      ]
    ],
    "8": [
      [
        71.3615,
        -175.576399
      ],
      [
        71.410201,
        -175.847601
      ],
      [
        71.358899,
        -175.578898
      ]
    ],
    "9": [
      [
        68.5747,
        -178.433002
      ],
      [
        68.76729700000001,
        -178.8853
      ],
      [
        68.57259900000001,
        -178.4426
      ]
    ],
    "10": [
      [
        71.380499,
        -177.838497
      ],
      [
        71.514701,
        -178.199098
      ],
      [
        71.37490100000001,
        -177.845695
      ]
    ],
    "11": [
      [
        71.5192,
        -178.202699
      ],
      [
        71.565899,
        -178.596697
      ],
      [
        71.518299,
        -178.208299
      ]
    ]
  },
  "Красноярский край": {
    "0": [
      [
        75.877052,
        113.919746
      ],
      [
        75.876946,
        113.581002
      ],
      [
        76.252975,
        113.297808
      ],
      [
        76.232781,
        112.97205600000001
      ],
      [
        76.12111,
        113.36152700000001
      ],
      [
        76.142366,
        113.06130300000001
      ],
      [
        76.051752,
        112.61603000000001
      ],
      [
        76.09822299999999,
        112.88358500000001
      ],
      [
        76.23378199999999,
        112.488032
      ],
      [
        76.20547799999999,
        112.752528
      ],
      [
        76.32291599999999,
        112.829142
      ],
      [
        76.45283699999999,
        112.379756
      ],
      [
        76.326838,
        111.964999
      ],
      [
        76.215587,
        111.94897900000001
      ],
      [
        76.360666,
        111.97806100000001
      ],
      [
        76.454888,
        112.264339
      ],
      [
        76.614136,
        111.625477
      ],
      [
        76.633857,
        111.59147300000001
      ],
      [
        76.679609,
        111.81189400000001
      ],
      [
        76.758633,
        111.30386800000001
      ],
      [
        76.773717,
        111.19517300000001
      ],
      [
        76.680441,
        110.108922
      ],
      [
        76.750219,
        108.286618
      ],
      [
        76.719557,
        107.974287
      ],
      [
        76.571975,
        108.010175
      ],
      [
        76.492836,
        107.711394
      ],
      [
        76.473748,
        106.603148
      ],
      [
        76.567055,
        106.443037
      ],
      [
        76.531166,
        106.662839
      ],
      [
        76.660613,
        106.858807
      ],
      [
        76.714666,
        106.789029
      ],
      [
        76.827856,
        107.332563
      ],
      [
        76.86319499999999,
        107.39872399999999
      ],
      [
        76.90919199999999,
        107.539279
      ],
      [
        76.99002499999999,
        107.24064299999999
      ],
      [
        77.04835899999999,
        106.856923
      ],
      [
        77.010274,
        106.672643
      ],
      [
        76.998251,
        105.46917499999999
      ],
      [
        77.12705,
        105.932752
      ],
      [
        77.083136,
        104.243337
      ],
      [
        77.20091699999999,
        105.417896
      ],
      [
        77.262638,
        105.618472
      ],
      [
        77.260747,
        105.27038999999999
      ],
      [
        77.358273,
        105.83358299999999
      ],
      [
        77.35680099999999,
        105.52155699999999
      ],
      [
        77.377805,
        106.30024999999999
      ],
      [
        77.422939,
        106.016033
      ],
      [
        77.56361,
        105.91852999999999
      ],
      [
        77.728854,
        104.10809099999999
      ],
      [
        77.635943,
        103.26014399999998
      ],
      [
        77.37285899999999,
        102.30925499999998
      ],
      [
        77.359912,
        102.14755699999998
      ],
      [
        77.105525,
        101.41683799999998
      ],
      [
        77.044215,
        101.28478099999998
      ],
      [
        77.036159,
        101.41889799999998
      ],
      [
        76.96538199999999,
        101.34908899999998
      ],
      [
        76.84141299999999,
        100.95242199999998
      ],
      [
        76.70044499999999,
        101.32120399999998
      ],
      [
        76.54408199999999,
        101.12464799999998
      ],
      [
        76.46341699999999,
        98.88536899999998
      ],
      [
        76.144166,
        99.77262099999999
      ],
      [
        76.081087,
        99.622588
      ],
      [
        76.091584,
        99.44908899999999
      ],
      [
        76.138496,
        99.20539999999998
      ],
      [
        76.25389,
        98.85694999999998
      ],
      [
        76.108779,
        97.96631899999998
      ],
      [
        76.035057,
        97.56559199999998
      ],
      [
        75.97080299999999,
        97.86251199999998
      ],
      [
        75.92686599999999,
        97.16881699999998
      ],
      [
        76.021058,
        97.39525699999997
      ],
      [
        76.023027,
        97.29776199999998
      ],
      [
        75.859446,
        96.47517599999998
      ],
      [
        75.97169600000001,
        96.71845399999998
      ],
      [
        76.004112,
        96.44451399999998
      ],
      [
        75.82875100000001,
        95.72492499999998
      ],
      [
        75.870163,
        95.62045499999998
      ],
      [
        76.03794,
        96.14998099999998
      ],
      [
        76.08805600000001,
        96.27628599999998
      ],
      [
        76.154386,
        94.61101099999999
      ],
      [
        76.109335,
        94.80956499999999
      ],
      [
        76.118528,
        93.71875999999999
      ],
      [
        76.051054,
        94.03373099999999
      ],
      [
        76.009222,
        93.42989499999999
      ],
      [
        76.06688399999999,
        93.36275599999999
      ],
      [
        76.09704999999998,
        93.49756699999999
      ],
      [
        76.11226999999998,
        93.25022899999999
      ],
      [
        76.09205299999998,
        93.16439999999999
      ],
      [
        76.07363699999998,
        93.32351099999998
      ],
      [
        75.96063799999997,
        92.82090099999998
      ],
      [
        75.88488699999998,
        93.05458899999998
      ],
      [
        75.89844299999997,
        93.17792399999998
      ],
      [
        75.92391699999997,
        92.97378499999998
      ],
      [
        75.98355599999998,
        93.36205999999997
      ],
      [
        75.93610799999998,
        93.27056199999997
      ],
      [
        75.93594099999997,
        94.18608899999997
      ],
      [
        75.72935899999997,
        91.70628399999997
      ],
      [
        75.62385999999998,
        91.69228399999997
      ],
      [
        75.59713399999998,
        90.29112299999997
      ],
      [
        75.53860899999998,
        89.95617699999997
      ],
      [
        75.43260699999998,
        89.75676099999997
      ],
      [
        75.46385699999998,
        89.31340299999997
      ],
      [
        75.48677499999998,
        89.52942799999997
      ],
      [
        75.50971699999998,
        89.30709199999997
      ],
      [
        75.39463599999998,
        88.74514899999997
      ],
      [
        75.35066799999997,
        89.23056099999997
      ],
      [
        75.38319099999997,
        88.87497799999997
      ],
      [
        75.35236099999997,
        88.76420099999997
      ],
      [
        75.33335599999997,
        88.95959699999997
      ],
      [
        75.23733299999996,
        88.71462499999997
      ],
      [
        75.18749799999996,
        88.29204099999997
      ],
      [
        75.13549599999996,
        88.24818099999997
      ],
      [
        75.11066199999996,
        86.88129099999998
      ],
      [
        74.99738999999997,
        87.75073699999997
      ],
      [
        74.98438999999996,
        87.19800999999997
      ],
      [
        74.94708299999996,
        87.39428999999997
      ],
      [
        74.85633199999997,
        87.07745699999997
      ],
      [
        74.72180399999996,
        87.07306999999997
      ],
      [
        74.70439299999997,
        86.75348299999997
      ],
      [
        74.64000099999997,
        86.87353999999998
      ],
      [
        74.59908599999997,
        86.96770899999997
      ],
      [
        74.66214199999997,
        86.56809699999997
      ],
      [
        74.75394599999997,
        86.24728899999997
      ],
      [
        74.77908499999997,
        86.39751199999996
      ],
      [
        74.83513699999996,
        86.12073299999996
      ],
      [
        74.82052699999996,
        85.99906699999995
      ],
      [
        74.65344399999995,
        85.75126399999995
      ],
      [
        74.63231099999994,
        85.87142599999994
      ],
      [
        74.55686499999995,
        86.77259499999994
      ],
      [
        74.50692299999994,
        86.57014899999994
      ],
      [
        74.42336699999994,
        86.79173699999994
      ],
      [
        74.48744599999995,
        87.13440299999993
      ],
      [
        74.40881099999994,
        87.04420899999994
      ],
      [
        74.36125699999994,
        87.21532099999993
      ],
      [
        74.43803099999994,
        87.31967599999993
      ],
      [
        74.35850399999994,
        87.37209699999993
      ],
      [
        74.38633599999994,
        87.03145299999993
      ],
      [
        74.27133899999994,
        87.01426499999992
      ],
      [
        74.31439799999994,
        86.73753899999993
      ],
      [
        74.40222699999994,
        86.98459299999993
      ],
      [
        74.47617099999994,
        86.54937499999993
      ],
      [
        74.42167399999994,
        86.06515399999992
      ],
      [
        74.31867099999994,
        86.30203899999992
      ],
      [
        74.35892199999994,
        85.89929199999992
      ],
      [
        74.22825399999994,
        86.67873299999992
      ],
      [
        74.17067499999993,
        86.78398699999993
      ],
      [
        74.05264099999994,
        86.89006599999992
      ],
      [
        74.07528399999994,
        87.45390099999992
      ],
      [
        74.02317499999994,
        86.92332199999991
      ],
      [
        73.87231199999994,
        87.21431499999991
      ],
      [
        73.84703599999993,
        87.10265099999991
      ],
      [
        73.85428299999994,
        86.10857299999991
      ],
      [
        73.53950199999994,
        80.5281519999999
      ],
      [
        73.42980799999994,
        80.7361819999999
      ],
      [
        73.28945099999994,
        80.3498749999999
      ],
      [
        73.19364899999994,
        80.8096819999999
      ],
      [
        73.16419999999994,
        80.31395599999989
      ],
      [
        73.07545499999993,
        80.5185989999999
      ],
      [
        73.01800599999993,
        80.8170379999999
      ],
      [
        72.79109299999993,
        80.8959559999999
      ],
      [
        72.67694999999993,
        80.6855139999999
      ],
      [
        72.60797999999994,
        80.9379329999999
      ],
      [
        72.50581599999994,
        80.7734899999999
      ],
      [
        72.43806799999994,
        80.8614029999999
      ],
      [
        72.33778799999995,
        81.9644839999999
      ],
      [
        72.13409999999995,
        82.4740429999999
      ],
      [
        72.10162099999995,
        82.2559569999999
      ],
      [
        71.86262299999996,
        82.6958749999999
      ],
      [
        71.83626299999996,
        83.3040969999999
      ],
      [
        71.62679099999995,
        83.6517069999999
      ],
      [
        71.23393099999996,
        83.1544309999999
      ],
      [
        71.12868399999995,
        83.26720899999991
      ],
      [
        71.11006899999995,
        83.1604809999999
      ],
      [
        70.99337699999995,
        83.42100999999991
      ],
      [
        70.45271299999995,
        83.7853739999999
      ],
      [
        70.30799099999994,
        83.5496489999999
      ],
      [
        70.28751399999994,
        82.9875999999999
      ],
      [
        70.07451699999994,
        83.1476269999999
      ],
      [
        69.60890399999994,
        84.5515109999999
      ],
      [
        69.54968399999994,
        84.2894259999999
      ],
      [
        69.71876599999995,
        83.5166529999999
      ],
      [
        69.92898499999994,
        83.1651819999999
      ],
      [
        69.98715199999994,
        82.7346259999999
      ],
      [
        70.02312399999994,
        82.9951759999999
      ],
      [
        70.12848499999994,
        82.7336569999999
      ],
      [
        70.14676399999995,
        82.2895719999999
      ],
      [
        70.05064999999995,
        82.20506999999989
      ],
      [
        70.16498299999995,
        82.36137299999989
      ],
      [
        70.28139899999995,
        82.0189879999999
      ],
      [
        70.29806999999995,
        82.2735129999999
      ],
      [
        70.45353999999995,
        82.1614299999999
      ],
      [
        70.21137699999994,
        81.8099069999999
      ],
      [
        70.46834199999994,
        82.1560129999999
      ],
      [
        70.73953699999994,
        82.4162899999999
      ],
      [
        70.99943199999994,
        82.1954569999999
      ],
      [
        71.06378499999994,
        82.3245679999999
      ],
      [
        71.25034599999994,
        82.2425989999999
      ],
      [
        71.40204099999994,
        82.9994569999999
      ],
      [
        71.52753599999994,
        82.9762939999999
      ],
      [
        71.67386699999994,
        83.3079589999999
      ],
      [
        71.76267299999995,
        82.84490199999989
      ],
      [
        71.68431199999995,
        81.6630179999999
      ],
      [
        72.09981599999995,
        80.60556899999989
      ],
      [
        72.34519899999995,
        79.47029199999989
      ],
      [
        72.38539799999995,
        78.42409599999989
      ],
      [
        72.37267199999995,
        78.5821849999999
      ],
      [
        72.34738899999995,
        78.44502399999989
      ],
      [
        72.22638599999995,
        78.6777809999999
      ],
      [
        72.11470799999995,
        78.9108269999999
      ],
      [
        71.88601099999995,
        80.3487989999999
      ],
      [
        71.83147599999995,
        80.0350629999999
      ],
      [
        71.67453999999995,
        80.1354189999999
      ],
      [
        71.61638999999995,
        79.3802769999999
      ],
      [
        71.41111299999996,
        79.1425009999999
      ],
      [
        71.05758299999995,
        80.5500619999999
      ],
      [
        70.85477999999995,
        80.5664799999999
      ],
      [
        70.80187799999995,
        80.6901829999999
      ],
      [
        70.71676499999994,
        80.5922979999999
      ],
      [
        70.65447999999994,
        80.7593809999999
      ],
      [
        70.50096199999993,
        80.6065039999999
      ],
      [
        70.39445699999993,
        80.7621889999999
      ],
      [
        70.43656999999993,
        80.17684699999991
      ],
      [
        70.11055199999993,
        79.4988839999999
      ],
      [
        70.14889599999992,
        79.3280629999999
      ],
      [
        70.04499899999992,
        79.3494389999999
      ],
      [
        69.88166899999992,
        78.87527199999991
      ],
      [
        69.74305899999992,
        79.13972099999991
      ],
      [
        69.69694799999992,
        79.00417899999991
      ],
      [
        69.65361299999992,
        79.5058409999999
      ],
      [
        69.34611799999992,
        79.85915699999991
      ],
      [
        69.20668299999991,
        80.95597399999991
      ],
      [
        69.27583499999992,
        81.58249899999991
      ],
      [
        69.44777099999992,
        81.68360399999992
      ],
      [
        69.34666699999991,
        81.93610499999991
      ],
      [
        69.25250499999991,
        81.75972999999992
      ],
      [
        69.18499999999992,
        81.84388199999992
      ],
      [
        69.16055699999991,
        82.46389199999992
      ],
      [
        69.05388399999991,
        82.31638699999992
      ],
      [
        68.81834999999991,
        82.55221099999991
      ],
      [
        68.66777699999992,
        83.04276499999992
      ],
      [
        68.57834499999991,
        82.45611099999992
      ],
      [
        68.49138599999992,
        82.68055199999992
      ],
      [
        68.29334199999992,
        82.70665899999992
      ],
      [
        68.17749699999993,
        82.39277099999991
      ],
      [
        67.94472399999994,
        82.36693899999992
      ],
      [
        67.92417199999994,
        81.72443699999992
      ],
      [
        67.72889099999995,
        82.10555499999992
      ],
      [
        67.59945099999995,
        82.11415999999993
      ],
      [
        67.53501399999995,
        82.39665999999993
      ],
      [
        67.42084899999995,
        82.23360599999992
      ],
      [
        67.33057899999994,
        82.36470899999992
      ],
      [
        67.23890499999995,
        82.11943999999993
      ],
      [
        66.80917299999994,
        83.17249299999993
      ],
      [
        66.66168299999994,
        83.30582399999993
      ],
      [
        66.61056699999995,
        83.08666299999993
      ],
      [
        66.46974399999995,
        83.08693599999994
      ],
      [
        66.19528399999994,
        83.53970899999993
      ],
      [
        66.06697299999995,
        83.28943599999992
      ],
      [
        65.82947099999994,
        83.50638399999993
      ],
      [
        65.79390299999994,
        84.03249099999992
      ],
      [
        65.68779299999994,
        84.32666499999992
      ],
      [
        65.59447199999994,
        84.19054099999993
      ],
      [
        65.50697799999993,
        84.49971499999992
      ],
      [
        65.29169099999993,
        84.58554599999992
      ],
      [
        65.09863799999992,
        84.31110199999992
      ],
      [
        64.99390199999992,
        84.39665799999992
      ],
      [
        64.90918599999992,
        84.27805299999991
      ],
      [
        64.75169999999991,
        85.14694899999992
      ],
      [
        64.76503499999991,
        85.85637599999993
      ],
      [
        64.64752699999991,
        85.80860199999992
      ],
      [
        64.45891399999991,
        85.84082799999992
      ],
      [
        64.2753059999999,
        86.03110499999991
      ],
      [
        64.05446499999991,
        85.95804699999991
      ],
      [
        63.93003099999991,
        85.51639699999991
      ],
      [
        63.646423999999904,
        85.2672209999999
      ],
      [
        63.586526999999904,
        85.0320379999999
      ],
      [
        63.3500389999999,
        85.4077699999999
      ],
      [
        63.3633679999999,
        85.6658259999999
      ],
      [
        63.138087999999904,
        85.4663939999999
      ],
      [
        63.042538999999906,
        85.5819479999999
      ],
      [
        62.183644999999906,
        84.4352659999999
      ],
      [
        61.806143999999904,
        84.6874939999999
      ],
      [
        61.5978159999999,
        85.85193699999991
      ],
      [
        61.474753999999905,
        85.9683309999999
      ],
      [
        61.38809199999991,
        85.64360899999991
      ],
      [
        60.85546199999991,
        84.25965299999991
      ],
      [
        60.36725699999991,
        84.78665999999991
      ],
      [
        59.97475699999991,
        84.52971799999992
      ],
      [
        59.88170899999991,
        87.09777199999992
      ],
      [
        59.68503999999991,
        87.18276299999992
      ],
      [
        59.67281799999991,
        87.52277399999993
      ],
      [
        59.263934999999904,
        87.90332799999993
      ],
      [
        59.3014339999999,
        88.63054599999992
      ],
      [
        59.0200399999999,
        88.83055799999993
      ],
      [
        58.9111529999999,
        88.38416299999993
      ],
      [
        58.4972659999999,
        87.91832799999993
      ],
      [
        58.1067109999999,
        88.14638499999994
      ],
      [
        57.887823999999895,
        89.40387699999994
      ],
      [
        57.63448999999989,
        89.39249499999994
      ],
      [
        57.61781899999989,
        89.14638699999993
      ],
      [
        57.12393099999989,
        88.52471299999993
      ],
      [
        57.085044999999894,
        88.73249199999994
      ],
      [
        56.83320799999989,
        88.62415599999994
      ],
      [
        56.71421199999989,
        88.74137399999994
      ],
      [
        56.58893799999989,
        88.50194799999994
      ],
      [
        56.38004699999989,
        88.70665999999994
      ],
      [
        56.302829999999894,
        89.06667599999994
      ],
      [
        55.8778269999999,
        89.45221799999995
      ],
      [
        55.465897999999896,
        88.63380599999995
      ],
      [
        54.9744889999999,
        89.46915499999994
      ],
      [
        55.0811669999999,
        89.65555599999995
      ],
      [
        55.1444979999999,
        90.16527499999995
      ],
      [
        55.0840699999999,
        90.48761599999995
      ],
      [
        54.9075489999999,
        90.49664899999995
      ],
      [
        54.850888999999896,
        90.94304399999994
      ],
      [
        54.658936999999895,
        90.93692599999994
      ],
      [
        54.517548999999896,
        91.24497799999995
      ],
      [
        54.06810899999989,
        91.56944899999995
      ],
      [
        53.86672099999989,
        91.43776499999994
      ],
      [
        53.64032999999989,
        91.54387499999994
      ],
      [
        53.375879999999896,
        91.96638199999994
      ],
      [
        53.1469979999999,
        91.47165499999994
      ],
      [
        52.974219999999896,
        91.51138799999994
      ],
      [
        52.634502999999896,
        91.25832899999995
      ],
      [
        52.5947729999999,
        90.89887699999994
      ],
      [
        52.1820349999999,
        90.49149799999994
      ],
      [
        51.9061599999999,
        90.84137399999993
      ],
      [
        51.7808869999999,
        92.50444399999994
      ],
      [
        52.2136709999999,
        93.67276399999993
      ],
      [
        52.8353289999999,
        94.13749999999993
      ],
      [
        52.911721999999905,
        94.51999099999993
      ],
      [
        53.23310899999991,
        94.70471299999993
      ],
      [
        53.310882999999905,
        94.58248999999992
      ],
      [
        53.385328999999906,
        94.73969999999993
      ],
      [
        53.45866299999991,
        95.25193699999993
      ],
      [
        53.36921699999991,
        95.39332499999993
      ],
      [
        53.52282599999991,
        95.75277599999994
      ],
      [
        53.44838599999991,
        96.08581299999994
      ],
      [
        53.61755299999991,
        96.24192599999995
      ],
      [
        53.55560999999991,
        96.65859699999994
      ],
      [
        53.99560299999991,
        96.04941999999994
      ],
      [
        54.32783299999991,
        95.65832299999994
      ],
      [
        54.576160999999914,
        96.05581499999994
      ],
      [
        54.512554999999914,
        96.57082799999993
      ],
      [
        54.92143699999991,
        96.71526699999994
      ],
      [
        55.020327999999914,
        96.57776999999994
      ],
      [
        55.25532799999991,
        96.70054199999994
      ],
      [
        55.324769999999916,
        96.92025399999994
      ],
      [
        55.77921399999992,
        96.77248699999994
      ],
      [
        56.116438999999914,
        97.12359199999995
      ],
      [
        56.04865999999991,
        97.35219799999994
      ],
      [
        56.18948199999991,
        97.58692399999994
      ],
      [
        56.387548999999915,
        97.55663699999994
      ],
      [
        56.39254499999991,
        97.88192199999993
      ],
      [
        56.559482999999915,
        97.87942099999994
      ],
      [
        56.55865099999991,
        97.74497699999993
      ],
      [
        56.780596999999915,
        97.77608999999994
      ],
      [
        56.81031999999991,
        97.48580599999994
      ],
      [
        56.95531599999991,
        97.62302799999993
      ],
      [
        57.04322099999991,
        97.34008499999993
      ],
      [
        57.80698299999991,
        97.91720199999993
      ],
      [
        57.72170299999991,
        98.97414699999993
      ],
      [
        57.79337199999991,
        99.42164099999994
      ],
      [
        58.08115199999991,
        99.68192599999993
      ],
      [
        58.07948199999991,
        100.04413899999993
      ],
      [
        57.86559299999991,
        100.12302699999994
      ],
      [
        57.54809599999991,
        100.40358199999994
      ],
      [
        57.40197899999991,
        100.74192199999995
      ],
      [
        57.500877999999915,
        100.96276199999994
      ],
      [
        57.66837199999991,
        100.79693699999994
      ],
      [
        57.773648999999914,
        100.89109099999995
      ],
      [
        57.88615099999991,
        100.80773999999995
      ],
      [
        57.898090999999916,
        101.03525499999995
      ],
      [
        58.11531499999992,
        101.10970199999996
      ],
      [
        58.418924999999916,
        101.68497199999996
      ],
      [
        58.71669999999992,
        102.51137199999997
      ],
      [
        59.07253499999992,
        102.40441599999997
      ],
      [
        59.19169799999992,
        102.53525199999997
      ],
      [
        59.32197699999992,
        103.01553799999998
      ],
      [
        59.26086499999992,
        103.32638099999998
      ],
      [
        59.179475999999916,
        103.24441999999998
      ],
      [
        59.021418999999916,
        103.38914799999998
      ],
      [
        58.848643999999915,
        103.95415099999998
      ],
      [
        58.73502699999992,
        103.85580899999998
      ],
      [
        58.75869999999992,
        104.23638499999998
      ],
      [
        58.60024599999992,
        104.44636799999998
      ],
      [
        58.668634999999924,
        104.79496899999998
      ],
      [
        58.815301999999924,
        104.75581599999998
      ],
      [
        58.894470999999925,
        104.83857999999998
      ],
      [
        59.09503299999992,
        105.36274799999998
      ],
      [
        59.469474999999925,
        105.18940899999998
      ],
      [
        59.46309599999992,
        104.97552599999999
      ],
      [
        59.693357999999925,
        104.79441999999999
      ],
      [
        59.837247999999924,
        105.01913599999999
      ],
      [
        59.84557899999992,
        105.49523199999999
      ],
      [
        60.25447499999992,
        105.47718899999998
      ],
      [
        60.402522999999924,
        104.65385599999998
      ],
      [
        60.63336399999992,
        104.51664899999997
      ],
      [
        60.74335599999992,
        104.60218899999997
      ],
      [
        60.83974499999992,
        104.45692699999996
      ],
      [
        60.893913999999924,
        104.63441699999997
      ],
      [
        61.07446999999993,
        104.61468799999997
      ],
      [
        61.17751999999993,
        105.01191999999998
      ],
      [
        61.34945599999993,
        104.78497699999997
      ],
      [
        61.420020999999934,
        105.27580699999997
      ],
      [
        61.617795999999935,
        105.80274499999997
      ],
      [
        61.68335599999993,
        105.94912899999997
      ],
      [
        61.84363299999993,
        105.91276099999997
      ],
      [
        62.030850999999934,
        106.41664299999998
      ],
      [
        62.24168799999993,
        106.44773199999997
      ],
      [
        62.261966999999935,
        106.31913999999998
      ],
      [
        62.317241999999936,
        106.47914199999998
      ],
      [
        62.477237999999936,
        106.71441799999998
      ],
      [
        62.599741999999935,
        106.81440899999998
      ],
      [
        62.681688999999935,
        106.46913399999998
      ],
      [
        62.84362999999993,
        106.44468899999998
      ],
      [
        62.896126999999936,
        106.21607399999998
      ],
      [
        63.06973999999994,
        106.12190599999998
      ],
      [
        63.110297999999936,
        106.40469699999998
      ],
      [
        63.331130999999935,
        106.44412599999998
      ],
      [
        63.36862199999994,
        106.73635299999998
      ],
      [
        63.49168299999994,
        106.50692999999998
      ],
      [
        63.69834699999994,
        106.62829699999999
      ],
      [
        63.84417499999994,
        106.79719699999998
      ],
      [
        63.98557099999994,
        106.70275299999999
      ],
      [
        63.85640599999994,
        107.55107199999999
      ],
      [
        63.99001199999994,
        107.67913899999999
      ],
      [
        64.03944999999995,
        108.322206
      ],
      [
        64.23860599999995,
        108.163027
      ],
      [
        64.29666499999995,
        107.620806
      ],
      [
        64.24445099999994,
        107.334415
      ],
      [
        64.51333999999994,
        106.604144
      ],
      [
        64.39917399999995,
        106.157199
      ],
      [
        64.42610399999994,
        106.064412
      ],
      [
        64.47471799999994,
        105.801923
      ],
      [
        64.57695199999993,
        105.722479
      ],
      [
        64.66471899999993,
        105.708869
      ],
      [
        64.65805099999993,
        105.877188
      ],
      [
        64.79749999999993,
        105.85219500000001
      ],
      [
        64.81806799999993,
        106.00971200000001
      ],
      [
        64.89500199999992,
        105.949134
      ],
      [
        64.85722199999992,
        106.223303
      ],
      [
        65.02805999999993,
        105.98247500000001
      ],
      [
        65.30888099999993,
        106.44219100000001
      ],
      [
        65.49943299999993,
        106.96526200000001
      ],
      [
        65.67165899999992,
        106.41831100000002
      ],
      [
        66.00472799999991,
        106.48886700000001
      ],
      [
        66.18859599999992,
        106.11746900000001
      ],
      [
        66.37804799999992,
        106.10079900000001
      ],
      [
        66.47555099999992,
        106.326919
      ],
      [
        66.79833599999992,
        106.195244
      ],
      [
        66.89527399999992,
        106.068323
      ],
      [
        67.01833599999992,
        105.52082200000001
      ],
      [
        67.34888599999992,
        106.804421
      ],
      [
        68.86026799999992,
        106.890816
      ],
      [
        69.41971499999993,
        106.136652
      ],
      [
        69.57250099999993,
        106.454705
      ],
      [
        69.51361799999994,
        106.81469
      ],
      [
        69.67499499999994,
        107.902199
      ],
      [
        69.85443799999994,
        108.23664199999999
      ],
      [
        69.77165999999994,
        109.22608199999999
      ],
      [
        70.03499599999994,
        109.54386199999999
      ],
      [
        70.22443399999993,
        109.328042
      ],
      [
        70.38500299999993,
        109.641655
      ],
      [
        70.43415099999993,
        110.298857
      ],
      [
        70.48305499999992,
        110.134979
      ],
      [
        70.63611599999992,
        110.123589
      ],
      [
        70.85526199999991,
        111.362481
      ],
      [
        71.06165199999991,
        112.07747
      ],
      [
        71.00278299999991,
        112.268876
      ],
      [
        71.16388499999991,
        112.52191900000001
      ],
      [
        71.24359699999991,
        112.72579200000001
      ],
      [
        71.40694299999991,
        112.01108500000001
      ],
      [
        72.13333599999991,
        112.01219900000001
      ],
      [
        72.14499299999991,
        111.77497900000002
      ],
      [
        72.36775599999991,
        111.09720800000001
      ],
      [
        72.51277599999992,
        111.31553000000001
      ],
      [
        72.67192399999992,
        110.69330800000002
      ],
      [
        72.79165799999991,
        110.63192100000002
      ],
      [
        72.89553899999991,
        110.64664500000002
      ],
      [
        72.99026499999991,
        110.77192000000002
      ],
      [
        73.03887899999991,
        110.94164200000002
      ],
      [
        73.17303499999991,
        110.79775300000001
      ],
      [
        73.18997099999991,
        110.42302000000001
      ],
      [
        73.31944099999991,
        110.56666500000001
      ],
      [
        73.40247799999992,
        110.16442200000002
      ],
      [
        73.40636799999992,
        110.56414100000002
      ],
      [
        73.62330099999991,
        111.15276300000002
      ],
      [
        73.71491399999991,
        110.84328400000003
      ],
      [
        73.43913499999991,
        109.82463000000003
      ],
      [
        73.46310599999991,
        109.23160600000003
      ],
      [
        73.39588299999991,
        109.28826900000003
      ],
      [
        73.25582999999992,
        108.17168400000003
      ],
      [
        73.25544199999992,
        108.57182200000003
      ],
      [
        73.22441399999991,
        108.40776800000003
      ],
      [
        73.0911359999999,
        108.51407500000003
      ],
      [
        73.2266859999999,
        108.10726800000003
      ],
      [
        73.1773559999999,
        106.36588200000003
      ],
      [
        73.0929969999999,
        106.20015600000002
      ],
      [
        72.9660759999999,
        106.21987800000002
      ],
      [
        72.83233199999991,
        105.98357400000002
      ],
      [
        72.78310899999991,
        105.25568500000001
      ],
      [
        72.88138199999992,
        105.72221400000001
      ],
      [
        73.25927299999992,
        106.12968400000001
      ],
      [
        73.30990999999992,
        106.78423900000001
      ],
      [
        73.51558199999992,
        107.07296600000001
      ],
      [
        73.64994299999992,
        106.13432300000001
      ],
      [
        73.66604899999992,
        108.21227100000002
      ],
      [
        73.75557999999991,
        108.41882200000002
      ],
      [
        73.82357999999991,
        108.30343600000002
      ],
      [
        73.78643999999991,
        108.53127200000002
      ],
      [
        74.27585799999991,
        109.93882600000002
      ],
      [
        74.28174799999991,
        109.37032300000001
      ],
      [
        74.34651999999991,
        110.04554700000001
      ],
      [
        74.44612899999991,
        110.42310200000001
      ],
      [
        74.49801599999991,
        110.20040800000001
      ],
      [
        74.6524109999999,
        111.79623900000001
      ],
      [
        74.8841539999999,
        112.13343600000002
      ],
      [
        74.94234999999989,
        112.76957500000002
      ],
      [
        75.21037799999989,
        113.50971000000001
      ],
      [
        75.37118299999989,
        113.69285300000001
      ],
      [
        75.38479299999989,
        113.52401500000002
      ],
      [
        75.43190299999989,
        113.74763300000002
      ],
      [
        75.53501399999989,
        113.34360400000003
      ],
      [
        75.6628199999999,
        113.26718900000003
      ],
      [
        75.5476239999999,
        112.78385200000002
      ],
      [
        75.6336829999999,
        112.86752400000003
      ],
      [
        75.69795999999991,
        112.60293700000003
      ],
      [
        75.73950999999991,
        112.83866300000003
      ],
      [
        75.85590299999991,
        112.24788100000002
      ],
      [
        75.70412399999991,
        113.13743700000002
      ],
      [
        75.65000799999991,
        113.60896400000001
      ],
      [
        75.55090399999992,
        113.38085200000002
      ],
      [
        75.53553799999992,
        113.53877300000002
      ],
      [
        75.85412499999991,
        113.91043800000001
      ]
    ],
    "1": [
      [
        78.539474,
        105.389221
      ],
      [
        78.794472,
        105.20439200000001
      ],
      [
        78.846358,
        104.85592000000001
      ],
      [
        78.734107,
        104.586588
      ],
      [
        78.808386,
        104.65953300000001
      ],
      [
        78.851163,
        104.08559000000001
      ],
      [
        78.859692,
        104.695277
      ],
      [
        78.985912,
        104.06683600000001
      ],
      [
        79.112216,
        104.06019900000001
      ],
      [
        79.160379,
        103.87002900000002
      ],
      [
        79.037272,
        102.98439300000001
      ],
      [
        78.832493,
        102.77458600000001
      ],
      [
        78.779637,
        102.46539000000001
      ],
      [
        79.19476499999999,
        102.93211900000001
      ],
      [
        79.19476499999999,
        101.38805900000001
      ],
      [
        79.12966499999999,
        101.27959200000001
      ],
      [
        79.10032999999999,
        101.504338
      ],
      [
        79.03197099999998,
        101.116781
      ],
      [
        78.95022199999998,
        101.665363
      ],
      [
        78.99502099999998,
        101.073392
      ],
      [
        78.84015999999998,
        100.869115
      ],
      [
        78.73555399999998,
        101.279142
      ],
      [
        78.78852399999998,
        100.698561
      ],
      [
        78.60424399999998,
        100.388533
      ],
      [
        78.59774499999997,
        100.647032
      ],
      [
        78.55555499999997,
        100.340537
      ],
      [
        78.34183299999997,
        100.166648
      ],
      [
        78.02908199999996,
        99.492257
      ],
      [
        77.94921799999996,
        100.09181199999999
      ],
      [
        78.21563499999996,
        101.58600499999999
      ],
      [
        78.14754999999997,
        102.78775599999999
      ],
      [
        78.30463999999996,
        104.78052999999998
      ],
      [
        78.53105599999996,
        105.38639499999998
      ]
    ],
    "2": [
      [
        74.009476,
        111.589165
      ],
      [
        74.037665,
        111.23024699999999
      ],
      [
        73.89753,
        110.98402399999999
      ],
      [
        74.00228,
        109.989083
      ],
      [
        73.806442,
        109.52600199999999
      ],
      [
        73.651802,
        109.74830599999999
      ],
      [
        73.683524,
        110.27355199999998
      ],
      [
        73.85923600000001,
        111.29341899999999
      ],
      [
        73.972081,
        111.21002999999999
      ],
      [
        74.005689,
        111.57575099999998
      ]
    ],
    "3": [
      [
        72.745781,
        79.559082
      ],
      [
        72.92038699999999,
        79.508026
      ],
      [
        73.08161199999999,
        79.169084
      ],
      [
        73.09825,
        79.271363
      ],
      [
        72.83341899999999,
        78.60708799999999
      ],
      [
        72.74044799999999,
        79.54750299999999
      ]
    ],
    "4": [
      [
        76.232475,
        96.694389
      ],
      [
        76.310836,
        95.883469
      ],
      [
        76.22485999999999,
        95.312691
      ],
      [
        76.09833499999999,
        96.338218
      ],
      [
        76.16258099999999,
        96.471801
      ],
      [
        76.205694,
        96.257697
      ],
      [
        76.250166,
        96.39494099999999
      ],
      [
        76.2628,
        96.482053
      ],
      [
        76.127219,
        96.52688199999999
      ],
      [
        76.217246,
        96.66527199999999
      ]
    ],
    "5": [
      [
        77.136055,
        96.583443
      ],
      [
        76.977501,
        95.301613
      ],
      [
        77.008719,
        96.021636
      ],
      [
        76.923279,
        95.78214200000001
      ],
      [
        77.10544499999999,
        96.546141
      ]
    ],
    "6": [
      [
        70.361641,
        82.932915
      ],
      [
        70.58883700000001,
        82.44899699999999
      ],
      [
        70.54786000000001,
        82.244835
      ],
      [
        70.42228000000001,
        82.230447
      ],
      [
        70.25694400000002,
        82.567665
      ],
      [
        70.35688800000001,
        82.92461300000001
      ]
    ],
    "7": [
      [
        75.323471,
        82.318222
      ],
      [
        75.45169,
        81.89700300000001
      ],
      [
        75.390113,
        82.07389
      ],
      [
        75.326309,
        81.591666
      ],
      [
        75.103166,
        82.046194
      ],
      [
        75.159082,
        82.148527
      ],
      [
        75.33736499999999,
        81.622024
      ],
      [
        75.30339299999999,
        81.914137
      ],
      [
        75.15814499999999,
        82.019414
      ],
      [
        75.32255799999999,
        82.03819
      ],
      [
        75.31903299999999,
        82.313244
      ]
    ],
    "8": [
      [
        70.456718,
        83.052635
      ],
      [
        70.763251,
        82.62024799999999
      ],
      [
        70.66300199999999,
        82.44063799999999
      ],
      [
        70.452164,
        82.69578
      ],
      [
        70.453307,
        83.052224
      ]
    ],
    "9": [
      [
        76.520386,
        112.779976
      ],
      [
        76.61436400000001,
        112.429254
      ],
      [
        76.570609,
        112.689477
      ],
      [
        76.631079,
        112.56575099999999
      ],
      [
        76.609611,
        112.018892
      ],
      [
        76.439613,
        112.452028
      ],
      [
        76.51233599999999,
        112.768304
      ]
    ],
    "10": [
      [
        74.943726,
        87.197166
      ],
      [
        74.894166,
        86.66510799999999
      ],
      [
        74.887087,
        86.23674799999999
      ],
      [
        74.80552899999999,
        86.898636
      ],
      [
        74.83924999999999,
        87.00003
      ],
      [
        74.903527,
        86.720558
      ],
      [
        74.927949,
        87.190665
      ]
    ],
    "11": [
      [
        70.785751,
        83.14328
      ],
      [
        70.892387,
        82.965225
      ],
      [
        70.76664099999999,
        82.97380700000001
      ],
      [
        70.94305499999999,
        82.784362
      ],
      [
        70.76055999999998,
        82.652725
      ],
      [
        70.54550399999998,
        82.933807
      ],
      [
        70.68591399999998,
        82.89933
      ],
      [
        70.76689099999999,
        83.139778
      ]
    ],
    "12": [
      [
        77.26992,
        89.742447
      ],
      [
        77.202111,
        89.219476
      ],
      [
        77.234582,
        89.726251
      ],
      [
        77.265724,
        89.741555
      ]
    ],
    "13": [
      [
        78.283531,
        106.808304
      ],
      [
        78.22822699999999,
        106.127892
      ],
      [
        78.26561099999999,
        106.80014
      ]
    ],
    "14": [
      [
        74.522942,
        86.250832
      ],
      [
        74.59539,
        85.969307
      ],
      [
        74.480858,
        85.653665
      ],
      [
        74.491973,
        86.04628000000001
      ],
      [
        74.520858,
        86.24741300000001
      ]
    ],
    "15": [
      [
        77.306946,
        107.75592
      ],
      [
        77.34011,
        107.387726
      ],
      [
        77.275002,
        107.290527
      ],
      [
        77.255837,
        107.481308
      ],
      [
        77.219361,
        107.208969
      ],
      [
        77.302002,
        107.752892
      ]
    ],
    "16": [
      [
        76.089973,
        97.369026
      ],
      [
        75.99472,
        96.714302
      ],
      [
        76.085808,
        97.363113
      ]
    ],
    "17": [
      [
        76.285698,
        97.11586
      ],
      [
        76.34088899999999,
        96.834724
      ],
      [
        76.30933499999999,
        96.927307
      ],
      [
        76.176583,
        96.665001
      ],
      [
        76.184861,
        96.740448
      ],
      [
        76.276971,
        97.110862
      ]
    ],
    "18": [
      [
        76.685585,
        96.457802
      ],
      [
        76.59108,
        95.88147000000001
      ],
      [
        76.677498,
        96.451919
      ]
    ],
    "19": [
      [
        76.980331,
        89.14225
      ],
      [
        77.04972000000001,
        88.761948
      ],
      [
        77.09530500000001,
        88.741776
      ],
      [
        77.12977400000001,
        89.02108
      ],
      [
        77.10163800000001,
        88.705254
      ],
      [
        76.97697300000002,
        89.133697
      ]
    ],
    "20": [
      [
        69.874557,
        83.486443
      ],
      [
        70.07855099999999,
        82.88169099999999
      ],
      [
        69.872474,
        83.486167
      ]
    ],
    "21": [
      [
        74.722641,
        85.699669
      ],
      [
        74.752892,
        85.10592
      ],
      [
        74.717087,
        85.68972
      ]
    ],
    "22": [
      [
        74.498474,
        84.978996
      ],
      [
        74.452027,
        84.45231
      ],
      [
        74.46322,
        84.55980699999999
      ],
      [
        74.476082,
        84.95138399999999
      ]
    ],
    "23": [
      [
        69.748665,
        83.837723
      ],
      [
        69.829086,
        83.48191899999999
      ],
      [
        69.74575,
        83.83075099999999
      ]
    ],
    "24": [
      [
        77.465363,
        106.954414
      ],
      [
        77.38383499999999,
        106.504524
      ],
      [
        77.45383399999999,
        106.938529
      ]
    ],
    "25": [
      [
        75.860054,
        82.386086
      ],
      [
        75.924225,
        81.65544200000001
      ],
      [
        75.852776,
        82.38444500000001
      ]
    ],
    "26": [
      [
        76.272331,
        95.07589
      ],
      [
        76.19577799999999,
        94.44989
      ],
      [
        76.267974,
        95.0745
      ]
    ],
    "27": [
      [
        70.455696,
        83.309448
      ],
      [
        70.518387,
        83.107583
      ],
      [
        70.37041500000001,
        83.063699
      ],
      [
        70.44924800000001,
        83.303865
      ]
    ],
    "28": [
      [
        70.211807,
        82.595528
      ],
      [
        70.32489,
        82.35717100000001
      ],
      [
        70.328087,
        82.28538700000001
      ],
      [
        70.190721,
        82.56969600000001
      ]
    ],
    "29": [
      [
        70.63147,
        83.314445
      ],
      [
        70.699531,
        83.18391500000001
      ],
      [
        70.529693,
        83.11197100000001
      ],
      [
        70.61711,
        83.31233300000001
      ]
    ],
    "30": [
      [
        70.737251,
        83.450333
      ],
      [
        70.704392,
        83.213914
      ],
      [
        70.687471,
        83.44555700000001
      ]
    ],
    "31": [
      [
        75.523109,
        82.280281
      ],
      [
        75.48372,
        81.896835
      ],
      [
        75.453057,
        81.97791199999999
      ],
      [
        75.412638,
        82.163412
      ],
      [
        75.515505,
        82.27438799999999
      ]
    ],
    "32": [
      [
        77.474586,
        82.676888
      ],
      [
        77.52769500000001,
        82.42408
      ],
      [
        77.482391,
        82.62117
      ],
      [
        77.51261000000001,
        82.233665
      ],
      [
        77.47008500000001,
        82.66233
      ]
    ],
    "33": [
      [
        70.759247,
        83.147667
      ],
      [
        70.673248,
        82.908447
      ],
      [
        70.563362,
        83.03639199999999
      ],
      [
        70.727997,
        83.14488899999999
      ]
    ],
    "34": [
      [
        76.587082,
        97.653358
      ],
      [
        76.67783299999999,
        97.322221
      ],
      [
        76.650414,
        97.463501
      ],
      [
        76.584695,
        97.34642
      ],
      [
        76.584086,
        97.64894
      ]
    ],
    "35": [
      [
        70.158752,
        83.118858
      ],
      [
        70.230079,
        82.90992
      ],
      [
        70.111275,
        82.939551
      ],
      [
        70.14900200000001,
        83.11641499999999
      ]
    ],
    "36": [
      [
        76.565636,
        94.457832
      ],
      [
        76.59597,
        93.939751
      ],
      [
        76.55982999999999,
        94.454247
      ]
    ],
    "37": [
      [
        74.140472,
        85.749359
      ],
      [
        74.18744600000001,
        85.352913
      ],
      [
        74.14785800000001,
        85.59011
      ],
      [
        74.13633000000002,
        85.743635
      ]
    ],
    "38": [
      [
        77.614754,
        92.09433
      ],
      [
        77.637168,
        91.858498
      ],
      [
        77.594109,
        92.040802
      ]
    ],
    "39": [
      [
        77.268639,
        105.996864
      ],
      [
        77.25039,
        105.790253
      ],
      [
        77.23942,
        105.90125200000001
      ]
    ],
    "40": [
      [
        70.885445,
        83.248558
      ],
      [
        70.957527,
        83.03344700000001
      ],
      [
        70.869026,
        83.22763700000002
      ]
    ],
    "41": [
      [
        70.171722,
        82.655746
      ],
      [
        70.398635,
        82.28299799999999
      ],
      [
        70.165138,
        82.64771999999999
      ]
    ],
    "42": [
      [
        69.905113,
        83.444725
      ],
      [
        70.053748,
        83.08327600000001
      ],
      [
        69.902694,
        83.44191800000002
      ]
    ],
    "43": [
      [
        70.936806,
        82.766281
      ],
      [
        70.775582,
        82.496362
      ],
      [
        70.937416,
        82.748277
      ]
    ],
    "44": [
      [
        74.865082,
        85.961777
      ],
      [
        74.793861,
        85.74844399999999
      ],
      [
        74.858581,
        85.95880799999999
      ]
    ],
    "45": [
      [
        70.562111,
        83.588142
      ],
      [
        70.530777,
        83.36564
      ],
      [
        70.45858,
        83.497223
      ],
      [
        70.555778,
        83.585915
      ]
    ],
    "46": [
      [
        73.892441,
        86.617027
      ],
      [
        73.96328000000001,
        86.29247299999999
      ],
      [
        73.89080900000002,
        86.59255099999999
      ]
    ],
    "47": [
      [
        70.485001,
        83.648224
      ],
      [
        70.389557,
        83.397111
      ],
      [
        70.47505199999999,
        83.626893
      ]
    ],
    "48": [
      [
        78.15992,
        93.804863
      ],
      [
        78.201584,
        93.641809
      ],
      [
        78.155168,
        93.786087
      ]
    ],
    "49": [
      [
        79.043724,
        92.870499
      ],
      [
        79.11172499999999,
        92.289276
      ],
      [
        79.04366399999999,
        92.848831
      ]
    ],
    "50": [
      [
        70.417557,
        83.666191
      ],
      [
        70.350525,
        83.41046899999999
      ],
      [
        70.41433,
        83.64319499999999
      ]
    ],
    "51": [
      [
        74.578667,
        85.132774
      ],
      [
        74.535973,
        84.809609
      ],
      [
        74.573364,
        85.127281
      ]
    ],
    "52": [
      [
        70.332947,
        82.333611
      ],
      [
        70.478362,
        82.2005
      ],
      [
        70.333748,
        82.308051
      ]
    ],
    "53": [
      [
        76.713974,
        94.607391
      ],
      [
        76.74816899999999,
        94.43552500000001
      ],
      [
        76.70705399999999,
        94.58422200000001
      ]
    ],
    "54": [
      [
        73.989052,
        86.934776
      ],
      [
        74.022277,
        86.784249
      ],
      [
        73.98219300000001,
        86.929337
      ]
    ],
    "55": [
      [
        75.46917,
        81.860863
      ],
      [
        75.419587,
        81.68577599999999
      ],
      [
        75.46469200000001,
        81.85883299999999
      ]
    ],
    "56": [
      [
        76.466141,
        95.147087
      ],
      [
        76.42247099999999,
        94.97036
      ],
      [
        76.45216399999998,
        95.109947
      ]
    ],
    "57": [
      [
        76.732109,
        111.442947
      ],
      [
        76.77680199999999,
        111.10961300000001
      ],
      [
        76.72983699999999,
        111.431863
      ]
    ],
    "58": [
      [
        75.200668,
        88.285446
      ],
      [
        75.16263599999999,
        88.133637
      ],
      [
        75.198112,
        88.28106
      ]
    ],
    "59": [
      [
        79.033081,
        104.411552
      ],
      [
        79.005608,
        104.161415
      ],
      [
        79.02774799999999,
        104.401031
      ]
    ],
    "60": [
      [
        72.381805,
        80.879807
      ],
      [
        72.432555,
        80.745194
      ],
      [
        72.37533499999999,
        80.877442
      ]
    ],
    "61": [
      [
        69.868805,
        83.352806
      ],
      [
        69.928276,
        83.202278
      ],
      [
        69.867668,
        83.347114
      ]
    ],
    "62": [
      [
        76.411026,
        113.421669
      ],
      [
        76.445357,
        113.28141799999999
      ],
      [
        76.40894300000001,
        113.41263599999999
      ]
    ]
  },
  "Иркутская область": {
    "0": [
      [
        58.458885,
        119.135536
      ],
      [
        58.612494000000005,
        118.763046
      ],
      [
        58.81471200000001,
        118.896942
      ],
      [
        58.88749500000001,
        118.786653
      ],
      [
        59.014410000000005,
        118.847795
      ],
      [
        59.065541,
        118.702478
      ],
      [
        59.213047,
        118.701394
      ],
      [
        59.286105000000006,
        118.851379
      ],
      [
        59.40998200000001,
        118.75582899999999
      ],
      [
        59.61054300000001,
        118.28359999999999
      ],
      [
        59.441652000000005,
        117.883614
      ],
      [
        59.527764000000005,
        117.236099
      ],
      [
        59.58720400000001,
        117.08442699999999
      ],
      [
        59.82942800000001,
        117.227477
      ],
      [
        59.90887200000001,
        117.05721199999999
      ],
      [
        60.01942900000001,
        117.297491
      ],
      [
        60.03581600000001,
        117.04859699999999
      ],
      [
        60.35720400000001,
        116.60080499999998
      ],
      [
        60.52664500000001,
        115.68609399999998
      ],
      [
        60.42970700000001,
        115.18914599999998
      ],
      [
        60.21997600000001,
        114.69663799999998
      ],
      [
        59.74970900000001,
        114.17302499999998
      ],
      [
        59.57637700000001,
        113.60247599999998
      ],
      [
        59.37664900000001,
        113.42109599999998
      ],
      [
        59.260270000000006,
        113.46304199999997
      ],
      [
        59.071679,
        112.57421899999997
      ],
      [
        58.871369,
        112.51998999999996
      ],
      [
        59.124985,
        112.44970899999997
      ],
      [
        59.310814,
        112.63220299999998
      ],
      [
        59.303039,
        112.28386899999998
      ],
      [
        59.504431,
        112.27388299999998
      ],
      [
        59.206090999999994,
        111.64610699999999
      ],
      [
        59.18331899999999,
        111.18221699999998
      ],
      [
        59.25998699999999,
        110.70139699999999
      ],
      [
        59.036934999999986,
        110.49971499999998
      ],
      [
        58.97415299999999,
        110.18193699999998
      ],
      [
        59.06165399999999,
        109.64055599999998
      ],
      [
        59.29193199999999,
        109.51667099999997
      ],
      [
        59.31693299999999,
        109.26054399999997
      ],
      [
        59.416656999999994,
        109.35582799999997
      ],
      [
        59.45109599999999,
        109.25943799999997
      ],
      [
        59.645537999999995,
        109.53387499999998
      ],
      [
        59.750265999999996,
        109.49472199999998
      ],
      [
        60.028867,
        109.76944899999998
      ],
      [
        60.226085999999995,
        109.72638799999999
      ],
      [
        60.579708,
        110.11776099999999
      ],
      [
        60.669146999999995,
        110.03998699999998
      ],
      [
        60.691919999999996,
        110.26776899999999
      ],
      [
        60.819145999999996,
        110.22998899999999
      ],
      [
        61.138588999999996,
        110.5075
      ],
      [
        61.327203,
        109.787499
      ],
      [
        61.533317999999994,
        109.874161
      ],
      [
        61.82330999999999,
        109.586388
      ],
      [
        62.287755999999995,
        110.000823
      ],
      [
        62.410802,
        109.913887
      ],
      [
        62.518581999999995,
        109.266945
      ],
      [
        62.824420999999994,
        109.65055100000001
      ],
      [
        62.933856999999996,
        109.468332
      ],
      [
        63.361917999999996,
        109.36304700000001
      ],
      [
        63.55414,
        109.04333100000001
      ],
      [
        63.572474,
        108.128048
      ],
      [
        63.808022,
        108.288601
      ],
      [
        63.864693,
        108.771938
      ],
      [
        64.114677,
        108.48997800000001
      ],
      [
        64.23773800000001,
        108.53805100000001
      ],
      [
        64.299125,
        108.346112
      ],
      [
        64.223442,
        108.026555
      ],
      [
        64.20830500000001,
        108.221112
      ],
      [
        63.99080700000001,
        108.261943
      ],
      [
        63.98996900000001,
        107.679164
      ],
      [
        63.85692000000001,
        107.56277
      ],
      [
        63.98552800000001,
        106.702778
      ],
      [
        63.84413200000001,
        106.79722199999999
      ],
      [
        63.69830400000001,
        106.628322
      ],
      [
        63.491640000000004,
        106.50695499999999
      ],
      [
        63.368579000000004,
        106.73637799999999
      ],
      [
        63.331088,
        106.44415099999999
      ],
      [
        63.110255,
        106.40472199999999
      ],
      [
        63.069697000000005,
        106.12193099999999
      ],
      [
        62.896084,
        106.21609899999999
      ],
      [
        62.843587,
        106.44471399999999
      ],
      [
        62.681646,
        106.46915899999999
      ],
      [
        62.599699,
        106.81443399999999
      ],
      [
        62.477195,
        106.71444299999999
      ],
      [
        62.317199,
        106.47916699999999
      ],
      [
        62.261924,
        106.31916499999998
      ],
      [
        62.241645,
        106.44775699999998
      ],
      [
        62.030808,
        106.41666799999999
      ],
      [
        61.84359,
        105.91278599999998
      ],
      [
        61.683313,
        105.94915399999998
      ],
      [
        61.617753,
        105.80276999999998
      ],
      [
        61.419978,
        105.27583199999998
      ],
      [
        61.349413,
        104.78500199999998
      ],
      [
        61.177476999999996,
        105.01194499999998
      ],
      [
        61.07442699999999,
        104.61471299999998
      ],
      [
        60.89387099999999,
        104.63444199999998
      ],
      [
        60.83970199999999,
        104.45695199999997
      ],
      [
        60.743312999999986,
        104.60221399999998
      ],
      [
        60.63332099999999,
        104.51667399999998
      ],
      [
        60.40247999999999,
        104.65388099999998
      ],
      [
        60.25443199999999,
        105.47721399999999
      ],
      [
        59.84553599999999,
        105.495257
      ],
      [
        59.83720499999999,
        105.019161
      ],
      [
        59.69331499999999,
        104.794445
      ],
      [
        59.46305299999999,
        104.975551
      ],
      [
        59.46943199999999,
        105.18943399999999
      ],
      [
        59.09498999999999,
        105.36277299999999
      ],
      [
        58.89442799999999,
        104.83860499999999
      ],
      [
        58.81525899999999,
        104.75584099999999
      ],
      [
        58.66859199999999,
        104.79499399999999
      ],
      [
        58.600202999999986,
        104.44639299999999
      ],
      [
        58.758656999999985,
        104.23640999999999
      ],
      [
        58.73498399999998,
        103.85583399999999
      ],
      [
        58.84860099999998,
        103.95417599999999
      ],
      [
        59.02137599999998,
        103.38917299999999
      ],
      [
        59.17943299999998,
        103.24444499999998
      ],
      [
        59.26082199999998,
        103.32640599999999
      ],
      [
        59.321933999999985,
        103.01556299999999
      ],
      [
        59.19165499999998,
        102.53527699999998
      ],
      [
        59.07249199999998,
        102.40444099999998
      ],
      [
        58.716656999999984,
        102.51139699999997
      ],
      [
        58.41888199999998,
        101.68499699999997
      ],
      [
        58.11527199999998,
        101.10972699999996
      ],
      [
        57.89804799999998,
        101.03527999999996
      ],
      [
        57.88610799999998,
        100.80776499999996
      ],
      [
        57.77360599999998,
        100.89111599999995
      ],
      [
        57.66832899999998,
        100.79696199999995
      ],
      [
        57.50083499999998,
        100.96278699999995
      ],
      [
        57.40193599999998,
        100.74194699999995
      ],
      [
        57.548052999999975,
        100.40360699999995
      ],
      [
        57.86554999999998,
        100.12305199999994
      ],
      [
        58.07943899999998,
        100.04416399999994
      ],
      [
        58.08110899999998,
        99.68195099999994
      ],
      [
        57.79332899999998,
        99.42166599999995
      ],
      [
        57.72165999999998,
        98.97417199999994
      ],
      [
        57.806939999999976,
        97.91722699999994
      ],
      [
        57.043177999999976,
        97.34010999999994
      ],
      [
        56.95527299999998,
        97.62305299999994
      ],
      [
        56.81027699999998,
        97.48583099999995
      ],
      [
        56.78055399999998,
        97.77611499999995
      ],
      [
        56.55860799999998,
        97.74500199999994
      ],
      [
        56.55943999999998,
        97.87944599999994
      ],
      [
        56.39250199999998,
        97.88194699999994
      ],
      [
        56.38750599999998,
        97.55666199999995
      ],
      [
        56.18943899999998,
        97.58694899999995
      ],
      [
        56.04861699999998,
        97.35222299999995
      ],
      [
        56.11639599999998,
        97.12361699999995
      ],
      [
        55.779170999999984,
        96.77251199999995
      ],
      [
        55.32472699999998,
        96.92027899999995
      ],
      [
        55.25528499999998,
        96.70056699999995
      ],
      [
        55.02028499999998,
        96.57779499999995
      ],
      [
        54.92139399999998,
        96.71529199999995
      ],
      [
        54.51251199999998,
        96.57085299999994
      ],
      [
        54.57611799999998,
        96.05583999999995
      ],
      [
        54.28195299999998,
        95.65334299999995
      ],
      [
        53.64556199999998,
        96.57167699999995
      ],
      [
        53.72888999999998,
        96.88667999999996
      ],
      [
        53.60001399999998,
        97.33223599999995
      ],
      [
        53.37417699999998,
        97.59223099999996
      ],
      [
        53.227226999999985,
        98.28583399999995
      ],
      [
        53.089730999999986,
        98.35194999999995
      ],
      [
        53.15389299999999,
        98.92361899999995
      ],
      [
        53.10250999999999,
        99.06528199999994
      ],
      [
        52.95379899999999,
        99.24998899999994
      ],
      [
        53.11222999999999,
        99.36972499999995
      ],
      [
        53.29640299999999,
        99.86194199999994
      ],
      [
        53.37611499999999,
        100.26556799999994
      ],
      [
        52.93944599999999,
        100.60306199999995
      ],
      [
        52.63473699999999,
        101.43777799999995
      ],
      [
        52.22445099999999,
        101.94610899999995
      ],
      [
        52.23417699999999,
        102.55943599999995
      ],
      [
        51.985283999999986,
        102.76972399999995
      ],
      [
        51.812508999999984,
        103.21112999999995
      ],
      [
        51.61251199999998,
        103.17666799999995
      ],
      [
        51.44000499999998,
        103.32084799999996
      ],
      [
        51.41147899999998,
        103.81388999999996
      ],
      [
        51.13187799999998,
        103.81381499999996
      ],
      [
        51.26845899999998,
        104.60943899999997
      ],
      [
        51.62102799999998,
        104.63600299999996
      ],
      [
        51.85251099999998,
        105.43998899999995
      ],
      [
        52.44000499999998,
        106.19194999999995
      ],
      [
        53.18695199999998,
        108.04055999999994
      ],
      [
        54.42056399999998,
        109.00556399999994
      ],
      [
        54.49000599999998,
        109.05027799999993
      ],
      [
        54.54306899999998,
        108.55804599999993
      ],
      [
        54.66945599999998,
        108.69638999999994
      ],
      [
        55.16444999999998,
        108.78611899999994
      ],
      [
        55.45167299999998,
        108.61749499999995
      ],
      [
        55.86694799999998,
        108.85360899999995
      ],
      [
        55.96416899999998,
        109.25834799999996
      ],
      [
        56.288616999999974,
        108.56639999999996
      ],
      [
        56.58388899999997,
        108.69860899999996
      ],
      [
        56.70361699999997,
        109.76777599999996
      ],
      [
        56.97972299999997,
        110.08250299999996
      ],
      [
        56.80750599999997,
        111.15249499999996
      ],
      [
        56.89500699999997,
        111.37165599999996
      ],
      [
        57.10750699999997,
        111.58555399999996
      ],
      [
        56.90194099999997,
        111.82471999999996
      ],
      [
        56.861115999999974,
        112.44054799999995
      ],
      [
        56.961112999999976,
        112.66250199999995
      ],
      [
        56.68527399999998,
        112.90387999999994
      ],
      [
        56.68194799999998,
        113.51111699999994
      ],
      [
        56.524446999999974,
        113.90082699999994
      ],
      [
        56.692772999999974,
        114.17221999999994
      ],
      [
        56.69361199999997,
        114.77304899999994
      ],
      [
        57.122771999999976,
        115.08415999999994
      ],
      [
        57.10333299999998,
        115.53082999999994
      ],
      [
        57.24583499999998,
        115.86276899999994
      ],
      [
        57.12686299999998,
        116.27263599999995
      ],
      [
        56.95656099999998,
        115.73680899999995
      ],
      [
        56.81222099999998,
        116.12110099999995
      ],
      [
        56.72388899999998,
        116.80721999999996
      ],
      [
        56.94861299999998,
        117.33110599999996
      ],
      [
        56.81695199999998,
        117.46054699999996
      ],
      [
        56.97471999999998,
        117.67442899999996
      ],
      [
        57.153329999999976,
        117.59694599999996
      ],
      [
        57.293335999999975,
        117.79721699999996
      ],
      [
        57.25444999999998,
        117.43137999999996
      ],
      [
        57.639725999999975,
        117.13777799999995
      ],
      [
        57.80416899999997,
        117.08443399999996
      ],
      [
        57.906661999999976,
        117.41748599999995
      ],
      [
        58.12721999999997,
        117.40749999999996
      ],
      [
        58.18555299999997,
        117.59055899999996
      ],
      [
        58.31361099999997,
        117.47916299999996
      ],
      [
        58.381381999999974,
        118.20833499999996
      ],
      [
        58.169163999999974,
        118.67221799999996
      ],
      [
        58.22267599999997,
        119.10838899999996
      ],
      [
        58.43971699999997,
        119.13388599999996
      ]
    ]
  },
  "Забайкальский край": {
    "0": [
      [
        54.450272,
        122.140549
      ],
      [
        54.572494,
        121.92221199999999
      ],
      [
        54.803877,
        121.90081899999998
      ],
      [
        54.741377,
        121.65138299999998
      ],
      [
        55.185545,
        122.02915999999998
      ],
      [
        55.608044,
        121.89721699999997
      ],
      [
        55.509993,
        121.33527399999997
      ],
      [
        55.808598,
        121.36109799999997
      ],
      [
        56.020542000000006,
        121.20525999999997
      ],
      [
        55.911655,
        120.12831099999997
      ],
      [
        56.135265000000004,
        120.05497799999996
      ],
      [
        56.287212000000004,
        120.53858999999996
      ],
      [
        56.489428000000004,
        119.96555399999995
      ],
      [
        56.602487,
        119.80637499999996
      ],
      [
        56.692483,
        119.87552599999997
      ],
      [
        56.763877,
        119.66693199999996
      ],
      [
        56.889389,
        119.89861399999997
      ],
      [
        57.028038,
        119.61608999999997
      ],
      [
        57.166655000000006,
        119.69193399999997
      ],
      [
        57.43109700000001,
        119.40054399999997
      ],
      [
        57.59109200000001,
        119.49636899999997
      ],
      [
        57.53776200000001,
        119.13720899999997
      ],
      [
        57.70026000000001,
        119.16638399999997
      ],
      [
        57.75664700000001,
        119.04165899999997
      ],
      [
        58.21359400000001,
        119.12248499999997
      ],
      [
        58.170260000000006,
        118.64749499999996
      ],
      [
        58.37775500000001,
        118.23665299999996
      ],
      [
        58.40052800000001,
        117.67221499999997
      ],
      [
        58.29942400000001,
        117.46666499999996
      ],
      [
        58.18553400000001,
        117.59055699999996
      ],
      [
        58.127201000000014,
        117.40749799999996
      ],
      [
        57.901646000000014,
        117.41331099999996
      ],
      [
        57.810811000000015,
        117.08472199999997
      ],
      [
        57.639707000000016,
        117.13777599999997
      ],
      [
        57.25443100000002,
        117.43137799999998
      ],
      [
        57.293317000000016,
        117.79721499999998
      ],
      [
        57.153311000000016,
        117.59694399999998
      ],
      [
        57.014434000000016,
        117.68887699999998
      ],
      [
        56.84720700000002,
        117.57277299999997
      ],
      [
        56.82582300000002,
        117.41026699999998
      ],
      [
        56.94859500000002,
        117.33110399999998
      ],
      [
        56.723314000000016,
        116.79582699999999
      ],
      [
        56.956544000000015,
        115.73680599999999
      ],
      [
        56.872484000000014,
        115.58638499999999
      ],
      [
        56.644151000000015,
        115.42970899999999
      ],
      [
        56.429667000000016,
        115.79880299999999
      ],
      [
        56.296939000000016,
        115.68414899999999
      ],
      [
        56.149433000000016,
        115.836935
      ],
      [
        56.04110400000002,
        115.75862
      ],
      [
        55.91666200000002,
        115.89804699999999
      ],
      [
        55.39694100000002,
        115.929983
      ],
      [
        55.37360400000002,
        116.376089
      ],
      [
        55.07166400000002,
        116.81303199999999
      ],
      [
        54.79554900000002,
        116.92387199999999
      ],
      [
        54.535830000000026,
        116.72331899999999
      ],
      [
        54.42388500000003,
        115.67083
      ],
      [
        53.99249700000003,
        114.975816
      ],
      [
        53.616437000000026,
        113.814707
      ],
      [
        53.389723000000025,
        113.832217
      ],
      [
        53.19666100000003,
        114.198327
      ],
      [
        53.07194500000003,
        114.12693300000001
      ],
      [
        53.06777900000003,
        114.325281
      ],
      [
        52.96721600000003,
        114.317216
      ],
      [
        52.71833000000003,
        114.123606
      ],
      [
        52.45027900000003,
        113.579174
      ],
      [
        52.34972300000003,
        112.53889
      ],
      [
        52.206947000000035,
        112.402217
      ],
      [
        52.28334000000004,
        112.07471899999999
      ],
      [
        51.92778100000004,
        111.60777099999999
      ],
      [
        51.78222000000004,
        111.01501299999998
      ],
      [
        51.50278700000004,
        110.69610599999999
      ],
      [
        51.61999700000004,
        110.04916399999999
      ],
      [
        51.346668000000044,
        109.379983
      ],
      [
        51.50667000000004,
        109.074174
      ],
      [
        51.50973000000004,
        108.597231
      ],
      [
        51.27083800000004,
        108.352786
      ],
      [
        51.10972900000004,
        108.570848
      ],
      [
        51.04889400000004,
        108.332224
      ],
      [
        50.83395200000004,
        108.251474
      ],
      [
        50.75806300000004,
        108.065554
      ],
      [
        50.50418000000004,
        108.685274
      ],
      [
        50.337226000000044,
        107.985545
      ],
      [
        49.98002200000004,
        107.754046
      ],
      [
        49.92023900000004,
        107.975688
      ],
      [
        49.67679300000004,
        107.945544
      ],
      [
        49.33668200000004,
        108.54340499999999
      ],
      [
        49.36095800000004,
        109.218735
      ],
      [
        49.16490600000004,
        110.22196299999999
      ],
      [
        49.25843000000004,
        110.39949099999998
      ],
      [
        49.14762600000004,
        110.78734699999998
      ],
      [
        49.373489000000035,
        111.37968499999998
      ],
      [
        49.49243100000003,
        112.75487499999998
      ],
      [
        49.61423700000003,
        113.10062299999998
      ],
      [
        49.974790000000034,
        113.49334599999999
      ],
      [
        50.282930000000036,
        114.34906499999998
      ],
      [
        50.19337300000004,
        114.96439799999999
      ],
      [
        49.902347000000034,
        115.400708
      ],
      [
        50.03240600000004,
        116.23392899999999
      ],
      [
        49.505406000000036,
        117.854565
      ],
      [
        49.925739000000036,
        118.562649
      ],
      [
        50.10459800000004,
        119.321872
      ],
      [
        50.33987700000004,
        119.352397
      ],
      [
        50.38476400000004,
        119.116314
      ],
      [
        51.67718000000004,
        120.092541
      ],
      [
        51.928237000000045,
        120.646373
      ],
      [
        52.15840400000005,
        120.778178
      ],
      [
        52.34473600000005,
        120.610201
      ],
      [
        52.54309600000005,
        120.72098
      ],
      [
        52.58407000000005,
        120.06123699999999
      ],
      [
        52.76812400000005,
        120.02428799999998
      ],
      [
        53.28642600000005,
        120.87081399999998
      ],
      [
        53.42041000000005,
        122.02573599999998
      ],
      [
        53.821116000000046,
        121.93055199999998
      ],
      [
        54.014169000000045,
        121.63389099999998
      ],
      [
        54.145006000000045,
        121.76194099999998
      ],
      [
        54.35444700000004,
        121.64888999999998
      ],
      [
        54.44500000000004,
        122.13971899999999
      ]
    ]
  },
  "Камчатский край": {
    "0": [
      [
        61.876656,
        174.513611
      ],
      [
        62.093604,
        174.33136
      ],
      [
        62.073608,
        174.103851
      ],
      [
        62.278327,
        174.00942999999998
      ],
      [
        62.44721,
        174.08190899999997
      ],
      [
        62.538328,
        173.67831499999997
      ],
      [
        62.32833,
        172.84634599999998
      ],
      [
        62.467765,
        172.04135399999998
      ],
      [
        62.309714,
        171.48941299999998
      ],
      [
        62.244155,
        170.85217699999998
      ],
      [
        62.28527,
        170.260258
      ],
      [
        62.542767999999995,
        169.978306
      ],
      [
        62.659153999999994,
        170.092746
      ],
      [
        62.973316999999994,
        169.131901
      ],
      [
        63.082767,
        168.444431
      ],
      [
        63.656091999999994,
        169.59997900000002
      ],
      [
        63.951378999999996,
        169.353305
      ],
      [
        64.023034,
        168.861362
      ],
      [
        64.317483,
        168.50055200000003
      ],
      [
        64.336648,
        167.718846
      ],
      [
        64.628029,
        167.234959
      ],
      [
        64.55192,
        165.85358200000002
      ],
      [
        64.76249,
        165.66998900000002
      ],
      [
        64.853584,
        165.23886700000003
      ],
      [
        64.85219599999999,
        165.02249800000004
      ],
      [
        64.69358199999999,
        165.02136900000005
      ],
      [
        64.683038,
        164.90000200000006
      ],
      [
        64.939691,
        164.18027500000005
      ],
      [
        64.801371,
        163.11579300000005
      ],
      [
        64.715541,
        163.26444400000005
      ],
      [
        64.550808,
        163.16886400000004
      ],
      [
        64.386928,
        163.46607300000005
      ],
      [
        64.180814,
        163.15082800000005
      ],
      [
        64.138594,
        162.77860500000006
      ],
      [
        63.940267,
        162.93055200000006
      ],
      [
        63.815267,
        162.71497600000006
      ],
      [
        63.62638,
        162.97803700000006
      ],
      [
        63.500549,
        162.97053100000005
      ],
      [
        63.387773,
        162.67609700000006
      ],
      [
        63.181109,
        162.76749800000005
      ],
      [
        63.069445,
        162.28999000000005
      ],
      [
        62.91527,
        162.27610500000006
      ],
      [
        62.708049,
        162.82664100000005
      ],
      [
        62.578335,
        162.69166100000004
      ],
      [
        62.295552,
        162.73579000000004
      ],
      [
        62.182219,
        162.15775600000003
      ],
      [
        62.09333,
        162.53831100000002
      ],
      [
        61.971665,
        162.50693800000002
      ],
      [
        61.935556,
        162.30332600000003
      ],
      [
        61.685556999999996,
        162.48026700000003
      ],
      [
        61.587706999999995,
        162.77622600000004
      ],
      [
        61.653983999999994,
        162.63293000000004
      ],
      [
        61.70878799999999,
        162.84565200000006
      ],
      [
        61.64209199999999,
        162.94604000000007
      ],
      [
        61.51064999999999,
        163.04355800000008
      ],
      [
        61.65978899999999,
        163.34118000000007
      ],
      [
        61.80395299999999,
        162.98642900000007
      ],
      [
        62.05661799999999,
        163.20212700000008
      ],
      [
        62.21903499999999,
        163.07805800000008
      ],
      [
        62.33089799999999,
        163.3882370000001
      ],
      [
        62.40736599999999,
        163.1362080000001
      ],
      [
        62.52247799999999,
        163.3057640000001
      ],
      [
        62.685669999999995,
        164.4524310000001
      ],
      [
        62.48683799999999,
        165.24392000000012
      ],
      [
        62.43945099999999,
        164.54100800000012
      ],
      [
        62.27044999999999,
        164.17244800000012
      ],
      [
        61.67897699999999,
        164.06537800000012
      ],
      [
        61.46214699999999,
        163.75756300000012
      ],
      [
        61.314033999999985,
        164.02378200000012
      ],
      [
        61.00425899999998,
        163.50692100000012
      ],
      [
        60.931009999999986,
        163.81247700000011
      ],
      [
        60.851427999999984,
        163.71033600000013
      ],
      [
        60.83236999999998,
        163.79215300000013
      ],
      [
        60.773372999999985,
        163.01009400000012
      ],
      [
        60.48245699999998,
        162.09914500000014
      ],
      [
        60.41579199999998,
        161.95551400000014
      ],
      [
        60.20634599999998,
        161.92783500000013
      ],
      [
        59.62501399999998,
        160.89593000000013
      ],
      [
        59.56981999999998,
        160.60929400000015
      ],
      [
        59.560263999999975,
        160.50478700000014
      ],
      [
        59.13926799999997,
        159.87459900000013
      ],
      [
        58.86998799999997,
        159.73626300000012
      ],
      [
        58.43304599999997,
        159.08553700000013
      ],
      [
        58.01762899999997,
        158.21876200000014
      ],
      [
        58.01285099999997,
        157.67815800000014
      ],
      [
        57.79598999999997,
        157.45414400000013
      ],
      [
        57.79965899999997,
        156.85528300000013
      ],
      [
        57.72601999999997,
        156.78698500000013
      ],
      [
        57.60798999999997,
        156.96232300000014
      ],
      [
        57.385852999999976,
        156.97709300000014
      ],
      [
        57.099433999999974,
        156.53701600000014
      ],
      [
        56.70624299999997,
        156.00323400000013
      ],
      [
        55.994659999999975,
        155.66256700000014
      ],
      [
        54.97721599999998,
        155.59098900000015
      ],
      [
        52.769520999999976,
        156.16754200000014
      ],
      [
        51.91915999999998,
        156.49467500000014
      ],
      [
        51.88152099999998,
        156.71054100000015
      ],
      [
        51.90915799999998,
        156.49232500000014
      ],
      [
        51.28904799999998,
        156.54135100000013
      ],
      [
        51.07054599999998,
        156.76048300000014
      ],
      [
        50.87496399999998,
        156.65562400000013
      ],
      [
        51.62429899999998,
        157.90216000000012
      ],
      [
        52.28387799999998,
        158.55062800000013
      ],
      [
        52.40979699999998,
        158.54606700000014
      ],
      [
        52.39974199999998,
        158.40187200000014
      ],
      [
        52.51846299999998,
        158.52763400000015
      ],
      [
        52.643214999999984,
        158.39351000000013
      ],
      [
        52.619906999999984,
        158.56031900000013
      ],
      [
        52.76190899999998,
        158.48054700000014
      ],
      [
        52.89043299999998,
        158.63588000000016
      ],
      [
        52.88793599999998,
        158.43396200000015
      ],
      [
        53.00523999999998,
        158.42337300000014
      ],
      [
        53.04229499999998,
        158.61096400000014
      ],
      [
        52.87762599999998,
        158.71229700000015
      ],
      [
        53.09209999999998,
        159.06492700000015
      ],
      [
        53.26612599999998,
        159.80888300000015
      ],
      [
        53.19235099999998,
        159.74653700000016
      ],
      [
        53.09246299999998,
        160.03354000000016
      ],
      [
        53.24457399999998,
        159.90620600000017
      ],
      [
        53.32018499999998,
        159.97393900000017
      ],
      [
        53.44548899999998,
        159.83940200000018
      ],
      [
        53.49265299999998,
        159.96432500000017
      ],
      [
        53.78015399999998,
        159.84481800000017
      ],
      [
        54.16629299999998,
        160.04527300000018
      ],
      [
        54.56281799999998,
        160.86242500000017
      ],
      [
        54.58481699999998,
        161.17976300000018
      ],
      [
        54.50801199999998,
        161.7201820000002
      ],
      [
        54.752288999999976,
        162.1218230000002
      ],
      [
        55.11353999999998,
        161.8356300000002
      ],
      [
        55.40725999999998,
        161.7359600000002
      ],
      [
        56.08206399999998,
        162.0918710000002
      ],
      [
        56.23159199999998,
        162.6218700000002
      ],
      [
        56.00956599999998,
        163.0621000000002
      ],
      [
        56.18514899999998,
        163.3694260000002
      ],
      [
        56.75031499999998,
        163.2411770000002
      ],
      [
        56.78089699999998,
        162.7828960000002
      ],
      [
        57.34120299999998,
        162.7876550000002
      ],
      [
        57.72047799999998,
        163.3258470000002
      ],
      [
        57.94736899999998,
        162.7065700000002
      ],
      [
        57.88558999999998,
        162.5104030000002
      ],
      [
        57.773868999999976,
        162.5163680000002
      ],
      [
        57.79553499999998,
        162.2906160000002
      ],
      [
        58.01286599999998,
        162.0021190000002
      ],
      [
        58.587392999999985,
        162.34434300000018
      ],
      [
        58.97658699999999,
        163.06542600000017
      ],
      [
        59.17491899999999,
        162.95187200000018
      ],
      [
        59.05433699999999,
        163.2492950000002
      ],
      [
        59.24841999999999,
        163.08640700000018
      ],
      [
        59.28286299999999,
        163.34786600000018
      ],
      [
        59.272696999999994,
        163.35512800000018
      ],
      [
        59.49089299999999,
        163.20704100000017
      ],
      [
        59.561055999999994,
        163.32145100000017
      ],
      [
        59.561668999999995,
        163.20209600000015
      ],
      [
        59.613777999999996,
        163.41831300000015
      ],
      [
        59.767582,
        163.38509500000015
      ],
      [
        59.890862,
        163.52100500000014
      ],
      [
        60.013,
        164.27959500000014
      ],
      [
        59.893166,
        164.07209200000014
      ],
      [
        59.855168,
        164.21409000000014
      ],
      [
        59.914916999999996,
        164.13465400000015
      ],
      [
        60.108081999999996,
        164.45292000000015
      ],
      [
        59.993137999999995,
        164.72982100000016
      ],
      [
        59.781223999999995,
        164.85692500000016
      ],
      [
        59.99066499999999,
        165.23419800000016
      ],
      [
        60.09875099999999,
        165.03325600000016
      ],
      [
        60.46888599999999,
        166.26311400000017
      ],
      [
        60.41166699999999,
        166.37709700000016
      ],
      [
        60.36511299999999,
        166.24384300000017
      ],
      [
        60.19219799999998,
        166.30239100000017
      ],
      [
        59.81603099999998,
        166.08603700000018
      ],
      [
        59.81702999999998,
        166.27069900000018
      ],
      [
        60.30833599999998,
        167.06353000000018
      ],
      [
        60.402695999999985,
        167.0502100000002
      ],
      [
        60.34063899999998,
        167.2088700000002
      ],
      [
        60.598779999999984,
        168.3724590000002
      ],
      [
        60.51291899999998,
        169.4210890000002
      ],
      [
        60.39558599999998,
        169.7401950000002
      ],
      [
        59.93261299999998,
        170.3215530000002
      ],
      [
        60.301389999999984,
        170.6919000000002
      ],
      [
        60.40958599999998,
        170.65848400000021
      ],
      [
        60.61594499999998,
        171.39606300000023
      ],
      [
        60.833804999999984,
        171.7197020000002
      ],
      [
        60.858775999999985,
        172.1034750000002
      ],
      [
        60.95269299999998,
        172.2234540000002
      ],
      [
        61.096277999999984,
        172.0535940000002
      ],
      [
        61.00858199999998,
        172.4081470000002
      ],
      [
        61.071606999999986,
        172.47472100000022
      ],
      [
        61.134914999999985,
        172.24797700000022
      ],
      [
        61.13063599999999,
        172.44559200000023
      ],
      [
        61.206887999999985,
        172.34692900000024
      ],
      [
        61.26602699999999,
        172.82436100000024
      ],
      [
        61.317551999999985,
        172.65473000000023
      ],
      [
        61.28199999999998,
        172.97208200000023
      ],
      [
        61.396191999999985,
        172.74539800000022
      ],
      [
        61.444580999999985,
        172.81758600000023
      ],
      [
        61.391300999999984,
        173.15706300000022
      ],
      [
        61.54110699999998,
        173.49191700000023
      ],
      [
        61.734217999999984,
        173.56361800000022
      ],
      [
        61.64179999999998,
        173.84700300000023
      ],
      [
        61.77402399999998,
        174.14545000000024
      ],
      [
        61.81866299999998,
        174.04614700000025
      ],
      [
        61.80627399999998,
        174.24373300000025
      ],
      [
        61.86693099999998,
        174.51330900000025
      ]
    ],
    "1": [
      [
        59.028751,
        164.751495
      ],
      [
        59.236914999999996,
        164.599167
      ],
      [
        59.006026,
        163.72860699999998
      ],
      [
        58.992748,
        163.931503
      ],
      [
        58.54025,
        163.415665
      ],
      [
        58.451779,
        163.500229
      ],
      [
        58.807556000000005,
        164.161193
      ],
      [
        58.870140000000006,
        164.655715
      ],
      [
        59.02724800000001,
        164.7498
      ]
    ],
    "2": [
      [
        54.68222,
        166.677643
      ],
      [
        54.857777,
        166.63014199999998
      ],
      [
        55.182722999999996,
        166.216615
      ],
      [
        55.327608,
        166.248841
      ],
      [
        55.288664,
        165.687883
      ],
      [
        54.947137999999995,
        166.165422
      ],
      [
        54.679748,
        166.675386
      ]
    ],
    "3": [
      [
        54.493973,
        168.134003
      ],
      [
        54.858638,
        167.429887
      ],
      [
        54.490306,
        168.13214100000002
      ]
    ]
  },
  "Ненецкий автономный округ": {
    "0": [
      [
        68.530251,
        44.217056
      ],
      [
        68.42913800000001,
        45.964916
      ],
      [
        68.41674900000001,
        45.867695000000005
      ],
      [
        68.10772000000001,
        46.544303000000006
      ],
      [
        67.81311100000002,
        46.67494500000001
      ],
      [
        67.69850300000002,
        45.285610000000005
      ],
      [
        67.57422000000001,
        45.302139000000004
      ],
      [
        67.31422500000001,
        44.832418000000004
      ],
      [
        67.15519800000001,
        45.597363
      ],
      [
        66.81136500000001,
        45.980778
      ],
      [
        66.887476,
        47.603138
      ],
      [
        67.01272700000001,
        47.743973000000004
      ],
      [
        67.19561000000002,
        47.676
      ],
      [
        67.44216800000001,
        47.926972
      ],
      [
        67.58660800000001,
        47.851919
      ],
      [
        67.69580700000002,
        48.678502
      ],
      [
        67.61958200000002,
        49.059055
      ],
      [
        67.70105600000002,
        48.735611
      ],
      [
        67.83450200000003,
        48.768080999999995
      ],
      [
        67.91588500000003,
        48.594916999999995
      ],
      [
        67.85266900000003,
        49.160388
      ],
      [
        68.09983100000004,
        50.044275
      ],
      [
        68.00978200000004,
        49.812554999999996
      ],
      [
        68.02108000000004,
        49.990109
      ],
      [
        68.36692000000004,
        50.7878
      ],
      [
        68.56966400000003,
        52.212551
      ],
      [
        68.46910900000003,
        52.055775999999994
      ],
      [
        68.46230300000003,
        52.32349899999999
      ],
      [
        68.35925300000004,
        52.167970999999994
      ],
      [
        68.32827800000004,
        52.426441999999994
      ],
      [
        68.47396900000004,
        52.76696799999999
      ],
      [
        68.62119200000004,
        52.52716499999999
      ],
      [
        68.59130900000004,
        52.28752699999999
      ],
      [
        68.95952600000004,
        53.87582999999999
      ],
      [
        68.97599800000003,
        54.58152499999999
      ],
      [
        68.89830800000003,
        53.61557899999999
      ],
      [
        68.82616500000003,
        54.01171999999999
      ],
      [
        68.59383600000004,
        53.71263599999999
      ],
      [
        68.39747100000004,
        53.95471899999999
      ],
      [
        68.31894900000003,
        53.66466099999999
      ],
      [
        68.33616700000003,
        53.324108999999986
      ],
      [
        68.13294300000003,
        53.12174799999998
      ],
      [
        68.23261300000003,
        54.106746999999984
      ],
      [
        67.98822000000003,
        53.99035699999998
      ],
      [
        68.29538600000002,
        54.494022999999984
      ],
      [
        68.10530900000002,
        54.806494999999984
      ],
      [
        68.24444600000002,
        54.96499499999998
      ],
      [
        68.31663400000002,
        54.86780099999998
      ],
      [
        68.54394400000002,
        55.37010499999998
      ],
      [
        68.64421600000003,
        56.06377199999998
      ],
      [
        68.52924900000004,
        57.31413399999998
      ],
      [
        68.74605300000003,
        57.849353999999984
      ],
      [
        68.70674700000004,
        57.196407999999984
      ],
      [
        68.72613200000004,
        57.92924199999998
      ],
      [
        68.97935800000003,
        58.90346599999998
      ],
      [
        68.96916700000003,
        59.30682799999998
      ],
      [
        68.91110800000003,
        58.987327999999984
      ],
      [
        68.72780400000002,
        59.51799399999998
      ],
      [
        68.65863600000002,
        59.17301899999998
      ],
      [
        68.40569200000002,
        59.11363199999998
      ],
      [
        68.35958000000002,
        59.77235299999998
      ],
      [
        68.44213800000003,
        59.94718399999998
      ],
      [
        68.62405200000003,
        59.79532499999998
      ],
      [
        68.70432800000003,
        59.93132199999998
      ],
      [
        68.68399600000004,
        60.42840599999998
      ],
      [
        68.88285500000003,
        60.87673899999998
      ],
      [
        68.78763300000003,
        61.22679399999998
      ],
      [
        69.07282600000003,
        60.95398699999998
      ],
      [
        69.11952500000004,
        60.65629499999998
      ],
      [
        69.25135200000004,
        60.635931999999976
      ],
      [
        69.55096500000003,
        60.183653999999976
      ],
      [
        69.66138400000003,
        60.345625999999974
      ],
      [
        69.61871400000003,
        60.79215599999998
      ],
      [
        69.69641200000002,
        60.68537799999998
      ],
      [
        69.71435500000003,
        60.78023899999998
      ],
      [
        69.85899300000003,
        60.85701599999998
      ],
      [
        69.47654800000002,
        64.38478999999998
      ],
      [
        69.25641800000002,
        65.07272499999998
      ],
      [
        69.00242100000003,
        64.53413499999998
      ],
      [
        68.86554400000003,
        64.57448999999998
      ],
      [
        68.80722000000003,
        65.35273399999998
      ],
      [
        68.56182900000003,
        65.67555699999998
      ],
      [
        68.43115000000003,
        65.46815499999998
      ],
      [
        68.35998500000004,
        64.54747199999998
      ],
      [
        68.19914200000004,
        64.53718799999999
      ],
      [
        68.03276100000004,
        63.83304099999999
      ],
      [
        67.89415100000004,
        63.94802999999999
      ],
      [
        67.83526700000003,
        63.87220099999999
      ],
      [
        67.68997400000003,
        63.13303499999999
      ],
      [
        67.57944000000003,
        62.86942499999999
      ],
      [
        67.38888900000003,
        62.83081299999999
      ],
      [
        67.00888600000003,
        61.64081899999999
      ],
      [
        67.09415200000004,
        52.15831399999999
      ],
      [
        66.92720500000004,
        51.51914699999999
      ],
      [
        66.78720600000004,
        51.54915399999999
      ],
      [
        66.11922200000004,
        49.00620799999999
      ],
      [
        66.13193100000004,
        48.14414699999999
      ],
      [
        65.80748500000004,
        47.66192499999999
      ],
      [
        65.81442700000004,
        47.12859199999999
      ],
      [
        66.40900800000004,
        44.22631699999999
      ],
      [
        66.65333900000005,
        44.59213099999999
      ],
      [
        66.78003200000005,
        44.358603999999985
      ],
      [
        66.90258300000005,
        44.52660199999998
      ],
      [
        67.03453400000005,
        44.29671299999998
      ],
      [
        67.08700100000004,
        44.38040799999998
      ],
      [
        67.25744800000004,
        43.771906999999985
      ],
      [
        67.66711500000004,
        44.20038199999998
      ],
      [
        67.83936300000003,
        44.10863099999998
      ],
      [
        67.91427600000003,
        44.30562899999998
      ],
      [
        67.97241900000003,
        44.17515899999998
      ],
      [
        68.03291200000002,
        44.36263099999998
      ],
      [
        68.01941700000002,
        44.21438099999998
      ],
      [
        68.30277900000002,
        44.19318699999998
      ],
      [
        68.66805200000002,
        43.24857699999998
      ],
      [
        68.53169300000002,
        44.18799099999998
      ]
    ],
    "1": [
      [
        69.061333,
        50
      ],
      [
        68.85605600000001,
        49.656502
      ],
      [
        68.708116,
        48.697224000000006
      ],
      [
        68.862526,
        48.223000000000006
      ],
      [
        68.731331,
        48.40824800000001
      ],
      [
        68.754447,
        48.30144400000001
      ],
      [
        68.891027,
        48.20589100000001
      ],
      [
        69.300885,
        48.34938800000001
      ],
      [
        69.496242,
        48.91005600000001
      ],
      [
        69.4763,
        49.399138000000015
      ],
      [
        69.139523,
        50.300913000000016
      ],
      [
        68.982939,
        50.13160900000002
      ],
      [
        69.204137,
        50.22435800000002
      ],
      [
        69.063246,
        50.01205200000002
      ]
    ],
    "2": [
      [
        68.379082,
        50.816055
      ],
      [
        68.478943,
        51.541278999999996
      ],
      [
        68.381196,
        50.82466599999999
      ]
    ],
    "3": [
      [
        73.288414,
        54.452026
      ],
      [
        73.42591800000001,
        54.90441499999999
      ],
      [
        73.15791300000001,
        56.549330999999995
      ],
      [
        73.05080400000001,
        55.789942999999994
      ],
      [
        73.02610800000001,
        56.4205
      ],
      [
        72.992142,
        56.315276
      ],
      [
        72.939858,
        55.518501
      ],
      [
        72.946893,
        56.239776
      ],
      [
        72.78261,
        56.099472
      ],
      [
        72.80681,
        55.453252
      ],
      [
        72.782695,
        55.543695
      ],
      [
        72.651363,
        55.90625
      ],
      [
        72.68339,
        55.634167
      ],
      [
        72.52283700000001,
        55.58839
      ],
      [
        72.58208700000002,
        55.081469999999996
      ],
      [
        72.46933200000001,
        55.55011
      ],
      [
        72.447748,
        55.121750999999996
      ],
      [
        72.437923,
        55.45047399999999
      ],
      [
        72.27692,
        55.292525999999995
      ],
      [
        72.171147,
        55.57355499999999
      ],
      [
        72.06342000000001,
        55.32441699999999
      ],
      [
        72.053869,
        55.44111199999999
      ],
      [
        71.98142100000001,
        55.32994399999999
      ],
      [
        71.93203600000001,
        55.41474699999999
      ],
      [
        71.90808700000001,
        55.14277399999999
      ],
      [
        71.88631300000002,
        55.50588799999999
      ],
      [
        71.62478500000002,
        55.63255499999999
      ],
      [
        71.18608700000001,
        56.19813699999999
      ],
      [
        70.70526000000001,
        57.579804999999986
      ],
      [
        70.58598200000002,
        57.40055299999999
      ],
      [
        70.66895100000002,
        56.73780199999999
      ],
      [
        70.54508800000002,
        57.287663999999985
      ],
      [
        70.61706300000002,
        56.58694399999999
      ],
      [
        70.71533600000002,
        56.589860999999985
      ],
      [
        70.73786600000003,
        56.50858199999998
      ],
      [
        70.68936600000002,
        56.30180699999998
      ],
      [
        70.68428400000002,
        56.15555699999998
      ],
      [
        70.64914400000002,
        56.29650199999998
      ],
      [
        70.56992200000002,
        56.62908199999998
      ],
      [
        70.67439900000002,
        55.79094399999998
      ],
      [
        70.56294900000002,
        56.006695999999984
      ],
      [
        70.63714300000002,
        55.610168999999985
      ],
      [
        70.67431400000002,
        55.561554999999984
      ],
      [
        70.61461500000003,
        55.85372199999998
      ],
      [
        70.62055800000003,
        55.79861099999999
      ],
      [
        70.73708800000003,
        55.41105799999999
      ],
      [
        70.70747900000003,
        55.33016799999999
      ],
      [
        70.63375700000003,
        55.48111099999999
      ],
      [
        70.68131000000002,
        55.27183399999999
      ],
      [
        70.60117100000002,
        55.31919699999999
      ],
      [
        70.54547600000002,
        55.16939099999999
      ],
      [
        70.80720300000002,
        54.50414299999999
      ],
      [
        70.72833800000002,
        54.42186499999999
      ],
      [
        70.63875400000002,
        54.78314299999999
      ],
      [
        70.78575600000002,
        53.542256999999985
      ],
      [
        70.86486600000002,
        53.336618999999985
      ],
      [
        70.81242200000003,
        53.73825699999998
      ],
      [
        70.93623100000002,
        53.768532999999984
      ],
      [
        71.08553000000002,
        53.509866999999986
      ],
      [
        71.11186600000002,
        53.808033999999985
      ],
      [
        71.08703300000002,
        54.10786499999998
      ],
      [
        71.10142200000001,
        54.24669699999998
      ],
      [
        71.13991800000001,
        54.00308599999998
      ],
      [
        71.14961400000001,
        54.21097599999998
      ],
      [
        71.20725500000002,
        54.13094799999998
      ],
      [
        71.21367000000002,
        53.519283999999985
      ],
      [
        71.44430600000003,
        53.26933899999999
      ],
      [
        71.54619700000002,
        53.49967299999999
      ],
      [
        71.40808200000002,
        53.159531999999984
      ],
      [
        71.50505800000002,
        53.00753199999998
      ],
      [
        71.44310800000002,
        53.108476999999986
      ],
      [
        71.47022200000002,
        52.935087999999986
      ],
      [
        71.37141500000003,
        52.945810999999985
      ],
      [
        71.48808300000003,
        52.65311699999999
      ],
      [
        71.71389000000003,
        52.54481299999999
      ],
      [
        71.56294300000003,
        52.52334099999999
      ],
      [
        71.54736300000003,
        52.39431299999999
      ],
      [
        71.46111400000004,
        52.45139499999999
      ],
      [
        71.59852700000003,
        52.26314399999999
      ],
      [
        71.52292000000003,
        52.17231299999999
      ],
      [
        71.46475300000003,
        52.28747899999999
      ],
      [
        71.45742200000004,
        51.90431199999999
      ],
      [
        71.54105500000004,
        51.61222799999999
      ],
      [
        71.78577400000005,
        51.43136999999999
      ],
      [
        72.06330900000005,
        51.582118999999985
      ],
      [
        72.15074900000005,
        51.86128699999998
      ],
      [
        72.05088800000004,
        52.453310999999985
      ],
      [
        72.14558400000004,
        52.402397999999984
      ],
      [
        72.13502600000004,
        52.562643999999985
      ],
      [
        72.18358700000005,
        52.54436899999998
      ],
      [
        72.27341600000004,
        52.44839599999998
      ],
      [
        72.23958700000004,
        52.64200699999998
      ],
      [
        72.31414100000005,
        52.723675999999976
      ],
      [
        72.36135900000005,
        52.573175999999975
      ],
      [
        72.32238800000005,
        52.756952999999974
      ],
      [
        72.41188800000005,
        52.689538999999975
      ],
      [
        72.43125200000004,
        52.831815999999975
      ],
      [
        72.47027600000004,
        52.70870499999997
      ],
      [
        72.56269100000004,
        53.283370999999974
      ],
      [
        72.60710900000004,
        52.85098299999997
      ],
      [
        72.61930800000003,
        52.70365199999997
      ],
      [
        72.64286000000003,
        53.69414899999997
      ],
      [
        72.70272000000003,
        52.410957999999965
      ],
      [
        72.84744100000003,
        52.570956999999964
      ],
      [
        72.88133000000003,
        53.621591999999964
      ],
      [
        72.90063900000004,
        53.28837099999996
      ],
      [
        72.94882700000004,
        53.063036999999966
      ],
      [
        73.01066200000004,
        53.37401099999997
      ],
      [
        73.09457800000004,
        53.14981799999997
      ],
      [
        73.21879800000004,
        53.37839799999997
      ],
      [
        73.28483100000004,
        54.454176999999966
      ]
    ],
    "4": [
      [
        71.138138,
        52.765945
      ],
      [
        71.27469599999999,
        52.635223
      ],
      [
        71.289412,
        52.239446
      ],
      [
        71.337417,
        52.785946
      ],
      [
        71.397192,
        52.724752
      ],
      [
        71.249639,
        53.081834
      ],
      [
        71.315915,
        52.923806
      ],
      [
        71.189412,
        53.264195
      ],
      [
        71.032278,
        53.061334
      ],
      [
        70.94889,
        53.180668000000004
      ],
      [
        71.12911100000001,
        52.78939200000001
      ]
    ],
    "5": [
      [
        71.346802,
        53.290859
      ],
      [
        71.397162,
        53.144085
      ],
      [
        71.35119499999999,
        53.297779
      ]
    ],
    "6": [
      [
        71.337608,
        53.248165
      ],
      [
        71.277612,
        53.401584
      ],
      [
        71.340248,
        53.233333
      ]
    ],
    "7": [
      [
        75.938698,
        60
      ],
      [
        76.045502,
        60.082916
      ],
      [
        76.122276,
        60.52325
      ],
      [
        76.11136,
        60.816137999999995
      ],
      [
        75.973055,
        60.50827699999999
      ],
      [
        76.059137,
        61.09132999999999
      ],
      [
        76.123919,
        61.171803999999995
      ],
      [
        76.149782,
        60.937051999999994
      ],
      [
        76.270639,
        61.156552999999995
      ],
      [
        76.16655800000001,
        62.525887
      ],
      [
        76.343331,
        64.74002399999999
      ],
      [
        76.385171,
        64.63794299999999
      ],
      [
        76.563775,
        65.469303
      ],
      [
        76.506028,
        66.002888
      ],
      [
        76.65503,
        65.795888
      ],
      [
        76.99322599999999,
        67.56177100000001
      ],
      [
        76.96028199999999,
        68.46477
      ],
      [
        76.70469899999999,
        69.07033
      ],
      [
        76.55841999999998,
        68.800831
      ],
      [
        76.29458799999999,
        68.199055
      ],
      [
        76.246866,
        68.272746
      ],
      [
        75.622417,
        63.754328
      ],
      [
        75.712778,
        63.626552000000004
      ],
      [
        75.44256,
        62.010856000000004
      ],
      [
        75.212031,
        61.443470000000005
      ],
      [
        75.319833,
        61.33072000000001
      ],
      [
        75.013444,
        60.760721000000004
      ],
      [
        75.09358300000001,
        60.54269300000001
      ],
      [
        74.981637,
        59.99205400000001
      ],
      [
        74.911393,
        60.65991600000001
      ],
      [
        74.857026,
        60.15963700000001
      ],
      [
        74.74047200000001,
        60.30886000000001
      ],
      [
        74.79277900000001,
        59.46324800000001
      ],
      [
        74.65200200000001,
        59.85755400000001
      ],
      [
        74.572832,
        59.770390000000006
      ],
      [
        74.72867000000001,
        59.08500000000001
      ],
      [
        74.61836400000001,
        59.28419600000001
      ],
      [
        74.61475600000001,
        59.15080700000001
      ],
      [
        74.42200000000001,
        59.15108500000001
      ],
      [
        74.56286100000001,
        58.267193000000006
      ],
      [
        74.40572600000002,
        58.63830600000001
      ],
      [
        74.25200200000002,
        58.72183100000001
      ],
      [
        74.24019900000002,
        58.62783400000001
      ],
      [
        74.22041600000001,
        58.72908400000001
      ],
      [
        74.18780800000002,
        58.652111000000005
      ],
      [
        74.18844900000002,
        58.251335000000005
      ],
      [
        74.11581000000002,
        58.535804000000006
      ],
      [
        74.07220100000002,
        58.331943
      ],
      [
        74.14283400000002,
        58.113414000000006
      ],
      [
        74.07378000000003,
        58.211496000000004
      ],
      [
        74.04017400000002,
        58.086776
      ],
      [
        73.99664100000003,
        58.280275
      ],
      [
        73.97439500000003,
        58.169054
      ],
      [
        74.18762100000004,
        57.482305000000004
      ],
      [
        74.08000900000003,
        57.536970000000004
      ],
      [
        74.07584400000003,
        57.190333
      ],
      [
        73.93725600000003,
        57.827834
      ],
      [
        73.88234000000003,
        57.746528000000005
      ],
      [
        73.76881400000003,
        57.89549900000001
      ],
      [
        73.88159100000003,
        56.58874900000001
      ],
      [
        73.80506200000004,
        57.31816500000001
      ],
      [
        73.59917500000003,
        57.599388000000005
      ],
      [
        73.55309300000003,
        57.153027
      ],
      [
        73.73714500000003,
        56.406751
      ],
      [
        73.59442300000002,
        56.897584
      ],
      [
        73.48001200000002,
        57.227749
      ],
      [
        73.28928600000002,
        56.899582
      ],
      [
        73.34270700000002,
        56.721554000000005
      ],
      [
        73.23318100000002,
        56.74327900000001
      ],
      [
        73.26162200000002,
        56.12611100000001
      ],
      [
        73.51465000000002,
        55.76122500000001
      ],
      [
        73.30456700000002,
        55.95666600000001
      ],
      [
        73.43995000000002,
        54.898752000000016
      ],
      [
        73.31223500000003,
        54.179834000000014
      ],
      [
        73.36784600000003,
        54.02169600000001
      ],
      [
        73.48584200000003,
        54.20213800000001
      ],
      [
        73.64161800000004,
        54.72594500000001
      ],
      [
        73.70826100000004,
        55.32841500000001
      ],
      [
        73.63920000000003,
        55.63911100000001
      ],
      [
        73.54737300000004,
        55.63730700000001
      ],
      [
        73.64447900000003,
        55.67797100000001
      ],
      [
        73.63070100000003,
        55.989554000000005
      ],
      [
        73.70009000000003,
        55.120499
      ],
      [
        73.77931300000003,
        55.408137
      ],
      [
        73.59595600000003,
        54.186553
      ],
      [
        73.74545300000003,
        53.638611000000004
      ],
      [
        73.91936500000003,
        54.31463900000001
      ],
      [
        73.90459600000003,
        55.04474900000001
      ],
      [
        73.99639900000003,
        54.59022000000001
      ],
      [
        74.15512000000003,
        55.02927600000001
      ],
      [
        74.00739300000002,
        56.35002700000001
      ],
      [
        74.09147600000003,
        55.85916300000001
      ],
      [
        74.14970200000003,
        56.09794400000001
      ],
      [
        74.24159000000003,
        55.108722000000014
      ],
      [
        74.26353100000003,
        55.83977800000002
      ],
      [
        74.35425100000003,
        55.27641600000002
      ],
      [
        74.48575100000004,
        56.24519100000002
      ],
      [
        74.55241600000004,
        55.52919300000002
      ],
      [
        74.68591500000004,
        55.81219300000002
      ],
      [
        74.67986600000003,
        56.96505400000002
      ],
      [
        74.73630800000004,
        56.44508100000002
      ],
      [
        74.78728000000004,
        55.880971000000024
      ],
      [
        74.93738900000004,
        56.72858100000003
      ],
      [
        74.91675100000003,
        56.351136000000025
      ],
      [
        74.98325600000004,
        56.46241500000003
      ],
      [
        74.96283300000005,
        55.90469100000003
      ],
      [
        75.05383600000005,
        55.98382900000003
      ],
      [
        75.07027700000005,
        55.788357000000026
      ],
      [
        75.17067300000005,
        55.921412000000025
      ],
      [
        75.17039000000005,
        56.34563200000002
      ],
      [
        75.07703000000005,
        56.64685600000002
      ],
      [
        75.25939500000005,
        56.98263300000002
      ],
      [
        75.29580900000005,
        56.74196800000002
      ],
      [
        75.36322200000005,
        56.98193900000002
      ],
      [
        75.31408900000005,
        57.88974500000002
      ],
      [
        75.47552700000006,
        57.56399300000002
      ],
      [
        75.59891600000006,
        58.26252100000002
      ],
      [
        75.62419200000006,
        57.94568800000002
      ],
      [
        75.66513900000007,
        58.05563100000002
      ],
      [
        75.75389200000006,
        58.56749500000002
      ],
      [
        75.80217100000006,
        58.71993800000002
      ],
      [
        75.93336600000006,
        59.98580000000002
      ]
    ],
    "8": [
      [
        68.268974,
        54.209835
      ],
      [
        68.337753,
        54.348026
      ],
      [
        68.246583,
        54.214943999999996
      ]
    ],
    "9": [
      [
        68.852531,
        55.667194
      ],
      [
        68.930222,
        55.390336000000005
      ],
      [
        68.853691,
        55.678945000000006
      ]
    ],
    "10": [
      [
        70.584999,
        56.063805
      ],
      [
        70.643165,
        55.85936
      ],
      [
        70.58816399999999,
        56.069526
      ]
    ],
    "11": [
      [
        70.581642,
        56.14386
      ],
      [
        70.62702900000001,
        55.99922299999999
      ],
      [
        70.58600000000001,
        56.148776999999995
      ]
    ],
    "12": [
      [
        70.588531,
        56.7925
      ],
      [
        70.506555,
        57.191832999999995
      ],
      [
        70.578781,
        56.79503
      ]
    ],
    "13": [
      [
        69.696198,
        60
      ],
      [
        69.71011399999999,
        59.583721
      ],
      [
        69.77994499999998,
        59.715666999999996
      ],
      [
        69.94116999999999,
        58.971109999999996
      ],
      [
        69.85414099999998,
        59.176806
      ],
      [
        69.85522399999998,
        59.062777
      ],
      [
        70.06805299999998,
        58.600581999999996
      ],
      [
        70.18871999999998,
        58.466640999999996
      ],
      [
        70.23752399999998,
        58.407168
      ],
      [
        70.20666299999998,
        58.823668
      ],
      [
        70.32455099999997,
        58.511973
      ],
      [
        70.46866299999998,
        59.070057999999996
      ],
      [
        69.80157899999998,
        60.554002
      ],
      [
        69.68660399999997,
        60.026833999999994
      ]
    ],
    "14": [
      [
        69.108559,
        59.32478
      ],
      [
        69.385138,
        58.720417999999995
      ],
      [
        69.25299799999999,
        59.234334
      ],
      [
        69.10861299999999,
        59.330917
      ]
    ],
    "15": [
      [
        75.894363,
        58.881779
      ],
      [
        75.948364,
        59.294666
      ],
      [
        75.898613,
        58.93353
      ]
    ],
    "16": [
      [
        74.384888,
        59.055946
      ],
      [
        74.397552,
        59.139083
      ],
      [
        74.340164,
        58.961918
      ],
      [
        74.368163,
        59.046611999999996
      ]
    ],
    "17": [
      [
        76.020363,
        59.333668
      ],
      [
        76.054496,
        59.499331000000005
      ],
      [
        76.020943,
        59.358112000000006
      ]
    ],
    "18": [
      [
        76.092415,
        60
      ],
      [
        76.092552,
        59.897305
      ],
      [
        76.169196,
        60.500778000000004
      ],
      [
        76.092918,
        60.009862000000005
      ]
    ]
  },
  "Ямало-Ненецкий автономный округ": {
    "0": [
      [
        68.43115,
        65.468168
      ],
      [
        68.561829,
        65.67557000000001
      ],
      [
        68.80722,
        65.35274700000001
      ],
      [
        68.865544,
        64.574503
      ],
      [
        68.985877,
        64.51685300000001
      ],
      [
        69.25641900000001,
        65.07273800000002
      ],
      [
        69.073687,
        66.20207800000001
      ],
      [
        68.90907600000001,
        66.56596800000001
      ],
      [
        69.13138100000002,
        65.56899800000001
      ],
      [
        68.81538700000002,
        67.166555
      ],
      [
        68.75705300000001,
        67.077697
      ],
      [
        68.48810900000001,
        67.723585
      ],
      [
        68.42680000000001,
        67.798383
      ],
      [
        68.36030200000002,
        68.229191
      ],
      [
        68.13669200000002,
        68.255358
      ],
      [
        68.25221500000002,
        68.607439
      ],
      [
        68.80982400000002,
        69.130914
      ],
      [
        68.88060100000003,
        68.972826
      ],
      [
        68.93310500000003,
        69.071908
      ],
      [
        68.98824300000003,
        68.44849599999999
      ],
      [
        69.33318200000002,
        68.02610299999999
      ],
      [
        69.51832400000002,
        68.15744299999999
      ],
      [
        69.69118300000002,
        67.044857
      ],
      [
        69.61576700000002,
        66.87626999999999
      ],
      [
        69.52438200000002,
        67.00304799999999
      ],
      [
        69.57137800000001,
        66.831386
      ],
      [
        70.022709,
        66.955494
      ],
      [
        70.028683,
        67.436381
      ],
      [
        70.211598,
        67.127909
      ],
      [
        70.446461,
        67.361963
      ],
      [
        70.528187,
        67.208635
      ],
      [
        70.712795,
        67.39177
      ],
      [
        70.832515,
        67.181131
      ],
      [
        70.75121,
        66.703936
      ],
      [
        70.861431,
        66.647273
      ],
      [
        71.060375,
        66.994219
      ],
      [
        71.106151,
        66.768686
      ],
      [
        71.720989,
        68.38191400000001
      ],
      [
        72.100207,
        68.72966100000001
      ],
      [
        72.441546,
        68.812217
      ],
      [
        72.656183,
        68.935516
      ],
      [
        72.935509,
        69.338828
      ],
      [
        72.915933,
        69.77329900000001
      ],
      [
        72.88498899999999,
        71.55482300000001
      ],
      [
        72.69251599999998,
        72.82527000000002
      ],
      [
        72.69182099999999,
        72.67438500000002
      ],
      [
        72.59090799999998,
        72.67635300000002
      ],
      [
        72.49298599999999,
        72.77204900000002
      ],
      [
        72.491323,
        72.59677200000003
      ],
      [
        72.482489,
        72.77635200000003
      ],
      [
        72.264328,
        72.82121100000003
      ],
      [
        71.86126800000001,
        72.34904400000003
      ],
      [
        71.70649100000001,
        72.33571600000003
      ],
      [
        71.47983100000002,
        71.83424300000003
      ],
      [
        71.28638700000002,
        72.04215900000003
      ],
      [
        71.12174600000002,
        72.62504400000003
      ],
      [
        70.99702200000002,
        72.59040700000003
      ],
      [
        70.85988400000002,
        72.83221300000002
      ],
      [
        70.57627700000002,
        72.70613100000003
      ],
      [
        70.38735900000002,
        72.76082500000003
      ],
      [
        70.29164100000001,
        72.38224000000002
      ],
      [
        70.14428000000001,
        72.57757400000003
      ],
      [
        69.993333,
        72.48763100000002
      ],
      [
        69.800004,
        72.68151600000002
      ],
      [
        69.631281,
        72.51815700000002
      ],
      [
        69.464534,
        72.69490600000002
      ],
      [
        69.430865,
        72.51051900000002
      ],
      [
        69.284419,
        72.64296400000002
      ],
      [
        69.126667,
        72.48749200000002
      ],
      [
        68.923948,
        72.58343200000002
      ],
      [
        68.44050299999999,
        73.60143600000002
      ],
      [
        68.212644,
        73.09377000000002
      ],
      [
        68.07872499999999,
        73.04273600000002
      ],
      [
        67.90225799999999,
        73.21548900000002
      ],
      [
        67.68428599999999,
        73.11479700000002
      ],
      [
        67.57750599999999,
        72.60362900000003
      ],
      [
        67.30564899999999,
        72.40338100000002
      ],
      [
        67.28492899999999,
        72.14807900000002
      ],
      [
        67.128924,
        72.22971300000002
      ],
      [
        67.111042,
        71.92366000000001
      ],
      [
        67.038067,
        72.06121800000001
      ],
      [
        66.884404,
        71.83179500000001
      ],
      [
        66.968373,
        71.40280200000001
      ],
      [
        66.89871,
        71.48585600000001
      ],
      [
        66.72956599999999,
        70.50735600000002
      ],
      [
        66.87892599999999,
        68.78046600000002
      ],
      [
        66.856793,
        69.09660500000003
      ],
      [
        66.78770999999999,
        68.92721800000002
      ],
      [
        66.75446099999999,
        69.17730200000003
      ],
      [
        66.61101299999999,
        69.13772100000003
      ],
      [
        66.32045599999998,
        70.39065800000003
      ],
      [
        66.24967799999997,
        72.25197100000003
      ],
      [
        66.34689999999998,
        72.43065900000002
      ],
      [
        66.49528999999998,
        72.32210900000003
      ],
      [
        66.98717999999998,
        73.87227100000003
      ],
      [
        67.28818299999998,
        73.93363400000003
      ],
      [
        67.66804299999997,
        74.72757800000002
      ],
      [
        68.02817999999996,
        74.79771500000003
      ],
      [
        68.37023499999997,
        74.31215700000003
      ],
      [
        68.71322899999997,
        74.48477200000002
      ],
      [
        68.99420399999997,
        76.18657600000002
      ],
      [
        68.98781899999997,
        76.55479300000002
      ],
      [
        68.75387199999997,
        76.61221100000002
      ],
      [
        68.49065099999997,
        77.31254200000002
      ],
      [
        68.29234899999997,
        77.12121300000003
      ],
      [
        68.24120999999997,
        77.31304600000003
      ],
      [
        68.10851199999996,
        77.18443800000003
      ],
      [
        67.87435099999996,
        77.26962700000003
      ],
      [
        67.78651499999997,
        77.04354500000002
      ],
      [
        67.57763099999997,
        77.68126200000002
      ],
      [
        67.32718099999997,
        77.60340500000002
      ],
      [
        67.43776799999996,
        77.71701500000002
      ],
      [
        67.36223799999996,
        77.91309700000002
      ],
      [
        67.47506899999996,
        77.85760100000002
      ],
      [
        67.51357399999996,
        77.98201200000001
      ],
      [
        67.55065199999996,
        78.50273400000002
      ],
      [
        67.11890599999995,
        79.99570700000002
      ],
      [
        67.15451999999995,
        79.86479500000003
      ],
      [
        67.39751599999995,
        78.90051600000002
      ],
      [
        67.56146299999995,
        78.77346400000002
      ],
      [
        67.33135299999995,
        79.54156800000001
      ],
      [
        67.63095899999995,
        78.84154200000002
      ],
      [
        67.60587399999994,
        78.46584700000002
      ],
      [
        67.69804399999994,
        77.96265900000003
      ],
      [
        67.74848899999994,
        77.45612900000003
      ],
      [
        67.91770899999993,
        77.58434800000003
      ],
      [
        68.11365399999993,
        77.47776700000003
      ],
      [
        68.26062599999993,
        78.14923600000003
      ],
      [
        68.45570899999993,
        77.85674100000003
      ],
      [
        68.53356599999992,
        77.76718900000003
      ],
      [
        68.90531599999993,
        77.60535500000003
      ],
      [
        69.25706799999993,
        75.96491300000004
      ],
      [
        69.25862399999993,
        75.46940700000003
      ],
      [
        69.10187099999993,
        73.87771700000003
      ],
      [
        69.20603399999993,
        73.77782600000003
      ],
      [
        69.42623399999994,
        73.91005100000004
      ],
      [
        69.76467399999994,
        73.54341400000004
      ],
      [
        69.94759599999995,
        73.75649500000004
      ],
      [
        70.10650799999995,
        73.69372200000004
      ],
      [
        70.40187199999995,
        74.20410400000004
      ],
      [
        70.59470499999995,
        74.31154800000004
      ],
      [
        71.18953699999994,
        73.58905200000004
      ],
      [
        71.39925399999994,
        72.98858000000004
      ],
      [
        71.63903699999995,
        73.49382800000004
      ],
      [
        71.79547799999995,
        73.52849600000003
      ],
      [
        72.17145499999995,
        74.99432400000003
      ],
      [
        72.43623299999996,
        75.06521600000004
      ],
      [
        72.79434099999996,
        74.76618900000004
      ],
      [
        72.86794999999996,
        74.98557100000004
      ],
      [
        72.74381199999996,
        75.45362700000004
      ],
      [
        72.69419799999996,
        75.28985600000004
      ],
      [
        72.52458899999996,
        75.63504700000004
      ],
      [
        72.48548199999996,
        75.47607400000004
      ],
      [
        72.26164999999996,
        75.68068600000004
      ],
      [
        72.15828699999996,
        75.46940600000003
      ],
      [
        71.92484299999995,
        75.24740700000004
      ],
      [
        71.77481099999996,
        75.21640800000004
      ],
      [
        71.52242399999996,
        75.49260800000005
      ],
      [
        71.41417799999996,
        75.20240800000005
      ],
      [
        71.33342999999996,
        75.28115800000005
      ],
      [
        71.11065099999996,
        77.77610000000004
      ],
      [
        70.95723299999996,
        77.92035500000004
      ],
      [
        70.89378799999996,
        78.39357500000004
      ],
      [
        70.99904199999996,
        78.43335500000003
      ],
      [
        71.10300699999996,
        78.21993800000003
      ],
      [
        71.23859499999996,
        78.27690700000002
      ],
      [
        71.22964599999996,
        77.93057000000002
      ],
      [
        71.30209399999995,
        77.37257200000002
      ],
      [
        71.56147799999995,
        76.32968900000002
      ],
      [
        71.87789999999995,
        75.99274400000002
      ],
      [
        72.05550399999996,
        76.88546700000002
      ],
      [
        71.81670499999996,
        77.60159900000002
      ],
      [
        71.86156599999995,
        78.10929700000003
      ],
      [
        71.95106599999995,
        78.24704500000003
      ],
      [
        72.08906699999996,
        78.03993800000003
      ],
      [
        72.10508799999995,
        77.39398700000004
      ],
      [
        72.35294999999995,
        78.07696300000003
      ],
      [
        72.27394799999995,
        78.60513100000003
      ],
      [
        72.34738799999995,
        78.44499800000003
      ],
      [
        72.22638499999995,
        78.67775500000003
      ],
      [
        72.11470699999995,
        78.91080100000003
      ],
      [
        71.88600999999996,
        80.34877300000004
      ],
      [
        71.83147499999995,
        80.03503700000003
      ],
      [
        71.67453899999995,
        80.13539300000004
      ],
      [
        71.61638899999996,
        79.38025100000003
      ],
      [
        71.41111199999996,
        79.14247500000003
      ],
      [
        71.05758199999995,
        80.55003600000003
      ],
      [
        70.85477899999995,
        80.56645400000004
      ],
      [
        70.80187699999995,
        80.69015700000004
      ],
      [
        70.71676399999994,
        80.59227200000004
      ],
      [
        70.65447899999994,
        80.75935500000004
      ],
      [
        70.50096099999993,
        80.60647800000004
      ],
      [
        70.39445599999993,
        80.76216300000004
      ],
      [
        70.43656899999993,
        80.17682100000005
      ],
      [
        70.11055099999993,
        79.49885800000004
      ],
      [
        70.14889499999992,
        79.32803700000004
      ],
      [
        70.04499799999992,
        79.34941300000004
      ],
      [
        69.88166799999992,
        78.87524600000005
      ],
      [
        69.74305799999992,
        79.13969500000005
      ],
      [
        69.69694699999992,
        79.00415300000004
      ],
      [
        69.65361199999992,
        79.50581500000004
      ],
      [
        69.34611699999992,
        79.85913100000005
      ],
      [
        69.20668199999992,
        80.95594800000005
      ],
      [
        69.27583399999992,
        81.58247300000005
      ],
      [
        69.44776999999992,
        81.68357800000005
      ],
      [
        69.34666599999991,
        81.93607900000005
      ],
      [
        69.25250399999992,
        81.75970400000006
      ],
      [
        69.18499899999992,
        81.84385600000006
      ],
      [
        69.16055599999991,
        82.46386600000005
      ],
      [
        69.05388299999991,
        82.31636100000006
      ],
      [
        68.81834899999991,
        82.55218500000005
      ],
      [
        68.66777599999992,
        83.04273900000005
      ],
      [
        68.57834399999992,
        82.45608500000006
      ],
      [
        68.49138499999992,
        82.68052600000006
      ],
      [
        68.29334099999993,
        82.70663300000005
      ],
      [
        68.17749599999993,
        82.39274500000005
      ],
      [
        67.94472299999994,
        82.36691300000005
      ],
      [
        67.92417099999994,
        81.72441100000006
      ],
      [
        67.72888999999995,
        82.10552900000006
      ],
      [
        67.59944999999995,
        82.11413400000006
      ],
      [
        67.53501299999995,
        82.39663400000006
      ],
      [
        67.42084799999995,
        82.23358000000006
      ],
      [
        67.33057799999995,
        82.36468300000006
      ],
      [
        67.23890399999995,
        82.11941400000006
      ],
      [
        66.80917199999995,
        83.17246700000007
      ],
      [
        66.66168199999994,
        83.30579800000007
      ],
      [
        66.61056599999995,
        83.08663700000007
      ],
      [
        66.46974299999995,
        83.08691000000007
      ],
      [
        66.19528299999995,
        83.53968300000007
      ],
      [
        66.06697199999995,
        83.28941000000006
      ],
      [
        65.82946999999994,
        83.50635800000006
      ],
      [
        65.79390199999995,
        84.03246500000006
      ],
      [
        65.68779199999994,
        84.32663900000006
      ],
      [
        65.59447099999994,
        84.19051500000006
      ],
      [
        65.50697699999994,
        84.49968900000006
      ],
      [
        65.29168999999993,
        84.58552000000006
      ],
      [
        65.09863699999993,
        84.31107600000006
      ],
      [
        64.99390099999992,
        84.39663200000005
      ],
      [
        64.90918499999992,
        84.27802700000005
      ],
      [
        64.92862499999993,
        84.92080300000005
      ],
      [
        64.75003599999992,
        85.16079300000006
      ],
      [
        64.78474899999992,
        85.82052200000005
      ],
      [
        64.58891699999992,
        85.91802600000005
      ],
      [
        64.45891299999992,
        85.84080200000005
      ],
      [
        64.26474499999992,
        86.03107900000005
      ],
      [
        64.05446399999992,
        85.95802100000004
      ],
      [
        63.930029999999924,
        85.51637100000005
      ],
      [
        63.64642299999992,
        85.26719500000004
      ],
      [
        63.58652599999992,
        85.03201200000004
      ],
      [
        63.35003799999992,
        85.40774400000004
      ],
      [
        63.35447899999992,
        85.67385700000004
      ],
      [
        63.13808699999992,
        85.46636800000005
      ],
      [
        63.08003599999992,
        85.58663700000004
      ],
      [
        62.932812999999925,
        85.52081100000004
      ],
      [
        62.178151999999926,
        84.43583600000004
      ],
      [
        62.56698399999993,
        83.78358500000003
      ],
      [
        62.453092999999924,
        83.29080200000003
      ],
      [
        62.66447899999992,
        82.74607900000002
      ],
      [
        62.75448299999992,
        82.75608800000002
      ],
      [
        62.814198999999924,
        82.14247100000001
      ],
      [
        62.68142399999992,
        81.79274000000001
      ],
      [
        62.823924999999925,
        81.33609000000001
      ],
      [
        63.120363999999924,
        81.11697500000001
      ],
      [
        63.08322399999992,
        80.58030800000002
      ],
      [
        62.79309499999992,
        79.91414000000002
      ],
      [
        62.605312999999924,
        79.84720000000002
      ],
      [
        62.61670399999993,
        78.80496300000001
      ],
      [
        62.458371999999926,
        78.00663900000002
      ],
      [
        62.79142599999992,
        77.15774800000003
      ],
      [
        62.97614099999992,
        77.03331200000002
      ],
      [
        63.04365399999992,
        76.66553100000003
      ],
      [
        62.94781299999992,
        76.28580100000003
      ],
      [
        63.11725299999992,
        75.79941300000003
      ],
      [
        63.04281299999992,
        74.48470100000003
      ],
      [
        63.17392499999992,
        74.24321500000003
      ],
      [
        63.19669799999992,
        73.35164500000003
      ],
      [
        63.43642099999992,
        73.10497100000003
      ],
      [
        63.273647999999916,
        72.66359600000004
      ],
      [
        63.18530899999992,
        71.60802300000005
      ],
      [
        63.59058999999992,
        71.56552800000004
      ],
      [
        63.68614699999992,
        70.91442200000004
      ],
      [
        63.90058599999992,
        70.63610200000005
      ],
      [
        64.01253999999992,
        70.55524700000005
      ],
      [
        64.19308199999992,
        70.79247500000005
      ],
      [
        64.47669599999992,
        69.21665600000006
      ],
      [
        64.43668799999992,
        68.85525100000005
      ],
      [
        64.33002899999993,
        68.99331300000006
      ],
      [
        64.22196599999992,
        68.88526600000006
      ],
      [
        64.30918399999992,
        68.45304600000006
      ],
      [
        64.02891199999992,
        67.79081400000005
      ],
      [
        64.22530699999992,
        66.80303800000006
      ],
      [
        64.48723899999992,
        66.93830600000005
      ],
      [
        64.55697099999992,
        66.71442900000005
      ],
      [
        64.57669899999992,
        65.44303700000005
      ],
      [
        64.44223999999991,
        65.18609400000004
      ],
      [
        64.2655889999999,
        63.59054500000004
      ],
      [
        64.3280889999999,
        63.40860000000004
      ],
      [
        64.46474499999991,
        63.458328000000044
      ],
      [
        64.55835799999991,
        62.52610100000005
      ],
      [
        64.73502399999991,
        62.81415600000005
      ],
      [
        64.87641199999992,
        62.675552000000046
      ],
      [
        65.30168999999992,
        62.815544000000045
      ],
      [
        65.33974499999992,
        62.478326000000045
      ],
      [
        65.51474699999993,
        62.42443900000004
      ],
      [
        65.71721599999992,
        62.01861700000004
      ],
      [
        65.86751499999993,
        62.84665800000004
      ],
      [
        66.07114399999993,
        62.85360700000004
      ],
      [
        66.23279599999994,
        63.29054900000004
      ],
      [
        66.32642199999994,
        63.223320000000044
      ],
      [
        66.48112999999994,
        63.409156000000046
      ],
      [
        66.89058399999993,
        65.10137700000004
      ],
      [
        67.15085299999993,
        65.18247800000005
      ],
      [
        67.47862699999993,
        66.10664100000004
      ],
      [
        67.56975199999994,
        65.79165400000004
      ],
      [
        67.69612599999994,
        66.21053900000004
      ],
      [
        67.79307899999993,
        66.01805000000004
      ],
      [
        67.93445199999994,
        66.08610400000005
      ],
      [
        68.01196599999993,
        65.28498800000006
      ],
      [
        68.22001899999994,
        65.26776100000005
      ],
      [
        68.43057399999994,
        65.48054400000005
      ]
    ],
    "1": [
      [
        70.824638,
        66.477942
      ],
      [
        70.90683,
        66.620276
      ],
      [
        70.833031,
        66.496947
      ]
    ],
    "2": [
      [
        70.969971,
        66.745391
      ],
      [
        71.030028,
        66.667442
      ],
      [
        71.067999,
        66.838218
      ],
      [
        70.972083,
        66.754943
      ]
    ],
    "3": [
      [
        69.446281,
        67.300003
      ],
      [
        69.474746,
        67.035919
      ],
      [
        69.58069499999999,
        67.351668
      ],
      [
        69.44777599999999,
        67.298057
      ]
    ],
    "4": [
      [
        68.752609,
        67.396446
      ],
      [
        68.800612,
        67.14914
      ],
      [
        68.753639,
        67.403001
      ]
    ],
    "5": [
      [
        66.728943,
        69.281166
      ],
      [
        66.78630700000001,
        69.156197
      ],
      [
        66.78636100000001,
        69.533974
      ],
      [
        66.72624900000001,
        69.29297
      ]
    ],
    "6": [
      [
        66.647751,
        69.367836
      ],
      [
        66.686607,
        69.294114
      ],
      [
        66.700553,
        69.440613
      ],
      [
        66.643165,
        69.378387
      ]
    ],
    "7": [
      [
        66.534447,
        70.068863
      ],
      [
        66.58721800000001,
        69.63247
      ],
      [
        66.75174500000001,
        69.482247
      ],
      [
        66.53749700000002,
        70.08992
      ]
    ],
    "8": [
      [
        66.693496,
        70.003891
      ],
      [
        66.654029,
        70.17238599999999
      ],
      [
        66.64327999999999,
        69.95297199999999
      ],
      [
        66.72975199999999,
        69.58238999999999
      ],
      [
        66.68905699999999,
        70.00158599999999
      ]
    ],
    "9": [
      [
        66.508003,
        69.815666
      ],
      [
        66.598,
        69.590279
      ],
      [
        66.484498,
        70.01008499999999
      ],
      [
        66.504975,
        69.80899799999999
      ]
    ],
    "10": [
      [
        66.7135,
        70
      ],
      [
        66.768301,
        69.796219
      ],
      [
        66.71344699999999,
        70.088912
      ],
      [
        66.713141,
        70.002197
      ]
    ],
    "11": [
      [
        73.008583,
        70
      ],
      [
        73.386307,
        69.987527
      ],
      [
        73.462447,
        70.409195
      ],
      [
        73.444611,
        71.202277
      ],
      [
        73.376222,
        71.36997
      ],
      [
        73.33905899999999,
        71.42602199999999
      ],
      [
        73.17366899999999,
        71.64835699999999
      ],
      [
        73.01561199999999,
        70.42280199999999
      ],
      [
        73.00503099999999,
        70.01533099999999
      ]
    ],
    "12": [
      [
        66.64225,
        70.439667
      ],
      [
        66.567864,
        71.024329
      ],
      [
        66.486727,
        70.719055
      ],
      [
        66.605393,
        70.303445
      ],
      [
        66.64022100000001,
        70.435861
      ]
    ],
    "13": [
      [
        66.727806,
        71.587303
      ],
      [
        66.63625400000001,
        71.59841800000001
      ],
      [
        66.59686500000001,
        71.23191700000001
      ],
      [
        66.68303,
        70.62833400000001
      ],
      [
        66.64631,
        71.00833000000002
      ],
      [
        66.718331,
        70.73619000000002
      ],
      [
        66.72347300000001,
        71.26838500000002
      ],
      [
        66.73480100000002,
        70.74277300000003
      ],
      [
        66.87794400000001,
        71.48074900000003
      ],
      [
        66.83019200000001,
        71.49622100000003
      ],
      [
        66.72997200000002,
        71.58341700000004
      ]
    ],
    "14": [
      [
        73.025002,
        74.660141
      ],
      [
        72.84324600000001,
        74.598947
      ],
      [
        72.987335,
        74.07414299999999
      ],
      [
        73.112137,
        74.32805699999999
      ],
      [
        73.04138900000001,
        74.89086199999998
      ],
      [
        73.02517,
        74.66750399999998
      ]
    ],
    "15": [
      [
        73.429337,
        75.566666
      ],
      [
        73.40438900000001,
        75.36222099999999
      ],
      [
        73.520081,
        76.088141
      ],
      [
        73.42477500000001,
        75.73975399999999
      ]
    ],
    "16": [
      [
        73.397224,
        76.737274
      ],
      [
        73.49299599999999,
        76.047837
      ],
      [
        73.40194699999999,
        76.746025
      ]
    ],
    "17": [
      [
        73.128723,
        76.402138
      ],
      [
        73.193558,
        76.10997099999999
      ],
      [
        73.13475,
        76.69603099999999
      ],
      [
        73.123971,
        76.44144599999998
      ]
    ],
    "18": [
      [
        72.434364,
        77.227608
      ],
      [
        72.60283700000001,
        77.61422
      ],
      [
        72.46008300000001,
        78.340697
      ],
      [
        72.28524900000001,
        77.801697
      ],
      [
        72.30230800000001,
        76.847581
      ],
      [
        72.422531,
        77.203056
      ]
    ],
    "19": [
      [
        67.458443,
        77.725304
      ],
      [
        67.537719,
        77.86443999999999
      ],
      [
        67.461333,
        77.73557899999999
      ]
    ]
  },
  "Краснодарский край": {
    "0": [
      [
        46.871048,
        38.673985
      ],
      [
        46.821939,
        38.911377
      ],
      [
        46.588037,
        38.860551
      ],
      [
        46.618318,
        39.174165
      ],
      [
        46.770547,
        39.232758000000004
      ],
      [
        46.807771,
        39.433867000000006
      ],
      [
        46.772073000000006,
        40.05311400000001
      ],
      [
        46.723040000000005,
        40.135816000000005
      ],
      [
        46.550822000000004,
        40.041380000000004
      ],
      [
        46.530543,
        40.213049000000005
      ],
      [
        46.34888,
        40.231100000000005
      ],
      [
        46.231662,
        40.756384000000004
      ],
      [
        46.299167,
        40.995534000000006
      ],
      [
        46.133883999999995,
        40.98499100000001
      ],
      [
        45.877483999999995,
        41.266104000000006
      ],
      [
        45.68804599999999,
        41.171369000000006
      ],
      [
        45.697207999999996,
        40.858596000000006
      ],
      [
        45.526652999999996,
        40.838592000000006
      ],
      [
        45.21738199999999,
        41.026908000000006
      ],
      [
        45.248326999999996,
        41.28971800000001
      ],
      [
        44.98888899999999,
        41.46305000000001
      ],
      [
        44.969441999999994,
        41.65775900000001
      ],
      [
        44.69832399999999,
        41.412208000000014
      ],
      [
        44.49221599999999,
        41.72997800000002
      ],
      [
        44.36860599999999,
        41.59443500000002
      ],
      [
        44.24472099999999,
        41.712766000000016
      ],
      [
        43.98777099999999,
        41.43971000000002
      ],
      [
        44.12095699999999,
        40.85557400000002
      ],
      [
        43.98110999999999,
        40.958044000000015
      ],
      [
        43.92083799999999,
        40.771659000000014
      ],
      [
        43.53365099999999,
        40.698596000000016
      ],
      [
        43.56647999999999,
        40.10308400000002
      ],
      [
        43.39884299999999,
        39.94791700000002
      ],
      [
        44.28197999999999,
        38.77986000000002
      ],
      [
        44.41345399999999,
        38.20789000000002
      ],
      [
        44.72300899999999,
        37.83000200000002
      ],
      [
        44.70556499999999,
        37.45758400000002
      ],
      [
        45.001870999999994,
        37.21361300000002
      ],
      [
        45.189201999999995,
        36.59966900000002
      ],
      [
        45.319061,
        36.99586300000002
      ],
      [
        45.299648,
        36.80186300000002
      ],
      [
        45.402338,
        36.763671000000016
      ],
      [
        45.302425,
        36.62472500000002
      ],
      [
        45.442923,
        36.85058500000002
      ],
      [
        45.375896,
        37.53011100000002
      ],
      [
        45.659200999999996,
        37.60497400000002
      ],
      [
        45.747364999999995,
        37.82497500000002
      ],
      [
        46.012668999999995,
        37.948055000000025
      ],
      [
        46.050171,
        38.112556000000026
      ],
      [
        45.997895,
        38.14619400000003
      ],
      [
        46.264172,
        38.28966500000003
      ],
      [
        46.412003,
        37.90425200000003
      ],
      [
        46.700004,
        37.70403000000003
      ],
      [
        46.631087,
        37.97594500000003
      ],
      [
        46.654586,
        38.57652600000003
      ],
      [
        46.721393,
        38.39438900000003
      ],
      [
        46.875059,
        38.49885700000003
      ],
      [
        46.87105,
        38.67398200000003
      ],
      [
        45.007223999999994,
        39.87803700000003
      ],
      [
        44.729164,
        40.02637600000003
      ],
      [
        44.357502,
        39.79165800000003
      ],
      [
        44.235274999999994,
        40.08054800000003
      ],
      [
        44.064446,
        39.96026800000003
      ],
      [
        44.182212,
        39.72721000000003
      ],
      [
        43.942493,
        39.720268000000026
      ],
      [
        43.755554,
        40.30804000000003
      ],
      [
        43.969994,
        40.458319000000024
      ],
      [
        44.292774,
        40.43942800000002
      ],
      [
        44.727493,
        40.38888800000002
      ],
      [
        44.729992,
        40.55054000000002
      ],
      [
        44.471935,
        40.61609900000002
      ],
      [
        44.470543,
        40.75277900000002
      ],
      [
        44.997771,
        40.41943900000002
      ],
      [
        45.191931,
        39.63942900000002
      ],
      [
        44.995273,
        39.30942800000002
      ],
      [
        44.961383,
        38.671379000000016
      ],
      [
        44.792224999999995,
        39.47166100000002
      ],
      [
        44.86888499999999,
        39.63916000000002
      ],
      [
        45.04166399999999,
        39.50083300000002
      ],
      [
        45.01777399999999,
        39.664441000000025
      ],
      [
        44.93943499999999,
        39.629164000000024
      ],
      [
        45.00000399999999,
        39.85629400000003
      ]
    ]
  },
  "Нижегородская область": {
    "0": [
      [
        57.888329,
        47.771103
      ],
      [
        58.023322,
        47.386658
      ],
      [
        58.074737,
        46.341325
      ],
      [
        57.931938,
        45.569993
      ],
      [
        58.091102,
        44.989433999999996
      ],
      [
        58.019157,
        44.802217
      ],
      [
        57.879991,
        44.867767
      ],
      [
        57.706384,
        44.747215
      ],
      [
        57.519442,
        44.315543999999996
      ],
      [
        57.429995999999996,
        43.841376999999994
      ],
      [
        57.563883,
        43.524711999999994
      ],
      [
        57.624162,
        43.47693699999999
      ],
      [
        57.279852,
        43.160653999999994
      ],
      [
        56.862739,
        43.199535999999995
      ],
      [
        56.808046999999995,
        42.832215
      ],
      [
        56.65193599999999,
        42.9011
      ],
      [
        56.46583299999999,
        42.879433999999996
      ],
      [
        56.42027899999999,
        42.588044
      ],
      [
        56.18721699999999,
        42.976937
      ],
      [
        56.13889199999999,
        42.685823
      ],
      [
        56.00417399999999,
        42.713327
      ],
      [
        55.65027799999999,
        42.090547
      ],
      [
        55.442499999999995,
        42.138047
      ],
      [
        55.08056199999999,
        41.792497
      ],
      [
        55.003893999999995,
        41.956939999999996
      ],
      [
        54.94639299999999,
        42.18665299999999
      ],
      [
        55.00556499999999,
        42.473876999999995
      ],
      [
        54.87139599999999,
        42.46690399999999
      ],
      [
        54.78445199999999,
        42.62860099999999
      ],
      [
        54.78334599999999,
        43.07638399999999
      ],
      [
        54.94666699999999,
        43.41971399999999
      ],
      [
        54.53667199999999,
        44.438039999999994
      ],
      [
        54.58555999999999,
        44.62526499999999
      ],
      [
        54.46972299999999,
        44.59832699999999
      ],
      [
        54.48750599999999,
        45.10110399999999
      ],
      [
        54.75278799999999,
        45.053602999999995
      ],
      [
        54.880557999999986,
        45.425824999999996
      ],
      [
        55.08972399999999,
        45.477208999999995
      ],
      [
        55.18055899999999,
        45.726093999999996
      ],
      [
        54.975557999999985,
        45.698041999999994
      ],
      [
        55.015557999999984,
        46.016102999999994
      ],
      [
        55.43888899999998,
        46.382206999999994
      ],
      [
        55.627493999999984,
        45.956936999999996
      ],
      [
        56.049520999999984,
        46.21353499999999
      ],
      [
        56.17138399999998,
        46.086651999999994
      ],
      [
        56.24860899999998,
        45.879155999999995
      ],
      [
        56.43832899999998,
        45.949712
      ],
      [
        56.46388699999998,
        45.651379
      ],
      [
        56.58888599999998,
        45.646101
      ],
      [
        56.650545999999984,
        45.92777
      ],
      [
        56.87276699999998,
        45.957768
      ],
      [
        56.96859899999998,
        46.752773000000005
      ],
      [
        57.199998999999984,
        46.726376
      ],
      [
        57.234436999999986,
        46.851933
      ],
      [
        57.34999899999998,
        46.676099
      ],
      [
        57.52332299999998,
        46.744435
      ],
      [
        57.52860299999998,
        47.471097
      ],
      [
        57.802489999999985,
        47.561101
      ],
      [
        57.881658999999985,
        47.76971
      ]
    ]
  }
};
