import { makeAutoObservable } from 'mobx';

import StatisticService from './StatisticService';
import { Statistic } from './models/Statistic';
import { Category } from './models/Category';
import GetStatisticDto from './dto/GetStatisticDto';
import { Dto } from '../../base/Dto';
import FileExportDto from './dto/FileExportDto';
import FileImportDto from './dto/FileImportDto';

export class StatisticStore {
  categoryLoader: boolean = false;
  statisticMapLoader: boolean = false;
  fileExportLoader: boolean = false;
  fileImportLoader: boolean = false;

  category: Category[] = [];
  statisticMap: Statistic[] = [];
  
  categoryActive: number = 0;
  mapPeriodStart: number = new Date().getTime();
  mapPeriodEnd: number = new Date().getTime();

  private statisticService: StatisticService;

  constructor() {
    makeAutoObservable(this);

    this.statisticService = new StatisticService();
  }

  // FORMS

  // API
  getCategory = async () => {
    if (this.categoryLoader) {
      return;
    }
    this.setLoadingCategory(true);

    try {
      const res = await this.statisticService.getCategory()
      if (res?.length) {
        this.setCategoryAll(res)
        this.setCategoryActive(res?.[0]?.vars?.[0]?.id||0)

        return true;
      }
    } catch (error) {
      console.log("getCategory -> error", error)
    } finally {
      this.setLoadingCategory(false)
    }

    return false
  };

  getStatisticMap = async (data: GetStatisticDto) => {
    if (this.categoryLoader) {
      return;
    }
    this.setLoadingStatisticMap(true);
    const dto = Dto.populate(GetStatisticDto, {
      ...data,
      period_start: this.mapPeriodStart,
      period_end: this.mapPeriodEnd,
      tab: data?.tab||this.categoryActive
    })

    try {
      const res = await this.statisticService.getStatisticMap(dto)
      if (res?.length) {
        this.setCategoryActive(data?.tab||this.categoryActive)
        this.setStatisticMap(res)

        return true;
      }
    } catch (error) {
      console.log("getStatisticMap -> error", error)
    } finally {
      this.setLoadingStatisticMap(false)
    }

    return false
  };

  fileExport = async (data: FileExportDto) => {
    if (this.fileExportLoader) {
      return;
    }

    this.setLoadingFileExport(true)
    const dto = Dto.populate(FileExportDto, data)

    try {
      const res = await this.statisticService.fileExport(dto)
      if (res?.file) {
        return res
      } else {
        return false
      }
    } catch (error) {
      console.log("fileExport -> error", error)
    } finally {
      this.setLoadingFileExport(false)
    }
  }

  fileImport = async (data: any) => {
    if (this.fileImportLoader) {
      return;
    }

    this.setLoadingFileImport(true)
    // const dto = Dto.populate(FileExportDto, data)

    const dto = new FormData();
    Object.keys(data).forEach((el) => {
      if (data?.[el]) {
        dto.append(el, data[el]);
      }
    });

    try {
      console.log("fileImport")
      const res = await this.statisticService.fileImport(dto)
      if (res?.length) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log("fileImport -> error", error)
    } finally {
      this.setLoadingFileImport(false)
    }
  }

  //OTHERS

  // SETTERS

  setLoadingCategory = (value: boolean) => {
    this.categoryLoader = value;
  };

  setLoadingStatisticMap = (value: boolean) => {
    this.statisticMapLoader = value;
  };

  setCategoryAll = (value: Category[]) => {
    this.category = value;
  };

  setStatisticMap = (value: Statistic[]) => {
    this.statisticMap = value;
  };
  
  setCategoryActive = (value: number) => {
    this.categoryActive = value
  }

  setMapPeriodStart = (value: number) => {
    this.mapPeriodStart = value
  }

  setMapPeriodEnd = (value: number) => {
    this.mapPeriodEnd = value
  }

  setLoadingFileExport = (value: boolean) => {
    this.fileExportLoader = value;
  };

  setLoadingFileImport = (value: boolean) => {
    this.fileImportLoader = value;
  };
}
