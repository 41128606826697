import { ConfigProject } from "../../../Helper/ConfigProject"
import { MaskListener } from "../../../Helper/MaskHelper"
import { View } from "../View/View"

export const InputUI = ({
    styleInput = {},
    style = {},
    placeholder = "",
    value = "",
    label = "",
    type = "text",
    name = "",
    onChange = false,
    mask = false,
    Error = ""
}) => {

    return (
        <>
        <View style={type === "textarea" ? {height: '100%', display: "flex", flexDirection: "column", ...style} : {...style}}>
            {
                label ?
                    <label style={{color: "#000"}}>{label}</label>
                : null
            }
            {
                type === "textarea" ?
                    <textarea
                        className="component_InputUI_input"
                        style={{...styleInput, height: "100%"}}
                        placeholder={placeholder}
                        value={value}
                        type={type}
                        onChange={(e) => {
                            if (onChange) {
                                let text = e.target.value
                                text = mask ? MaskListener(text, mask) : text
                                onChange(text, name)
                            }
                        }}
                        onClick={() => {
                            if (onChange) {
                                let text = value
                                text = mask ? MaskListener(text, mask) : text
                                onChange(text, name)
                            }
                        }}
                    />
                :
                <input
                    className="component_InputUI_input"
                    style={{styleInput}}
                    placeholder={placeholder}
                    value={value}
                    type={type}
                    onChange={(e) => {
                        if (onChange) {
                            let text = e.target.value
                            text = mask ? MaskListener(text, mask) : text
                            onChange(text, name)
                        }
                    }}
                    onClick={() => {
                        if (onChange) {
                            let text = value
                            text = mask ? MaskListener(text, mask) : text
                            onChange(text, name)
                        }
                    }}
                />
            }
        </View>
        {
            Error ?
            <View className="component_InputUI_ErrorBlock">
                {Error}
            </View> : null
        }
        </>
    )
}