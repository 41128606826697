import { View } from "../View/View"

export const TouchableHighlight = (props) => {
    const {
        onPress = null,
        style = {}
    } = props

    let styleDefault = {...style, cursor: 'pointer'}

    return (
        <View onClick={onPress} style={styleDefault} {...props}>
            {props.children}
        </View>
    )
}