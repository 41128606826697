import { MapContainer, TileLayer, Marker, Popup, useMap, Polygon, Polyline, LayerGroup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { View } from "../ui/View/View"
import { packRegions } from "../../Helper/Regions";
import { Text } from "../ui/Text/Text";



export const Map = ({data}) => {
    const {latitude, longitude} = {latitude: 66.25, longitude: 94.15}

    const MapView = () => {
        let map = useMap();
        map.setView([latitude, longitude], map.getZoom());
        map.attributionControl.setPrefix('')
        
        return null;
    }

    return (
        <View style={{width: '100%', height: '100%'}}>
            <View style={{backgroundColor: 'rgba(217, 214, 214, 0.698)',borderRadius: 35,padding: 16,flexDirection: 'column',position: 'absolute',zIndex: 999999,left: 16,bottom: 16}}>
                <View style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
                    <View style={{backgroundColor: '#B2ADADCC', width: 38, height: 38, borderRadius: 100}}></View>
                    <View style={{backgroundColor: '#000', width: 5, height: 5, borderRadius: 100, margin: "0 8px"}}></View>
                    <View>
                        <Text style={{color: "#000", fontWeight: '400', fontSize: 12}}>= 0,0 %</Text>
                    </View>
                </View>
                <View style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
                    <View style={{backgroundColor: '#00BE08CC', width: 38, height: 38, borderRadius: 100}}></View>
                    <View style={{backgroundColor: '#000', width: 5, height: 5, borderRadius: 100, margin: "0 8px"}}></View>
                    <View>
                        <Text style={{color: "#000", fontWeight: '400', fontSize: 12}}>{'< 0,0 %'}</Text>
                    </View>
                </View>
                <View style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
                    <View style={{backgroundColor: '#FEB7B7CC', width: 38, height: 38, borderRadius: 100}}></View>
                    <View style={{backgroundColor: '#000', width: 5, height: 5, borderRadius: 100, margin: "0 8px"}}></View>
                    <View>
                        <Text style={{color: "#000", fontWeight: '400', fontSize: 12}}>{'<= 10,0 %'}</Text>
                    </View>
                </View>
                <View style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
                    <View style={{backgroundColor: '#E41414CC', width: 38, height: 38, borderRadius: 100}}></View>
                    <View style={{backgroundColor: '#000', width: 5, height: 5, borderRadius: 100, margin: "0 8px"}}></View>
                    <View>
                        <Text style={{color: "#000", fontWeight: '400', fontSize: 12}}>{'> 10,0 %'}</Text>
                    </View>
                </View>
            </View>
            <MapContainer
                classsName="map"
                center={[latitude, longitude]}
                zoom={3}
                scrollWheelZoom={true}
            >
                <TileLayer
                    attribution='&copy; Neoside'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    data?.map((el, key) => {
                        return (
                            <Polygon
                                // positions={el?.region?.polygon||[]}
                                positions={packRegions?.[el?.region?.name]?.[0]||[]}
                                style={{borderColor: el?.color, color: el?.color, backgroundColor: el?.color}}
                                color={el?.color}
                            >
                                <Popup>
                                    <View style={{marginBottom: 6}}>
                                        <Text style={{textDecoration: "underline", fontWeight: '700'}}>
                                            {el?.region?.name||"null"}
                                        </Text>
                                    </View>
                                    <View>
                                        <Text style={{display: 'block'}}>{el?.value||"0"}</Text>
                                    </View>
                                </Popup>
                            </Polygon>
                        )
                    })
                }
                <MapView />
            </MapContainer>
        </View>
    )
}