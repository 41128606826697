import { modelFactory } from '../../base/ModelFactory';
import AuthApiRepository from './AuthApiRepository';
import LoginDto from './dto/LoginDto';
import RegistrationDto from './dto/RegistrationDto';
import { AuthAccess } from './models/AuthAccess';

export default class AuthService {
  authApi: AuthApiRepository;

  constructor() {
    this.authApi = new AuthApiRepository();
  }

  isAuth = async () => {
    const data = await this.authApi.isAuth();

    return (data as any)?.data as any;
  };
  
  login = async (dto: LoginDto) => {
    const data = await this.authApi.login(dto);

    return (data as any)?.data as AuthAccess;
  };

  registration = async (dto: RegistrationDto) => {
    const data = await this.authApi.registration(dto);

    return (data as any)?.data as AuthAccess;
  };

  logout = async () => {
    const { data } = await this.authApi.logout();
    return data;
  };
}
