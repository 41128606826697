export const MaskListener = (text, mask) => {
    let TextSplit = text.split("");
    let MaskSplit = mask.split("");
    
    if (MaskSplit[text.length-1] !== "_") {
        if (MaskSplit[text.length] === "_" && TextSplit[text.length-1] !== MaskSplit[text.length-1]) {
            TextSplit[text.length]= TextSplit[text.length-1]
        }
        TextSplit[text.length-1] = MaskSplit[text.length-1]
    }

    return TextSplit.join("")
}