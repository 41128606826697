import React from "react"
import { View } from "../Components/ui/View/View"
import { AppLayout } from "../Components/Header/AppLayout"
import { useRootStore } from "../core/hooks/useRootStore";
import { ProfileAdminScreen } from "../Screens/ProfileAdminScreen";
import { ProfileWatcherScreen } from "../Screens/ProfileWatcherScreen";
import { ProfileRegionScreen } from "../Screens/ProfileRegionScreen";

export const ProfileRoute = () => {
    const { userStore } = useRootStore();
    let title = ""
    let component = <></>

    switch (userStore.user?.type_account) {
        case 1:
            title = "Администратор"
            component = <ProfileAdminScreen />
            break;
        case 2:
            title = "Наблюдатель (Министр)"
            component = <ProfileWatcherScreen />
            break;
    
        default:
            title = userStore.user?.regions?.[0]?.name
            component = <ProfileRegionScreen />
            break;
    }

    return (
        <AppLayout
            title={title}
            tabMenuUsers={userStore.user?.type_account === 1}
        >
            <View className="container" style={{padding: '16px 0'}}>
                {component}
            </View>
        </AppLayout>
    )
}