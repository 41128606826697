import AbstractGraphqlRepository from '../../../../../base/api/graphql/AbstractGraphqlRepository';

export default class TokenGraphqlApiRepository extends AbstractGraphqlRepository {
  setAccessToken = (token: string) => {
    this.apiClient.setAccessToken(token);
  };

  clearAccessToken = () => {
    this.apiClient.clearAccessToken();
  };
}
