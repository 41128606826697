import AbstractApiRepository from '../../base/api/AbstractApiRepository';

export default class RegionApiRepository extends AbstractApiRepository {

  getRegionAll = () => {
    return this.apiClient.get({
      url: `/region/list`,
    });
  };
}
