import { useState } from "react"

export const TableUI = (props) => {

    return (
        <table>
            <thead>
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Region</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    props?.data ?
                        props.data.map((el, key) => {
                            return (
                                <tr key={`alluser-${key}`}>
                                    <th scope="row">{el?.email}</th>
                                    <td>{el?.regions?.[0]?.name}</td>
                                    <td></td>
                                </tr>
                            )
                        })
                    : null
                }
            </tbody>
        </table>
    )
}