import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  HeadersDefaults,
  // InternalAxiosRequestConfig
} from 'axios';

import { appConfig } from '../../../appConfig';
import Log from '../../log/Log';
import Notification from '../../ui/Notification';
import IApiClient from '../IApiClient';
import { IAxiosConfig } from './IAxiosInterfaces';

export default class AxiosClient implements IApiClient {
  static readonly SUCCESS_STATUSES = [200, 201];
  static readonly SERVER_ERROR = 500;
  static readonly VERSION_NOT_VALID = 426;
  static readonly BAD_REQUEST = 400;
  static readonly UN_AUTH = 401;

  api: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    this.api = axios.create(config);
    this.api.defaults.baseURL = this.getDefaultBaseUrl();
    this.api.defaults.headers.common['Content-Type'] = 'application/json';
    this.setDeviceId();

    this.setInterceptorRequest();
    this.setInterceptorResponse();
  }

  getDefaultBaseUrl = () => {
    return appConfig.apiUrl.find(item => item.default)?.baseUrl;
  };

  setDeviceId = async () => {
    
  };

  setBaseUrl = (value: string) => {
    this.api.defaults.baseURL = value;
  };

  setAccessToken = (token: string) => {
    this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`
  };

  setLanguage = (language: any) => {
    
  };

  clearAccessToken = () => {
    // this.api.defaults.headers['Authorization'] = null;
  };

  get = <T extends {}>(config: IAxiosConfig) => {
    if (config?.data) {
      config.url += "?"+ Object.entries(config?.data).map(e => e.join('=')).join('&')
    }
    return this.api.get<T>(config.url, config.config);
  };

  post = <T extends {}>(config: IAxiosConfig) => {
    return this.api.post<T>(config.url, config.data, config.config);
  };

  put = <T extends {}>(config: IAxiosConfig) => {
    return this.api.put<T>(config.url, config.data, config.config);
  };

  delete = <T extends {}>(config: IAxiosConfig) => {
    return this.api.delete<T>(config.url, config.config);
  };

  protected getApiErrors = (error: any) => {
    if (error) {
      Notification.showError(error || 'Unknown error');
    }
  };

  private setInterceptorRequest = () => {
    this.api.interceptors.request.use(
      async config => {
        const newConfig = {
          ...config,
          headers: {
            ...config.headers,
          } as AxiosRequestHeaders,
        };

        return newConfig;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      },
    );
  };

  private setInterceptorResponse = () => {
    this.api.interceptors.response.use(
      response => {
        Log.res(response);

        if (!AxiosClient.SUCCESS_STATUSES.includes(response.status)) {
          Notification.showError(response.data?.error || 'Unknown error');

          return Promise.reject(response);
        }

        if (response?.data?.message) {
          Notification.showSuccess(response?.data?.message);
        }

        if (response?.data?.errorInput) {
          return Promise.reject(response?.data);
        }

        return response?.data;
      },
      error => {
        // global showing error messages
        Log.resE(error?.response);

        this.getApiErrors(error?.response?.data?.error || '');

        if (error.response?.status) {
          switch (error.response?.status) {
            case AxiosClient.VERSION_NOT_VALID:
              Notification.showError('Application version error');
              break;

            case AxiosClient.BAD_REQUEST:
              Notification.showError('Error: ' + error.response?.data?.error || '');
              break;

            case AxiosClient.SERVER_ERROR:
              Notification.showError('Server error');
              break;

            case AxiosClient.UN_AUTH: {
              if ((this.api.defaults.headers as any)['Authorization']) {
                // call logout here
                return;
              }
            }
          }
        }

        return Promise.reject(error);
      },
    );
  };
}
