// import { showMessage } from 'react-native-flash-message';

export default class Notification {
  static showSuccess = (successMessage: string) => {
    console.log("showSuccess", successMessage)
    // showMessage({
    //   message: successMessage,
    //   type: 'success',
    //   // type: 'default', // FOR custom colors
    //   // backgroundColor: colors.success, // FOR custom colors
    //   icon: 'success',
    // });
  };

  static showError = (errorMessage: string) => {
    console.log("showError", errorMessage)
    // showMessage({
    //   message: errorMessage,
    //   type: 'danger',
    //   // type: 'default', // FOR custom colors
    //   // backgroundColor: colors.error, // FOR custom colors
    //   icon: 'danger',
    // });
  };
}
