import GetUserDto from './dto/GetUserDto';
import { Region } from './models/Region';
import RegionApiRepository from './RegionApiRepository';

export default class RegionService {
  apiRepository: RegionApiRepository;

  constructor() {
    this.apiRepository = new RegionApiRepository();
  }
  getRegionAll = async () => {
    const data = await this.apiRepository.getRegionAll();
    
    return (data as any)?.data as [Region];
  };
}
