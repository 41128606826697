import AbstractApiRepository from '../AbstractApiRepository';
import { ApiClientTypes } from '../ApiClientTypes';
import GraphqlClient from './GraphqlClient';

export default abstract class AbstractGraphqlRepository extends AbstractApiRepository {
  // @ts-ignore
  apiClient!: GraphqlClient;

  constructor() {
    super(ApiClientTypes.graphql);
  }
}
