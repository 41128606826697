import React, { useEffect } from "react"
import { View } from "../Components/ui/View/View"
import { useRootStore } from "../core/hooks/useRootStore"
import {DotLoader} from "react-spinners";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import DateHelper from "../core/helpers/DateHelper";

export const StartRoute = observer(() => {
    const navigate = useNavigate();

    const {
        authStore,
        userStore,
        statisticStore
    } = useRootStore();


    useEffect(() => {
        init()
    }, [])

    const getDateMap = () => {
        var curr = new Date;
        var first = curr.getDate() - curr.getDay() + 1;

        var firstday = new Date(curr.setDate(first))
        var lastday = new Date(curr.setDate(firstday.getDate()+6))

        return {
            monday: firstday,
            sunday: lastday
        }
    }

    const init = async () => {
        const checkAuth = await authStore.checkIsAuth()
        if (!authStore.isAuth) {
            navigate("/login");
            return;
        }

        statisticStore.setMapPeriodStart(DateHelper.getDatePickerDefault().monday.getTime())
        statisticStore.setMapPeriodEnd(DateHelper.getDatePickerDefault().sunday.getTime())

        await statisticStore.getCategory()
        await statisticStore.getStatisticMap({
            tab: statisticStore.categoryActive
        })

        await userStore.getUser(checkAuth?.id)

        if (userStore.user?.id) {
            navigate("/map");
            return;
        }
    }

    return (
        <View className='container containerCenter'>
            <DotLoader
                color={"rgb(200, 155, 155)"}
                loading={true}
                cssOverride={override}
                size={100}
                aria-label="Loading..."
                data-testid="loader"
                speedMultiplier={1}
            />
        </View>
    )
})

const override = {
    display: "block",
    margin: "0 auto",
  };