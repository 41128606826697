import { Text } from "../ui/Text/Text"
import { View } from "../ui/View/View"

export const Footer = () => {
    return (
        <footer>
            <View className="containerFooterCopy">
                <Text className="containerFooterCopyText">2024 © Neoside</Text>
            </View>
        </footer>
    )
}