import 'reflect-metadata';
import './assets/css/App.css';
import './assets/css/Fonts.css';
import './assets/css/AppStyle.css'
import './assets/css/ComponentStyle.css'
import './assets/css/Maps.css'
import 'regenerator-runtime'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { MapRoute } from './Routes/MapRoute';
import { observer } from 'mobx-react-lite';
import { StartRoute } from './Routes/StartRoute';
import { LoginRoute } from './Routes/LoginRoute';
import { useRootStore } from './core/hooks/useRootStore';
import { ProfileRoute } from './Routes/ProfileRoute';
import { UsersRoute } from './Routes/UsersRoute';

const App = observer(() => {
  const {
    authStore,
  } = useRootStore();
  
  return (
    <Router>
      <Routes>
        <Route path={'/'} exact element={<StartRoute />} />
        {
          authStore.isAuth ?
          <>
          <Route path={'/map'} element={<MapRoute />} />
          <Route path={'/profile'} element={<ProfileRoute />} />
          <Route path={'/users'} element={<UsersRoute />} />
          </>
          :
          <Route path={'/login'} element={<LoginRoute />} />
        }
      </Routes>
    </Router>
  );
})

export default App;