import { useNavigate } from "react-router-dom"
import { ButtonUI } from "../ui/Buttons/ButtonUI"
import { Text } from "../ui/Text/Text"
import { View } from "../ui/View/View"
import { Footer } from "./Footer"
import { MenuHeader } from "./MenuHeader"
import { useRootStore } from "../../core/hooks/useRootStore"



export const AppLayout = (props) => {
    const {authStore} = useRootStore()
    const navigate = useNavigate()

    const handleMap = () => {
        navigate("/map");
    }

    const handleExit = async () => {
        try {
            await authStore.logout()
            return true
        } catch (error) {
            return false
        } finally {
            navigate("/");
        }
    }

    const handleUsers = () => {
        navigate("/users");
    }

    const handleProfile = () => {
        navigate("/profile");
    }

    return (
        <View>
            <View className="header">
                <View style={{flex: 1}}></View>
                <View style={{flex: 1, margin: '0px 16px', textAlign: 'center'}}>
                    <Text style={{fontSize: 27, fontWeight: '700'}}>{props?.title||""}</Text>
                </View>
                <View style={{flex: 1, display: 'flex', justifyContent: 'end'}}>
                    {
                        props?.tabMenuUsers ?
                        <ButtonUI
                            text={"Пользователи"}
                            onHandle={handleUsers}
                            style={{marginRight: 16, backgroundColor: '#087990', borderColor: "#087990"}}
                        />
                        : null
                    }
                    {
                        props?.tabMenuProfile ?
                        <ButtonUI
                            text={"Профиль"}
                            onHandle={handleProfile}
                            style={{marginRight: 16}}
                        />
                        : null
                    }
                    <ButtonUI
                        text={"Карта"}
                        onHandle={handleMap}
                        style={{marginRight: 16, backgroundColor: '#198754', borderColor: "#198754"}}
                    />

                    <ButtonUI
                        text={"Выйти"}
                        onHandle={handleExit}
                        style={{backgroundColor: 'rgb(220, 53, 69)', borderColor: 'rgb(220, 53, 69)'}}
                    />
                </View>
            </View>
            <View className="containerContentBody">
                {props.children}
            </View>
            <View>
                <Footer />
            </View>
        </View>
    )
}