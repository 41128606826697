import TokenApiRepository from './repositories/TokenApiRepository';
import TokenGraphqlApiRepository from './repositories/TokenGraphqlApiRepository';
import TokenLocalRepository from './repositories/TokenLocalRepository';

export default class TokenService {
  tokenLocal: TokenLocalRepository;
  tokenApi: TokenApiRepository;
  tokenGraphqlApi: TokenGraphqlApiRepository;

  constructor() {
    this.tokenLocal = new TokenLocalRepository();
    this.tokenApi = new TokenApiRepository();
    this.tokenGraphqlApi = new TokenGraphqlApiRepository();
  }

  saveToken = (token: string) => {
    this.tokenApi.setAccessToken(token);
    // this.tokenGraphqlApi.setAccessToken(token);
    return this.tokenLocal.set(token);
  };

  deleteToken = () => {
    this.tokenApi.clearAccessToken();
    this.tokenGraphqlApi.clearAccessToken();
    return this.tokenLocal.removeAll();
  };

  getToken = async () => {
    const token = await this.tokenLocal.get();
    this.tokenApi.setAccessToken(token);
    this.tokenGraphqlApi.setAccessToken(token);

    return token;
  };
}
