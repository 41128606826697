
import { ApiUrlType } from './modules/apiUrl/types/ApiUrl';

export const appConfig = {
  isTest: false,
  apiUrl: [
    {
      type: ApiUrlType.dev,
      default: false,
      label: 'DEV: url',
      // baseUrl: 'http://127.0.0.1:8000/api/v1',
      baseUrl: 'https://epidmapapi.innovasystem.pro/api/v1',
      wsUrl: 'wss://epidmapapi.innovasystem.pro',
    },
    {
      type: ApiUrlType.prod,
      default: true,
      label: 'PROD: url',
      // baseUrl: 'http://127.0.0.1:8000/api/v1',
      baseUrl: 'https://epidmapapi.innovasystem.pro/api/v1',
      wsUrl: 'wss://epidmapapi.innovasystem.pro',
    },
  ],

  settings: {
    logEnabled: true,
  },
};
