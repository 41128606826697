import { IErrorFromParam, Nullable } from '../base/types/BaseTypes';

export default class FormHelper {
  static updateForm = <T>(form: T, key: any, value: any) => {
    return { ...form, [key]: value };
  };

  /**
   * @see working example https://gitlab.netimob.com/interstone/mobile-app/-/blob/dev/src/modules/contact/ContactStore.ts
   */
  static updateErrorForm = <T>(form: T, errors: Nullable<IErrorFromParam>) => {
    if (errors) {
      Object.keys(errors).forEach(key => {
        form = FormHelper.updateForm(form, key, errors[key]?.[0]);
      });
    }

    return form;
  };
}
