import { jsonProperty, Serializable } from 'ts-serializable';

import { Nullable } from '../../../base/types/BaseTypes';

export default class RegistrationDto extends Serializable {
  @jsonProperty(String, null) email: Nullable<string> = null;
  @jsonProperty(String, null) password: Nullable<string> = null;
  @jsonProperty(Number, null) type_account: Nullable<number> = null;
  @jsonProperty(Number, null) region: Nullable<number> = null;
}
