import GetUserDto from './dto/GetUserDto';
import { User } from './models/User';
import UserApiRepository from './UserApiRepository';

export default class UserService {
  apiRepository: UserApiRepository;

  constructor() {
    this.apiRepository = new UserApiRepository();
  }

  getUser = async (dto: GetUserDto) => {
    const data = await this.apiRepository.getUser(dto);
    
    return (data as any)?.data as User;
  };

  getUserAll = async () => {
    const data = await this.apiRepository.getUserAll();
    
    return (data as any)?.data as [User];
  };
}
