import { makeAutoObservable } from 'mobx';

import { resetStores } from '../../RootStore';
import { Dto } from '../../base/Dto';
import { Nullable } from '../../base/types/BaseTypes';
import FormHelper from '../../helpers/FormHelper';
import AuthService from './AuthService';
import LoginDto from './dto/LoginDto';
import { LoginForm, LoginFormFields } from './forms/LoginForm';
import TokenService from './modules/token/TokenService';
import { RegistrationForm, RegistrationFormFormFields } from './forms/RegistrationForm';
import RegistrationDto from './dto/RegistrationDto';

export class AuthStore {
  loader: boolean = false;
  loaderRegistration: boolean = false;
  accessToken: Nullable<string> = null;
  isAuth: boolean = false;

  loginForm = LoginForm;
  registrationForm = RegistrationForm;

  private authService: AuthService;
  private tokenService: TokenService;

  constructor() {
    makeAutoObservable(this);
    this.authService = new AuthService();
    this.tokenService = new TokenService();
  }

  // FORMS

  changeLoginForm = (key: LoginFormFields, value: string) => {
    this.loginForm = FormHelper.updateForm(this.loginForm, key, value);
  };

  changeRegistrationForm = (key: RegistrationFormFormFields, value: string) => {
    this.registrationForm = FormHelper.updateForm(this.registrationForm, key, value);
  };

  resetLoginForm = () => {
    this.loginForm = LoginForm;
  };

  resetRegistrationForm = () => {
    this.registrationForm = RegistrationForm;
  };

  // API

  login = async () => {
    if (this.loader) {
      return;
    }
    this.setLoading(true);
    this.setIsAuth(true)

    const dto = Dto.populate(LoginDto, this.loginForm)

    try {
      const res = await this.authService.login(dto) as any
      if (res?.token) {
        this.setCookie("jwt", res?.token, {
          expires: res?.expires||60,
          "max-age": res?.expires||60
        })

        await this.saveToken(res.token)

        return true;
      }
    } catch (error) {
      console.log("login -> error", error)
    } finally {
      this.setLoading(false)
      this.setIsAuth(false)
    }

    return false
  };

  registration = async () => {
    if (this.loaderRegistration) {
      return;
    }
    this.setLoadingRegistration(true);

    const dto = Dto.populate(RegistrationDto, this.registrationForm)

    try {
      const res = await this.authService.registration(dto) as any
      if (res?.token) {
        return true;
      }
    } catch (error) {
      console.log("registration -> error", error)
    } finally {
      this.setLoadingRegistration(false)
    }

    return false
  };

  logout = async () => {
    this.deleteCookie('jwt')
    this.setIsAuth(false)
    resetStores();
  };

  // OTHERS

  checkIsAuth = async () => {
    if (this.isAuth) {
      return;
    }

    this.setIsAuth(false)

    try {
      const getCookie = this.getCookie('jwt')
      await this.saveToken(getCookie||"")
      
      const res = await this.authService.isAuth() as any
      if (res?.id) {
        this.setIsAuth(true)
        return res
      } else {
        this.setIsAuth(false)
      }
    } catch (error) {
      this.setIsAuth(false)
      console.log("checkIsAuth -> error", error)
    } finally {
      this.setLoading(false)
    }

    return false
  };

  saveToken = async (token: string) => {
    await this.tokenService.saveToken(token);
    this.setAccessToken(token);
  };

  // SETTERS

  setLoading = (value: boolean) => {
    this.loader = value;
  };

  setLoadingRegistration = (value: boolean) => {
    this.loaderRegistration = value;
  };

  setIsAuth = (value: boolean) => {
    this.isAuth = value
  }

  private setAccessToken = (value: Nullable<string>) => {
    this.accessToken = value;
  };

  private getCookie = (name: any) => {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));

    return matches ? decodeURIComponent(matches[1]) : false;
  }

  private setCookie = (name: any, value: any, options: any = {}) => {
    options = {
      path: '/',
      ...options
    };
  
    if (options?.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
  }

  private deleteCookie = (name: any) => {
    this.setCookie(name, "", {
      'max-age': -1
    })
  }
}
