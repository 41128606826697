export default class DateHelper {
  static getDatePickerDefault = () => {
    var curr = new Date;
    var first = curr.getDate() - curr.getDay() + 1;

    var firstday = new Date(curr.setDate(first))
    var lastday = new Date(curr.setDate(firstday.getDate()+6))

    return {
        monday: firstday,
        sunday: lastday
    }
  };
}
