import { makeAutoObservable } from 'mobx';

import { Nullable } from '../../base/types/BaseTypes';
import UserService from './UserService';
import GetUserDto from './dto/GetUserDto';
import { User } from './models/User';

export class UserStore {
  userLoader: boolean = false;
  userAllLoader: boolean = false;

  user: Nullable<User> = null;
  userAll: User[] = [];

  private userService: UserService;

  constructor() {
    makeAutoObservable(this);

    this.userService = new UserService();
  }

  // FORMS

  // API

  getUser = async (id: number) => {
    if (this.userLoader) {
      return;
    }
    this.setLoading(true);

    try {
      const res = await this.userService.getUser({id} as GetUserDto)
      if (res?.id) {
        this.setUser(res)

        return true;
      }
    } catch (error) {
      console.log("getUser -> error", error)
    } finally {
      this.setLoading(false)
    }

    return false
  };

  getUserAll = async () => {
    if (this.userAllLoader) {
      return;
    }
    this.setLoadingUserAll(true);

    try {
      const res = await this.userService.getUserAll()
      if (res?.length) {
        this.setUserAll(res)

        return true;
      }
    } catch (error) {
      console.log("getUserAll -> error", error)
    } finally {
      this.setLoadingUserAll(false)
    }

    return false
  };

  //OTHERS

  // SETTERS

  setLoading = (value: boolean) => {
    this.userLoader = value;
  };
  setLoadingUserAll = (value: boolean) => {
    this.userAllLoader = value;
  };

  setUser = (value: Nullable<User>) => {
    this.user = value;
  };

  setUserAll = (value: User[]) => {
    this.userAll = value;
  };
}
