import FileExportDto from './dto/FileExportDto';
import FileImportDto from './dto/FileImportDto';
import GetStatisticDto from './dto/GetStatisticDto';
import { Category } from './models/Category';
import { Statistic } from './models/Statistic';

import StatisticApiRepository from './StatisticApiRepository';

export default class StatisticService {
  apiRepository: StatisticApiRepository;

  constructor() {
    this.apiRepository = new StatisticApiRepository();
  }

  getCategory = async () => {
    const data = await this.apiRepository.getCategory();
    
    return (data as any)?.data as [Category];
  };

  getStatisticMap = async (dto: GetStatisticDto) => {
    const data = await this.apiRepository.getStatisticMap(dto)
    
    return (data as any)?.data as [Statistic];
  };

  fileExport = async (dto: FileExportDto) => {
    const data = await this.apiRepository.fileExport(dto)
    
    return (data as any) as any;
  };

  fileImport = async (dto: any) => {
    const data = await this.apiRepository.fileImport(dto)
    
    return (data as any) as string;
  };
}
