import AbstractApiRepository from '../../base/api/AbstractApiRepository';
import GetUserDto from './dto/GetUserDto';

export default class UserApiRepository extends AbstractApiRepository {
  getUser = (data: GetUserDto) => {
    return this.apiClient.get({
      url: `/user/${data?.id}`,
    });
  };

  getUserAll = () => {
    return this.apiClient.get({
      url: `/user/all`,
    });
  };
}
